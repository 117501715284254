import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@store'
import { useSnackbar } from '@phaidra/ava/snackbar'
import { closeSnackbar } from '@store/snackbar'

const SnackbarManager: React.FC = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const messages = useSelector((state: RootState) => state.snackbars.messages)

  useEffect(() => {
    messages.forEach(({ message, options }) => {
      enqueueSnackbar(message, { preventDuplicate: true, ...options })
      dispatch(closeSnackbar())
    })
  }, [messages, enqueueSnackbar, dispatch])

  return null
}

export default SnackbarManager
