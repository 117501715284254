import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'
import { RawData, Organisation } from '@phaidra/user-portal/src/services/request-tags'

import {
  View,
  Views,
  Tags,
  ChartResponse,
  ChartParams,
  ControlStates,
  ControlStateParams,
  PostViewsParams,
  TagMetaData,
  ChartStatsResponse,
  ClientPoints,
  UnitDimensionality,
} from './types'

export const trendViewerApi = createApi({
  reducerPath: 'trendViewerApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api`,
  }),
  endpoints: (builder) => ({
    getViews: builder.query<Views['data'], { installationId: string }>({
      transformResponse: (response: Views) => ({
        ...response.data,
        unsaved: {
          id: -1,
          title: 'Untitled',
          tags: [[], [], []],
          constants: [[], [], []],
          client_point_metadata: {},
        },
        saved: response.data.saved.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
        component: response.data.component.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
      }),
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/trend/views`,
        method: 'get',
      }),
      keepUnusedDataFor: 0,
    }),
    getTags: builder.query<Tags['data'], { installationId: string }>({
      transformResponse: (response: Tags) => {
        const addParentToChildren = (id: string) => {
          const node = response.data.nodes[id]
          if (node.type === 'component') {
            node.children.forEach((childId) => {
              const childNode = response.data.nodes[childId]
              if (childNode?.type === 'component') {
                childNode.component = id
                addParentToChildren(childId)
              }
            })
          }
        }
        addParentToChildren(response.data.root)
        return response.data
      },
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/trend/views/tags`,
        method: 'get',
      }),
      keepUnusedDataFor: 0,
    }),
    getChart1: builder.query<ChartResponse, ChartParams>({
      keepUnusedDataFor: 0,
      transformResponse: (response: ChartResponse) => ({
        data: response.data,
        constants: response.constants,
      }),
      query: ({
        installationId, startTime, endTime, tagIds, ...params
      }) => {
        const tagParams = tagIds.reduce((acc, id) => {
          if (typeof id === 'number') {
            return `${acc}&tagId=${id}`
          } if (typeof id === 'string') {
            return `${acc}&clientPointName=${id}`
          }
          return acc
        }, '')

        return {
          url: `/v1/installations/${installationId}/trend/charts?installationId=${installationId}&startTime=${
            startTime}&endTime=${endTime}${tagParams}`,
          method: 'get',
          params,
        }
      },
    }),
    getChart2: builder.query<ChartResponse, ChartParams>({
      keepUnusedDataFor: 0,
      transformResponse: (response: ChartResponse) => ({
        data: response.data,
        constants: response.constants,
      }),
      query: ({
        installationId, startTime, endTime, tagIds, ...params
      }) => {
        const tagParams = tagIds.reduce((acc, id) => {
          if (typeof id === 'number') {
            return `${acc}&tagId=${id}`
          } if (typeof id === 'string') {
            return `${acc}&clientPointName=${id}`
          }
          return acc
        }, '')

        return {
          url: `/v1/installations/${installationId}/trend/charts?installationId=${installationId}&startTime=${
            startTime}&endTime=${endTime}${tagParams}`,
          method: 'get',
          params,
        }
      },
    }),
    getChart3: builder.query<ChartResponse, ChartParams>({
      keepUnusedDataFor: 0,
      transformResponse: (response: ChartResponse) => ({
        data: response.data,
        constants: response.constants,
      }),
      query: ({
        installationId, startTime, endTime, tagIds, ...params
      }) => {
        const tagParams = tagIds.reduce((acc, id) => {
          if (typeof id === 'number') {
            return `${acc}&tagId=${id}`
          } if (typeof id === 'string') {
            return `${acc}&clientPointName=${id}`
          }
          return acc
        }, '')

        return {
          url: `/v1/installations/${installationId}/trend/charts?installationId=${installationId}&startTime=${
            startTime}&endTime=${endTime}${tagParams}`,
          method: 'get',
          params,
        }
      },
    }),
    getChart1Statistics: builder.query<ChartStatsResponse, ChartParams>({
      keepUnusedDataFor: 0,
      transformResponse: (response: ChartStatsResponse) => ({
        data: response.data,
      }),
      query: ({
        installationId, startTime, endTime, tagIds, ...params
      }) => {
        const tagParams = tagIds.reduce((acc, id) => {
          if (typeof id === 'number') {
            return `${acc}&tagId=${id}`
          } if (typeof id === 'string') {
            return `${acc}&clientPointName=${id}`
          }
          return acc
        }, '')

        return {
          url: `/v1/installations/${installationId}/trend/charts/stats?installationId=${installationId}&startTime=${
            startTime}&endTime=${endTime}${tagParams}`,
          method: 'get',
          params,
        }
      },
    }),
    getChart2Statistics: builder.query<ChartStatsResponse, ChartParams>({
      keepUnusedDataFor: 0,
      transformResponse: (response: ChartStatsResponse) => ({
        data: response.data,
      }),
      query: ({
        installationId, startTime, endTime, tagIds, ...params
      }) => {
        const tagParams = tagIds.reduce((acc, id) => {
          if (typeof id === 'number') {
            return `${acc}&tagId=${id}`
          } if (typeof id === 'string') {
            return `${acc}&clientPointName=${id}`
          }
          return acc
        }, '')

        return {
          url: `/v1/installations/${installationId}/trend/charts/stats?installationId=${installationId}&startTime=${
            startTime}&endTime=${endTime}${tagParams}`,
          method: 'get',
          params,
        }
      },
    }),
    getChart3Statistics: builder.query<ChartStatsResponse, ChartParams>({
      keepUnusedDataFor: 0,
      transformResponse: (response: ChartStatsResponse) => ({
        data: response.data,
      }),
      query: ({
        installationId, startTime, endTime, tagIds, ...params
      }) => {
        const tagParams = tagIds.reduce((acc, id) => {
          if (typeof id === 'number') {
            return `${acc}&tagId=${id}`
          } if (typeof id === 'string') {
            return `${acc}&clientPointName=${id}`
          }
          return acc
        }, '')

        return {
          url: `/v1/installations/${installationId}/trend/charts/stats?installationId=${installationId}&startTime=${
            startTime}&endTime=${endTime}${tagParams}`,
          method: 'get',
          params,
        }
      },
    }),
    postViews: builder.query<{ data: View }, PostViewsParams>({
      query: ({ installationId, ...data }) => ({
        url: `/v1/installations/${installationId}/trend/views`,
        method: 'post',
        data,
      }),
    }),
    deleteView: builder.query<void, { installationId: string, viewId: number }>({
      query: ({ installationId, viewId }) => ({
        url: `/v1/installations/${installationId}/trend/views/${viewId}`,
        method: 'delete',
      }),
      onQueryStarted: (args, api) => {
        api.queryFulfilled.then(() => {
          setTimeout(() => {
            api.dispatch(
              trendViewerApi.util.updateQueryData(
                'getViews',
                { installationId: args.installationId },
                (draft) => ({
                  ...draft,
                  saved: draft.saved.filter(({ id }) => id !== args.viewId),
                  components: draft.component.filter(({ id }) => id !== args.viewId),
                }),
              ),
            )
          }, 0)
        }).catch(() => {})
      },
    }),
    renameView: builder.query<void, { installationId: string, viewId: number, title: string }>({
      query: ({ installationId, viewId, title }) => ({
        url: `/v1/installations/${installationId}/trend/views/${viewId}/rename`,
        method: 'patch',
        data: { title },
      }),
    }),
    getControlStates: builder.query<ControlStates['data'], ControlStateParams>({
      transformResponse: (response: ControlStates) => response.data,
      query: ({ installationId, ...params }) => ({
        url: `/v1/installations/${installationId}/trend/control-state`,
        method: 'get',
        params,
      }),
    }),
    getTagMetadata: builder.query<TagMetaData['data'], {
      installationId: string
      tagId: string | number
      metadataValidationV2: boolean
    }>({
      transformResponse: (response: TagMetaData) => response.data,
      query: ({ installationId, tagId, metadataValidationV2 }) => ({
        url: `/${metadataValidationV2 ? 'v2' : 'v1'}/installations/${installationId}/tag/${tagId}/metadata`,
        method: 'get',
      }),
      keepUnusedDataFor: 0,
    }),
    getClientPoints: builder.query<Record<string, string>, { installationId: string }>({
      transformResponse: (response: ClientPoints) => response.data.client_points,
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/trend/client-points`,
        method: 'get',
        meta: {
          tag: RawData.GetClientPoints,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getUnitDimensionalityRawData: builder.query<UnitDimensionality['data'], void>({
      transformResponse: (response: UnitDimensionality) => response.data,
      query: () => ({
        url: '/v1/organisation/unit-dimensionality',
        method: 'get',
        meta: {
          tag: Organisation.GetUnitDimensionality,
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useGetViewsQuery,
  useLazyGetViewsQuery,
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useLazyGetChart1Query,
  useLazyGetChart2Query,
  useLazyGetChart3Query,
  useLazyGetChart1StatisticsQuery,
  useLazyGetChart2StatisticsQuery,
  useLazyGetChart3StatisticsQuery,
  usePostViewsQuery,
  useLazyPostViewsQuery,
  useDeleteViewQuery,
  useLazyDeleteViewQuery,
  useRenameViewQuery,
  useLazyRenameViewQuery,
  useGetControlStatesQuery,
  useLazyGetTagMetadataQuery,
  useLazyGetControlStatesQuery,
  useGetClientPointsQuery,
  useGetUnitDimensionalityRawDataQuery,
  util: { updateQueryData, upsertQueryData },
  endpoints: {
    getChart1: { useQueryState: useGetChartQuery1State },
    getChart2: { useQueryState: useGetChartQuery2State },
    getChart3: { useQueryState: useGetChartQuery3State },
    getChart1Statistics: { useQueryState: useGetChartQuery1StatisticsState },
    getChart2Statistics: { useQueryState: useGetChartQuery2StatisticsState },
    getChart3Statistics: { useQueryState: useGetChartQuery3StatisticsState },
  },
} = trendViewerApi
