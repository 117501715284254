import styled from '../../../../styled'

export const Overlay = styled('div', {})<{
  x: number
  y: number
  allowed: boolean
  width: number
  height: number
}>(({
  x,
  y,
  allowed,
  width,
  height,
}) => ({
  cursor: allowed ? 'pointer' : 'not-allowed',
  position: 'absolute',
  top: `${y}px`,
  left: `${x}px`,
  width: `${width}px`,
  height: `${height}px`,
  zIndex: 1,
}))
