import React, {
  FC, useRef,
} from 'react'

import { useAppSelector, useAppDispatch } from '@store'
import { setSearchTerm } from '@store/tag-management'
import { SearchIcon, CloseIcon } from '@phaidra/ava/icons'
import IconButton from '@phaidra/ava/icon-button'
import { useSnackbar } from '@phaidra/ava/snackbar'
import { useAutocomplete } from '@phaidra/ava/autocomplete'
import { PointName } from '@services/tag-management/types'
import TagsList from '../tags-list'
import {
  NoOptionsContainer, Container, Input, Listbox,
} from '../styles'
import { Props as ListItemProps } from '../list-item'

export type Props = {
  installationId: string
  onChange: (value: string) => void
  placeholder?: string
  size?: 'small' | 'medium' | 'large'
  options: PointName[]
  handleOnCopy?: (value: string) => void
  pointNamesUsedInMapping: ListItemProps['pointNamesUsedInMapping']
}

const ListSearch: FC<Props> = ({
  installationId, onChange, placeholder, size = 'large', options = [], handleOnCopy, pointNamesUsedInMapping,
}) => {
  const dispatch = useAppDispatch()
  const searchTerm = useAppSelector((state) => state.tags[installationId]?.tagSearchTerm ?? '')
  const setSearch = (value: string) => {
    dispatch(setSearchTerm({ installationId, tagSearchTerm: value }))
  }
  const { enqueueSnackbar } = useSnackbar()
  const buttonRef = useRef<HTMLDivElement>()
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    groupedOptions,
    popupOpen,
    getClearProps,
  } = useAutocomplete({
    options,
    getOptionLabel: (option) => (option as PointName)?.name,
  })

  const onCopy = (option: string) => {
    enqueueSnackbar('Copied to clipboard', { variant: 'success' })
    setSearch('')
    handleOnCopy?.(option)
  }

  const onInsert = (option: string) => {
    onChange(option)
    setSearch('')
  }

  return (
    <>
      <Container
        container
        open={popupOpen}
        ref={buttonRef}
        alignItems="center"
        size={size}
        {...getRootProps()}
      >
        <Input
          type="text"
          placeholder={placeholder ?? 'Tag Search'}
          {...getInputProps()}
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
            getInputProps().onChange(e)
          }}
        />
        {searchTerm ? (
          <IconButton
            data-testid="clear-search"
            style={{ marginRight: '8px' }}
            size="small"
            onClick={(e) => {
              onChange('')
              setSearch('')
              getClearProps().onClick(e)
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <SearchIcon fontSize="small" style={{ marginRight: '12px' }} />
        )}
      </Container>
      <Listbox
        {...getListboxProps()}
        variant="list"
        width={buttonRef.current?.offsetWidth || 0}
        open={popupOpen}
        height={groupedOptions.length === 0 && popupOpen ? 36 : 224}
      >
        <TagsList
          search={searchTerm}
          height={groupedOptions.length === 0 && popupOpen ? 36 : 224}
          options={options}
          pointNamesUsedInMapping={pointNamesUsedInMapping}
          onInsert={onInsert}
          onCopy={onCopy}
          noResults={(
            <NoOptionsContainer>
              No options available
            </NoOptionsContainer>
          )}
        />
      </Listbox>
    </>
  )
}

export default ListSearch
