import oneOf from '@phaidra/utils/one-of'
import styled from '../styled'
import List from '../list'
import ListItem from '../list-item'
import Search from '../search/search'
import Typography from '../typography'

export const AutocompleteSearch = styled(
  Search,
  { shouldForwardProp: (propName) => propName !== 'isOpen' },
)<{ isOpen: boolean }>(({ isOpen }) => ({
  borderRadius: isOpen ? '4px 4px 0 0' : undefined,
}))

AutocompleteSearch.displayName = 'AutocompleteSearch'

export const SearchHistoryList = styled(
  List,
  { shouldForwardProp: (propKey) => !oneOf(propKey, 'width', 'isOpen') },
)<{ width: number, isOpen: boolean }>(({ theme, width, isOpen }) => ({
  visibility: isOpen ? 'visible' : 'hidden',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.container.border.default}`,
  borderTop: 'unset',
  borderRadius: '0 0 4px 4px',
  boxShadow: '0px 4px 6px -4px rgba(0,0,0,0.6)',
  width,
  zIndex: 100,
}))

SearchHistoryList.displayName = 'SearchHistoryList'

export const HistoryHeading = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
}))

HistoryHeading.displayName = 'HistoryHeading'

export const HistoryItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.subdued,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  '&:hover': {
    color: theme.palette.text.default,
    backgroundColor: theme.palette.hover.surface.default,
  },
}))

HistoryItem.displayName = 'HistoryItem'
