import React from 'react'
import { PickersDay, PickersLayout, PickersActionBar } from '@mui/x-date-pickers'
import TextField, { TextFieldProps } from '@mui/material/TextField'

import styled from '../styled'
import IconButton from '../icon-button'

export const StyledContainer = styled('div', {
  shouldForwardProp: (prop: string) => ![
    'size'].includes(prop),
})<{
  size?: 'large' | 'medium' | 'small'
}>(({
  size,
}) => {
  const height = size === 'small' ? '28px' : '44px'

  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& div': {
      height,
    },
  }
})

export const StyledTextField = styled(
  (props: TextFieldProps) => (<TextField variant="filled" {...props} />),
)<{ error?: boolean, size?: 'medium' | 'small' }>(({ theme, error, size }) => {
  const inputStyles = size === 'small' ? {
    fontSize: '0.75rem',
    marginRight: theme.spacing(-0.75),
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
  } : {
    fontSize: '1rem',
    marginRight: theme.spacing(-0.5),
    paddingTop: theme.spacing(2.625),
    paddingLeft: theme.spacing(1.5),
  }

  return {
    width: size === 'small' ? '110px' : '251px',
    height: '44px',
    '& .Mui-disabled': {
      color: `${theme.palette.text.disabled} !important`,
      '& svg': {
        color: theme.palette.text.disabled,
      },
    },
    '& .MuiFilledInput-root': {
      color: theme.palette.text.default,
      border: `solid 1px ${error ? theme.palette.error.main : theme.palette.container.border.default}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.container.surface.drawer,
      '&::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.hover.surface.default,
        borderColor: error ? theme.palette.error.main : theme.palette.container.border.default,
        color: theme.palette.text.default,
      },
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: theme.palette.container.surface.disabled,
      borderColor: theme.palette.container.surface.disabled,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiInputLabel-root': {
      marginTop: theme.spacing(-0.625),
      color: theme.palette.text.subdued,
      '&.Mui-focused': {
        color: theme.palette.text.subdued,
      },
      '&.Mui-error': {
        color: theme.palette.text.subdued,
      },
    },
    '& input': inputStyles,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& svg': {
      position: 'relative',
      width: '20px',
      height: '20px',
      ...size === 'small' && {
        width: '10px',
        height: '10px',
      },
      color: theme.palette.default.main,
    },
  }
})

export const StyledPickerDay = styled(PickersDay)(({ theme }) => ({
  height: '36px',
  aspectRatio: '1',
  marginRight: theme.spacing(0.75),
  marginLeft: '0',
  fontWeight: '500',
  backgroundColor: `${theme.palette.container.surface.drawer} !important`,
  '&:hover': {
    backgroundColor: `${theme.palette.hover.surface.default} !important`,
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.container.surface.activeHigh} !important`,
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#313330 !important',
  },
}))

export const StyledPickersActionBar = styled(PickersActionBar)(({ theme }) => ({
  '& button': {
    fontWeight: 500,
    color: theme.palette.alert.text.success,
    border: `1px solid ${theme.palette.container.border.secondary}`,
    width: '96px',
    height: '28px',
    '&:hover': {
      backgroundColor: theme.palette.hover.surface.default,
    },
  },
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& > svg': {
    color: `${theme.palette.text.default} !important`,
  },
}))

export const StyledLayout = styled(PickersLayout)(({ theme }) => ({
  backgroundColor: theme.palette.container.surface.drawer,
  zIndex: 10,
  '& > div': {
    '& .MuiDateCalendar-root': {
      width: '324px',
      backgroundColor: theme.palette.container.surface.drawer,
      '& .MuiDateCalendar-viewTransitionContainer': {
        '& > div': {
          '& > div': {
            '& .MuiDayCalendar-header': {
              paddingLeft: theme.spacing(1),
              '& .MuiDayCalendar-weekDayLabel': {
                height: '36px',
                aspectRatio: '1',
                marginRight: theme.spacing(0.75),
                marginLeft: '0',
              },
            },
            '& .MuiDayCalendar-slideTransition': {
              '& .MuiDayCalendar-monthContainer': {
                paddingLeft: theme.spacing(1),
              },
            },
          },
          '& .MuiMonthCalendar-root': {
            padding: `${theme.spacing(2)} 0`,
            '& .MuiPickersMonth-root': {
              '& .MuiPickersMonth-monthButton': {
                borderRadius: '4px !important',
                fontWeight: '500 !important',
                margin: '0 !important',
                padding: '0 !important',
                height: '35px !important',
                width: '55px !important',
                '&:hover': {
                  backgroundColor: `${theme.palette.hover.surface.default} !important`,
                },
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.container.surface.activeHigh} !important`,
                  '&:hover': {
                    backgroundColor: '#313330 !important',
                  },
                },
              },
            },
          },
          '& .MuiYearCalendar-root': {
            padding: `${theme.spacing(2)} 0`,
            '& .MuiPickersYear-root': {
              '& .MuiPickersYear-yearButton': {
                borderRadius: '4px !important',
                fontWeight: '500 !important',
                margin: '0 !important',
                padding: '0 !important',
                height: '35px !important',
                width: '55px !important',
                backgroundColor: theme.palette.container.surface.drawer,
                '&:hover': {
                  backgroundColor: `${theme.palette.hover.surface.default} !important`,
                },
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.container.surface.activeHigh} !important`,
                  '&:hover': {
                    backgroundColor: '#313330 !important',
                  },
                },
              },
            },
          },
        },
      },
    },
    '& .MuiMultiSectionDigitalClock-root': {
      '& li': {
        textTransform: 'lowercase',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: `${theme.palette.hover.surface.default} !important`,
        },
        '&.Mui-selected': {
          backgroundColor: `${theme.palette.container.surface.activeHigh} !important`,
        },
        '&.Mui-selected:hover': {
          backgroundColor: '#313330 !important',
        },
      },
    },
  },
}))
