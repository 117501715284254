import React, {
  FC, ReactNode, useState,
} from 'react'
import ListItemText from '@mui/material/ListItemText'
import { SvgIconProps } from '@mui/material/SvgIcon'
import Icon from '@mui/material/Icon'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Grid from '../grid'

import {
  DrawerHeader,
  DrawerFooter,
  ListItemButton,
  Divider,
  Drawer,
  BottomListItem,
  FooterListItem,
  Label,
  StyledListItemText,
  DrawerList,
  StyledChip,
} from './styles'

import PhaidraLogo from './assets/phaidra-logo'
import PhaidraLogoWithText from './assets/phaidra-logo-with-text'
import DelayRender from '../delay-render'

interface HeaderData {
  title: string
  subTitle: string
  icon: FC<SvgIconProps>
}

export interface TopListData {
  children?: {
    label: string
    path: string
    icon: FC<SvgIconProps>
    params?: Record<string, string>
    chip?: string
  }[]
}

export interface BottomListData {
  label: string | ReactNode
  onClick: (event?: React.MouseEvent) => void
  icon: string
  iconTestId?: string
  iconRight?: string
}

export interface AppBarVerticalProps extends MuiAppBarProps {
  headerData?: HeaderData
  topListData: TopListData[]
  bottomListData: BottomListData[]
  clientName?: string
  push: (path: string) => void
  pathname?: string
  keepNavBarOpened?: boolean
}

const AppBarVertical: FC<AppBarVerticalProps> = ({
  headerData,
  clientName,
  topListData,
  bottomListData,
  push,
  pathname = '',
  keepNavBarOpened = false,
}) => {
  const [open, setOpen] = useState(false)
  const currentSelectedPath = pathname === '/' ? 'systems-home' : pathname?.slice(1)
  const openDrawer = (keepNavBarOpened || open)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const HeaderIcon = headerData?.icon
  return (
    <Drawer
      data-testid="app-bar-vertical"
      variant="permanent"
      open={openDrawer}
      sx={{ boxShadow: openDrawer ? '4px 0px 10px 0px rgba(0, 0, 0, 0.25)' : 0 }}
      onMouseOver={handleDrawerOpen}
      onMouseOut={handleDrawerClose}
    >
      {headerData && (
        <DrawerHeader open={openDrawer}>
          <ListItemButton disableRipple tabIndex={-1} style={{ pointerEvents: 'none', minHeight: '64px' }}>
            <ListItemIcon data-testid="header-icon">
              <HeaderIcon
                style={{
                  width: '32px',
                  height: '32px',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={headerData.title}
              primaryTypographyProps={{ component: 'h1', lineHeight: '20px' }}
              secondary={headerData.subTitle}
              secondaryTypographyProps={{ component: 'h2', lineHeight: '16px' }}
            />
          </ListItemButton>
        </DrawerHeader>
      )}

      <DrawerList sx={{ pl: '3px', pr: '2px' }}>
        {topListData.map(({ children }, index) => (
          <React.Fragment key={children[0].label}>
            {children?.map(({
              label: subLabel,
              path: subPath,
              icon: LinkIcon,
              params: subParams,
              chip,
            }) => (
              <ListItem
                key={subLabel}
                dense
                sx={{ p: 0 }}
              >
                <ListItemButton
                  disableRipple
                  selected={currentSelectedPath === subPath}
                  onClick={() => {
                    const queryParams = new URLSearchParams(subParams).toString()
                    push(`${subPath}${queryParams ? `?${queryParams}` : ''}`)
                  }}
                >
                  <LinkIcon
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                  {openDrawer && (
                    <>
                      <ListItemText
                        primary={subLabel}
                        sx={{ mx: 1, my: 0, flex: 'unset' }}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                      {chip && (
                        <DelayRender delayMs={100}>
                          <StyledChip label={chip} />
                        </DelayRender>
                      )}
                    </>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
            {index !== topListData.length - 1 && (
              <ListItem
                sx={{
                  pt: 0,
                  pb: 0,
                  pl: 1.5,
                  pr: 1.5,
                }}
              >
                <Divider />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </DrawerList>

      <DrawerFooter>
        {bottomListData.map(({
          label,
          icon,
          iconTestId,
          onClick,
          iconRight,
        }, index) => (
          <BottomListItem
            open={openDrawer}
            index={index}
            bottomListDataLen={bottomListData.length}
            key={String(label)}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ListItemButton disableRipple dense sx={{ p: 0, pl: 1.5 }} onClick={onClick}>
                <ListItemIcon>
                  <Icon style={{ fontSize: '1.25rem' }} data-testid={iconTestId}>{icon}</Icon>
                </ListItemIcon>
                <StyledListItemText>
                  <Label>{label}</Label>
                </StyledListItemText>
                {(openDrawer && iconRight) && (
                  <ListItemIcon>
                    <Icon style={{ fontSize: '1.25rem' }}>{iconRight}</Icon>
                  </ListItemIcon>
                )}
              </ListItemButton>
            </Grid>
          </BottomListItem>
        ))}

        <div style={{ paddingInline: 4 }}>
          <Divider />
        </div>

        <FooterListItem open={openDrawer} clientName={clientName}>
          <ListItemButton disableRipple dense tabIndex={-1}>
            {openDrawer ? (
              <ListItemIcon>
                <PhaidraLogoWithText
                  style={{
                    width: '48px',
                    height: '20px',
                  }}
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <PhaidraLogo />
              </ListItemIcon>
            )}

            <ListItemText
              primary="Powered by"
              primaryTypographyProps={{
                color: 'text.defaultInverse',
                fontSize: 'caption.fontSize',
                fontWeight: 'caption.fontWeight',
              }}
              secondary={(
                <>
                  <span>for </span>
                  <span style={{ textTransform: 'capitalize' }}>{clientName}</span>
                </>
              )}
              secondaryTypographyProps={{ fontSize: 'caption.fontSize', color: 'text.subduedInverse' }}
            />
          </ListItemButton>
        </FooterListItem>
      </DrawerFooter>
    </Drawer>
  )
}

export default AppBarVertical
