import MuiIconButton from '@mui/material/IconButton'
import { HelpOutlineIcon, InfoOutlinedIcon, WarningIcon } from '../icons'
import styled from '../styled'

export const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  marginTop: theme.spacing(-0.25),
  '&.MuiButtonBase-root:hover': {
    backgroundColor: theme.palette.hover.surface.default,
  },
  '& > svg': {
    height: '14px',
    width: '14px',
  },
}))

export const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.icon.subdued,
}))

export const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  color: theme.palette.icon.subdued,
}))

export const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.alert.text.error,
  marginTop: theme.spacing(-0.25),
}))
