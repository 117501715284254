import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const TrendsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26" data-testid="tags-icon">
    <path
      d="M1.61279 22.9074V3.98075L4.02445 1.56909H22.9511L25.3628 3.98075V22.9074L22.9511 25.3191H4.02445L1.61279
      22.9074Z"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <rect
      x="13.4878"
      y="8.73709"
      width="6.6567"
      height="6.6567"
      transform="rotate(45 13.4878 8.73709)"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
  </SvgIcon>
)

export default TrendsIcon
