import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LeftChevron: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="2 0 17 30" data-testid="icon-chevron-left">
    <path
      d={'M0.153508 14.9996L14.6162 0.599609L16.8457 2.89333L4.74096 14.9996L16.8457 \
      27.1059L14.552 29.3996L0.153508 14.9996Z'}
    />
  </SvgIcon>
)

export default LeftChevron
