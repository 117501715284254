import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'
import { localToUTC } from '@phaidra/utils/time'

import { InstallationTags } from '@services/request-tags'
import {
  InstallationData, CreateInstallationQuery, GetConfigsForInstallationResponse, Config,
} from './types'

const DEFAULT_INSTALLATION_STATUSES = [
  'IN_DESIGN',
  'PROCESSING',
  'READY_TO_PROMOTE',
  'EDITING',
  'IN_PRODUCTION',
]

export const installationApi = createApi({
  reducerPath: 'installationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api`,
  }),
  tagTypes: [InstallationTags.GetInstallation],
  endpoints: (builder) => ({
    getInstallation: builder.query<InstallationData, string[] | void>({
      transformResponse: (response: InstallationData) => ({
        ...response,
        data: response.data.map((d) => ({
          ...d,
          createdAt: localToUTC(d.createdAt)?.getTime(),
          lastModified: localToUTC(d.lastModified)?.getTime(),
        })),
      }),
      providesTags: () => [{ type: InstallationTags.GetInstallation }],
      query: (statuses) => {
        const searchQuery = new URLSearchParams()

        if (statuses && statuses.length > 0) {
          statuses?.forEach((status) => searchQuery.append('status', status))
        } else {
          DEFAULT_INSTALLATION_STATUSES.forEach((status) => searchQuery.append('status', status))
        }

        return ({
          url: `/v2/installations?${searchQuery}`,
          method: 'get',
          meta: { tag: InstallationTags.GetInstallation },
        })
      },
      keepUnusedDataFor: 100000000000,
    }),
    createInstallation: builder.mutation<null, CreateInstallationQuery>({
      invalidatesTags: (_, error) => !error && [{ type: InstallationTags.GetInstallation }],
      query: (body) => ({
        url: '/async/v1/installations',
        method: 'post',
        data: body,
      }),
    }),
    renameInstallation: builder.mutation<void, { installationId: string, newName: string }>({
      invalidatesTags: (_, error) => !error && [{ type: InstallationTags.GetInstallation }],
      query: ({ installationId, ...body }) => ({
        url: `/v1/installations?installationId=${installationId}`,
        method: 'patch',
        data: body,
      }),
    }),
    archiveInstallation: builder.mutation<void, { installationId: string }>({
      invalidatesTags: (_, error) => !error && [{ type: InstallationTags.GetInstallation }],
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/archive`,
        method: 'patch',
      }),
    }),
    unarchiveInstallation: builder.mutation<void, { installationId: string }>({
      invalidatesTags: (_, error) => !error && [{ type: InstallationTags.GetInstallation }],
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/unarchive`,
        method: 'patch',
      }),
    }),
    promoteInstallation: builder.mutation<void, { installationId: string }>({
      invalidatesTags: (_, error) => !error && [{ type: InstallationTags.GetInstallation }],
      query: ({ installationId }) => ({
        url: `/v2/installations/${installationId}/deployPromote/promote`,
        method: 'post',
      }),
    }),
    deployInstallation: builder.mutation<void, { installationId: string }>({
      invalidatesTags: (_, error) => !error && [{ type: InstallationTags.GetInstallation }],
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/deploy`,
        method: 'patch',
      }),
    }),
    getConfigsForInstallation: builder.query<Config[], { installationId: string }>({
      transformResponse: (response: GetConfigsForInstallationResponse) => response.data,
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/configs`,
        method: 'get',
        meta: { tag: InstallationTags.GetInstallationConfigs },
      }),
    }),
  }),
})

export const {
  useGetInstallationQuery,
  useLazyGetInstallationQuery,
  useCreateInstallationMutation,
  useRenameInstallationMutation,
  useArchiveInstallationMutation,
  useUnarchiveInstallationMutation,
  usePromoteInstallationMutation,
  useDeployInstallationMutation,
  useGetConfigsForInstallationQuery,
  useLazyGetConfigsForInstallationQuery,
  endpoints: {
    getInstallation: { useQueryState: useGetInstallationQueryState },
    getConfigsForInstallation: { useQueryState: useGetConfigsForInstallationQueryState },
  },
} = installationApi
