import styled from '../../styled'
import Typography from '../../typography'
import MenuItem from '../../menu-item'

export const StyledSmallTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.subdued,
  marginBottom: theme.spacing(-0.25),
  whiteSpace: 'pre',
}))

export const StyledLargeTitle = styled(Typography)<{
  disabled?: boolean
  size?: 'large' | 'medium' | 'small'
}>(({ disabled, size, theme }) => ({
  color: disabled ? theme.palette.text.disabled : theme.palette.text.subdued,
  fontSize: size === 'small' ? theme.typography.body.fontSize : 'inherit',
  lineHeight: size === 'small' ? theme.typography.body.lineHeight : 'inherit',
  whiteSpace: 'pre',
  paddingLeft: size === 'small' ? theme.spacing(0) : theme.spacing(0.5),
}))

export const StyledValue = styled(Typography)({
  whiteSpace: 'pre',
  fontSize: '1rem',
  width: '100%',
})

export const StyledMenuItem = styled(MenuItem)<{ selected?: boolean }>(({ selected }) => ({
  ...(selected && {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
  }),
}))
