import React from 'react'
import { Line } from './styles'

export interface ConstantLineProps {
  id: number | string
  x: number
  y: number
  width: number
  show?: boolean
  isPreview?: boolean
}

const ConstantLine = ({
  id,
  x,
  y,
  width,
  show = true,
  isPreview = false,
}: ConstantLineProps) => (
  <Line
    data-testid={isPreview ? 'constant-line-preview' : `constant-line-${id}`}
    x={x}
    y={y}
    width={width}
    isPreview={isPreview}
    show={show}
  />
)

export default ConstantLine
