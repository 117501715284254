import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type FilterTypes = 'all' | 'clientLive' | 'clientHistorical' | 'phaidraTag'
export type FilterTagsTypes = {
  componentType: Record<string, boolean>
  tagType: Record<string, boolean>
  testResults: Record<string, boolean>
  status: Record<string, boolean>
  triggerProcessingStatus: Record<string, boolean>
  disabledClearFilter: boolean
}

export type TimesliceEditorModeTypes = 'create' | 'edit' | 'insert' | 'failed' | 'fillGap'

const initialState: Record<string, {
  filter: FilterTypes
  recentSearches: string[] // TODO: remove this when flag `enabledTimesliceRedesign` is removed
  tagSearchTerm: string // TODO: remove this when flag `enabledTimesliceRedesign` is removed
  filterTagsData: FilterTagsTypes // TODO: remove this when flag `enabledTimesliceRedesign` is removed
  tagsPageSearchTerm: string
  timesliceModalData: { // TODO: remove this when flag `enabledTimesliceRedesign` is removed
    mode: TimesliceEditorModeTypes
    expression: string
    startTime: number
    endTime: number
  }
  tableCheckedTags: {
    id: string | number
    checked: boolean
  }[]
}> = {}

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setFilterTagsData: (
      state,
      action: PayloadAction<{ installationId: string, filterTagsData: FilterTagsTypes }>,
    ) => {
      const { installationId, filterTagsData } = action.payload
      return {
        ...state,
        [installationId]: {
          ...state[installationId],
          filterTagsData,
        },
      }
    },
    setFilter: (state, action: PayloadAction<{ installationId: string, filter: FilterTypes }>) => ({
      ...state,
      [action.payload.installationId]: { ...state[action.payload.installationId], filter: action.payload.filter },
    }),
    setSearchTerm: (state, action: PayloadAction<{ installationId: string, tagSearchTerm: string }>) => {
      const recentSearches = state[action.payload.installationId]?.recentSearches ?? []
      let updatedSearches = recentSearches
      if (action.payload.tagSearchTerm) {
        updatedSearches = Array.from(recentSearches?.length === 10
          ? new Set([action.payload.tagSearchTerm, ...recentSearches.slice(0, 9)])
          : new Set([action.payload.tagSearchTerm, ...recentSearches]))
      }

      return ({
        ...state,
        [action.payload.installationId]: {
          ...state[action.payload.installationId],
          tagSearchTerm: action.payload.tagSearchTerm,
          recentSearches: updatedSearches,
        }
        ,
      })
    },
    setTagsPageSearchTerm: (state, action: PayloadAction<{ installationId: string, searchTerm: string }>) => ({
      ...state,
      [action.payload.installationId]: {
        ...state[action.payload.installationId],
        tagsPageSearchTerm: action.payload.searchTerm,
      },
    }),
    setExpression: (
      state,
      action: PayloadAction<{
        installationId: string
        value: string
      }>,
    ) => ({
      ...state,
      [action.payload.installationId]: {
        ...state[action.payload.installationId],
        timesliceModalData: {
          ...state[action.payload.installationId]?.timesliceModalData,
          expression: action.payload.value,
        },
      },
    }),
    setStartTime: (
      state,
      action: PayloadAction<{
        installationId: string
        value: number
      }>,
    ) => ({
      ...state,
      [action.payload.installationId]: {
        ...state[action.payload.installationId],
        timesliceModalData: {
          ...state[action.payload.installationId]?.timesliceModalData,
          startTime: action.payload.value,
        },
      },
    }),
    setEndTime: (
      state,
      action: PayloadAction<{
        installationId: string
        value: number
      }>,
    ) => ({
      ...state,
      [action.payload.installationId]: {
        ...state[action.payload.installationId],
        timesliceModalData: {
          ...state[action.payload.installationId]?.timesliceModalData,
          endTime: action.payload.value,
        },
      },
    }),
    setMode: (
      state,
      action: PayloadAction<{
        installationId: string
        mode: 'create' | 'edit' | 'insert' | 'failed' | 'fillGap'
      }>,
    ) => ({
      ...state,
      [action.payload.installationId]: {
        ...state[action.payload.installationId],
        timesliceModalData: {
          ...state[action.payload.installationId]?.timesliceModalData,
          mode: action.payload.mode,
        },
      },
    }),
    setTableCheckedTags: (
      state,
      action: PayloadAction<{
        installationId: string
        tableCheckedTags: {
          id: string | number
          checked: boolean
        }[]
      }>,
    ) => {
      const { installationId, tableCheckedTags } = action.payload

      return {
        ...state,
        [installationId]: {
          ...state[installationId],
          tableCheckedTags,
        },
      }
    },
  },
})

export const {
  setFilter,
  setSearchTerm,
  setFilterTagsData,
  setTagsPageSearchTerm,
  setExpression,
  setStartTime,
  setEndTime,
  setTableCheckedTags,
  setMode,
} = tagSlice.actions

export default tagSlice.reducer
