import React, { ReactNode, useEffect, useState } from 'react'
import {
  LDOptions, useFlags, asyncWithLDProvider, LDContext,
} from 'launchdarkly-react-client-sdk'

interface LaunchDarklyProviderProps {
  children: ReactNode
  clientSideID: string | undefined
  Loader: () => JSX.Element
  context?: LDContext
  options?: LDOptions
}

export const LaunchDarklyFlagsProvider: React.FC<LaunchDarklyProviderProps> = ({
  children,
  clientSideID,
  options,
  context,
  Loader,
}) => {
  const [LDProviderComponent, setLDProviderComponent] = useState<React.ComponentType<any> | null>(null)

  useEffect(() => {
    async function initializeLDProvider() {
      const LDProvider = await asyncWithLDProvider({ clientSideID, options, context })
      setLDProviderComponent(() => LDProvider)
    }

    initializeLDProvider()
  }, [clientSideID, options, context])

  return (
    LDProviderComponent ? <LDProviderComponent>{children}</LDProviderComponent> : <Loader />
  )
}

export const useLaunchDarklyFeatureFlags = (flagKey: string): boolean => {
  const flags = useFlags()

  if (!flags) {
    throw new Error('useFeatureFlag must be used within a LaunchDarklyProvider')
  }
  return flags[flagKey] || false
}

export const useLaunchDarklyNumericFlags = (flagKey: string, fallbackValue: number): number => {
  const flags = useFlags()

  if (!flags) {
    throw new Error('useFeatureFlag must be used within a LaunchDarklyProvider')
  }
  return flags[flagKey] || fallbackValue
}
