import styled from '@phaidra/ava/styled'

import Button from '@phaidra/ava/button'
import Grid from '@phaidra/ava/grid'

export const NodeContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'style' && prop !== 'nestingLevel',
})<{ style: object, nestingLevel: number }>(({ style, nestingLevel }) => ({
  ...style,
  display: 'flex',
  alignItems: 'center',
  width: nestingLevel > 0 ? 'calc(100% - 32px)' : 'calc(100% - 16px)',
}))

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isTag',
})<{ isTag: boolean }>(({ isTag }) => (({ theme }) => ({
  display: 'flex',
  border: `solid 1px ${theme.palette.container.border.subdued}`,
  borderRadius: theme.shape.borderRadius,
  minWidth: '320px',
  width: 'inherit',
  minHeight: '46px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  backgroundColor: theme.palette.container.surface.default,
  overflow: 'hidden',
  ...(isTag && {
    '&:hover': {
      borderColor: theme.palette.container.border.default,
      backgroundColor: theme.palette.hover.surface.default,
    },
  }),
})))

export const HorizontalConnector = styled('div')(({ theme }) => ({
  height: '1px',
  width: '36px',
  backgroundColor: theme.palette.container.border.subdued,
  position: 'absolute',
  right: 0,
  zIndex: -1,
}))

export const VerticalConnector = styled('div')(({ theme }) => ({
  width: '1px',
  backgroundColor: theme.palette.container.border.subdued,
  marginLeft: theme.spacing(1.5),
  zIndex: -1,
}))

export const Collapser = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.container.surface.default,
  border: `solid 1px ${theme.palette.container.border.subdued}`,
  marginLeft: theme.spacing(0.625),
  marginRight: theme.spacing(3.75),
  height: '14px',
  width: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}))

export const IdContainer = styled(Grid)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '12px',
  color: theme.palette.container.border.default,
  height: 12,
  marginBottom: theme.spacing(0.25),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
}))

export const NameContainer = styled(Grid)({
  fontSize: '0.75rem',
  lineHeight: '16px',
  height: 16,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
})

export const AddTagButton = styled(Button)(({ theme }) => ({
  minHeight: '0 !important',
  minWidth: 0,
  fontSize: `${theme.typography.caption.fontSize} !important`,
}))

export const OverflowingContainer = styled(Grid)({
  overflow: 'hidden',
})
