import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@phaidra/ava/grid'
import NelInput from '@phaidra/ava/nel-input'
import {
  useGetPointNamesQuery, useGetPaginatedTagsQuery, useGetTagsInUseQuery, useGetPointNamesUsedInQuery,
} from '@services/tag-management'
import { useLaunchDarklyFeatureFlags, FeatureFlagNames } from '@phaidra/utils/darkly-feature-flags'
import { useQueryParam } from '@common/use-query-param'
import { useGetInstallationQueryState } from '@services/installation'
import { useFormContext } from 'react-hook-form'
import { setSearchTerm } from '@store/tag-management'
import { useAppSelector, useAppDispatch } from '@store'
import { NELUserGuideLink } from '@config/app.config'
import { StyledInputBase } from '../../app/tag-management/find-and-replace/find/styles'
import { transformSearchOptions } from './utils'
import NelExpressionQueryPanel from './nel-expression-query-panel'
import { NELInfoLink } from './styles'

type NELEditorProps = { fieldName: string, enableFilterByDropdown?: boolean, error?: boolean }
const NELExpressionEditor = ({ enableFilterByDropdown, fieldName, error }: NELEditorProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const installationId = useQueryParam('installationId')
  const dispatch = useAppDispatch()
  const nelInputTextAreaRef = useRef(null)

  const tagSearchTerm = useAppSelector((state) => state.tags[installationId]?.tagSearchTerm) ?? ''

  const { data: pointNames } = useGetPointNamesQuery({ installationId })
  const { data: tagsResponse } = useGetPaginatedTagsQuery({
    installationId, page: 1, pageSize: -1, namesOnly: true,
  })
  const { data: tagsInUse } = useGetTagsInUseQuery({ installationId })
  const { data: pointNamesUsedInMapping } = useGetPointNamesUsedInQuery({ installationId })
  const installation = useGetInstallationQueryState(undefined, {
    selectFromResult: (response) => response?.data?.data.find(({ id }) => id === installationId),
  })
  const isPointNamesInUseEnabled = useLaunchDarklyFeatureFlags(FeatureFlagNames.EnabledPointNamesInUse)
  const isNELIntegrationEnabled = useLaunchDarklyFeatureFlags(FeatureFlagNames.EnableNELIntegration)
  const isSyntaxHighlightingEnabled = useLaunchDarklyFeatureFlags(FeatureFlagNames.EnableNELSyntaxHighlighting)

  const [searchOptions, setSearchOptions] = useState({
    all: [],
    clientLive: [],
    clientHistorical: [],
    phaidraTag: [],
  })
  const { watch, setValue } = useFormContext()
  const expression = watch(fieldName, '')
  const userGuide = NELUserGuideLink

  useEffect(() => {
    dispatch(setSearchTerm({ installationId, tagSearchTerm: '' }))
  }, [dispatch, installationId])

  useEffect(() => {
    if (pointNames && tagsResponse && tagsInUse && pointNamesUsedInMapping) {
      const transformedOptions = transformSearchOptions({
        pointNames,
        tagsResponse,
        tagsInUse,
        pointNamesUsedInMapping,
        isPointNamesInUseEnabled,
        tagSearchTerm,
      })
      setSearchOptions(transformedOptions)
    }
  }, [
    pointNames,
    tagsResponse,
    tagsInUse,
    pointNamesUsedInMapping,
    isPointNamesInUseEnabled,
    tagSearchTerm,
  ])

  return (
    <Grid container direction="column">
      <Grid mb="20px">
        <NelExpressionQueryPanel
          searchOptions={searchOptions}
          components={tagsResponse?.components}
          enableFilterByDropdown={enableFilterByDropdown}
          pointsUsedInMapping={pointNamesUsedInMapping}
          installation={installation}
          expressionFieldName={fieldName}
          filterByFieldName={`${fieldName}-filter-by`}
        />
      </Grid>
      <Grid mb="8px">
        {isSyntaxHighlightingEnabled
          ? (
            <NelInput
              dataTestId="nel-exp"
              placeholder=""
              value={expression}
              onChange={(value) => setValue(fieldName, value)}
              name="fieldName"
              inputProps={{ 'data-testid': fieldName }}
              height="124px"
              isError={error}
              ref={nelInputTextAreaRef}
            />
          )
          : (
            <StyledInputBase
              data-testid="nel-exp"
              placeholder=""
              multiline
              value={expression}
              onChange={(e) => setValue(fieldName, e.target.value)}
              name="fieldName"
              inputProps={{ 'data-testid': fieldName }}
              height="124px"
              padding="4px 12px"
              isError={error}
            />
          )}
      </Grid>
      <Grid container flex={1} justifyContent="flex-end">
        {isNELIntegrationEnabled && (
          <NELInfoLink
            sx={{ mr: '20px' }}
            onClick={() => {
              if (expression.length) searchParams.set('nel', expression)
              navigate(`/nel-debugger${`?${searchParams.toString()}`}`)
            }}
          >
            NEL Debugger
          </NELInfoLink>
        )}
        <NELInfoLink
          target="_blank"
          rel="noopener noreferrer"
          href={userGuide}
        >
          NEL User Guide
        </NELInfoLink>
      </Grid>

    </Grid>
  )
}

export default NELExpressionEditor
