import React, { memo, type FC } from 'react'
import Tooltip from '@phaidra/ava/tooltip'
import useShowStatsTooltip from './use-stats-tooltip-notification'

import { type ChartIndex } from '../../types'
import StatsTooltipContent from './stats-tooltip-content'

export interface Props {
  chartIndex: ChartIndex
  children: React.ReactElement
  showTooltip: boolean
}

const StatsTooltip: FC<Props> = ({
  chartIndex,
  children,
  showTooltip: showTooltipOverride,
}) => {
  const [showTooltip, closeTooltip] = useShowStatsTooltip()

  return (
    <Tooltip
      open={showTooltipOverride && showTooltip && chartIndex === 0}
      title={<StatsTooltipContent closeTooltip={closeTooltip} />}
      placement="right"
      PopperProps={{
        // Require stopping propagation to prevent child component from
        // receiving click event.
        onClick: (e) => e.stopPropagation(),
      }}
    >
      {children}
    </Tooltip>
  )
}

export default memo(StatsTooltip)
