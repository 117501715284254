import React from 'react'

import Banner from '@phaidra/ava/banner'
import Link from '@phaidra/ava/link'

const ErrorMessage = (
  <>
    An error occurred. Please try again or contact Phaidra at
    {' '}
    <Link
      href="mailto:support@phaidra.ai"
      sx={{ fontWeight: '400', color: '#000', textDecorationColor: '#000' }}
    >
      support@phaidra.ai
    </Link>
    .
  </>
)

const ErrorBanner = () =>
  <Banner flex data={[{ message: ErrorMessage, variant: 'error' }]} />

export default ErrorBanner
