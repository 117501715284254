import React, { FC } from 'react'
import { StyledAccordionHeaderWrapper } from './styles'

interface Props {
  children: React.ReactNode
  onClick: () => void
}

const StyledAccordionHeader: FC<Props> = ({ children, onClick }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'NumpadEnter'].includes(e.key)) {
      onClick()
    }
  }

  return (
    <StyledAccordionHeaderWrapper
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </StyledAccordionHeaderWrapper>
  )
}

export default StyledAccordionHeader
