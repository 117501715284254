import React, { FC, useState, useEffect } from 'react'
import { StyledLinearProgress, LinearProgressContainer } from './styles'

interface EstimateProps {
  estimate: number
  value?: never
}

interface ValueProps {
  estimate?: never
  value: number
}

export type Props = EstimateProps | ValueProps

const LinearProgress: FC<Props> = ({ estimate, value }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (estimate) {
      const interval = 200
      const counter = Math.floor(estimate / interval)

      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress < 90) {
            return oldProgress + (90 / counter)
          }
          return oldProgress + ((99.5 - oldProgress) / oldProgress)
        })
      }, interval)

      return () => {
        clearInterval(timer)
      }
    }
  }, [estimate])

  return (
    <LinearProgressContainer style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <StyledLinearProgress variant="determinate" value={value ?? progress} data-testid="linear-progress" />
    </LinearProgressContainer>
  )
}

export default LinearProgress
