import MUITextField from '@mui/material/TextField'

import styled from '../styled'

export const StyledTextField = styled(MUITextField)<{ error?: boolean }>(({ theme, error }) => ({
  '& .MuiOutlinedInput-root': {
    height: '44px',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fieldset: {
      border: `solid 1px ${
        error ? theme.palette.error.main : theme.palette.container.border.default
      }`,
    },
    '&.Mui-focused': {
      backgroundColor: error
        ? theme.palette.hover.surface.error
        : theme.palette.hover.surface.default,
      fieldset: {
        border: `solid 1px ${
          error ? theme.palette.error.main : theme.palette.container.border.default
        }`,
      },
    },
    '&:hover': {
      backgroundColor: error
        ? theme.palette.hover.surface.error
        : theme.palette.hover.surface.default,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.container.surface.disabled,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: theme.spacing(-0.625),
    color: theme.palette.default.light,
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      marginTop: 0,
      '&.Mui-error': {
        color: theme.palette.alert.text.error,
      },
      '&.Mui-focused': {
        color: error ? theme.palette.alert.text.error : theme.palette.default.light,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.icon.disabled,
    },
  },
  '& input.MuiInputBase-input': {
    borderRadius: theme.shape.borderRadius,
    '&:autofill': {
      WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.background.default} inset !important`,
    },
  },
}))
