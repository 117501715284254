import { createApi } from '@reduxjs/toolkit/query/react'
import { GridRowId } from '@phaidra/ava/data-grid'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import { TagManagementTags } from '@services/request-tags'
import {
  TagsData,
  StatusData,
  TagMetaData,
  TagMetaDataPatch,
  PointNamesType,
  Timeline,
  TestResult,
  ExportCSV,
  ExportJSON,
  TagTriggerProcessingStatusType,
  FindInFindAndReplaceType,
  TimelineData,
  ReplaceInFindAndReplacePatchReq,
  ReplaceInFindAndReplacePatchResType,
  TagsInUseData,
  TagsValidation,
  TagMetaDataValidation,
  PointNamesUsedInType,
} from './types'
import { SearchPanelSubmitDataProps } from '../../app/tag-management/find-and-replace/types'

export const tagManagementApi = createApi({
  reducerPath: 'tagManagementApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api`,
  }),
  tagTypes: [
    'Tags',
    'Metadata',
    'TagTriggerProcessingStatus',
    'Timeline',
    'TagsInUse',
    'PointNames',
    'PointNamesUsedIn',
    'GetTagPage',
  ],
  endpoints: (builder) => ({
    getStatus: builder.query<StatusData['data'], { installationId: string }>({
      transformResponse: (response: StatusData) => response.data,
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/tag/status`,
        method: 'get',
      }),
    }),
    updateTagStatuses: builder.mutation<TagsData['data'],
    { installationId: string, tagIds: Array<GridRowId>, status: string }>({
      invalidatesTags: (_, error) => !error && [
        { type: 'Tags' },
        { type: 'TagTriggerProcessingStatus' },
        { type: 'GetTagPage' },
      ],
      query: ({ installationId, ...body }) => ({
        url: `/v1/installations/${installationId}/tag/status`,
        method: 'patch',
        data: body,
        meta: { tag: TagManagementTags.UpdateTagStatuses },
      }),
    }),
    getTagMetadata: builder.query<TagMetaData['data'], {
      installationId: string
      tagId: GridRowId
      metadataValidationV2?: boolean
    }>({
      providesTags: () => [{ type: 'Metadata' }],
      transformResponse: (response: TagMetaData) => response.data,
      query: ({ installationId, tagId, metadataValidationV2 }) => ({
        url: `/${metadataValidationV2 ? 'v2' : 'v1'}/installations/${installationId}/tag/${tagId}/metadata`,
        method: 'get',
        meta: {
          tag: TagManagementTags.GetTagMetadata,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getTagMetadataValidation: builder.query<TagMetaDataValidation['imputations'], {
      installationId: string
      tagId: GridRowId
    }>({
      providesTags: () => [{ type: 'Metadata' }],
      transformResponse: (response: TagMetaDataValidation) => response.imputations,
      query: ({ installationId, tagId }) => ({
        url: `/async/v1/installations/${installationId}/tag/${tagId}/metadata-validation`,
        method: 'get',
        meta: {
          tag: TagManagementTags.GetTagMetadataValidation,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    updateTagMetadata: builder.mutation<TagMetaData['data'], TagMetaDataPatch>({
      invalidatesTags: (_, error) => !error && ['GetTagPage'],
      transformResponse: (response: TagMetaData['data']) => response,
      query: ({ installationId, tagId, ...body }) => ({
        url: `/v1/installations/${installationId}/tag/${tagId}/metadata`,
        method: 'patch',
        data: body,
        meta: {
          tag: TagManagementTags.UpdateTagMetadata,
        },
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patchResultMetadata = dispatch(
          tagManagementApi.util.updateQueryData(
            'getTagMetadata',
            { installationId: arg.installationId, tagId: arg.tagId, metadataValidationV2: arg.metadataValidationV2 },
            (draft) => ({ ...draft, stateMap: arg.stateMap }),
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResultMetadata.undo()
        }
      },
    }),
    getPointNames: builder.query<PointNamesType['data'], { installationId: string }>({
      providesTags: () => [{ type: 'PointNames' }],
      transformResponse: (response: PointNamesType) => response.data,
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/point`,
        method: 'get',
        meta: { tag: TagManagementTags.GetPointNames },
      }),
    }),
    getPointNamesUsedIn: builder.query<PointNamesUsedInType['data'], { installationId: string }>({
      providesTags: () => [{ type: 'PointNamesUsedIn' }],
      transformResponse: (response: PointNamesUsedInType) => response.data,
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/point/in-use`,
        method: 'get',
        meta: { tag: TagManagementTags.GetPointNamesUsedIn },
      }),
    }),
    getTagTriggerProcessingStatus: builder.query<TagTriggerProcessingStatusType['data'], { installationId: string }>({
      providesTags: () => [{ type: 'TagTriggerProcessingStatus' }],
      transformResponse: (response: TagTriggerProcessingStatusType) => response.data,
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/process`,
        method: 'get',
      }),
    }),
    createTimeslice: builder.mutation<TagMetaData['data'],
    {
      installationId: string
      tagId: GridRowId
      startTimestamp: number
      stopTimestamp: number
      nelExpression?: string
      isExcluded?: boolean
      healthRuleId?: string
      ignoreWarnings?: boolean
    }>({
      invalidatesTags: (_, error) => !error && [
        'Metadata',
        'Tags',
        'TagTriggerProcessingStatus',
        'Timeline',
        'TagsInUse',
        'PointNames',
        'PointNamesUsedIn',
        'GetTagPage',
      ],
      query: ({
        installationId, tagId, ignoreWarnings, ...body
      }) => ({
        url: `/v1/installations/${installationId}/tag/${tagId}/imputation${
          ignoreWarnings ? `?ignoreWarnings=${ignoreWarnings}` : ''}`,
        method: 'post',
        data: body,
        meta: {
          tag: TagManagementTags.CreateTimeslice,
        },
      }),
    }),
    updateTags: builder.mutation<null, { installationId: string }>({
      invalidatesTags: (_, error) => !error && ['GetTagPage'],
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/process`,
        method: 'post',
      }),
    }),
    deleteTimeslice: builder.mutation<void,
    {
      installationId: string
      tagId: GridRowId
      imputationId: number
    }>({
      invalidatesTags: (_, error) => !error && ['Metadata',
        'Tags',
        'TagTriggerProcessingStatus',
        'Timeline',
        'TagsInUse',
        'PointNames',
        'PointNamesUsedIn',
        'GetTagPage',
      ],
      query: ({ installationId, tagId, imputationId }) => ({
        url: `/v1/installations/${installationId}/tag/${tagId}/imputation/${imputationId}`,
        method: 'delete',
        meta: {
          tag: TagManagementTags.DeleteTimeslice,
        },
      }),
    }),
    updateTimeslice: builder.mutation<void, {
      installationId: string
      tagId: GridRowId
      imputationId: number
      startTimestamp: number
      stopTimestamp: number
      nelExpression?: string
      isExcluded?: boolean
      ignoreWarnings?: boolean
      avoidRefetch?: boolean
    }>({
      query: ({
        installationId, tagId, imputationId, ignoreWarnings, ...body
      }) => ({
        url:
          `/v1/installations/${installationId}/tag/${tagId}/imputation/${imputationId}${
            ignoreWarnings ? `?ignoreWarnings=${ignoreWarnings}` : ''}`,
        method: 'patch',
        data: body,
        meta: { tag: TagManagementTags.UpdateTimeslice },
      }),
      onQueryStarted: async (arg, api) => {
        api.queryFulfilled.then(() => {
          if (!arg.avoidRefetch) {
            api.dispatch(tagManagementApi.util.invalidateTags([
              'Metadata',
              'Tags',
              'TagTriggerProcessingStatus',
              'Timeline',
              'TagsInUse',
              'PointNames',
              'PointNamesUsedIn',
              'GetTagPage',
            ]))
          }
        }).catch(() => {})
      },
    }),
    updateTimesliceV2: builder.mutation<TagMetaData['data'], {
      installationId: string
      tagId: GridRowId
      imputationId: number
      startTimestamp: number
      stopTimestamp: number
      nelExpression?: string
      isExcluded?: boolean
      ignoreWarnings?: boolean
      metadataValidationV2?: boolean
      avoidRefetch?: boolean
    }>({
      query: ({
        installationId, tagId, imputationId, ignoreWarnings, ...body
      }) => ({
        url:
          `/v1/installations/${installationId}/tag/${tagId}/imputation/${imputationId}${
            ignoreWarnings ? `?ignoreWarnings=${ignoreWarnings}` : ''}`,
        method: 'patch',
        data: body,
        meta: { tag: TagManagementTags.UpdateTimeslice },
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          if (!arg.avoidRefetch) {
            dispatch(tagManagementApi.util.invalidateTags([
              'Tags',
              'TagTriggerProcessingStatus',
              'Timeline',
              'TagsInUse',
              'PointNames',
              'PointNamesUsedIn',
              'GetTagPage',
            ]))
          }
          dispatch(
            tagManagementApi.util.updateQueryData(
              'getTagMetadata',
              { installationId: arg.installationId, tagId: arg.tagId, metadataValidationV2: arg.metadataValidationV2 },
              (draft) => ({ ...draft, ...data }),
            ),
          )
        } catch { /** handle error */ }
      },
    }),

    insertTimeslice: builder.mutation<void, {
      installationId: string
      tagId: GridRowId
      timelines: Timeline[]
      ignoreWarnings?: boolean
    }>({
      invalidatesTags: (_, error) => !error && [
        'Metadata',
        'TagTriggerProcessingStatus',
        'Timeline',
        'TagsInUse',
        'PointNames',
        'PointNamesUsedIn',
        'GetTagPage',
        'Tags',
        'Timeline',
      ],
      query: ({
        installationId, tagId, ignoreWarnings, timelines,
      }) => ({
        url: `/v1/installations/${installationId}/tag/${tagId}/imputation${
          ignoreWarnings ? `?ignoreWarnings=${ignoreWarnings}` : ''}`,
        method: 'patch',
        data: timelines,
      }),
    }),
    insertTimesliceV2: builder.mutation<TagMetaData['data'], {
      installationId: string
      tagId: GridRowId
      timelines: Timeline[]
      ignoreWarnings?: boolean
      metadataValidationV2?: boolean
    }>({
      invalidatesTags: (_, error) => !error && [
        { type: 'TagTriggerProcessingStatus' },
        { type: 'Timeline' },
        { type: 'TagsInUse' },
        { type: 'PointNames' },
      ],
      query: ({
        installationId, tagId, ignoreWarnings, timelines,
      }) => ({
        url: `/v1/installations/${installationId}/tag/${tagId}/imputation${
          ignoreWarnings ? `?ignoreWarnings=${ignoreWarnings}` : ''}`,
        method: 'patch',
        data: timelines,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch(
            tagManagementApi.util.updateQueryData(
              'getTagMetadata',
              { installationId: arg.installationId, tagId: arg.tagId, metadataValidationV2: arg.metadataValidationV2 },
              (draft) => ({ ...draft, ...data }),
            ),
          )
        } catch { /** handle error */ }
      },
    }),
    toggleTestResult: builder.mutation<void, {
      installationId: string
      tagId: GridRowId
      healthRuleId: TestResult['healthRuleId']
      failedAt: Omit<TestResult['failedAt'], 'imputationCreated'>
    }>({
      invalidatesTags: (_, error) => !error && ['Tags', 'GetTagPage'],
      query: ({
        installationId, tagId, ...body
      }) => ({
        url: `/v1/installations/${installationId}/tag/${tagId}/testResult`,
        method: 'patch',
        data: body,
        meta: { tag: TagManagementTags.ToggleTestResult },
      }),
    }),
    downloadCSV: builder.query<ExportCSV['data'], { installationId: string }>({
      transformResponse: (response: ExportCSV) => response.data,
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/export`,
        method: 'get',
      }),
    }),
    downloadJSON: builder.query<ExportJSON['data'], { installationId: string }>({
      transformResponse: (response: ExportJSON) => response.data,
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/exportTagConfig`,
        method: 'get',
      }),
    }),
    findInFindAndReplace: builder.query<
    FindInFindAndReplaceType['data'],
    SearchPanelSubmitDataProps & { installationId: string }
    >({
      transformResponse: ({ data }: FindInFindAndReplaceType) => data,
      query: ({
        installationId, findWhat, matchCase, matchSearchExpression, findWhere,
      }) => {
        const findLocation = Object.keys(findWhere).filter((value) => findWhere[value]).join('&findLocation=')

        return ({
          url: `/v1/installations/${installationId}/find?q=${encodeURIComponent(findWhat)}&matchCase=${
            matchCase}&matchExpression=${matchSearchExpression}&findLocation=${findLocation}`,
          method: 'get',
          meta: {
            tag: TagManagementTags.Find,
          },

        })
      },
    }),
    replaceInFindAndReplace: builder.mutation<
    ReplaceInFindAndReplacePatchResType['data'],
    ReplaceInFindAndReplacePatchReq
    >({
      transformResponse: ({ data }: ReplaceInFindAndReplacePatchResType) => data,
      query: ({ installationId, ignoreWarnings, ...body }) => ({
        url: `/v1/installations/${installationId}/replace?ignoreWarnings=${ignoreWarnings}`,
        method: 'patch',
        data: body,
        meta: {
          tag: TagManagementTags.Replace,
        },

      }),
    }),
    getTimeline: builder.query<TimelineData['data'], { installationId: string }>({
      providesTags: () => [{ type: 'Timeline' }],
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/timeline`,
        method: 'get',
      }),
      transformResponse: (response: TimelineData) => response.data,
    }),
    stopProcessingTags: builder.query<TagsData['data'], { installationId: string }>({
      transformResponse: (response: any) => response,
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/tag/process`,
        method: 'delete',
        meta: {
          tag: TagManagementTags.StopProcessingTags,
        },
      }),
    }),
    getTagsInUse: builder.query<TagsInUseData['data'], { installationId: string }>({
      providesTags: () => [{ type: 'TagsInUse' }],
      transformResponse: (response: TagsInUseData) => response.data,
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/in-use`,
        method: 'get',
      }),
    }),
    getTagsValidation: builder.query<any, { installationId: string }>({
      query: ({ installationId }) => ({
        url: `/async/v1/installations/${installationId}/tag/validation`,
        method: 'get',
        meta: {
          tag: TagManagementTags.GetTagsValidation,
        },
      }),
      transformResponse: (response: TagsValidation) => response.data,
    }),
    getTagFilters: builder.query<any, { installationId: string }>({
      query: ({ installationId }) => ({
        url: `/v1/installations/${installationId}/tag/filters`,
        method: 'get',
        meta: {
          tag: TagManagementTags.GetTagFilters,
        },
      }),
    }),
    getPaginatedTags: builder.query<TagsData['data'], {
      installationId: string
      page: number
      pageSize: number
      namesOnly: boolean
      search?: string | ''
      componentTypes?: string[]
      tagTypes?: string[]
      testResultStatus?: string[]
      triggerProcessStatus?: string[]
      status?: string[]
    }>({
      providesTags: () => [{ type: 'GetTagPage' }],
      transformResponse: (response: TagsData) => response.data,
      query: ({ installationId, ...body }) => ({
        url: `/v1/installations/${installationId}/get-tag-page`,
        method: 'post',
        data: body,
        meta: {
          tag: TagManagementTags.GetTagPage,
        },
      }),
    }),
    postIdentityMap: builder.mutation<null, {
      installationId: string
      formData: FormData
    }>({
      query: ({ installationId, formData }) => ({
        url: `/async/v1/installations/${installationId}/tag/identity-map`,
        method: 'post',
        data: formData,
      }),
    }),
  }),
})

export const {
  useGetStatusQuery,
  useLazyGetStatusQuery,
  useUpdateTagStatusesMutation,
  useGetTagMetadataQuery,
  useLazyGetTagMetadataQuery,
  useGetTagTriggerProcessingStatusQuery,
  useLazyGetTagTriggerProcessingStatusQuery,
  useUpdateTagMetadataMutation,
  useGetPointNamesQuery,
  useGetPointNamesUsedInQuery,
  useCreateTimesliceMutation,
  useUpdateTagsMutation,
  useDeleteTimesliceMutation,
  useUpdateTimesliceMutation,
  useUpdateTimesliceV2Mutation,
  useInsertTimesliceMutation,
  useInsertTimesliceV2Mutation,
  useToggleTestResultMutation,
  useLazyDownloadCSVQuery,
  useLazyDownloadJSONQuery,
  useLazyFindInFindAndReplaceQuery,
  useReplaceInFindAndReplaceMutation,
  useGetTimelineQuery,
  useLazyGetTimelineQuery,
  useStopProcessingTagsQuery,
  useLazyStopProcessingTagsQuery,
  useGetTagsInUseQuery,
  useLazyGetTagsInUseQuery,
  useGetTagsValidationQuery,
  useLazyGetTagsValidationQuery,
  useGetTagMetadataValidationQuery,
  useLazyGetTagMetadataValidationQuery,
  useGetTagFiltersQuery,
  useLazyGetTagFiltersQuery,
  useGetPaginatedTagsQuery,
  useLazyGetPaginatedTagsQuery,
  usePostIdentityMapMutation,
  useLazyGetPointNamesQuery,
  useLazyGetPointNamesUsedInQuery,
  endpoints: {
    getTimeline: { useQueryState: useGetTimelineQueryState },
    getTagTriggerProcessingStatus: { useQueryState: useGetTagTriggerProcessingStatusQueryState },
    getStatus: { useQueryState: useGetStatusQueryState },
    getTagMetadata: { useQueryState: useGetTagMetadataQueryState },
    getTagsValidation: { useQueryState: useGetTagsValidationQueryState },
    findInFindAndReplace: { useQueryState: useFindInFindAndReplaceQueryState },
    getTagsInUse: { useQueryState: useGetTagsInUseQueryState },
    getPointNames: { useQueryState: useGetPointNamesQueryState },
    getPointNamesUsedIn: { useQueryState: useGetPointNamesUsedInQueryState },
    getPaginatedTags: { useQueryState: useGetPaginatedTagsQueryState },
  },
} = tagManagementApi
