import React, {
  memo,
  useEffect,
  useRef,
  type FC,
} from 'react'
import { RotatableArrowIcon } from '@phaidra/ava/icons'
import Typography from '@phaidra/ava/typography'
import { useTrendViewerProps } from '../../provider'
import { toggleExpandChart, type ChartsState } from '../../store/charts'
import { type ChartIndex } from '../../types'
import StyledAccordion from '../styled-accordion'
import { ChartTagsTypography } from './styles'

const CHART_HEADER_LABEL = {
  0: 'Chart 1',
  1: 'Chart 2',
  2: 'Chart 3',
}

export interface Props {
  chartIndex: ChartIndex
  chartTagsCount: number
  viewId: number | undefined
  children(isExpanded: boolean): React.ReactNode
  onChange?: () => void
}

const CollapsibleChartContainer: FC<Props> = ({
  chartIndex,
  chartTagsCount,
  viewId,
  children,
  onChange,
}) => {
  const { useAppDispatch, useAppSelector } = useTrendViewerProps()
  const dispatch = useAppDispatch()
  const chartsState: ChartsState = useAppSelector((state) => state.charts)
  const chartExpanded = chartsState[viewId]?.[chartIndex]?.chartExpanded ?? true

  const isMountedRef = useRef(false)
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true
      return
    }
    onChange?.()
  }, [chartExpanded, onChange])

  const toggleIsExpanded = () => {
    dispatch(toggleExpandChart({ viewId, chartIndex }))
  }

  const chartTagsText = `${chartTagsCount}/6 added tags`

  return (
    <StyledAccordion expanded={chartExpanded} onChange={toggleIsExpanded}>
      <>
        <RotatableArrowIcon direction={!chartExpanded ? 'up' : 'down'} isTiny />
        <Typography
          fontWeight={500}
          variant="caption"
          component="p"
          style={{ marginLeft: -2 }}
        >
          {CHART_HEADER_LABEL[chartIndex]}
        </Typography>
        <ChartTagsTypography>
          {chartTagsText}
        </ChartTagsTypography>
      </>
      {children(chartExpanded)}
    </StyledAccordion>
  )
}

export default memo(CollapsibleChartContainer)
