import React from 'react'
import Form from '@phaidra/ava/form'
import { GridColumns, GridColDef } from '@phaidra/ava/data-grid'
import Grid from '@phaidra/ava/grid'
import Toggle from '@phaidra/ava/toggle'
import Tooltip from '@phaidra/ava/tooltip'
import { TagStatus } from '@services/tag-management/types'
import { statuses } from '@common/status-styles/styles'
import { Setpoint } from '@services/setpoints/types'
import IconButton from '@phaidra/ava/icon-button'
import { NoteIcon } from '@phaidra/ava/icons'
import Typography from '@phaidra/ava/typography'
import { FrozenDropdownOption } from '../../tag-management/tags/table/utils/status-cell/styles'
import { ComponentTagNameContainer } from '../../tag-management/tags/table/styles'
import * as Styled from '../styles'

export const DataTypes = {
  PARAM: 'PARAM',
  SETPOINT: 'SETPOINT',
  COMPONENT: 'COMPONENT',
} as const

export interface SetpointTableRow {
  node_path: string[]
  id: string
  name: string
  status: string
  parentId: string | null
  type: keyof typeof DataTypes
  isUsed: string
  componentType: string
  setpoint?: Setpoint
  component_search_terms?: string
  navigationId?: string
  value?: string
  paramType?: string
  paramId?: number
  param_status?: string
  setpoint_fallback_status?: string | null
  toggleSetpoint?: () => void
  openSetpointDrawer?: () => void
}

export const columns: GridColumns = [
  {
    cellClassName: () => 'status-column',
    field: 'status',
    headerName: 'Status',
    width: 164,
    renderCell: ({ value }) => {
      const status = {
        label: statuses[value as TagStatus]?.displayName,
        value,
        color: statuses[value as TagStatus]?.color,
        borderColor: statuses[value as TagStatus]?.borderColor,
      }
      return (
        <Form
          render={() => (
            <FrozenDropdownOption
              color={status?.color}
              borderColor={status?.borderColor}
              disabled={false}
            >
              { status?.label}
            </FrozenDropdownOption>

          )}
        />

      )
    },
  },
]

const getDataTestIdForRow = (row: SetpointTableRow) => {
  if (row.type === DataTypes.SETPOINT) {
    return row.setpoint.fullSetpointName
  }

  if (row.type === DataTypes.PARAM) {
    return `${row.setpoint.fullSetpointName}.${row.paramType}`
  }

  return `component-name-${row.id}`
}

export const groupingColDef: Omit<GridColDef<SetpointTableRow, string>, 'field'> = {
  headerName: 'Component and Setpoints',
  flex: 1,
  cellClassName: ({ row }) => {
    if (row.type === DataTypes.COMPONENT) return 'component-record'
    if (row.type === DataTypes.SETPOINT) return 'setpoint-record'
    if (row.type === DataTypes.PARAM) return 'param-record'
  },
  renderCell: ({
    row, rowNode, api, value,
  }) => {
    const isComponent = row.type === DataTypes.COMPONENT
    const isSetpoint = row.type === DataTypes.SETPOINT
    const isParam = row.type === DataTypes.PARAM
    const isSetpointToggledOff = row?.isUsed === 'false' && row?.setpoint?.status === 'COMPLETE'
    const cursor = !isComponent ? 'pointer' : 'default'
    return (
      <Grid
        container
        alignItems="center"
        style={{ cursor, flexWrap: 'nowrap' }}
      >
        {isSetpoint && (
          <Grid
            item
            display="flex"
            alignItems="center"
          >
            <Styled.SwitchContainer>
              <Tooltip title="Configure setpoint to enable" placement="top">
                <Toggle
                  defaultChecked={row.isUsed === 'true'}
                  disabled={row.status !== 'COMPLETE'}
                  onChange={() => row.toggleSetpoint()}
                />
              </Tooltip>
            </Styled.SwitchContainer>
          </Grid>
        )}
        <Grid
          onClick={() => {
            if (isSetpoint) api.setRowChildrenExpansion(row.id, !rowNode.childrenExpanded)
          }}
          flex={1}
          item
          display="flex"
          alignItems="center"
        >
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '12px',
            }}
          >
            {isSetpoint && rowNode.children?.length && (
              <Grid mr="8px">
                <IconButton
                  size="tiny"
                  aria-label="Expand Setpoint"
                >
                  <Styled.KeyboardArrowUpIconContainer
                    isOpen={rowNode.childrenExpanded}
                    fontSize="small"
                  />
                </IconButton>
              </Grid>
            )}
            <ComponentTagNameContainer
              isComponent={isComponent}
              data-testid={getDataTestIdForRow(row)}
            >
              <Grid container flexDirection="column" flex={1} display="flex">
                {isComponent && (
                  <Typography variant="body" fontWeight={700}>
                    {value}
                  </Typography>
                )}
                {isSetpoint && (
                  <Typography variant="body">
                    {value}
                  </Typography>
                )}
                {isParam && (
                  <Styled.SBRName variant="caption" disabled={!row.value || isSetpointToggledOff}>
                    {value}
                  </Styled.SBRName>
                )}

                {isParam && row.value && (
                  <Styled.SBRValue
                    disabled={isSetpointToggledOff}
                    variant="caption"
                  >
                    {row.value}
                  </Styled.SBRValue>
                )}
              </Grid>
            </ComponentTagNameContainer>
          </Grid>
        </Grid>
        {isSetpoint && row?.setpoint?.notes && (
          <Grid display="flex" item justifyContent="flex-end">
            <IconButton
              aria-label="note-button"
              size="small"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                row.openSetpointDrawer()
              }}
            >
              <NoteIcon sx={{ height: '14px', width: '14px' }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    )
  },
}
