import styled from '@phaidra/ava/styled'
import Grid from '@phaidra/ava/grid'
import IconButton from '@phaidra/ava/icon-button'

export const StyledListItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),

  '&:hover': {
    '.MuiButtonBase-root': {
      display: 'inline-flex',
    },
  },
}))

export const Container = styled(Grid)(({ theme }) => ({
  width: 'auto',
  '&:hover': {
    backgroundColor: '#F2F2F2',
    paddingInline: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: '16px',
  width: '16px',
  display: 'none',
  '&.MuiButtonBase-root:hover': {
    background: 'none',
  },
  '& > svg': {
    color: theme.palette.icon.default,
  },
}))
