/* eslint-disable no-console */

import { NotificationsContext } from '@providers/notification'
import { useStartIngestionMutation } from '@services/historical-data-ingestion'
import { Ingestion, IngestionStatus } from '@services/historical-data-ingestion/types'
import {
  useState, useCallback, useContext,
} from 'react'
import { ProcessingIngestion } from '../types'

type ReturnType<T> = [T, (value: T) => void]

export const useLocalStorage = <DataType>(key: string, initialValue: DataType): ReturnType<DataType> => {
  const [storedValue, setStoredValue] = useState<DataType>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })
  const setValue = (value: DataType) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export const useNotification = () => {
  const { triggerNotification } = useContext(NotificationsContext)

  const notifySuccess = useCallback((timestamp: string) => {
    triggerNotification({
      message: `Data ingestion for ${timestamp} has successfully completed.`,
      variant: 'success',
      dismissable: true,
    })
  }, [triggerNotification])

  const notifyError = useCallback((failedIngestion: Ingestion, onRetry?: any) => {
    triggerNotification({
      message: `Data ingestion for ${failedIngestion.formattedTimestamp} failed to process${
        failedIngestion.status_message ? ` due to ${failedIngestion.status_message}` : ''
      }.`,
      variant: 'error',
      dismissable: true,
      actionButtons: onRetry ? [{
        label: 'Retry ingestion',
        variant: 'text',
        color: 'error',
        onClick: onRetry,
        closeOnClick: true,
      }] : [],
    })
  }, [triggerNotification])

  return { notifySuccess, notifyError }
}

export const useStartIngestionProcess = ({ onComplete }: { onComplete: (value: ProcessingIngestion) => void }): [
  (installationId: string, timestamp: Ingestion) => void,
  { isLoading: boolean },
] => {
  const [startIngestion, { isLoading }] = useStartIngestionMutation()
  const { notifyError } = useNotification()

  const startProcess = (installationId: string, timestamp: Ingestion) =>
    startIngestion({
      installationId,
      body: { gcs_folder_path: timestamp.gcs_folder_path },
    }).then(({ data: processingTimestamp }: { data: Ingestion }) => {
      const ingestionTimestamp = {
        ...timestamp,
        id: processingTimestamp.id,
        status: IngestionStatus.PROCESSING,
        started_at: processingTimestamp.started_at,
      }
      onComplete({
        installationId,
        visible: true,
        timestamp: ingestionTimestamp,
      })
    }).catch((error: Error) => {
      notifyError({ ...timestamp, status_message: error.message })
    })

  return [startProcess, { isLoading }]
}
