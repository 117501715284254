import React, { FC, ReactNode, useState } from 'react'
import {
  VariantType,
} from 'notistack'

import {
  StyledBox, CloseIcon, StyledIconButon, StyledRoot,
} from './styles'
import {
  WarningIcon,
  InfoOutlinedIcon,
  CheckCircleRoundedIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from '../icons'
import Typography from '../typography'
import Grid from '../grid'
import Button from '../button'
import { Props as ButtonProps } from '../button/types'
import { useTheme } from '../theme-provider'

const icons: Record<VariantType, ReactNode> = {
  error: <WarningIcon fontSize="small" />,
  warning: <WarningIcon fontSize="small" />,
  success: <CheckCircleRoundedIcon fontSize="small" />,
  info: <InfoOutlinedIcon fontSize="small" />,
  default: null,
}

export interface BannerData {
  message: ReactNode
  variant?: VariantType
  dismissable?: boolean
  iconOverride?: ReactNode
  actionButtons?: Array<{
    label: string
    onClick?: () => void
    closeOnClick?: boolean
    disabled?: boolean
    color?: ButtonProps['color']
    size?: ButtonProps['size']
    variant?: 'text' | 'outlined' | 'contained'
    type?: 'button' | 'submit'
    loading?: boolean
  }>
}

export interface BannerProps extends React.ComponentProps<typeof StyledRoot> {
  data: Array<BannerData>
  flex?: boolean
  onDismiss?: (index: number) => void
}

const Banner: FC<BannerProps> = ({
  data, flex, onDismiss, className,
}) => {
  const theme = useTheme()
  const [index, setIndex] = useState(0)

  if (data.length === 0 || !data[index]) {
    return null
  }

  return (
    <StyledRoot className={className} flex={flex}>
      <StyledBox
        container
        gap={1}
        alignItems="flex-start"
        flexWrap="nowrap"
        variant={data[index].variant}
        data-testid="banner"
      >
        {data[index].iconOverride ?? icons[data[index].variant]}
        {data[index].message}
        <div
          style={{
            display: 'flex',
            marginLeft: 'auto',
            alignItems: 'center',
            maxHeight: '20px',
          }}
        >
          {data[index].actionButtons && data[index].actionButtons.map(({
            label,
            onClick,
            closeOnClick = true,
            color,
            disabled = false,
            variant = 'text',
            type = 'button',
            size = 'medium',
            loading = false,
          }) => (
            <Button
              data-testid={`${label.toLowerCase()}-button`}
              key={label}
              type={type}
              variant={variant}
              disabled={disabled}
              color={color}
              size={size}
              onClick={() => {
                if (closeOnClick && type !== 'submit') {
                  setIndex(index - 1)
                }
                onClick?.()
              }}
              loading={loading}
            >
              {label}
            </Button>
          ))}
          {data.length > 1 && (
            <Grid
              container
              flexWrap="nowrap"
              flex={1}
              justifyContent="flex-end"
              alignItems="center"
              gap={0.5}
              data-testid="paginator"
            >
              <StyledIconButon
                data-testid="previous-banner"
                size="tiny"
                disabled={index === 0}
                onClick={() => setIndex(index - 1)}
              >
                <KeyboardArrowLeftIcon style={{ color: theme.palette.icon.default }} />
              </StyledIconButon>
              <Typography whiteSpace="nowrap" fontWeight="500" variant="body">
                {`${index + 1}-${data.length}`}
              </Typography>
              <StyledIconButon
                data-testid="next-banner"
                size="tiny"
                disabled={index === data.length - 1}
                onClick={() => setIndex(index + 1)}
              >
                <KeyboardArrowRightIcon style={{ color: theme.palette.icon.default }} />
              </StyledIconButon>
            </Grid>
          )}
          {data[index].dismissable && (
            <StyledIconButon
              size="tiny"
              sx={{ marginLeft: '8px' }}
              onClick={() => {
                if (onDismiss) {
                  onDismiss(index)
                  if (index > 0 && index === data.length - 1) {
                    setIndex(index - 1)
                  }
                }
              }}
            >
              <CloseIcon />
            </StyledIconButon>
          )}
        </div>
      </StyledBox>
    </StyledRoot>
  )
}
export default Banner
