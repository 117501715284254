import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Component, ComponentTree } from '@services/system-instance/types'

type UpdateAction = 'SERVER'
| 'ADDED_COMPONENT'
| 'DELETED_COMPONENT'
| 'UPDATE_NAME'
| 'UPDATE_DESCRIPTION'
| 'UPDATE_HIERARCHY'
export interface SystemBuilderState {
  lastUpdateAction?: UpdateAction
  componentTree?: ComponentTree
  persistedComponentTreeHash?: string
  hasSendUpdatedTreeToBackEnd?: boolean
  isFetching?: boolean
}

export interface AddNewComponents {
  parentId: string
  components: Array<{
    id: string
    name: string
    description: string
    amount: number
  }>
}

export interface AddRelations {
  componentId: string
  relations: Array<{ cxnName: string, componentId: string }>
}

const initialState: SystemBuilderState = {
  lastUpdateAction: undefined,
  componentTree: undefined,
  persistedComponentTreeHash: undefined,
  hasSendUpdatedTreeToBackEnd: true,
  isFetching: false,
}

export const systemBuilderSlice = createSlice({
  name: 'systemBuilder',
  initialState,
  reducers: {
    updateSystemBuilderState: (
      state,
      { payload }: PayloadAction<Partial<SystemBuilderState>>,
    ) => ({
      ...state,
      ...payload,
    }),
    resetLastUpdateAction: (state) => ({
      ...state,
      lastUpdateAction: undefined,
    }),
    updateTreeHash: (state, { payload }: PayloadAction<{ persistedComponentTreeHash: string }>) => ({
      ...state,
      persistedComponentTreeHash: payload.persistedComponentTreeHash,
    }),
    updateTreeHierarchy: (state, { payload }: PayloadAction<{
      componentTree: ComponentTree
      persistedComponentTreeHash?: string
    }>) => ({
      ...state,
      lastUpdateAction: 'UPDATE_HIERARCHY',
      componentTree: payload.componentTree,
      persistedComponentTreeHash: payload.persistedComponentTreeHash,
    }),
    setComponentTree: (state, { payload }: PayloadAction<{
      componentTree: ComponentTree
      persistedComponentTreeHash?: string
    }>) => ({
      ...state,
      lastUpdateAction: 'SERVER',
      componentTree: payload.componentTree,
      persistedComponentTreeHash: payload.persistedComponentTreeHash,
    }),
    addNewComponents: (state, { payload }: PayloadAction<AddNewComponents>) => {
      const updatedComponents: Record<string, Component> = { ...state.componentTree.components }

      payload.components.forEach((component) => {
        for (let i = 0; i < component.amount; i += 1) {
          const maxInstance = Math.max(0, ...Object.values(updatedComponents)
            .filter(({ component: { defName } }) => defName === component.id)
            .map(({ component: { instance } }) => instance))
          updatedComponents[`${component.id}.${maxInstance + 1}`] = {
            description: component.description,
            component: {
              id: `${component.id}.${maxInstance + 1}`,
              defName: component.id,
              instance: maxInstance + 1,
              name: `${component.name} ${maxInstance + 1}`,
              tags: [],
            },
          }
          updatedComponents[payload.parentId] = {
            ...updatedComponents[payload.parentId],
            children: [
              ...(updatedComponents[payload.parentId].children || []),
              `${component.id}.${maxInstance + 1}`,
            ],
          }
        }
      })

      return {
        ...state,
        lastUpdateAction: 'ADDED_COMPONENT',
        componentTree: {
          ...state.componentTree,
          components: updatedComponents,
        },
      }
    },
    deleteComponent: (state, { payload }: PayloadAction<string>) => {
      const updatedComponents: Record<string, Component> = { ...state.componentTree.components }

      const componentKeys = Object.keys(updatedComponents)
      for (let i = 0; i < componentKeys.length; i += 1) {
        if (updatedComponents[componentKeys[i]].children?.includes(payload)) {
          updatedComponents[componentKeys[i]] = {
            ...updatedComponents[componentKeys[i]],
            children: updatedComponents[componentKeys[i]].children.filter((id) => id !== payload),
          }
          break
        }
      }

      const removeRelationship = (id: string) => {
        Object.keys(updatedComponents).forEach((key) => {
          updatedComponents[key] = {
            ...updatedComponents[key],
            component: {
              ...updatedComponents[key].component,
              relations: updatedComponents[key].component.relations?.filter(
                ({ componentId }) => componentId !== id,
              ),
            },
          }
        })
      }

      const resursiveDelete = (id: string) => {
        updatedComponents[id].children?.forEach((childId) => {
          resursiveDelete(childId)
        })
        removeRelationship(id)
        delete updatedComponents[id]
      }
      resursiveDelete(payload)

      return {
        ...state,
        lastUpdateAction: 'DELETED_COMPONENT',
        componentTree: {
          ...state.componentTree,
          components: updatedComponents,
        },
      }
    },
    updateName: (state, { payload: { componentId, name } }: PayloadAction<{ componentId: string, name: string }>) => ({
      ...state,
      lastUpdateAction: 'UPDATE_NAME',
      componentTree: {
        ...state.componentTree,
        components: {
          ...state.componentTree.components,
          [componentId]: {
            ...state.componentTree.components[componentId],
            component: {
              ...state.componentTree.components[componentId].component,
              name,
            },
          },
        },
      },
    }),
    updateDescription: (
      state,
      { payload: { componentId, description } }: PayloadAction<{ componentId: string, description: string }>,
    ) => ({
      ...state,
      lastUpdateAction: 'UPDATE_DESCRIPTION',
      componentTree: {
        ...state.componentTree,
        components: {
          ...state.componentTree.components,
          [componentId]: {
            ...state.componentTree.components[componentId],
            description,
          },
        },
      },
    }),
    addRelations: (state, { payload: { componentId, relations } }: PayloadAction<AddRelations>) => ({
      ...state,
      lastUpdateAction: 'UPDATE_DESCRIPTION',
      componentTree: {
        ...state.componentTree,
        components: {
          ...state.componentTree.components,
          [componentId]: {
            ...state.componentTree.components[componentId],
            component: {
              ...state.componentTree.components[componentId].component,
              relations,
            },
          },
        },
      },
    }),
  },
})

export const {
  updateSystemBuilderState,
  resetLastUpdateAction,
  setComponentTree,
  updateTreeHierarchy,
  addNewComponents,
  deleteComponent,
  updateName,
  updateDescription,
  addRelations,
  updateTreeHash,
} = systemBuilderSlice.actions

export default systemBuilderSlice.reducer
