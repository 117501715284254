import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { historicalDataIngestionApi } from '@services/historical-data-ingestion'
import { setpointsApi } from '@services/setpoints'
import { componentsApi } from '@services/components'
import { systemInstanceApi } from '@services/system-instance'
import { datasetsApi } from '@services/datasets'
import { installationApi } from '@services/installation'
import { versionsApi } from '@services/versions'
import { tagManagementApi } from '@services/tag-management'
import { trendViewerApi, trendViewerReducers } from '@phaidra/trend-viewer'
import { nelDebuggerApi } from '@services/nel-debugger'
import { healthRulesApi } from '@services/healthrules'
import { constraintsApi } from '@services/constraints'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import sidebarsReducer from './sidebars'
import snackbarReducer from './snackbar'
import systemBuilderReducer from './system-builder'
import tagsReducer from './tag-management'

const rootReducer = combineReducers({
  sidebars: sidebarsReducer,
  snackbars: snackbarReducer,
  systemBuilder: systemBuilderReducer,
  tags: tagsReducer,
  [historicalDataIngestionApi.reducerPath]: historicalDataIngestionApi.reducer,
  [setpointsApi.reducerPath]: setpointsApi.reducer,
  [healthRulesApi.reducerPath]: healthRulesApi.reducer,
  [componentsApi.reducerPath]: componentsApi.reducer,
  [systemInstanceApi.reducerPath]: systemInstanceApi.reducer,
  [datasetsApi.reducerPath]: datasetsApi.reducer,
  [installationApi.reducerPath]: installationApi.reducer,
  [versionsApi.reducerPath]: versionsApi.reducer,
  [tagManagementApi.reducerPath]: tagManagementApi.reducer,
  [trendViewerApi.reducerPath]: trendViewerApi.reducer,
  [nelDebuggerApi.reducerPath]: nelDebuggerApi.reducer,
  [constraintsApi.reducerPath]: constraintsApi.reducer,
  ...trendViewerReducers,
})

const store = configureStore({
  reducer: (state, action) =>
    rootReducer(action.type === 'RESET' ? undefined : state, action),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(historicalDataIngestionApi.middleware)
      .concat(setpointsApi.middleware)
      .concat(healthRulesApi.middleware)
      .concat(componentsApi.middleware)
      .concat(systemInstanceApi.middleware)
      .concat(datasetsApi.middleware)
      .concat(installationApi.middleware)
      .concat(versionsApi.middleware)
      .concat(tagManagementApi.middleware)
      .concat(trendViewerApi.middleware)
      .concat(nelDebuggerApi.middleware)
      .concat(constraintsApi.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
