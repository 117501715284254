import styled from '@phaidra/ava/styled'

export const Container = styled('div')<{ isOver?: boolean, hideDropzone?: boolean }>(({
  isOver,
  hideDropzone,
  theme,
}) => ({
  display: 'flex',
  width: '216px',
  height: '36px',
  borderRadius: theme.shape.borderRadius * 1.5,
  marginTop: theme.spacing(0.5),
  backgroundColor: theme.palette.container.surface.drawer,
  border: `solid 1px ${theme.palette.container.border.subduedLow}`,
  overflow: 'hidden',
  alignItems: 'center',
  position: 'relative',
  ...(!hideDropzone && { zIndex: 102 }),
  ...(isOver && !hideDropzone && {
    backgroundColor: theme.palette.alert.surface.successLow,
    borderColor: theme.palette.alert.border.success,
  }),
}))
