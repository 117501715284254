import { RemoveIcon } from '../icons'
import styled from '../styled'

export const UncheckedIcon = styled('span')<{ disabled?: boolean }>(({ theme }) => ({
  borderRadius: 5,
  width: 20,
  height: 20,
  padding: `${theme.spacing(0.375)} ${theme.spacing(0.25)}`,
  border: `1px solid ${theme.palette.container.border.default}`,
  background: 'transparent',
  'input:disabled ~ &': {
    backgroundColor: `${theme.palette.container.surface.disabled} !important`,
    border: `1px solid ${theme.palette.container.surface.disabled} !important`,
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
}))

export const CheckedIcon = styled(UncheckedIcon)<{ disabled?: boolean }>(({ theme, disabled }) => {
  const fill = theme.palette.icon.defaultInverse.substr(1)
  let bgColor = theme.palette.container.surface.activeHigh

  if (disabled) {
    bgColor = theme.palette.container.surface.disabled
  }

  return ({
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
      'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' '
      + 'width=\'16\' height=\'16\' fill=\'none\'%3E%3Cpath '
      + 'd=\'M4.77362 11.3327C4.53779 11.3326 4.31164 11.2389 4.1449 11.0722L0.587775 '
      + '7.51664C0.423515 7.34945 0.331955 7.12418 0.333017 6.88986C0.334079 6.65553 0.427676 '
      + '6.4311 0.593443 6.2654C0.759211 6.09971 0.983736 6.00615 1.21816 6.00509C1.45259 6.00403 '
      + '1.67796 6.09555 1.84522 6.25974L4.73972 9.153L12.1162 0.960196C12.274 0.784992 12.495 '
      + '0.679613 12.7305 0.667242C12.966 0.65487 13.1968 0.736519 13.372 0.894227C13.5473 1.05193 '
      + '13.6527 1.27278 13.6651 1.50819C13.6775 1.74359 13.5958 1.97427 13.438 2.14947L5.4345 '
      + '11.0385C5.35372 11.1281 5.25557 11.2004 5.14599 11.251C5.03642 11.3015 4.91772 11.3293 '
      + '4.79707 11.3327H4.77362Z\' '
      + `fill='%23${fill}'/%3E%3C/svg%3E")`,
      content: '""',
    },
    backgroundColor: bgColor,
    border: `1px solid ${bgColor}`,
    'input:disabled ~ &': {
      backgroundColor: `${theme.palette.container.surface.disabled} !important`,
      border: `1px solid ${theme.palette.container.surface.disabled} !important`,
    },
    'input:hover ~ &': {
      backgroundColor: bgColor,
    },
  })
})

export const IndeterminateIcon = styled(RemoveIcon)<{ disabled?: boolean }>(({ theme, disabled }) => {
  const fill = theme.palette.icon.defaultInverse
  let bgColor = theme.palette.container.surface.activeHigh

  if (disabled) {
    bgColor = theme.palette.container.surface.disabled
  }

  return ({
    display: 'block',
    width: 20,
    height: 20,
    color: fill,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${bgColor}`,
    backgroundColor: bgColor,
    'input:disabled ~ &': {
      backgroundColor: `${theme.palette.container.surface.disabled} !important`,
      border: `1px solid ${theme.palette.container.surface.disabled} !important`,
    },
    'input:hover ~ &': {
      backgroundColor: bgColor,
    },
  })
})

export const HoverDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ disabled, theme }) => ({
  borderRadius: 100,
  position: 'relative',
  width: '30px',
  height: '30px',
  justifyContent: 'center',
  display: 'flex',
  cursor: disabled ? 'no-drop' : 'default',
  '&:hover': disabled ? {} : { backgroundColor: theme.palette.hover.surface.default },
}))

export const Container = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(0.375)} 0`,
  width: 'fit-content',
  marginRight: theme.spacing(-0.625),
}))
