import React, {
  FC, useEffect, useRef, useState,
} from 'react'
import MUITabs, { TabsProps as MUITabsProps } from '@mui/material/Tabs'
import styled from '../styled'

const StyledTabs = styled(MUITabs, {
  shouldForwardProp: (prop: string) => !['scrolled', 'noBorder'].includes(prop),
})<TabsProps & { scrolled: boolean, noBorder?: boolean }>(({
  theme, scrolled, noBorder,
}) => ({
  minHeight: '40px',
  '& .MuiTab-root': {
    fontWeight: '400',
    order: '1',
    marginLeft: theme.spacing(-0.125),
    height: '40px',
    minHeight: '40px',
    borderRadius: '0',
    color: theme.palette.text.subdued,
    borderColor: theme.palette.dividers.low,
    fontSize: '0.75rem',
    lineHeight: '16px',
    maxWidth: '200px',
    minWidth: '80px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    flexDirection: 'unset',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '280px !important',
    },
    padding: '12px',

    '&:first-of-type': {
      borderRadius: '4px 0 0 0',
    },

    '&:hover': {
      backgroundColor: theme.palette.hover.surface.default,
    },
    '&.Mui-selected': {
      fontWeight: '500',
      color: theme.palette.text.active,
      zIndex: 1,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.hover.surface.default,
      },
      ...(noBorder ? {
        fontWeight: '500',
        backgroundColor: 'none',
        borderBottom: `3px solid ${theme.palette.container.border.secondary}`,
      } : {
        borderBottom: `2px solid ${theme.palette.container.border.secondary}`,
      }),
    },
    ...(noBorder && {
      borderRadius: '0 !important',
      border: 'none',
      borderBottom: `1px solid ${theme.palette.dividers.low}`,
      '&:hover': {
        backgroundColor: `${theme.palette.hover.surface.default} !important`,
      },
    }),
  },
  ...(noBorder && {
    '.MuiButtonBase-root': {
      borderBottom: `1px solid ${theme.palette.dividers.low}`,
    },
  }),
  '.MuiTabs-indicator': {
    display: 'none',
  },
  '.MuiTabs-flexContainer': {
    marginLeft: theme.spacing(0.125),
  },
  '.MuiTabs-scrollButtons': {
    border: `1px solid ${theme.palette.dividers.low}`,
    width: '30px',
    height: '40px',
    zIndex: '1',
    marginLeft: theme.spacing(-0.125),

    '&:hover': {
      backgroundColor: theme.palette.hover.surface.default,
    },
    '&.Mui-disabled': {
      pointerEvents: 'all',
      opacity: '1',
      '& > svg': {
        fill: theme.palette.icon.disabled,
      },
    },
    '& > svg': {
      fill: theme.palette.icon.default,
    },
  },
  '& > .MuiTabs-scroller': {
    marginLeft: /* istanbul ignore next */ scrolled ? 'none' : theme.spacing(-0.125),
    borderLeft: `1px solid ${theme.palette.dividers.low}`,
  },
  '.MuiTabs-scrollButtons:not(.MuiTabs-scrollButtons ~ .MuiTabs-scrollButtons)': {
    order: '2',
    borderRightWidth: '0',
    boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.10)',
    '&:hover': {
      zIndex: '2',
    },
  },
  '.MuiTabs-scrollButtons ~ .MuiTabs-scrollButtons': {
    borderRadius: '0px 4px 0px 0px',
    order: '3',
    borderLeftWidth: '0',
    '&:hover': {
      zIndex: '2',
    },
  },
  '& .MuiTabs-scroller:nth-of-type(1)': {
    '& .MuiTab-root': {
      '&:last-of-type': {
        borderRadius: '0 4px 0 0',
      },
    },
  },
  '& .MuiTabs-scroller:nth-of-type(2)': {
    '& .MuiTab-root': {
      '&:last-of-type': {
        borderRadius: '0 4px 0 0',
      },
    },
  },
}))

export type TabsProps = MUITabsProps & {
  scrollable?: boolean
  noBorder?: boolean
}

const Tabs: FC<TabsProps> = ({ scrollable = false, ...props }) => {
  const tabsRef = useRef<HTMLDivElement>()
  const [scroll, setScroll] = useState<boolean>(false)

  const listenScrollEvent = () => {
    /* istanbul ignore next */
    const item = document.getElementsByClassName('MuiTabs-flexContainer')?.item(0)
    setScroll(Math.abs(item.getBoundingClientRect().x - item.parentElement.getBoundingClientRect().x) > 10)
  }

  useEffect(() => {
    tabsRef.current.children[1]?.addEventListener('scroll', listenScrollEvent)
    /* istanbul ignore next */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => tabsRef?.current?.children[1]?.removeEventListener('scroll', listenScrollEvent)
  }, [])

  return (
    <StyledTabs
      ref={tabsRef}
      scrolled={scroll}
      {...(scrollable && {
        variant: 'scrollable',
        scrollButtons: 'auto',
      })}
      {...props}
    />
  )
}
export default Tabs
