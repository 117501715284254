import styled from '@phaidra/ava/styled'
import IconButton from '@phaidra/ava/icon-button'

export const NodeContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  maxHeight: '28px',
  width: 'calc(100% - 120px)',
}))

export const HorizontalConnector = styled('div')(() => ({
  height: '1px',
  backgroundColor: '#D4D4D5',
  position: 'absolute',
  right: '100%',
  left: '-20px',
  top: '1px',
}))

export const Collapser = styled('div')({
  position: 'absolute',
  left: '-26px',
  height: '16px',
  aspectRatio: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  backgroundColor: '#fff',
})

export const Container = styled('div')({
  display: 'flex',
  minWidth: '320px',
  width: 'inherit',
})

export const VerticalTagConnector = styled('div')(({
  width: '1px',
  height: '45px',
  backgroundColor: '#d4d4d5',
  marginRight: '35px',
  marginLeft: '19.5px',
  marginTop: '-14.5px',
  zIndex: -1,
}))

export const StyledIconButton = styled(IconButton)({
  '& > svg': {
    color: '#155E2A !important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.MuiButtonBase-root': {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
})
