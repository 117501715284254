import styled from '../../../../styled'

export const Line = styled('div', {})<{
  x: number
  y: number
  show: boolean
  isPreview: boolean
  width: number
}>(({
  x,
  y,
  show,
  isPreview,
  width,
  theme,
}) => ({
  position: 'absolute',
  height: '1px',
  top: `${Math.max(10, Math.min(y, 267))}px`,
  left: `${x}px`,
  width: show ? `${width}px` : '0',
  backgroundColor: isPreview ? theme.palette.icon.subduedInverse : theme.palette.icon.default,
  display: y && show ? 'block' : 'none',
  zIndex: 2,
}))
