import React, { FC, useState } from 'react'
import { Controller, useFormContext, UseControllerProps } from 'react-hook-form'

import BaseDropdown, { BaseApi } from '../base'
import Typography from '../../typography'
import Grid from '../../grid'
import MenuItem from '../../menu-item'
import { CheckIcon } from '../../icons'
import { StyledMenuItem } from './styles'
import Label from './label'

export interface Api {
  clear: () => void
}

export type Props = {
  label: string
  options: Array<{ label: string, value: any }>
  name: string
  defaultValue?: string | number
  rules?: UseControllerProps['rules']
  fullWidth?: boolean
  clearable?: boolean
  'data-testid'?: string
  disabled?: boolean
  size?: 'large' | 'medium' | 'small'
  noSelection?: boolean
  excludeSelectedValue?: boolean
  website?: boolean
}

const Basic: FC<Props> = ({
  label,
  options,
  name,
  defaultValue,
  rules,
  fullWidth,
  clearable,
  'data-testid': dataTestId,
  disabled,
  size,
  noSelection = false,
  excludeSelectedValue = false,
  website,
}) => {
  const { control } = useFormContext()
  const [baseApi, setBaseApi] = useState<BaseApi | null>(null)

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Grid container flexDirection="column" style={{ position: 'relative' }} data-testid={`container-${dataTestId}`}>
          <BaseDropdown
            size={size}
            fullWidth={fullWidth}
            error={!!error}
            onApiReady={setBaseApi}
            disabled={value?.loading || disabled}
            label={(
              <Label {...{
                value, label, rules, options, clearable, disabled, onChange, noSelection, size,
              }}
              />
            )}
            data-testid={dataTestId}
            menuLabel={`${label} menu`}
            menu={(
                options.length > 0 ? (
                  options.map(({ label: optionLabel, value: optionValue }) => {
                    const isSelected = optionValue === value
                    return (excludeSelectedValue && isSelected ? null : (
                      <StyledMenuItem
                        key={`${optionLabel}:${optionValue}`}
                        selected={isSelected && !website}
                        aria-selected={isSelected && !website}
                        onClick={() => {
                          onChange(optionValue)
                          baseApi.close()
                        }}
                        disableRipple
                        size={`${size}`}
                        sx={(theme) => ({ justifyContent: 'space-between', paddingRight: theme.spacing(1.5) })}
                      >
                        {optionLabel}
                        {isSelected && (
                          <CheckIcon
                            fontSize="small"
                            sx={{ color: '#1A1C19', ...(website && { fontSize: '0.875rem' }) }}
                          />
                        )}
                      </StyledMenuItem>
                    ))
                  })
                ) : (
                  <MenuItem disableRipple disabled size={`${size}`}>
                    No options available
                  </MenuItem>
                )
            )}
            website={website}
          />
          {error && <Typography color="error" variant="caption">{error.message}</Typography>}
        </Grid>
      )}
    />
  )
}

export default Basic
