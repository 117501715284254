import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  type FC,
} from 'react'
import { type ChartIndex } from '../../../types'

type PreviewTagSkeletonValue = {
  tagId?: number | null
  clientPointId?: string | null
  chartIndex: ChartIndex | null
}

type PreviewTagSkeletonProp = {
  previewTagSkeletonValue: PreviewTagSkeletonValue
  setPreviewTagSkeletonValue: (newValue: PreviewTagSkeletonValue | null) => void
}

const PreviewTagSkeleton = createContext<PreviewTagSkeletonProp>({
  previewTagSkeletonValue: null,
  setPreviewTagSkeletonValue: null,
})

const PreviewTagSkeletonProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [previewTagSkeletonValue, setPreviewTagSkeletonValue] = useState<PreviewTagSkeletonValue | null>()

  const value = useMemo(() => ({
    previewTagSkeletonValue,
    setPreviewTagSkeletonValue,
  }), [previewTagSkeletonValue])

  return (
    <PreviewTagSkeleton.Provider value={value}>
      {children}
    </PreviewTagSkeleton.Provider>
  )
}

export const usePreviewTagSkeleton = () => useContext(PreviewTagSkeleton)

export default PreviewTagSkeletonProvider
