import React, { FC } from 'react'

import alfred from './assets/alfred.svg'
import Grid from '../../../grid'
import Typography from '../../../typography'

interface Props {
  label?: string
}

const NoRowsOverlay: FC<Props> = ({ label }) => (
  <Grid container flexDirection="column" gap={1} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
    <img alt="phaidra-logo" src={alfred} />
    <Typography variant="body" component="p" fontWeight="700">{label || 'No rows'}</Typography>
  </Grid>
)

export default NoRowsOverlay
