import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ConstraintsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26" data-testid="icon-constraints">
    <path
      d="M1.22412 22.9074V3.98075L3.63578 1.56909H22.5625L24.9741 3.98075V22.9074L22.5625 25.3191H3.63578L1.22412
    22.9074Z"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <path d="M10.3153 7.39868L10.3153 11.6772" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M10.3153 15.2109L10.3153 19.4895" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M10.9423 15.7435L6.64539 15.7435" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M10.9384 11.0781L6.64539 11.0781" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M19.5529 11.0781L15.2833 11.0781" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M19.5529 15.7435L15.2794 15.7435" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M15.9039 7.39868V11.6772" stroke="currentColor" strokeWidth="1.25" fill="none" />
    <path d="M15.9039 15.2109V19.4895" stroke="currentColor" strokeWidth="1.25" fill="none" />
  </SvgIcon>
)

export default ConstraintsIcon
