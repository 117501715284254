export const RawData = {
  GetClientPoints: 'GetClientPoints',
}

export const Organisation = {
  GetUnitDimensionality: 'GetUnitDimensionality',
}

export const ActionExplorer = {
  GetAgentList: 'GetAgentList',
  GetActionsDebugger: 'GetActionsDebugger',
  GetPlantSetpoints: 'GetPlantSetpoints',
  GetAgentActionsList: 'GetAgentActionsList',
}
