import { RunStatus } from '@services/healthrules/types'
import { TagStatus } from '@services/tag-management/types'
import { colors } from '@phaidra/ava/theme-provider/themes/common'

export type StatusInfo = {
  displayName: string
  status: TagStatus | RunStatus
  color: string
  borderColor: string
}

export type Statuses = Record<TagStatus | RunStatus, StatusInfo>

export const statuses: Statuses = {
  COMPLETE: {
    displayName: 'Complete',
    status: 'COMPLETE',
    color: colors.green[8],
    borderColor: colors.gray[3],
  },
  INCOMPLETE: {
    displayName: 'Incomplete',
    status: 'INCOMPLETE',
    color: colors.red[7],
    borderColor: colors.red[2],
  },
  NOT_REQUIRED: {
    displayName: 'Not required',
    status: 'NOT_REQUIRED',
    color: colors.blue[7],
    borderColor: colors.blue[4],
  },
  CONFIRM: {
    displayName: 'Confirm',
    status: 'CONFIRM',
    color: colors.red[8],
    borderColor: colors.red[3],
  },
  PHAIDRA_TO_CALCULATE: {
    displayName: 'Phaidra to calculate',
    status: 'PHAIDRA_TO_CALCULATE',
    color: colors.gold[7],
    borderColor: colors.gold[3],
  },
  DOES_NOT_EXIST: {
    displayName: 'Does not exist',
    status: 'DOES_NOT_EXIST',
    color: colors.gray[8],
    borderColor: colors.gray[3],
  },
  PASS: {
    displayName: 'Passing',
    status: 'PASS',
    color: colors.green[8],
    borderColor: colors.gray[3],
  },
  FAIL: {
    displayName: 'Failing',
    status: 'FAIL',
    color: colors.red[7],
    borderColor: colors.red[2],
  },
  IGNORE: {
    displayName: 'Ignored',
    status: 'IGNORE',
    color: colors.blue[7],
    borderColor: colors.blue[4],
  },
}
