import {
  VariantType,
} from 'notistack'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'

import styled from '../styled'

export const StyledCard = styled(Card)<{
  severity: VariantType
}>(({
  severity,
  theme,
}) => {
  let bgColor = theme.palette.container.surface.default
  let borderColor = theme.palette.container.border.subduedHigh
  const fontColor = theme.palette.text.default
  switch (severity) {
    case 'success':
      bgColor = theme.palette.alert.surface.success
      borderColor = theme.palette.alert.border.success
      break
    case 'error':
      bgColor = theme.palette.alert.surface.error
      borderColor = theme.palette.alert.border.error
      break
    case 'warning':
      bgColor = theme.palette.alert.surface.warning
      borderColor = theme.palette.alert.border.warning
      break
    case 'info':
      bgColor = theme.palette.alert.surface.info
      borderColor = theme.palette.alert.border.info
      break
  }

  return ({
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    color: fontColor,
    minWidth: '440px!important',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    maxWidth: '720px',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      maxHeight: 'unset',
    },
    maxHeight: '44px',
    display: 'flex',
    alignItems: 'center',
  })
})

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(1.5),
  width: '100%',
}))

export const StyledIconContainerRight = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto !important',
  marginRight: 0,
  marginTop: theme.spacing(-1.25),
  marginBottom: theme.spacing(-1.25),
}))
