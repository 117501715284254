import React, { ReactNode } from 'react'
import { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary'

import Grid from '../grid'
import { KeyboardArrowDownRoundedIcon, StyledAccordionSummary } from './styles'

type AccordionSummaryProps = Omit<MuiAccordionSummaryProps, 'color'> & {
  rightComponent?: ReactNode
}

const AccordionSummary = (props: AccordionSummaryProps) => {
  const { rightComponent, ...otherProps } = props
  return (
    <StyledAccordionSummary
      expandIcon={(
        <Grid container alignItems="center" gap={2}>
          {rightComponent}
          <KeyboardArrowDownRoundedIcon />
        </Grid>
      )}
      {...otherProps}
    />
  )
}
export default AccordionSummary
