import React from 'react'
import Box from '@phaidra/ava/box'
import LoadingIcon from '@phaidra/ava/loading-icon'

const PageSpinner = () => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <LoadingIcon />
  </Box>
)

export default PageSpinner
