import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ChevronDownIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20" fill="none" data-testid="chevron-down-icon">
    <path
      d="M10 13.4775L4 7.45142L4.95572 6.52246L10 11.5661L15.0443 6.52246L16 7.47818L10 13.4775Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default ChevronDownIcon
