import Drawer from '@mui/material/Drawer'
import styled from '../styled'

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    maxWidth: '1080px',
    width: '100%',
    zIndex: 1500,
  },
})
