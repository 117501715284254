import styled from '../../../../styled'
import leftTriangle from '../assets/triangle-left.svg'

export const BASE_HEIGHT = 9
const OFFSET_BETWEEN_MARKER_AND_CHARTLINE = 1
const MARKER_WIDTH = 8

export const Console = styled('div')(({ theme }) => ({
  height: '32px',
  backgroundColor: theme.palette.container.surface.constants,
  color: theme.palette.container.surface.default,
  borderRadius: theme.shape.borderRadius,
  animation: 'fadeIn 300ms',
  animationDelay: '500ms',
  animationFillMode: 'forwards',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginTop: theme.spacing(-1.5),
}))

export const ConsoleButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(-1.25),
  boxSizing: 'border-box',
  cursor: 'pointer',
  backgroundColor: theme.palette.container.surface.constants,
  color: theme.palette.container.surface.default,
  border: 'none',
  '&:hover': {
    backgroundColor: theme.palette.alert.text.success,
  },
  '&:first-of-type': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}))

export const ConsoleValue = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: theme.palette.alert.text.success,
  },
  minWidth: '50px',
}))

export const PaletteContainer = styled('div', {})<{
  x: number
  y: number
  hide: boolean
}>(({
  x,
  y,
  hide,
}) => ({
  display: hide ? 'none' : 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: `${y - (BASE_HEIGHT / 2)}px`,
  left: `${x + 10 - OFFSET_BETWEEN_MARKER_AND_CHARTLINE}px`,
  zIndex: 10,
  cursor: 'pointer',
}))

export const RightSideMarker = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.0625),
  width: `${MARKER_WIDTH}px`,
  height: `${BASE_HEIGHT}px`,
  background: `url("${leftTriangle}") center left no-repeat`,
  backgroundSize: 'contain',
}))

export const VerticalDivider = styled('div')(({ theme }) => ({
  width: '1px',
  height: '32px',
  backgroundColor: theme.palette.container.border.default,
}))

export const ValueInput = styled('input')({
  all: 'unset',
  fontSize: '0.75rem',
  maxWidth: '100px',
  cursor: 'text',
})
