import {
  VariantType,
} from 'notistack'
import styled from '../styled'
import Grid from '../grid'
import IconButton from '../icon-button'
import { CloseIcon as CustomCloseIcon } from '../icons'

export const StyledRoot = styled('div')<{ flex?: boolean }>(({ flex }) => ({
  flex: flex ? 1 : 0,
}))

export const StyledBox = styled(Grid)<{ variant?: VariantType }>(({ variant, theme }) => {
  let bgColor = theme.palette.container.surface.default
  let borderColor = theme.palette.container.border.subduedHigh
  let iconColor = ''
  switch (variant) {
    case 'success':
      bgColor = theme.palette.alert.surface.success
      borderColor = theme.palette.alert.border.success
      iconColor = theme.palette.alert.text.success
      break
    case 'error':
      bgColor = theme.palette.alert.surface.error
      borderColor = theme.palette.alert.border.error
      iconColor = theme.palette.alert.text.error
      break
    case 'warning':
      bgColor = theme.palette.alert.surface.warning
      borderColor = theme.palette.alert.border.warning
      iconColor = theme.palette.alert.text.warning
      break
    case 'info':
      bgColor = theme.palette.alert.surface.info
      borderColor = theme.palette.alert.border.info
      iconColor = theme.palette.alert.text.info
      break
  }

  return ({
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    alignItems: 'center',
    '& svg': {
      color: iconColor,
    },
    fontSize: '0.875rem',
    lineHeight: '18px',
  })
})

export const StyledIconButon = styled(IconButton)({
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  width: '14px !important',
  '& svg': {
    fontSize: '14px',
  },
})

export const CloseIcon = styled(CustomCloseIcon)(({ theme }) => ({
  color: `${theme.palette.icon.default} !important`,
  height: '20px',
  width: '20px',
  fontSize: '14px',
}))
