import { BannerData } from 'packages/ava/src/banner'
import React, {
  createContext, useCallback, useState, useMemo, PropsWithChildren,
} from 'react'

export type Message = { id: string } & BannerData

interface NotificationsContextType {
  notifications: Message[]
  triggerNotification: (notification: Omit<Message, 'id'>) => void
  removeNotification: (id: string) => void
}

export const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined)

export const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<Message[]>([])

  const triggerNotification = useCallback((notification: Omit<Message, 'id'>) => {
    const id = String(new Date().getTime())
    setNotifications((prev) => [...prev, { id, ...notification }])
  }, [])

  const removeNotification = useCallback((id: string) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id))
  }, [])

  const value = useMemo(() => ({
    notifications,
    triggerNotification,
    removeNotification,
  }), [notifications, triggerNotification, removeNotification])

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  )
}
