import React, { FC } from 'react'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

interface Props {
  children: React.ReactNode
}

const LocalizationProvider: FC<Props> = ({ children }) =>
  <MuiLocalizationProvider dateAdapter={AdapterDateFns}>{children}</MuiLocalizationProvider>

export default LocalizationProvider
