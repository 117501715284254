import MUIRadioGroup from '@mui/material/RadioGroup'

import styled from '../styled'

export const StyledRadioGroup = styled(MUIRadioGroup)(({ row }) => ({
  ...(!row ? {
    '& > *': {
      marginBottom: '10px',
    },
    '& > :last-of-type': {
      marginBottom: '0px',
    },
  } : {
    rowGap: '8px',
  }),
}))
