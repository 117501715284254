import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const HealthRulesIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 25" fill="none" data-testid="health-rules-icon">
    <path
      fill="none"
      d="M0.625 21.9633V3.03666L3.03666 0.625H21.9633L24.375 3.03666V21.9633L21.9633 24.375H3.03666L0.625 21.9633Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      fill="none"
      d="M5.79419 5.97266L5.79419 18.9691M19.2058 5.97266V18.9691"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      fill="none"
      d="M19.2065 10.3438H5.79419"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      fill="none"
      d="M19.2065 14.5979H5.79419"
      stroke="currentColor"
      strokeWidth="1.25"
    />
  </SvgIcon>
)

export default HealthRulesIcon
