import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SetpointsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26" data-testid="setpoints-icon">
    <path
      d="M1.22412 22.9074V3.98075L3.63578 1.56909H22.5625L24.9741 3.98075V22.9074L22.5625 25.3191H3.63578L1.22412
      22.9074Z"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <circle
      cx="13.0991"
      cy="13.5027"
      r="1.88257"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <path
      d="M18.6201 11.3345L13.0991 6.5788L7.57815 11.3345"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <path
      d="M7.57815 15.5536L13.0991 20.3094L18.6201 15.5536"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
  </SvgIcon>
)

export default SetpointsIcon
