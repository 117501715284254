import { DataGridPro } from '@mui/x-data-grid-pro'

import styled from '../../styled'

export const ACTION_BUTTON_SIZE = 48

export const Container = styled('div')<{
  noBorder: boolean
}>(({ noBorder, theme }) => ({
  position: 'relative',
  height: '100%',
  ...(noBorder
    ? { border: 'none' }
    : {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.container.border.subdued}`,
      overflow: 'hidden',
    }),
  '& .control-state.negative': {
    color: theme.palette.icon.error,
    fontSize: '0.75rem',
  },
  '& .control-state.positive': {
    color: theme.palette.icon.disabledInverse,
    fontSize: '0.75rem',
  },
  '& .cursor-pointer': {
    cursor: 'pointer',
  },
}))

export const StyledDataGrid = styled(DataGridPro)<{
  disableFullscreen?: boolean
  fullscreen: boolean
  grayBackground?: boolean
  grayBackgroundOnHover?: boolean
  grayBackgroundOnSelected?: boolean
}>(({
  theme,
  disableFullscreen,
  fullscreen,
  grayBackground,
  grayBackgroundOnHover,
  grayBackgroundOnSelected,
}) => ({
  borderRadius: 0,
  borderColor: theme.palette.container.border.subdued,

  '&.MuiDataGrid-root': {
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-iconButtonContainer > button': {
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: '1.4rem!important',
    width: '22px',
    height: '22px',
    backgroundColor: theme.palette.alert.surface.success,
    color: theme.palette.alert.text.success,
    '&:hover': {
      backgroundColor: 'inherit',
      color: theme.palette.alert.text.success,
    },
  },
  '& .MuiDataGrid-columnHeader--sorted': {
    '& .MuiDataGrid-iconButtonContainer > button': {
      backgroundColor: theme.palette.alert.text.success,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.alert.surface.success,
        color: theme.palette.alert.text.success,
      },
    },
  },
  '& .MuiDataGrid-cellContent': {
    fontSize: '0.875rem !important',
  },
  ...(grayBackgroundOnSelected && {
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: `${theme.palette.container.surface.low} !important`,
    },
  }),
  ...(grayBackgroundOnHover && {
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: `${theme.palette.container.surface.low} !important`,
      },
    },
  }),
  ...(grayBackground && {
    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.container.surface.drawer,
      '&:hover': {
        backgroundColor: `${theme.palette.container.surface.drawer} !important`,
      },
    },
  }),
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'inherit',
  },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    backgroundColor: 'white',

    '& .cellStatusWarning': {
      backgroundColor: theme.palette.alert.surface.warning,
    },
    '& .cellStatusInfo': {
      backgroundColor: theme.palette.alert.surface.info,
    },
    '& .cellStatusSuccess': {
      backgroundColor: theme.palette.alert.surface.success,
    },
    '& .cellStatusError': {
      backgroundColor: theme.palette.alert.surface.error,
    },
  },
  '& .MuiDataGrid-pinnedColumns': {
    '& .pinnedCellStatusWarning': {
      backgroundColor: theme.palette.alert.surface.warning,
    },
    '& .pinnedCellStatusInfo': {
      backgroundColor: theme.palette.alert.surface.info,
    },
    '& .pinnedCellStatusSuccess': {
      backgroundColor: theme.palette.alert.surface.success,
    },
    '& .pinnedCellStatusError': {
      backgroundColor: theme.palette.alert.surface.error,
    },
  },
  '& .MuiDataGrid-pinnedColumnHeaders--left': {
    backgroundColor: theme.palette.container.surface.low,
    boxShadow: 'none',
    borderRight: `solid 1px ${theme.palette.container.border.subdued}`,
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    backgroundColor: theme.palette.container.surface.drawer,
    boxShadow: 'none',
    borderLeft: `solid 1px ${theme.palette.container.border.subdued}`,
  },
  '& .MuiDataGrid-pinnedColumns--left': {
    boxShadow: 'none',
    borderRight: `solid 1px ${theme.palette.container.border.subdued}`,
  },
  '& .MuiDataGrid-pinnedColumns--right': {
    backgroundColor: theme.palette.container.surface.drawer,
    boxShadow: 'none',
    borderLeft: `solid 1px ${theme.palette.container.border.subdued}`,
  },
  '& .MuiDataGrid-columnHeadersInner': {
    minHeight: 'inherit',
    backgroundColor: theme.palette.container.surface.low,
  },
  '& .MuiDataGrid-columnHeaders': {
    borderRadius: 0,
    borderBottomColor: theme.palette.container.border.subdued,
    backgroundColor: theme.palette.container.surface.low,
  },
  '& .MuiDataGrid-columnHeader': {
    '&:focus': {
      outline: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  '& .MuiDataGrid-cell': {
    borderBottomColor: theme.palette.container.border.subdued,
    outline: 'none !important',
  },
  '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
    ...(fullscreen && { borderBottomColor: 'transparent' }),
  },
  ...(!disableFullscreen && {
    '& .MuiDataGrid-row--lastVisible': {
      paddingBottom: '120px',
    },
  }),
  '.MuiDataGrid-columnHeaderTitle': {
    fontSize: '0.875rem !important',
    marginLeft: '2.6rem',
    fontWeight: 700,
  },
  '.MuiDataGrid-columnHeaderCheckbox': {
    borderRight: '0px',
  },
  '.MuiDataGrid-cellCheckbox': {
    borderRight: '0px',
  },
  '.MuiDataGrid-treeDataGroupingCell': {
    fontSize: '0.875rem !important',
    '& .MuiIconButton-root': {
      color: theme.palette.text.default,
      textTransform: 'none',
      fontSize: '16px',
      border: '1px solid transparent',

      '&:hover': {
        backgroundColor: theme.palette.alert.surface.success,
        border: `1px solid ${theme.palette.alert.border.success} !important`,
      },

      '&.Mui-selected': {
        backgroundColor: theme.palette.alert.text.success,
        color: theme.palette.background.default,
        cursor: 'initial',
        pointerEvents: 'none',
      },
    },
  },
  'div:has(> .MuiDataGrid-treeDataGroupingCell)': {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '.MuiDataGrid-row': {
    '&.Mui-selected': {
      backgroundColor: 'inherit',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  '.MuiDataGrid-withBorder': {
    borderColor: theme.palette.container.border.subdued,
  },
}))

export const ActionButton = styled('button')(({ theme }) => ({
  width: `${ACTION_BUTTON_SIZE}px`,
  height: `${ACTION_BUTTON_SIZE}px`,
  backgroundColor: theme.palette.container.surface.default,
  border: `1px solid ${theme.palette.alert.border.success}`,
  borderRadius: '50%',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,

  '&:hover svg': {
    opacity: 0.4,
  },
}))

export const LeftFade = styled('div')({
  background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
  width: '40px',
  position: 'absolute',
  height: '100%',
  bottom: 0,
  pointerEvents: 'none',
})

export const RightFade = styled('div')({
  background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
  width: '40px',
  position: 'absolute',
  height: '100%',
  bottom: 0,
  pointerEvents: 'none',
})

export const FullscreenActionButtonContainer = styled('div')({
  position: 'absolute',
  right: '30px',
  bottom: '22px',
})
