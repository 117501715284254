/* eslint-disable max-len */
import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SystemSetupIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.84912 4.18379V22.5927L3.89467 24.6382H22.3036L24.3491 22.5927V4.18379L22.3036 2.13824H3.89467L1.84912 4.18379ZM3.3769 0.888245L0.599121 3.66602V23.1105L3.3769 25.8882H22.8213L25.5991 23.1105V3.66602L22.8213 0.888245H3.3769ZM19.6868 14.024H19.0618H13.7134V19.3509V19.9759L12.4634 19.9759V19.3509V14.024H7.13648H6.51148V12.774H7.13648H12.4634V7.4256V6.8006H13.7134V7.4256V12.774H19.0618H19.6868V14.024Z" fill="currentColor" />
  </SvgIcon>
)

export default SystemSetupIcon
