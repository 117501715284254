import React, { FC, useRef, useState } from 'react'
import { UseControllerProps } from 'react-hook-form'

import Tooltip from '../../../tooltip'
import Grid from '../../../grid'
import { CloseIcon } from '../../../icons'
import { StyledProgress } from '../../base/styles'
import { StyledSmallTitle, StyledLargeTitle, StyledValue } from '../styles'

interface Props {
  value: any
  label: string
  rules: UseControllerProps['rules']
  options: Array<{ label: string, value: any }>
  clearable: boolean
  disabled: boolean
  onChange: (...event: any[]) => void
  noSelection: boolean
  size?: 'large' | 'medium' | 'small'
}

const Label: FC<Props> = ({
  value,
  label,
  rules,
  options,
  clearable,
  disabled,
  onChange,
  noSelection,
  size,
}) => {
  const labelRef = useRef<HTMLSpanElement>()
  const [showTooltip, setShowTooltip] = useState(false)

  const checkTooltip = () => {
    if (!labelRef.current) return
    setShowTooltip(labelRef.current.offsetWidth < labelRef.current.scrollWidth)
  }

  const resetTooltip = () => {
    setTimeout(() => setShowTooltip(false), 500)
  }

  if (value === undefined || value === null) {
    return (
      <StyledLargeTitle disabled={disabled} size={size}>
        {`${label} ${rules?.required ? '*' : ''}`}
      </StyledLargeTitle>
    )
  }

  if (value.loading) {
    return <StyledProgress data-testid="dropdown-progress" />
  }

  if (noSelection) {
    return (
      <Grid container flexDirection="column" alignItems="flex-start">
        <StyledLargeTitle disabled={disabled} size={size}>
          {label}
        </StyledLargeTitle>
      </Grid>
    )
  }

  const selectedLabel = options.find(({ value: optionValue }) => value === optionValue)?.label
  const tooltip = showTooltip ? selectedLabel : ''

  return (
    <>
      <Grid container flexDirection="column" alignItems="flex-start" width="80%">
        {size !== 'small' && label && (
          <StyledSmallTitle variant="caption">
            {`${label} ${rules?.required ? '*' : ''}`}
          </StyledSmallTitle>
        )}
        <Tooltip title={tooltip} style={{ width: '100%', textAlign: 'left' }}>
          <StyledValue ref={labelRef} onMouseEnter={checkTooltip} onMouseLeave={resetTooltip}>
            {selectedLabel}
          </StyledValue>
        </Tooltip>
      </Grid>
      {clearable && (
        <CloseIcon
          sx={(theme) => ({ color: theme.palette.text.default, fontSize: '16px', marginRight: 1 })}
          onClick={(event) => {
            event.stopPropagation()
            onChange(null)
          }}
          data-testid="icon-close"
        />
      )}
    </>
  )
}

export default Label
