import { createBreakpoints } from '@mui/system'

declare module '@mui/system' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}

const breakpoints = createBreakpoints({
  values: {
    xs: 320,
    sm: 768,
    md: 963,
    lg: 1441,
    xl: 1920,
  },
  unit: 'px',
})

const typography = {
  h1: {
    fontSize: '3rem',
    lineHeight: '60px',
    fontWeight: 700,
  },
  h2: {
    fontSize: '2.25rem',
    lineHeight: '44px',
    fontWeight: 400,
  },
  h3: {
    fontSize: '1.75rem',
    lineHeight: '32px',
    fontWeight: 400,
  },
  h4: {
    fontSize: '1.375rem',
    lineHeight: '26px',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1.125rem',
    lineHeight: '26px',
    fontWeight: 400,
  },
  button: {
    fontSize: '0.875rem',
    lineHeight: '18px',
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1rem',
    lineHeight: '20px',
    fontWeight: 400,
  },
  body: {
    fontSize: '0.875rem',
    lineHeight: '18px',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '16px',
    fontWeight: 400,
  },
  small: {
    fontSize: '0.625rem',
    lineHeight: '12px',
    fontWeight: 400,
  },
}

export const colors = {
  gray: {
    1: '#1A1C19',
    2: '#2D2D2E',
    3: '#58585A',
    4: '#727273',
    5: '#B8B8B9',
    6: '#D4D4D5',
    7: '#E9E9EA',
    8: '#F2F2F2',
    9: '#FBFBFB',
    10: '#FFFFFF',
  },
  green: {
    1: '#00390E',
    2: '#155E2A',
    3: '#6BBD73',
    4: '#86D98C',
    5: '#96E79C',
    6: '#B5F5B8',
    7: '#E2FCDC',
    8: '#F4FFF1',
  },
  gold: {
    1: '#875300',
    2: '#CB8000',
    3: '#E7A340',
    4: '#FFB85D',
    5: '#FFC377',
    6: '#FCDAA8',
    7: '#FFEEDC',
    8: '#FFF7EF',
  },
  blue: {
    1: '#002F67',
    2: '#00458D',
    3: '#6391DD',
    4: '#7FACFB',
    5: '#B4CEFF',
    6: '#D6E3FF',
    7: '#EBF0FF',
    8: '#F8F9FF',
  },
  purple: {
    1: '#4C3398',
    2: '#654DB3',
    3: '#7E66CE',
    4: '#9880EA',
    5: '#B49CFF',
    6: '#CEBDFF',
    7: '#E8DDFF',
    8: '#F6EEFF',
  },
  red: {
    1: '#930006',
    2: '#BA1B1B',
    3: '#DD3730',
    4: '#FF5449',
    5: '#FF897A',
    6: '#FFB4A9',
    7: '#FFDAD4',
    8: '#FFEDE9',
  },
} as const

export default {
  components: {
    MuiIcon: {
      defaultProps: {
        baseClassName: 'material-symbols-sharp',
      },
    },
  },
  palette: {
    default: {
      main: colors.gray[1],
      light: colors.gray[4],
      dark: colors.gray[5],
    },
    icon: {
      default: colors.gray[1],
      defaultInverse: colors.gray[9],
      subdued: colors.gray[4],
      subduedInverse: colors.gray[5],
      disabled: colors.gray[5],
      disabledInverse: colors.gray[3],
      active: colors.green[2],
      activeInverse: colors.green[5],
      link: colors.blue[2],
      linkInverse: colors.blue[4],
      success: colors.green[2],
      successInverse: colors.green[4],
      error: colors.red[2],
      errorInverse: colors.red[5],
      warning: colors.gold[2],
      warningInverse: colors.gold[4],
      info: colors.blue[2],
      infoInverse: colors.blue[4],
    },
    container: {
      surface: {
        default: colors.gray[10],
        low: colors.gray[8],
        medium: colors.gray[7],
        high: colors.gray[6],
        highInverse: colors.gray[4],
        accent: colors.blue[7],
        activeLow: colors.gray[5],
        activeHigh: colors.gray[1],
        disabled: colors.gray[6],
        destructive: colors.red[2],
        drawer: colors.gray[9],
        constants: colors.gray[2],
        websiteMission: colors.green[1],
        websiteWidget: colors.gold[8],
        websiteFeatured: colors.blue[8],
      },
      border: {
        default: colors.gray[4],
        high: colors.gray[1],
        subdued: colors.gray[6],
        subduedLow: colors.gray[8],
        subduedHigh: colors.gray[1],
        disabled: colors.gray[6],
        secondary: colors.green[2],
        websiteShare: colors.green[6],
      },
    },
    gauge: {
      green: colors.green[2],
      blue: colors.blue[2],
    },
    hover: {
      icon: {
        subduedInverse: colors.gray[6],
      },
      surface: {
        default: `${colors.gray[1]}0D`,
        error: `${colors.red[2]}1A`,
        inverse: `${colors.gray[10]}1A`,
      },
      text: {
        websiteMission: colors.green[3],
      },
    },
    alert: {
      text: {
        success: colors.green[2],
        error: colors.red[2],
        warning: colors.gold[2],
        info: colors.blue[2],
      },
      surface: {
        success: colors.green[7],
        successLow: colors.green[8],
        successInverse: colors.green[2],
        error: colors.red[8],
        errorInverse: colors.red[1],
        warning: colors.gold[7],
        warningInverse: colors.gold[1],
        info: colors.blue[7],
        infoInverse: colors.blue[1],
      },
      border: {
        success: colors.green[2],
        successInverse: colors.green[4],
        error: colors.red[2],
        errorInverse: colors.red[5],
        warning: colors.gold[2],
        warningInverse: colors.gold[4],
        info: colors.blue[2],
        infoInverse: colors.blue[4],
      },
    },
    dividers: {
      default: colors.gray[4],
      low: colors.gray[5],
      high: colors.gray[2],
    },
  },
  breakpoints,
  typography,
}
