import { createTheme, Palette, ThemeOptions } from '@mui/material/styles'
import deepmerge from '@mui/utils/deepmerge'
import common, { colors } from './common'

const dark: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: colors.gray[1],
    },
    text: {
      default: colors.gray[9],
      subdued: colors.gray[5],
      disabled: colors.gray[3],
      active: colors.green[5],
      link: colors.blue[4],
      websiteGold: colors.gold[3],
    },
    primary: {
      main: colors.green[5],
    },
    error: {
      main: colors.red[4],
    },
    dividers: {
      default: colors.gray[3],
      low: colors.gray[5],
      high: colors.gray[2],
    },
    container: {
      border: {
        secondary: colors.gray[8],
      },
    },
  } as Palette,
}

export default createTheme(deepmerge(common, dark))
