import Highcharts from 'highcharts'

export enum ThresholdLimit {
  High,
  Low,
}

const SOFT_THRESHOLD_RATIO = 0.01

export const getMinAndMax = (series: Highcharts.SeriesOptionsType[]): number[] | null[] => {
  if (series.length === 0) {
    return [0, 0]
  }

  let min: number
  let max: number

  series.forEach((serie: Highcharts.SeriesOptionsType) => {
    if (serie.type === 'line' && serie.data.length > 0) {
      const flatValues = serie.data
        .map((x: number[]) => x[1])
        .filter((x) => x !== null)

      const serieMin = Math.min(...flatValues)
      const serieMax = Math.max(...flatValues)

      min = (min === undefined) ? serieMin : Math.min(min, serieMin)
      max = (max === undefined) ? serieMax : Math.max(max, serieMin)
    }
  })

  return [min, max]
}

export const getSoftMin = (series: Highcharts.SeriesOptionsType[]): number | null => {
  if (!series) {
    return null
  }
  const [min, max] = getMinAndMax(series)
  return min - ((max - min) * SOFT_THRESHOLD_RATIO)
}

export const getSoftMax = (series: Highcharts.SeriesOptionsType[]): number | null => {
  if (!series) {
    return null
  }
  const [min, max] = getMinAndMax(series)
  return max + ((max - min) * SOFT_THRESHOLD_RATIO)
}

export const addThresholdSeries = (
  props: { label: string, data: number[][], limit: ThresholdLimit },
): Highcharts.SeriesOptionsType => {
  const { label, data, limit } = props
  const hasData = data.length > 0

  function getMarker() {
    if (limit === ThresholdLimit.Low) {
      return {
        marker: {
          symbol: 'triangle-down',
        },
      }
    }
    if (limit === ThresholdLimit.High) {
      return {
        marker: {
          symbol: 'triangle',
        },
      }
    }

    return {}
  }

  return {
    linecap: 'linecap: square',
    data,
    type: 'line',
    dashStyle: 'LongDash',
    name: label,
    color: '#B8B8B9',
    showInLegend: false,
    visible: hasData === true,
    connectNulls: false,
    legendIndex: 0,
    lineWidth: 1,
    zIndex: 0,
    ...getMarker(),
  }
}
