import {
  InstallationTags, NELDebuggerTags, TagManagementTags,
} from '@services/request-tags'

export const DEFAULT_ERROR_MESSAGE = 'A server error has occurred. Please try again.'

export const errorMessages: Record<string, string> = {
  [NELDebuggerTags.GetRenderableExpression]: '',
  [InstallationTags.GetInstallationConfigs]: 'Could not retrieve configs',
  [TagManagementTags.StopProcessingTags]: 'Unable to stop tag processing. Please try again later.',
  [NELDebuggerTags.EvalExpression]: 'Expression evaluation failed',
  [NELDebuggerTags.EvalConstraintExpression]: 'Expression evaluation failed',
  [NELDebuggerTags.GetExpressionData]: 'Could not retrieve data',
  default: DEFAULT_ERROR_MESSAGE,
}
