import React from 'react'

import Grid from '../../../grid'
import LoadingIcon from '../../../loading-icon'

const LoadingOverlay = () => (
  <Grid container alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
    <LoadingIcon />
  </Grid>
)

export default LoadingOverlay
