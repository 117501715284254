export const environments = {
  production: {
    grafanaObserver: true,
    logrocket: true,
  },
  staging: {
    grafanaObserver: true,
    logrocket: true,
  },
}

export const NELUserGuideLink = 'https://www.notion.so/phaidra/NEL-User-Guide-142e0a23b5b8809c8828edbdfc595361'
