import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const PhaidraLogoWithText: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 48 20" fill="none" data-testid="phaidra-logo-with-text">
    <g clipPath="url(#clip0_12774_1260)">
      <path
        d="M40.9148 5.4937C42.0922 4.90513 42.5695 3.47352 41.9809 2.29612C41.3924 1.11872 39.9608
      0.641382 38.7834 1.22995C37.606 1.81852 37.1286 3.25013 37.7172 4.42753C38.3058 5.60493 39.7374
      6.08227 40.9148 5.4937Z"
        fill="#96E79C"
      />
      <path
        d="M46.2965 5.60735C47.5386 5.17164 48.1923 3.8115 47.7566 2.56938C47.3209 1.32727 45.9608
      0.673557 44.7186 1.10927C43.4765 1.54498 42.8228 2.90512 43.2585 4.14724C43.6942 5.38935 45.0544
      6.04306 46.2965 5.60735Z"
        fill="#96E79C"
      />
      <path
        d="M34.1874 0.97522H27.509C27.5069 0.97522 27.5056 0.97522 27.5035 0.977309C27.4768 0.977309
       27.4501 0.977309 27.4233 0.977309C27.1103 0.977309 26.8004 1.03896 26.5112 1.15873C26.2221 1.27851
        25.9593 1.45407 25.738 1.67539C25.5167 1.89671 25.3411 2.15945 25.2213 2.44862C25.1016 2.73779
        25.0399 3.04772 25.0399 3.36071C25.0399 3.6737 25.1016 3.98363 25.2213 4.2728C25.3411 4.56196
        25.5167 4.82471 25.738 5.04603C25.9593 5.26735 26.2221 5.44291 26.5112 5.56268C26.8004 5.68246
        27.1103 5.74411 27.4233 5.74411C27.4501 5.74411 27.4768 5.74411 27.5035 5.74411C27.5035 5.74411
        27.5069 5.74411 27.509 5.74411H34.1874C34.8184 5.74191 35.4229 5.49027 35.8691 5.04408C36.3153
        4.5979 36.567 3.99338 36.5692 3.36238C36.5684 2.73044 36.3174 2.12453 35.871 1.67717C35.4247
        1.22981 34.8194 0.977429 34.1874 0.97522V0.97522ZM34.1874 4.85368H29.2773C29.6198 4.4306 29.8067
         3.90276 29.8067 3.35841C29.8067 2.81406 29.6198 2.28622 29.2773 1.86314H34.1436C34.9216 1.86314
          35.6031 2.43977 35.6758 3.21446C35.6956 3.42195 35.6719 3.6313 35.6062 3.82909C35.5404 4.02689
          35.4341 4.20877 35.294 4.36308C35.1539 4.5174 34.9831 4.64075 34.7925 4.72523C34.602 4.80971
          34.3959 4.85346 34.1874 4.85368Z"
        fill="#96E79C"
      />
      <path
        d="M5.25944 10.9276C4.80002 10.6657 4.27979 10.5294 3.75101 10.5323C3.24503 10.5296 2.7477
      10.6635 2.31153 10.92C2.06623 11.063 1.84323 11.2413 1.64966
      11.449V10.6451H0V20.0466H1.64966V16.4899C1.84263 16.7043 2.06555 16.8896 2.31153 17.0402C2.74395
      17.3074 3.24275 17.4475 3.75101 17.4447C4.28746 17.4498 4.81563 17.3124 5.28159 17.0465C5.74568
      16.7808 6.11784 16.3887 6.39808 15.8703C6.67831 15.3519 6.81843 14.7141 6.81843 13.9569C6.81843
      13.221 6.67525 12.598 6.38888 12.0879C6.10252 11.5779 5.72604 11.1911 5.25944 10.9276ZM4.60426
       15.4938C4.4431 15.6547 4.2512 15.7815 4.04001 15.8666C3.82881 15.9517 3.60263 15.9935 3.37495
        15.9894C3.08249 15.9901 2.79474 15.9157 2.53926 15.7733C2.28044 15.6311 2.06158 15.4261 1.90288
         15.1771C1.73771 14.9178 1.65255 14.6157 1.65802 14.3084V14.2457H1.65175V13.5943H1.65802C1.65132
          13.2873 1.73771 12.9854 1.9058 12.7285C2.06755 12.485 2.28741 12.2857 2.54553 12.1485C2.80089
          12.0105 3.08677 11.9386 3.37704 11.9396C3.86174 11.9396 4.27249 12.1016 4.60927 12.4255C4.94606
           12.7495 5.11445 13.2607 5.11445 13.959C5.11306 14.6521 4.94299 15.1637 4.60426 15.4938Z"
        fill="white"
      />
      <path
        d="M12.0561 10.5323C11.5043 10.5323 11.015 10.6494 10.5882 10.8837C10.2974 11.0421 10.0397
      11.2549 9.82898 11.5104V7.50244H8.17932V17.3068H9.82898V13.588C9.8229 13.2959 9.88983 13.0069
      10.0237 12.7473C10.151 12.5071 10.3462 12.3098 10.5849 12.1798C10.8297 12.0436 11.1256 11.9756
       11.4724 11.9759C11.9911 11.9759 12.3581 12.1233 12.5734 12.418C12.7887 12.7127 12.8964 13.1361
       12.8964 13.6883V17.3076H14.5461V13.2483C14.5461 12.3953 14.3328 11.7294 13.9063 11.2505C13.4799
        10.7717 12.8631 10.5323 12.0561 10.5323Z"
        fill="white"
      />
      <path
        d="M21.1761 11.4298C20.9814 11.2148 20.7525 11.0334 20.4988 10.8928C20.0609 10.6517
      19.5683
      10.5276 19.0685 10.5322C18.664 10.5321 18.2629 10.6045 17.8839 10.7458C17.4995 10.8875 17.1476
       11.1051 16.8489 11.3855C16.5334 11.6846 16.2844 12.0468 16.1181 12.4485C15.9362 12.873 15.8452
        13.3696 15.8452 13.9381C15.8452 14.5323 15.9341 15.0487 16.1118 15.4875C16.2719 15.9002 16.519
         16.2736 16.8363 16.5822C17.1331 16.8675 17.4854 17.0885 17.8714 17.2316C18.2548 17.3734 18.6605
          17.4455 19.0693 17.4447C19.5676 17.4496 20.0593 17.3299 20.4996 17.0966C20.7536 16.9608 20.9826
          16.7827 21.1769 16.5701V17.3068H22.8329V10.6451H21.1761V11.4298ZM20.9342 15.2493C20.783 15.5006
           20.5632 15.7035 20.3007 15.8343C20.0411 15.9633 19.7549 16.0299 19.465 16.0286C19.1484 16.0299
           18.8358 15.9569 18.5524 15.8155C18.2573 15.6658 18.0142 15.4304 17.8551 15.1403C17.6771 14.8338
            17.5882 14.4377 17.5885 13.9519C17.5885 13.4625 17.6773 13.0714 17.8551 12.7786C18.017 12.4999
            18.2603 12.2775 18.5524 12.1414C18.8385 12.0095 19.15 11.942 19.465 11.9437C19.7523 11.9427
            20.0361 12.007 20.2949 12.1318C20.5558 12.2566 20.7764 12.4522 20.9317 12.6963C21.0946 12.947
             21.1761 13.2627 21.1761 13.6435V14.2958C21.1761 14.6724 21.0955 14.9902 20.9342 15.2493Z"
        fill="white"
      />
      <path d="M26.0247 10.645H24.3938V17.3068H26.0247V10.645Z" fill="white" />
      <path
        d="M32.5232 11.4352C32.3317 11.2329 32.112 11.0594 31.8709 10.92C31.4311 10.664 30.9307 10.5308
       30.4218 10.5343C29.8951 10.532 29.377 10.6684 28.9197 10.9296C28.4534 11.1929 28.0773 11.5797 27.7915
        12.09C27.5057 12.6003 27.3625 13.2233 27.3619 13.959C27.3619 14.7162 27.5019 15.3539 27.7819
        15.8723C28.0618 16.3907 28.4351 16.7828 28.9017 17.0486C29.3647 17.3131 29.8894 17.4505 30.4227
        17.4468C30.9436 17.4532 31.4554 17.3095 31.8968 17.0327C32.1293 16.8865 32.3403 16.7086 32.5236
         16.5041V17.3089H34.18V7.50244H32.5232V11.4352ZM32.2817 15.1645C32.1264 15.4165 31.9095 15.6248
          31.6516 15.77C31.3215 15.9522 30.9416 16.0237 30.5679 15.974C30.1942 15.9242 29.8462 15.7559
          29.5753 15.4938C29.2365 15.1637 29.0672 14.6514 29.0672 13.9569C29.0672 13.2625 29.2365 12.7516
           29.5753 12.4243C29.7378 12.2659 29.9301 12.1414 30.1412 12.0579C30.3522 11.9745 30.5777 11.9339
           30.8046 11.9383C31.0947 11.9374 31.3805 12.0092 31.6357 12.1472C31.8939 12.2843 32.1138 12.4836
           32.2754 12.7272C32.4437 12.9845 32.5301 13.2868 32.5232 13.5942V14.2778C32.529 14.5904 32.4452
           14.8981 32.2817 15.1645Z"
        fill="white"
      />
      <path
        d="M39.3478 10.5323C39.0348 10.5268 38.7238 10.5821 38.4319 10.6952C38.1745 10.7971 37.9416
      10.9522 37.7483 11.1503C37.6336 11.2696 37.5325 11.4013 37.447
      11.543V10.6451H35.7974V17.3068H37.447V13.995C37.4475 13.7612 37.4769 13.5283 37.5348 13.3018C37.5917
      13.0729 37.6895 12.8561 37.8235 12.662C37.962 12.4634 38.149 12.3036 38.3667 12.1978C38.5949 12.0825
       38.8781 12.025 39.2166 12.0252C39.3892 12.0192 39.5616 12.0411 39.7272 12.09C39.816 12.1163 39.9012
       12.153 39.9813 12.1995L40.2821 10.6877C40.2445 10.6626 40.1476 10.6305 39.9896 10.5908C39.7787 10.546
        39.5633 10.5264 39.3478 10.5323Z"
        fill="white"
      />
      <path
        d="M46.2348 10.6451V11.4298C46.0401 11.2148 45.8112 11.0334 45.5575 10.8928C45.1196 10.6517
      44.627 10.5276 44.1272 10.5322C43.7225 10.532 43.321 10.6044 42.9418 10.7458C42.5574 10.8876 42.2055
       11.1052 41.9067 11.3855C41.5914 11.6847 41.3424 12.0469 41.1759 12.4485C40.9943 12.873 40.9034 13.3696
        40.9031 13.9381C40.9031 14.5323 40.9921 15.0487 41.1701 15.4875C41.33 15.9003 41.5772 16.2737 41.8946
        16.5822C42.1911 16.8676 42.5434 17.0886 42.9292 17.2316C43.3127 17.3734 43.7184 17.4455 44.1272
         17.4447C44.6255 17.4496 45.1171 17.3299 45.5575 17.0966C45.8115 16.9608 46.0405 16.7827 46.2348
         16.5701V17.3068H47.8912V10.6451H46.2348ZM45.9937 15.2493C45.8423 15.5006 45.6224 15.7035 45.3598
         15.8343C45.1002 15.9633 44.814 16.0299 44.5241 16.0286C44.2075 16.0299 43.8949 15.9569 43.6116
         15.8155C43.3159 15.666 43.0724 15.4306 42.9129 15.1403C42.7349 14.8338 42.6461 14.4377 42.6463
         13.9519C42.6463 13.4625 42.7352 13.0714 42.9129 12.7786C43.0746 12.5004 43.3175 12.2784 43.6091
          12.1422C43.8951 12.0103 44.2066 11.9429 44.5216 11.9446C44.8093 11.9434 45.0935 12.0077 45.3527
           12.1326C45.6136 12.2575 45.8343 12.4531 45.9895 12.6971C46.1525 12.9478 46.234 13.2636 46.234
            13.6444V14.2966C46.2345 14.6727 46.1544 14.9902 45.9937 15.2493Z"
        fill="white"
      />
      <path
        d="M25.2096 7.50244C25.0213 7.50236 24.8373 7.5581 24.6807 7.66263C24.5241 7.76715 24.4021
       7.91575 24.3299 8.08965C24.2578 8.26355 24.2389 8.45493 24.2755 8.63958C24.3122 8.82424 24.4028
       8.99389 24.5358 9.12706C24.6689 9.26024 24.8384 9.35097 25.0231 9.38778C25.2077 9.42459 25.3991
        9.40582 25.5731 9.33386C25.747 9.26189 25.8957 9.13995 26.0004 8.98347C26.105 8.82698 26.161
        8.64297 26.161 8.45471C26.1611 8.32972 26.1365 8.20593 26.0887 8.09042C26.041 7.97492 25.9709
        7.86996 25.8825 7.78153C25.7942 7.6931 25.6893 7.62294 25.5738 7.57506C25.4584 7.52717 25.3346
         7.5025 25.2096 7.50244Z"
        fill="#FBFBFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_12774_1260">
        <rect width="47.8911" height="19.0726" fill="white" transform="translate(0 0.97522)" />
      </clipPath>
    </defs>
  </SvgIcon>

)

export default PhaidraLogoWithText
