import React, { FC, ReactElement } from 'react'

import {
  CheckIcon,
  InfoOutlinedIcon,
  LinkIcon,
  WarningIcon,
  CloseSymbol,
} from '../icons'
import { StyledChip, StyledChipProps } from './styles'

export type ChipProps = Omit<StyledChipProps, 'color'>
& {
  hideIcon?: boolean
  iconOverride?: ReactElement
  color?: StyledChipProps['color'] | 'link'
  iconPosition?: 'left' | 'right'
}

const Chip: FC<ChipProps> = ({
  label,
  color,
  hideIcon,
  iconOverride,
  iconPosition = 'left',
  ...props
}) => {
  let icon: ReactElement

  if (color === 'success') {
    icon = <CheckIcon />
  } else if (color === 'error') {
    icon = <WarningIcon />
  } else if (color === 'warning') {
    icon = <WarningIcon />
  } else if (color === 'info') {
    icon = <InfoOutlinedIcon />
  } else if (color === 'link') {
    icon = <LinkIcon />
  }

  const displayIcon = iconOverride ?? icon

  return (
    <StyledChip
      {...(!hideIcon && { icon: displayIcon })}
      label={label}
      color={color === 'link' ? 'default' : color}
      iconPosition={iconPosition}
      deleteIcon={(
        <CloseSymbol
          style={{
            fontVariationSettings: '"FILL" 1',
            marginLeft: '4px',
            marginRight: 0,
            color: '#1A1C19',
            fontSize: '0.875rem',
          }}
        />
      )}
      {...props}
    />
  )
}

export default Chip
