import { useCallback, useMemo } from 'react'
import useUserLocalStorage from '@phaidra/utils/use-user-local-storage'
import safelyParseBool from '@phaidra/utils/safely-parse-bool'

const LOCAL_STORAGE_KEY = 'trend-viewer-stats-tooltip-notification'

const useShowStatsTooltip = () => {
  const [notificationDismissed, setNotificationDismissed] = useUserLocalStorage(LOCAL_STORAGE_KEY)

  const showStatsTooltip = useMemo(() => !safelyParseBool(notificationDismissed, false), [notificationDismissed])
  const closeStatsTooltip = useCallback(() => setNotificationDismissed(`${true}`), [setNotificationDismissed])

  return [showStatsTooltip, closeStatsTooltip] as const
}

export default useShowStatsTooltip
