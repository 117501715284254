import React, { FC, lazy, Suspense } from 'react'
import {
  BrowserRouter, MemoryRouter, Route, Routes, Navigate,
} from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import CssBaseline from '@phaidra/ava/css-baseline'
import ThemeProvider, { ThemeType } from '@phaidra/ava/theme-provider'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import { LocalizationProvider } from '@phaidra/ava/date-pickers'
import { SnackbarProvider } from '@phaidra/ava/snackbar'
import Grid from '@phaidra/ava/grid'
import PageLoader from '@common/page-loader'
import store from '@store'
import { LaunchDarklyFlags, LaunchDarklyFlagsProvider, FeatureFlagNames } from '@phaidra/utils/darkly-feature-flags'
import { ReactFlowProvider } from '@xyflow/react'

import { SetpointsProvider } from '@providers/setpoints'
import { HealthRulesProvider } from '@providers/healthrules'
import { ConstraintsProvider } from '@providers/constraints'
import { VisualNelProvider } from '@providers/nel'
import { NELEditorProvider } from '@providers/nel-editor'
import AuthHelper from './auth-helper'
import App from '../app'

dayjs.extend(utc)

const HistoricalMetaData = lazy(() => import('../app/historical-meta-data'))
const HistoricalDataIngestion = lazy(() => import('../app/historical-data-ingestion'))
const SystemBuilder = lazy(() => import('../app/system-builder'))
const Systems = lazy(() => import('../app/systems'))
const LogIn = lazy(() => import('../log-in'))
const TagManagement = lazy(() => import('../app/tag-management'))
const TrendViewerWrapper = lazy(() => import('../app/trend-viewer-wrapper'))
const NELDebugger = lazy(() => import('../app/nel-debugger'))
const SetpointSelection = lazy(() => import('../app/setpoint-selection'))
const HealthRules = lazy(() => import('../app/health-rules'))
const Constraints = lazy(() => import('../app/constraints'))

interface Props {
  Router: typeof BrowserRouter | typeof MemoryRouter
  theme?: ThemeType
}

const Main: FC<Props> = ({ Router, theme }) => (
  <LaunchDarklyFlagsProvider
    clientSideID={process.env.LAUNCH_DARKLY_CLIENT_ID}
    context={{ kind: 'app-name', key: 'atlas' }}
    Loader={PageLoader}
  >
    <Auth0Provider
      domain={process.env.PHAIDRA_ATLAS_AUTH0_DOMAIN}
      clientId={process.env.PHAIDRA_ATLAS_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <CssBaseline>
        <SnackbarProvider>
          <Provider store={store}>
            <LocalizationProvider>
              <ThemeProvider theme={theme}>
                <Router>
                  <AuthHelper>
                    <Routes>
                      <Route path="/login" element={<LogIn />} />
                      <Route
                        path="/nel-debugger"
                        element={(
                          <Suspense fallback={<PageLoader />}>
                            <ReactFlowProvider data-testid="nel_debugger_page">
                              <VisualNelProvider>
                                <NELDebugger />
                              </VisualNelProvider>
                            </ReactFlowProvider>
                          </Suspense>
                        )}
                      />
                      <Route path="/" element={<App />}>
                        <Route
                          path="/data"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="hdi-page">
                                <HistoricalDataIngestion />
                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/setpoints"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="setpoint-page">
                                <LaunchDarklyFlags
                                  authorizedFlags={[FeatureFlagNames.SetpointSelectionV1]}
                                  renderOn={() => <SetpointsProvider><SetpointSelection /></SetpointsProvider>}
                                  renderOff={() => <Navigate to="/" replace />}
                                />

                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/health-rules"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="health-rules-page">
                                <LaunchDarklyFlags
                                  authorizedFlags={[FeatureFlagNames.HealthRulesV1]}
                                  renderOn={() => (
                                    <Suspense fallback={<PageLoader />}>
                                      <Grid container data-testid="health-rules-page">
                                        <HealthRulesProvider><HealthRules /></HealthRulesProvider>
                                      </Grid>
                                    </Suspense>
                                  )}
                                  renderOff={() => <Navigate to="/" replace />}
                                />

                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/data/metadata"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="hdi-metadata-page">
                                <HistoricalMetaData />
                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/builder"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="system-builder-page">
                                <SystemBuilder />
                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/tags"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="tag-management-page">
                                <ReactFlowProvider>
                                  <VisualNelProvider>
                                    <TagManagement />
                                  </VisualNelProvider>
                                </ReactFlowProvider>
                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/trend-viewer"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="trend-viewer-page">
                                <TrendViewerWrapper />
                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route
                          path="/constraints"
                          element={(
                            <LaunchDarklyFlags
                              authorizedFlags={[FeatureFlagNames.EnableAtlasConstraints]}
                              renderOn={() => (
                                <Suspense fallback={<PageLoader />}>
                                  <Grid container data-testid="constraints-page">
                                    <ReactFlowProvider>
                                      <VisualNelProvider>
                                        <ConstraintsProvider>
                                          <NELEditorProvider>
                                            <Constraints />
                                          </NELEditorProvider>
                                        </ConstraintsProvider>
                                      </VisualNelProvider>
                                    </ReactFlowProvider>
                                  </Grid>
                                </Suspense>
                              )}
                              renderOff={() => <Navigate to="/" replace />}
                            />
                          )}
                        />
                        <Route
                          path="/"
                          element={(
                            <Suspense fallback={<PageLoader />}>
                              <Grid container data-testid="systems-page">
                                <Systems />
                              </Grid>
                            </Suspense>
                          )}
                        />
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Route>
                    </Routes>
                  </AuthHelper>
                </Router>
              </ThemeProvider>
            </LocalizationProvider>
          </Provider>
        </SnackbarProvider>
      </CssBaseline>
    </Auth0Provider>
  </LaunchDarklyFlagsProvider>
)

export default Main
