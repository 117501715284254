import { useAuth0 } from '@auth0/auth0-react'
import useLocalStorage from '../use-local-storage'
import { type StringLiteral } from '../types'

const useUserLocalStorage = <T extends string>(keyName: StringLiteral<T>) => {
  const { user: { email } } = useAuth0()

  // user's `email` will not change and the `keyName` will not change, so therefore
  // we can be sure that `key` will not change.
  const key = `${keyName}:${email}` as StringLiteral<string>
  return useLocalStorage(key)
}

export default useUserLocalStorage
