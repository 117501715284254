import styled from '@phaidra/ava/styled'
import ToggleButton from '@phaidra/ava/toggle-button'
import Grid from '@phaidra/ava/grid'
import Link from '@phaidra/ava/link'
import Button from '@phaidra/ava/button'

export const TagLink = styled(Link)({
  fontSize: 'small',
  textDecoration: 'none',
})

export const ChartWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'width',
})<{ width: number }>(({ width }) => ({
  width,
}))

export const TrendContainer = styled('div')(({ theme }) => ({
  height: '261px',
  marginBottom: theme.spacing(2),
}))

export const ControlStateSquare = styled('div')(({ color }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '2px',
  backgroundColor: color,
  border: 'solid 1px #58585A',
}))

export const ControlStateContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'enabled',
})<{ enabled: boolean }>(({ enabled }) => ({
  alignItems: 'center',
  flex: 0,
  flexWrap: 'nowrap',
  cursor: 'pointer',
  ...(!enabled && { color: '#B8B8B9' }),
}))

export const NavigatorContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  position: 'relative',
  zIndex: 1,
}))

export const TimeQuickSelect = styled(ToggleButton)({
  '& > .MuiButtonBase-root': {
    height: '30px!important',
  },
  flexWrap: 'wrap',
})

export const CaptureLoadingContainer = styled(Grid)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'white',
  zIndex: 200,
})

export const TitleContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: 4,
  marginBottom: theme.spacing(1.5),
}))

export const StyledTextButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(-1.5),
  marginTop: theme.spacing(-1),
  color: theme.palette.text.active,
  fontSize: '1rem !important',
}))
