import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowDown'
import { SvgIconProps } from '@mui/material/SvgIcon'
import styled from '../../styled'

type Direction = 'up' | 'down' | 'left' | 'right'

interface Props extends SvgIconProps {
  direction: Direction
  isTiny?: boolean
}

const TRANSFORM_LOOKUP: Record<Direction, string> = {
  up: 'rotate(0deg)',
  right: 'rotate(90deg)',
  down: 'rotate(180deg)',
  left: 'rotate(270deg)',
}

const PROPS_NOT_TO_FORWARD: PropertyKey[] = ['direction', 'isTiny']

const RotatableArrowIcon = styled(
  KeyboardArrowUpIcon,
  { shouldForwardProp: (prop) => !PROPS_NOT_TO_FORWARD.includes(prop) },
)<Props>(({ direction, isTiny }) => ({
  transform: TRANSFORM_LOOKUP[direction],
  fontSize: isTiny ? '1rem !important' : undefined,
}))

export default RotatableArrowIcon
