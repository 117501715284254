import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const TrendsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26" data-testid="trends-icon">
    <circle cx="18.7416" cy="7.16083" r="1.69348" fill="currentColor" />
    <circle cx="14.4115" cy="18.324" r="1.69348" fill="currentColor" />
    <circle cx="10.1479" cy="10.8069" r="1.69348" fill="currentColor" />
    <circle cx="6.21765" cy="18.324" r="1.69348" fill="currentColor" />
    <path
      d="M2.75901 24.6174L0.625122 22.4835V3.00131L2.75901 0.867432H22.2412L24.3751 3.00132V22.4835L22.2412
      24.6174H2.75901Z"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <path
      d="M5.77649 19.0472L10.1443 10.6657L14.4398 18.3239L19.1117 6.44095"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
  </SvgIcon>
)

export default TrendsIcon
