import Highcharts from 'highcharts'
import { ConstantsProps } from './constant-lines/types'

export default (
  options: Highcharts.Options,
  redrawCallback?: Function,
  constants?: boolean | ConstantsProps,
): Highcharts.Options => {
  const tempValues = (constants as ConstantsProps)?.tempValues || []
  const isConstantDragging = ((constants as ConstantsProps)?.isConstantDragging
  && (constants as ConstantsProps)?.prevConstantState) || false

  const processValues = (values: {
    unit?: string
    label?: string
    value: number
  }[]): Record<string, number[]> => values.reduce((acc: Record<string, number[]>, valueObj: {
    unit?: string
    label?: string
    value: number
  }) => {
    const unit = valueObj.unit || ''
    const label = valueObj.label || ''
    const key = `${label}_${unit}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(valueObj.value)
    return acc
  }, {})

  const valuesMap = isConstantDragging
    ? processValues((constants as ConstantsProps)?.prevConstantState || [])
    : processValues(tempValues || [])

  // Iterate over each unit and label combination to update the corresponding yAxis
  Object.keys(valuesMap).forEach((key) => {
    const [label, unit] = key.split('_')
    const values = valuesMap[key]
    const maxValue = Math.max(...values)
    const minValue = Math.min(...values)

    if (Array.isArray(options.yAxis)) {
      const yAxisIndex = options.yAxis.findIndex((axis) => {
        const axisTitle = axis.title?.text || ''
        const axisUnit = axisTitle.split(' ').pop().replace(/[()]/g, '') || ''
        const axisLabel = axisTitle.split('(')[0].trim() || ''
        return axisUnit === unit && axisLabel === label
      })

      if (yAxisIndex >= 0) {
        const currentYAxisOptions = options.yAxis[yAxisIndex]
        const currentMinValue = currentYAxisOptions.min
        const currentMaxValue = currentYAxisOptions.max
        const shouldRescale = currentMinValue !== minValue || currentMaxValue !== maxValue

        if (shouldRescale) {
          options.yAxis[yAxisIndex] = {
            ...currentYAxisOptions,
            min: minValue < currentMinValue ? minValue : currentMinValue,
            max: maxValue > currentMaxValue ? maxValue : currentMaxValue,
          }
        }
      }
    }
  })

  return {
    ...options,
    chart: {
      ...options.chart,
      animation: false,
    },
    series: options.series
      ? options.series.map((serie) => ({
        ...serie,
        events: {
          ...serie.events,
          hide() {
            if (redrawCallback) {
              redrawCallback(this.yAxis)
            }
          },
          show() {
            if (redrawCallback) {
              redrawCallback(this.yAxis)
            }
          },
        },
      }))
      : [],
  }
}
