import styled from '../styled'

type AnimatedContainerProps = {
  animate?: boolean
  animationDurationMs?: number
}

const PROPS_NOT_TO_FORWARD: PropertyKey[] = ['animate', 'animationDurationMs']

export const AnimatedContainer = styled('div', {
  shouldForwardProp: (propName) => !PROPS_NOT_TO_FORWARD.includes(propName),
})<AnimatedContainerProps>(({
  animate,
  animationDurationMs,
}) => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  animation: animate ? `fadeIn ${animationDurationMs}ms` : undefined,
}))
