import React, {
  FC, CSSProperties, useRef,
} from 'react'
import { VariableSizeList } from 'react-window'

import NoRowsOverlay from '@common/no-rows-overlay'
import FadeScroller from '@common/fade-scroller'
import ListItem, { Props as ListItemProps, PointName } from '../list-item'

export type Props = {
  onCopy: ListItemProps['onCopy']
  onInsert: ListItemProps['onInsert']
  options: PointName[]
  height?: number
  search: string
  noResults?: JSX.Element
  pointNamesUsedInMapping: ListItemProps['pointNamesUsedInMapping']
}

const TagsList: FC<Props> = ({
  onCopy, onInsert, options, height, search, noResults, pointNamesUsedInMapping,
}) => {
  const ref = useRef(null)
  const rowHeights = useRef<Record<number, number>>({})

  const getRowHeight = (index: number) => (rowHeights.current[index]) || 26

  const setRowHeight = (index: number | string, size: number) => {
    ref?.current?.resetAfterIndex?.(0)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  const virtualizedRowRenderer = ({ index, style }: { index: number, style: CSSProperties }) => {
    const option = options[index]
    return (
      <ListItem
        search={search}
        index={index}
        option={{ ...option, fullTagName: option.fullTagName ?? option.name }}
        onCopy={onCopy}
        onInsert={onInsert}
        style={style}
        setRowHeight={setRowHeight}
        pointNamesUsedInMapping={pointNamesUsedInMapping}
      />
    )
  }

  return (options.length
    ? (
      <FadeScroller childRef={ref} hideTopFade>
        <VariableSizeList
          ref={ref}
          itemCount={options.length}
          itemSize={getRowHeight}
          width="100%"
          height={height ?? 500}
          overscanCount={10}
        >
          {({ index, style }) => virtualizedRowRenderer({ index, style })}
        </VariableSizeList>
      </FadeScroller>
    )
    : (
      (noResults
        || <NoRowsOverlay height={height} textOne="No search results found" textTwo="Please try another search." />)
    )
  )
}

export default TagsList
