import { useEffect } from 'react'
import axios, { type AxiosError } from 'axios'
import { useSnackbar } from '@phaidra/ava/snackbar'
import getAxiosResponseErrorHandler from './utils/get-axios-response-error-handler'
import {
  type AxiosBaseQuery,
  type AxiosRequestConfig,
  type GetAxiosResponseErrorHandler,
} from './types'

const loadErrorInterceptor = (props: GetAxiosResponseErrorHandler) => axios.interceptors.response.use(
  (response) => response,
  getAxiosResponseErrorHandler(props),
)

export const useAxiosErrorInterceptor = (errorMessages: Readonly<Record<string, string>>) => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    loadErrorInterceptor({
      enqueueSnackbar,
      errorMessages,
    })
  }, [enqueueSnackbar, errorMessages])
}

const axiosBaseQuery = ({ baseUrl }: { baseUrl: string }): AxiosBaseQuery => async ({
  url,
  method,
  data,
  params,
  meta,
  responseType,
}) => {
  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      data,
      params,
      meta,
      responseType,
    } as AxiosRequestConfig)
    if (responseType === 'blob') {
      const installationId = url.split('/')[1]
      const zipLink = window.URL.createObjectURL(result.data)
      const link = document.createElement('a')
      link.href = zipLink
      link.setAttribute('download', `Actions debug - ${installationId}.zip`)
      link.click()
      return { data: null }
    }

    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError as AxiosError<{ error_code: string, error_message: string }>
    return {
      error: {
        status: err.response?.status,
        data: responseType === 'blob' ? null : (err.response?.data || err.message),
      },
    }
  }
}

export default axiosBaseQuery
