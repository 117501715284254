import React, { FC, useEffect } from 'react'
import { Controller, useFormContext, UseControllerProps } from 'react-hook-form'
import { OutlinedTextFieldProps } from '@mui/material/TextField'

import Grid from '../grid'
import Typography from '../typography'
import { StyledTextField } from './styles'

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  rules?: UseControllerProps['rules']
  'data-testid'?: string
  forceError?: boolean
  maxCharsAllowed?: number
  forceTriggerOnStart?: boolean
  forceTriggerOnChange?: boolean
  hideError?: boolean
}

const TextField: FC<Props> = ({
  name = '',
  rules,
  defaultValue = '',
  forceError = false,
  'data-testid': dataTestId,
  maxCharsAllowed,
  forceTriggerOnStart = false,
  forceTriggerOnChange = false,
  hideError = false,
  ...props
}) => {
  const { control, trigger, formState } = useFormContext()

  useEffect(() => {
    if (forceTriggerOnStart) {
      trigger()
    }
  }, [forceTriggerOnStart, trigger])

  useEffect(() => {
    if (forceTriggerOnChange && formState.isDirty) {
      trigger()
    }
  }, [forceTriggerOnChange, formState.isDirty, formState.isValid, trigger])

  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Grid
          container
          flexDirection="column"
          style={{ position: 'relative' }}
          data-testid={dataTestId}
        >
          <StyledTextField
            {...props}
            variant="outlined"
            label={props.label ? `${props.label} ${rules?.required ? '*' : ''}` : ''}
            value={value}
            onChange={onChange}
            error={!!error || forceError}
          />
          {!hideError && error && (
            <Typography color="error" variant="caption">
              {error?.type === 'maxLength' ? (
                <>
                  <span>{error?.message?.split('|')[0]}</span>
                  <span style={{ float: 'right' }}>{error?.message?.split('|')[1]}</span>
                </>
              ) : (
                error?.message
              )}
            </Typography>
          )}
          {maxCharsAllowed && !error && (
            <Typography variant="caption">
              <span style={{ float: 'right' }}>
                {value.length}
                /
                {maxCharsAllowed}
              </span>
            </Typography>
          )}
        </Grid>
      )}
    />
  )
}

export default TextField
