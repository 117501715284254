/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext, useMemo, PropsWithChildren,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

import { useQueryParam } from '@common/use-query-param'
import {
  useLazyGetHealthRuleDetailsQuery,
  useLazyGetHealthRulesQuery,
} from '@services/healthrules'

import { HealthRulesResponse, SingleHealthRuleResponse } from '@services/healthrules/types'

export type HealthRulesContextValue = {
  state: {
    isGetHealthRulesLoading: boolean
    isGetHealthRuleDetailsLoading: boolean
    healthRules: HealthRulesResponse
    selectedHealthRule: SingleHealthRuleResponse
    selectedHealthRuleId: string | null
  }
  actions: {
    setHealthRules: Dispatch<SetStateAction<HealthRulesResponse>>
    setSelectedHealthRule: Dispatch<SetStateAction<SingleHealthRuleResponse>>
    setSelectedHealthRuleId: Dispatch<SetStateAction<string>>
  }
}
export const HealthRulesContext = createContext<HealthRulesContextValue | undefined>(undefined)

export const HealthRulesProvider = ({ children }: PropsWithChildren) => {
  const installationId = useQueryParam('installationId')
  const [getHealthRules, {
    data: healthRulesResponse,
    isLoading: isGetHealthRulesLoading,
  }] = useLazyGetHealthRulesQuery()
  const [getHealthRuleDetails, {
    data: singleHealthRuleDetails,
    isLoading: isGetHealthRuleDetailsLoading,
  }] = useLazyGetHealthRuleDetailsQuery()
  const [healthRules, setHealthRules] = useState<HealthRulesResponse | null>(null)
  const [selectedHealthRule, setSelectedHealthRule] = useState<SingleHealthRuleResponse | null>(null)
  const [selectedHealthRuleId, setSelectedHealthRuleId] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!installationId) return
      setHealthRules(null)
      await getHealthRules({ installationId }).unwrap().catch()
    }
    fetchData()
  }, [installationId])

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedHealthRuleId) return
      setSelectedHealthRule(null)
      await getHealthRuleDetails({ healthRuleId: selectedHealthRuleId, installationId }).unwrap().catch()
    }
    fetchData()
  }, [selectedHealthRuleId])

  useEffect(() => {
    setHealthRules(healthRulesResponse)
  }, [healthRulesResponse])

  useEffect(() => {
    setSelectedHealthRule(singleHealthRuleDetails)
  }, [singleHealthRuleDetails])

  const value = useMemo(() => ({
    state: {
      isGetHealthRulesLoading,
      healthRules,
      selectedHealthRule,
      selectedHealthRuleId,
      isGetHealthRuleDetailsLoading,
    },
    actions: { setHealthRules, setSelectedHealthRule, setSelectedHealthRuleId },
  }), [
    installationId, isGetHealthRulesLoading, isGetHealthRuleDetailsLoading,
    healthRules, selectedHealthRule, selectedHealthRuleId,
  ])

  return (
    <HealthRulesContext.Provider value={value}>
      {children}
    </HealthRulesContext.Provider>
  )
}

export const useHealthRules = (): HealthRulesContextValue => useContext(HealthRulesContext)
