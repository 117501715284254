import React, { type FC } from 'react'
import { TypographyProps } from '@phaidra/ava/typography'
import { AccordionHeaderTypographyWrapper } from './styles'

const AccordionHeaderTypography: FC<TypographyProps> = ({ children, ...props }) => (
  <AccordionHeaderTypographyWrapper variant="caption" component="p" {...props}>
    <span>
      {children}
    </span>
  </AccordionHeaderTypographyWrapper>
)

export default AccordionHeaderTypography
