import React, {
  FC,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react'
import Grid from '@phaidra/ava/grid'
import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from '@phaidra/ava/icons'

import { useTrendViewerProps } from '../../provider'
import {
  StyledPaper,
  Children,
  CollapseButton,
} from './styles'

const Sidebar: FC<PropsWithChildren> = ({ children }) => {
  const { useAppSelector, useAppDispatch, setSidebarOpen } = useTrendViewerProps()
  const dispatch = useAppDispatch()
  const sidebar = useAppSelector((state) => state.sidebars.trendViewer)
  const [open, setOpen] = useState<boolean>(sidebar.open)

  useEffect(() => {
    setOpen(sidebar.open)
  }, [sidebar])

  return (
    <Grid
      container
      flex={0}
      flexWrap="nowrap"
      data-testid="sidebar"
      sx={(theme) => ({ backgroundColor: theme.palette.container.surface.default })}
    >
      <StyledPaper square open={open} elevation={0}>
        <Grid container flexDirection="column" height="100%">
          <Children>
            {children}
          </Children>
        </Grid>
      </StyledPaper>

      <CollapseButton
        open
        variant="contained"
        startIcon={open
          ? <KeyboardArrowLeftIcon sx={{ ml: -0.25 }} />
          : <KeyboardArrowRightIcon />}
        size="small"
        onClick={() => {
          setOpen(!open)
          dispatch(setSidebarOpen({ name: 'trendViewer', open: !open }))
        }}
      />
    </Grid>
  )
}

export default Sidebar
