import React, { PropsWithChildren } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import Main from './main'

const root = createRoot(document.getElementById('root'))

root.render(
  <Main theme="light" Router={({ children }: PropsWithChildren<{}>) => <BrowserRouter>{children}</BrowserRouter>} />,
)
