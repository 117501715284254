import { createSlice, type PayloadAction, type Draft } from '@reduxjs/toolkit'
import { type ChartIndex } from '../../types'

type ChartStateKey = 'chartExpanded' | 'statsExpanded'
type ChartState = Record<ChartStateKey, boolean>

export type ViewState = Record<ChartIndex, ChartState>

type ViewId = number
export type ChartsState = Record<ViewId, ViewState>

const initialState: ChartsState = {}

const getToggleFunc = (propertyToToggle: ChartStateKey) =>
  (state: Draft<ChartsState>, action: PayloadAction<{ viewId: ViewId, chartIndex: ChartIndex }>) => {
    const { viewId, chartIndex } = action.payload

    const currentViewState = state[viewId]
    const currentChartState = currentViewState[chartIndex]
    const currentPropertyValue = currentChartState[propertyToToggle]

    return ({
      ...state,
      [viewId]: {
        ...currentViewState,
        [chartIndex]: {
          ...currentChartState,
          [propertyToToggle]: !currentPropertyValue,
        },
      },
    })
  }

export const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    toggleExpandChart: getToggleFunc('chartExpanded'),
    toggleExpandStats: getToggleFunc('statsExpanded'),
    initialiseView: (state, action: PayloadAction<{ viewId: ViewId, initialState: ViewState }>) => ({
      ...state,
      [action.payload.viewId]: action.payload.initialState,
    }),
  },
})

export const {
  toggleExpandChart,
  toggleExpandStats,
  initialiseView,
} = chartsSlice.actions

export default chartsSlice.reducer
