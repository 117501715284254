import React, { Component, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
  hasParentError?: boolean
  fallbackUI: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean // Tracks if there has been an error
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: !!props.hasParentError }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // TODO: investigate remote logging solution i.e. LogRocket.captureException(error)
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    const { props, state } = this
    if (state.hasError) return props.fallbackUI

    return props.children
  }
}

export default ErrorBoundary
