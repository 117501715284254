import AccordionSummary from '@mui/material/AccordionSummary'

import styled from '../styled'

import { KeyboardArrowDownIcon } from '../icons'

export const KeyboardArrowDownRoundedIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fill: theme.palette.icon.default,
  fontSize: '20px',
  '&:hover': {
    backgroundColor: theme.palette.hover.surface.default,
    borderRadius: theme.shape.borderRadius * 2.5,
  },
}))

export const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme }) => ({
  padding: '0',
  fontWeight: 400,
  fontSize: '1.125rem',
  color: theme.palette.text.default,
  '& .MuiAccordionSummary-content': {
    margin: `${theme.spacing(2)} 0`,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: '5px',
    '& > div > .MuiSvgIcon-root': {
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none',
    '& > div > .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
}))
