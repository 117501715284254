import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Sidebar {
  open: boolean
}

export type Page = 'systemBuilder' | 'tagMapping' | 'trendViewer'

export type SidebarState = Record<Page, Sidebar>

const initialState: SidebarState = {
  systemBuilder: { open: true },
  tagMapping: { open: true },
  trendViewer: { open: true },
}

export const sidebarSlice = createSlice({
  name: 'sidebars',
  initialState,
  reducers: {
    setSidebarOpen: (state, action: PayloadAction<{ name: Page, open: boolean }>) => ({
      ...state,
      [action.payload.name]: {
        ...state[action.payload.name],
        open: action.payload.open,
      },
    }),
  },
})

export const { setSidebarOpen } = sidebarSlice.actions

export default sidebarSlice.reducer
