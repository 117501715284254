import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const TrashIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20" data-testid="icon-delete">
    <mask
      id="mask0_186_11660"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="-2"
      y="-2"
      width="24"
      height="24"
    >
      <rect x="-2" y="-2" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_186_11660)">
      <path d={'M3.5 18.5V4H2.5V2.5H7V1.625H13V2.5H17.5V4H16.5V18.5H3.5ZM5 17H15V4H5V17ZM7.4\
        15H8.9V6H7.4V15ZM11.1 15H12.6V6H11.1V15Z'}
      />
    </g>
  </SvgIcon>
)

export default TrashIcon
