import styled from '@phaidra/ava/styled'
import Typography from '@phaidra/ava/typography'

export const AccordionHeaderTypographyWrapper = styled(Typography)({
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
  '&:after': {
    content: '""',
    flex: 1,
    display: 'inline-block',
    width: '100%',
    marginRight: '-100%',
    marginLeft: '10px',
    height: '4px',
    borderTop: '1px solid black',
  },
})
