import { createContext, useContext } from 'react'
import { TypedUseSelectorHook } from 'react-redux'
import { CombinedState, Reducer, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { PayloadAction } from '@reduxjs/toolkit'

import { trendViewerApi } from '../services/trend-viewer'
import { AgentsResponse } from '../services/trend-viewer/types'

type Reducers = Record<string, ReturnType<Reducer>> & {
  [trendViewerApi.reducerPath]: ReturnType<typeof trendViewerApi.reducer>
}

export type Dispatcher = ThunkDispatch<CombinedState<Reducers>, undefined, AnyAction>

export interface Context {
  useAppSelector: TypedUseSelectorHook<CombinedState<Reducers>>
  useAppDispatch: () => Dispatcher
  installationName: string
  setSidebarOpen: any
  agentList?: AgentsResponse
  setAgent?: (payload: any) => PayloadAction<any>
  timestamp: string | null
}

const context = createContext<Context>(undefined)

export default context.Provider

export const useTrendViewerProps = () => useContext(context)
