import styled from '@phaidra/ava/styled'
import Grid, { GridProps } from '@phaidra/ava/grid'

export const Container = styled(Grid)<
GridProps & { size?: 'small' | 'medium' | 'large', open: boolean }>(({ theme, size, open }) => {
  let height = '52px'
  let padding = '16px'
  const hoverBorderColor = theme.palette.mode === 'dark' ? '#86D98C' : '#155E2A'
  if (size === 'medium') {
    height = '44px'
  }
  if (size === 'small') {
    height = '24px'
    padding = '4px 12px'
  }

  return ({
    height,
    border: 'solid 1px #58585A',
    borderRadius: open ? '4px 4px 0 0' : '4px',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    minWidth: '300px',
    '&:hover': {
      borderColor: open ? '#58585A' : hoverBorderColor,
      backgroundColor: theme.palette.mode === 'dark' ? '#00390E' : '#F4FFF1',
    },
    '& > *': {
      maxHeight: height,
    },
    '& > input': {
      padding,
    },
  })
})

export const Input = styled('input')(({ theme }) => ({
  backgroundColor: 'inherit',
  border: 'none',
  padding: '16px',
  paddingRight: '4px',
  flex: 1,
  fontSize: '16px',
  color: theme.palette.text.default,
  caretColor: theme.palette.mode === 'dark' ? '#86D98C' : '#155E2A',
  '&:focus': {
    outline: 'none',
  },
}))

export const Listbox = styled('ul')<{
  width: number
  height?: number
  open: boolean
  variant: 'tree' | 'list' }>(({
  width, open, variant, height,
}) => ({
  display: open ? 'block' : 'none',
  width: `${width}px`,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  overflow: 'hidden',
  maxHeight: variant === 'tree' ? 295 : 228,
  border: open ? 'solid 1px #58585A' : 'none',
  borderTop: 'none',
  borderRadius: '0 0 4px 4px',
  backgroundColor: '#fff',
  height: `${height}px`,
}))

export const StyledListLink = styled('li')(({ theme }) => {
  /* istanbul ignore next */
  const color = theme.palette.mode === 'dark' ? '#7FACFB' : '#00458D'
  /* istanbul ignore next */
  const hoverColor = theme.palette.mode === 'dark' ? '#f2f2f2' : '#1A1C19'

  return {
    color,
    textDecorationColor: color,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: hoverColor,
      cursor: 'pointer',
    },
  }
})

export const NoOptionsContainer = styled('div')({
  padding: '6px 12px',
  color: '#B8B8B9',
})
