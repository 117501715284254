import React from 'react'
import { escapeRegExp } from 'lodash-es'

const getMatchingOption = (
  item: string,
  matcherText: string,
  options?: {
    customStyle?: Record<string, string>
    replaceWith?: string
  },
) => {
  if (!matcherText) {
    return item
  }

  const parts = item.split(new RegExp(`(${escapeRegExp(matcherText)})`, 'gi'))
  return (
    parts.map((part) => (
      <span
        key={`styled-part-${Math.random()}`}
        style={
              part.toLowerCase() === matcherText.toLowerCase()
                ? (options?.customStyle || { fontWeight: '700' })
                : {}
            }
      >
        {part.toLowerCase() === matcherText.toLowerCase() ? (options?.replaceWith || part) : part}
      </span>
    ))
  )
}

export default getMatchingOption
