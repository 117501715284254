import styled from '../../../../styled'
import leftTriangle from '../assets/triangle-left.svg'

const BASE_HEIGHT = 32
const MARKER_WIDTH = 8

export const Marker = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.0625),
  width: `${MARKER_WIDTH}px`,
  height: `${BASE_HEIGHT}px`,
  background: `transparent url("${leftTriangle}") center left no-repeat`,
  backgroundSize: 'contain',
}))

export const PaletteContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.625),
  marginRight: theme.spacing(-1),
  height: `${BASE_HEIGHT}px`,
  position: 'relative',
  left: '-1px',
  display: 'flex',
  flexDirection: 'row',
  zIndex: 1,
}))

export const PreviewValue = styled('div')(({ theme }) => ({
  height: `${BASE_HEIGHT}px`,
  backgroundColor: theme.palette.container.surface.constants,
  position: 'relative',
  left: '-1px',
  boxSizing: 'border-box',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.container.surface.default,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))
