import React, {
  FC, useState, useRef, useMemo,
} from 'react'
import { GridRowsProp, GridRowId } from '@phaidra/ava/data-grid'
import { VariableSizeTree } from 'react-vtree'
import AutoSizer from 'react-virtualized-auto-sizer'

import { useAppSelector, useAppDispatch } from '@store'
import { setSearchTerm } from '@store/tag-management'
import ClickAwayListener from '@phaidra/ava/clickaway-listener'
import { SearchIcon, CloseIcon } from '@phaidra/ava/icons'
import IconButton from '@phaidra/ava/icon-button'
import { useSnackbar } from '@phaidra/ava/snackbar'
import { useGetTagsInUseQueryState } from '@services/tag-management'
import {
  NoOptionsContainer, Container, Listbox, Input,
} from '../styles'
import Node from '../node'
import { getTagsInUseMap } from '../../../app/tag-management/utils'

export type ProcessStatus = 'UP_TO_DATE' | 'NEEDS_PROCESSING'
export type TagStatus = 'COMPLETE' | 'INCOMPLETE' | 'NOT_REQUIRED'
| 'CONFIRM' | 'PHAIDRA_TO_CALCULATE' | 'DOES_NOT_EXIST'

interface Row {
  id: GridRowId
  name: string
  type: string
  processStatus: ProcessStatus
  tagStatus: TagStatus
  hasNote: boolean
  fullTagName: string
  failedCount?: number
  passedCount?: number
  ignoredCount?: number
  unit: string
}

interface Component {
  name: string
  componentType: string
  status?: Row['tagStatus']
  tags: GridRowsProp<Row>
  id: GridRowId
  component_id: string
  hasNote: boolean
  failedCount?: number
  passedCount?: number
  ignoredCount?: number
}

export type TreeSearchProps = {
  installationId: string
  installationName: string
  data: Array<Component>
  onChange: (value: string) => void
  placeholder?: string
  size?: 'small' | 'medium' | 'large'
  handleOnCopy?: (value: string) => void
}

export interface Tag {
  id: GridRowId
  type: 'tag'
  name: string
  component: string
}

export interface PointName {
  name: string
  fullTagName: string
}

type Children = Array<{
  name: string
  fullTagName: string
}>

export interface TreeData {
  id: GridRowId
  name: string
  type: string
  instance: string | number
  children?: Children
  component?: string
  fullTagName: string
}
export interface Tags {
  id: GridRowId
  name: string
  fullTagName: string
  type: string
  children?: Children
  component: string
}

const TreeSearch: FC<TreeSearchProps> = ({
  installationId, installationName, onChange, placeholder, size = 'large', data, handleOnCopy,
}) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const searchTerm = useAppSelector((state) => state.tags[installationId]?.tagSearchTerm ?? '')
  const setSearch = (value: string) => {
    dispatch(setSearchTerm({ installationId, tagSearchTerm: value }))
  }
  const { enqueueSnackbar } = useSnackbar()
  const ref = useRef()

  const buttonRef = useRef<HTMLDivElement>()

  const onCopy = (option: string) => {
    enqueueSnackbar('Copied to clipboard', { variant: 'success' })
    setSearch('')
    handleOnCopy?.(option)
  }

  const onInsert = (option: string) => {
    onChange(option)
    setSearch('')
  }

  const { data: allTagsInUseData } = useGetTagsInUseQueryState({ installationId })

  const tagsInUseMap = getTagsInUseMap(allTagsInUseData)

  const filteredData = useMemo(() => {
    if (data) {
      const components = data
      const nodes: Record<string, TreeData | Tags> = {}
      nodes[installationName] = {
        id: installationName,
        name: installationName,
        fullTagName: installationName,
        type: 'root',
        children: [],
        instance: installationName,
      }
      components.forEach(({
        name, tags, component_id,
      }) => {
        nodes[installationName].children.push({ name, fullTagName: component_id })
        const children = tags.map((item) => ({
          name: item.name,
          fullTagName: item.fullTagName,
        }))
        if (children.length) {
          nodes[component_id] = {
            name,
            id: component_id,
            fullTagName: component_id,
            children,
            type: 'component',
            component: installationName,
          }
        }
        tags.forEach((tag) => {
          nodes[tag.fullTagName] = {
            type: 'tag',
            component: component_id,
            name: tag.name,
            id: tag.fullTagName,
            fullTagName: tag.fullTagName,
          }
        })
      })
      const nodeData: {
        root: string
        nodes: Record<string, TreeData | Tags>
      } = { nodes, root: installationName }
      // TODO: remove the above code when backend API similar to trend-viewer is available
      const foundIds = Object.entries(nodeData.nodes).reduce<Array<string>>((acc, [id]) => [
        ...acc,
        ...(id
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ? [id] : []),
      ], [])

      const componentIds = foundIds.filter((id) => nodeData.nodes[id].type === 'component')
      const tagIds = foundIds.filter((id) => nodeData.nodes[id].type === 'tag')

      const addParents = (id: string) => {
        if (!foundIds.includes(id)) {
          foundIds.push(id)
        }
        if (nodeData.nodes[id]?.component !== undefined) {
          addParents(nodeData.nodes[id].component)
        }
      }

      const addChildren = (id: string) => {
        const node = nodeData.nodes[id]
        if (node.type === 'component') {
          node.children.forEach((childId) => {
            const childNode = nodeData.nodes[childId.fullTagName]
            if (!childNode) {
              return
            }
            if (!foundIds.includes(childId.fullTagName)) {
              foundIds.push(childId.fullTagName)
            }
            if (childNode.type === 'component') {
              addChildren(childId.fullTagName)
            }
          })
        }
      }

      componentIds.forEach((componentId) => {
        addParents(componentId)
        addChildren(componentId)
      })

      tagIds.forEach((tagId) => {
        addParents(tagId)
      })

      const treeNodes: Record<string, TreeData | Tags> = {}
      foundIds.forEach((id) => {
        treeNodes[id] = nodeData.nodes[id]
      })

      const filtered: {
        root: string
        nodes: Record<string, TreeData | Tags>
      } = {
        ...nodeData,
        nodes: treeNodes,
      }

      const getChildren = (key: string, component?: string, isLast?: boolean): any => {
        const node = filtered.nodes[key]
        if (node) {
          if (node.type === 'component' || node.type === 'root') {
            const children = node.children.filter((child) => !!filtered.nodes[child.fullTagName])
            return {
              id: key,
              name: node.name,
              fullTagName: node.fullTagName,
              type: node.type,
              instance: node.id,
              children: children
                .map((child, index) => getChildren(child.fullTagName, key, index === children.length - 1)),
              isLast,
              component,
            }
          }
          return {
            id: key,
            name: node.name,
            fullTagName: node.fullTagName,
            type: node.type,
            component,
            isLast,
          }
        }
      }

      return getChildren(filtered.root)
    }
  }, [data, installationName, searchTerm])

  function* treeWalker(refresh: boolean): any {
    const stack = []

    stack.push({
      nestingLevel: 0,
      node: filteredData,
    })

    while (stack.length !== 0) {
      const {
        node: {
          children = [],
          id,
          name: nodeName,
          component,
          type,
          isLast,
          fullTagName,
        },
        nestingLevel,
      } = stack.pop()

      let height = 40
      if (children.length === 0) {
        height = (nodeName.length > 118 ? 59 : 32)
      }

      const isOpened = yield refresh ? {
        id,
        isLeaf: children.length === 0,
        isOpenByDefault: true,
        name: nodeName,
        nestingLevel,
        type,
        component,
        isLast,
        fullTagName,
        defaultHeight: height,
      } : id

      if (children.length !== 0 && isOpened) {
        for (let i = children.length - 1; i >= 0; i -= 1) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            node: children[i],
          })
        }
      }
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Container
          container
          open={open}
          ref={buttonRef}
          alignItems="center"
          size={size}
          onClick={() => setOpen((o) => !o)}
        >
          <Input
            type="text"
            placeholder={placeholder || 'Tag Search'}
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!open) {
                setOpen(true)
              }
              setSearch(e.target.value.toLowerCase())
            }}
          />
          {searchTerm ? (
            <IconButton
              data-testid="clear-search"
              style={{ marginRight: '8px' }}
              size="small"
              onClick={() => {
                onChange('')
                setSearch('')
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <SearchIcon fontSize="small" style={{ marginRight: '12px' }} />
          )}
        </Container>
        {open && (
          <Listbox
            variant="tree"
            width={buttonRef.current.offsetWidth}
            open={open}
          >
            {!filteredData
              ? (open
            && (
              <NoOptionsContainer>
                No options available
              </NoOptionsContainer>
            )
              )
              : (
                <AutoSizer style={{ height: '295px', width: `${buttonRef.current.offsetWidth}px` }}>
                  {({ height }) => (
                    <VariableSizeTree
                      // eslint-disable-next-line react/jsx-no-bind
                      treeWalker={treeWalker}
                      ref={ref}
                      itemData={{
                        installationName,
                        search: searchTerm,
                        onInsert,
                        onCopy,
                        tagsInUseMapping: tagsInUseMap,
                      }}
                      height={height}
                      style={{ overflowX: 'hidden', width: '100% !important' }}
                    >
                      {Node}
                    </VariableSizeTree>
                  )}
                </AutoSizer>
              )}
          </Listbox>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default TreeSearch
