const safelyParseBool = (bool: string, defaultValue: boolean | null = null) => {
  if (bool === 'true') {
    return true
  }

  if (bool === 'false') {
    return false
  }

  return defaultValue
}

export default safelyParseBool
