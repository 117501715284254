import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import { Versions, VersionsData, VersionsQuery } from './types'

export const versionsApi = createApi({
  reducerPath: 'versionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/versions`,
  }),
  endpoints: (builder) => ({
    getVersions: builder.query<Array<Versions>, VersionsQuery>({
      transformResponse: (response: VersionsData) => response.data,
      query: (params) => ({
        url: '',
        method: 'get',
        params,
      }),
    }),
  }),
})

export const {
  useGetVersionsQuery,
  useLazyGetVersionsQuery,
} = versionsApi
