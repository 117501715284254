import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const DataLogo: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 26 26" data-testid="data-logo-icon">
    <path
      d="M3.35801 25.2632L1.22412 23.1294V3.64713L3.358 1.51324H22.8402L24.9741 3.64713V23.1294L22.8402
    25.2632H3.35801Z"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <circle
      cx="8.40771"
      cy="8.46387"
      r="1.625"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <circle
      cx="17.7905"
      cy="8.46387"
      r="1.625"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <circle
      cx="8.40771"
      cy="18.3126"
      r="1.625"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <circle
      cx="17.7905"
      cy="18.3126"
      r="1.625"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
    <circle
      cx="13.0991"
      cy="13.3882"
      r="1.625"
      stroke="currentColor"
      strokeWidth="1.25"
      fill="none"
    />
  </SvgIcon>
)

export default DataLogo
