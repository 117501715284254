import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const NoteIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 16" data-testid="icon-note">
    <path
      d={'M0.571289 15.8979V0.285645H8.92843L13.4284 4.59955V15.8979H0.571289ZM8.28557 \
      5.21583V1.51819H1.857V14.6653H12.1427V5.21583H8.28557Z'}
    />
  </SvgIcon>
)

export default NoteIcon
