import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const KeyboardArrowLeftIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0.7 0 21 20" data-testid="arrow-icon-left">
    <path
      d="M6.79101 10L12.8171 4L13.7461 4.95572L8.70245 10L13.7461 15.0443L12.7904 16L6.79101 10Z"
    />
  </SvgIcon>
)

export default KeyboardArrowLeftIcon
