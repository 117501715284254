import React, {
  memo,
  useEffect,
  useRef,
  type FC,
} from 'react'
import { RotatableArrowIcon } from '@phaidra/ava/icons'
import Typography from '@phaidra/ava/typography'

import { useTrendViewerProps } from '../../provider'
import { toggleExpandStats, type ChartsState } from '../../store/charts'
import { type ChartIndex } from '../../types'
import StyledAccordion from '../styled-accordion'
import StatsTooltip from './stats-tooltip'

export interface Props {
  chartIndex: ChartIndex
  viewId: number | undefined
  children: React.ReactNode
  onChange?: () => void
  showTooltip: boolean
  expandedOverride?: boolean
}

const CollapsibleStatsContainer: FC<Props> = ({
  chartIndex,
  viewId,
  children,
  onChange,
  showTooltip,
  expandedOverride,
}) => {
  const { useAppDispatch, useAppSelector } = useTrendViewerProps()
  const dispatch = useAppDispatch()
  const chartsState: ChartsState = useAppSelector((state) => state.charts)
  const chartExpanded = expandedOverride
    ?? chartsState[viewId]?.[chartIndex]?.statsExpanded
    ?? true

  const isMountedRef = useRef(false)
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true
      return
    }
    onChange?.()
  }, [chartExpanded, onChange])

  const toggleIsExpanded = () => {
    dispatch(toggleExpandStats({ viewId, chartIndex }))
  }

  return (
    <StyledAccordion expanded={chartExpanded} onChange={toggleIsExpanded}>
      <>
        <RotatableArrowIcon direction={!chartExpanded ? 'up' : 'down'} isTiny />
        <StatsTooltip chartIndex={chartIndex} showTooltip={showTooltip}>
          <Typography variant="caption" component="p" style={{ marginLeft: -2 }}>
            Statistics
          </Typography>
        </StatsTooltip>
      </>
      {children}
    </StyledAccordion>
  )
}

export default memo(CollapsibleStatsContainer)
