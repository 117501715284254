import MUIRadio from '@mui/material/Radio'

import styled from '../styled'

export const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  border: `1px solid ${theme.palette.container.border.default}`,
  'input:disabled ~ &': {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.container.border.disabled}`,
  },
}))

export const CheckedIcon = styled(RadioIcon)<{ disabled?: boolean }>(({ theme }) => ({
  border: `1px solid ${theme.palette.text.default}`,
  'input:disabled ~ &': {
    border: `1px solid ${theme.palette.container.border.disabled}`,
  },
}))

export const StyledRadio = styled(MUIRadio)(({ theme }) => ({
  padding: '0',
  marginTop: theme.spacing(-0.625),
}))
