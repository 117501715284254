import ToggleButton from '@mui/material/ToggleButton'

import { CheckIcon as MUICheckIcon } from '../icons'
import styled from '../styled'

export const StyledToggledButton = styled(ToggleButton, {
  shouldForwardProp: (prop: string) => !['reduceBorder', 'isThin'].includes(prop),
})<{ reduceBorder: boolean, isThin: boolean }>(({
  theme,
  reduceBorder,
  isThin,
}) => ({
  '&.MuiButtonBase-root': {
    borderColor: `${theme.palette.dividers.low} !important`,
    textTransform: 'none',
    fontSize: '1rem',
    height: isThin ? '28px' : '44px',
    minWidth: '58px',
    color: theme.palette.text.subdued,
    fontWeight: 400,
    ...(reduceBorder ? { borderTopWidth: 0, borderBottomWidth: 0, borderRadius: 0 } : {}),

    '&:hover': {
      backgroundColor: theme.palette.hover.surface.default,
      color: theme.palette.text.default,
    },

    '&.Mui-selected': {
      color: theme.palette.text.default,
      backgroundColor: theme.palette.container.surface.low,
      cursor: 'initial',
      pointerEvents: 'none',
    },

    '&:not(.Mui-selected) > :first-of-type': {
      display: 'none',
    },
  },
}))

export const CheckIcon = styled(MUICheckIcon)(({ theme }) => ({
  height: '16px !important',
  marginLeft: theme.spacing(-0.625),
}))
