/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { SBR, Setpoint } from '@services/setpoints/types'

import Typography from '@phaidra/ava/typography'
import Button from '@phaidra/ava/button'
import Grid from '@phaidra/ava/grid'
import NELExpressionEditor from '@common/nel-expression-editor'

import { useSetpoints } from '@providers/setpoints'
import InlineEdit from '@phaidra/ava/inline-edit'
import { NotesEditorContainer, StyledInputBase, StyledTextField } from '../styles'

export interface SetpointFieldProps {
  setpoint: Setpoint
  param?: SBR
  fieldName: string
}

export const SetpointSubmitButton = ({
  onSubmit, disabled = false, label = 'Submit', loading,
}: any) => (
  <Button
    data-testid="setpoint-submit-button"
    key={label}
    disabled={disabled}
    onClick={() => {
      onSubmit?.()
    }}
    loading={loading}
  >
    Submit
  </Button>
)

export const StringField = ({ param, fieldName }: SetpointFieldProps) => {
  const { value } = param
  const defaultValue = value || ''
  const { state: { setpointFieldErrors } } = useSetpoints()
  const { watch, setValue } = useFormContext()
  const inputValue = watch(fieldName)
  const hasFieldError = setpointFieldErrors && !!setpointFieldErrors[fieldName]
  useEffect(() => setValue(fieldName, defaultValue), [])

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <StyledInputBase
          isError={hasFieldError}
          placeholder={param?.name}
          name={fieldName}
          value={inputValue}
          onChange={(e) => setValue(fieldName, e.target.value)}
          inputProps={{ 'data-testid': 'setpoint-string-input' }}
        />
      </Grid>
    </Grid>
  )
}

export const NumberField = ({ param, fieldName }: SetpointFieldProps) => {
  const { value } = param
  const defaultValue = value || ''
  const { state: { setpointFieldErrors } } = useSetpoints()

  const { watch, setValue } = useFormContext()
  const inputValue = watch(fieldName)
  const hasFieldError = setpointFieldErrors && !!setpointFieldErrors[fieldName]

  useEffect(() => setValue(fieldName, defaultValue), [])

  return (
    <Grid sx={{ maxWidth: '250px' }}>
      <StyledTextField
        type="number"
        error={hasFieldError}
        defaultValue={inputValue}
        name={fieldName}
        ref={null}
        label={param?.name}
        inputProps={{
          'aria-label': fieldName,
          onChange: (e) => setValue(fieldName, (e.target as HTMLInputElement)?.value),
          'data-testid': 'setpoint-number-input',
        }}
      />
    </Grid>

  )
}

export const ExpressionField = ({ param, fieldName }: SetpointFieldProps) => {
  const { value = '' } = param
  const defaultValue = value || ''
  const { state: { setpointFieldErrors } } = useSetpoints()
  const { setValue } = useFormContext()
  const hasFieldError = setpointFieldErrors && !!setpointFieldErrors[fieldName]

  useEffect(() => setValue(fieldName, defaultValue), [])

  return (
    <Grid container direction="column" columnSpacing="0">
      <Grid item mb="4px">
        <Typography fontSize="1.125rem" fontWeight={700}>NEL expression</Typography>
      </Grid>
      <Grid item>
        <NELExpressionEditor fieldName={fieldName} error={hasFieldError} />
      </Grid>
    </Grid>
  )
}

export const SetpointNotes = ({ setpoint, fieldName }: SetpointFieldProps) => {
  const [editNotes, setEditNotes] = useState<boolean>(false)
  const defaultValue = setpoint?.notes
  const { watch, setValue } = useFormContext()
  const notes = watch(fieldName)

  useEffect(() => setValue(fieldName, defaultValue), [])

  return (
    <Grid container direction="row">
      <Grid container flexDirection="column" margin="6px 6px 0px 0px" width="auto" style={{ width: '100%' }}>
        <Grid container gap={0.5} alignItems="center" minHeight="32px">
          <Typography variant="h5" fontWeight={700}>Notes</Typography>
        </Grid>
      </Grid>
      <NotesEditorContainer
        onClick={() => setEditNotes(true)}
        style={{
          cursor: 'pointer',
          width: '100%',
          border: 'none',
        }}
        data-testid="inline-edit-container"
      >
        <InlineEdit
          fontSize="0.875rem"
          fontWeight={400}
          editing={editNotes}
          labelPlaceholder="Add notes here..."
          value={notes || ''}
          onSave={async (val) => {
            setValue(fieldName, val)
            setEditNotes(false)
          }}
          onCancel={() => {
            setEditNotes(false)
          }}
        />
      </NotesEditorContainer>
    </Grid>
  )
}
