import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider'

import styled from '../styled'

export interface DividerProps extends MuiDividerProps {
  color?: 'default' | 'low' | 'high'
}

const Divider = styled(
  MuiDivider,
  { shouldForwardProp: (prop) => prop !== 'color' },
)<DividerProps>(({ color, theme }) => {
  let backgroundColor = theme.palette.dividers.default
  if (color === 'low') {
    backgroundColor = theme.palette.dividers.low
  }
  if (color === 'high') {
    backgroundColor = theme.palette.dividers.high
  }
  return {
    backgroundColor,
  }
})

export default Divider
