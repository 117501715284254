import React from 'react'
import { Overlay } from './styles'

export interface YAxisOverlayProps {
  x: number
  y: number
  width: number
  height: number
  allowed?: boolean
  onMouseEnter?: (ev: React.MouseEvent) => void
  onMouseOut?: () => void
  onMouseMove?: (ev: React.MouseEvent) => void
  onClick?: () => void
}

const YAxisOverlay = ({
  x,
  y,
  width,
  height,
  allowed = true,
  onMouseEnter,
  onMouseOut,
  onMouseMove,
  onClick,
}: YAxisOverlayProps) => (
  <Overlay
    data-testid="constant-yaxis-overlay"
    aria-hidden="true"
    onMouseEnter={onMouseEnter}
    onMouseOut={onMouseOut}
    onBlur={onMouseOut}
    onClick={onClick}
    onMouseMove={onMouseMove}
    x={x}
    y={y}
    width={width}
    height={height}
    allowed={allowed}
  />
)

export default YAxisOverlay
