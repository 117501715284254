import styled from '@phaidra/ava/styled'
import InputBase from '@phaidra/ava/input-base'
import DataGrid from '@phaidra/ava/data-grid'

export const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (prop: string) => !['isError', 'height', 'padding'].includes(prop),
})<{ isError?: boolean, height?: string, padding?: string }>(({ isError, height, padding }) => ({
  flex: 2,
  height,
  minHeight: height,
  border: '1px solid #58585A',
  minWidth: '100%',
  borderRadius: '4px',
  display: 'block',
  padding: padding || '7px 10px',
  overflow: 'auto',
  outline: 'none',
  fontSize: '0.875rem',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  ...(isError && { borderColor: '#BA1B1B' }),
}))

export const StyledDataGrid = styled(DataGrid)(({
  '& .expandable-row-cells': {
    alignItems: 'flex-start !important',
    display: 'inline !important',
    wordWrap: 'break-word !important',
    paddingTop: '13px !important',
    paddingBottom: '8px !important',
    textOverflow: 'ellipsis !important',
  },
  '& .expandable-row-cell-arrow': {
    paddingTop: 'unset !important',
  },
}))
