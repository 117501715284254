import React, { useEffect, useState, type FC } from 'react'
import { AnimatedContainer } from './styles'

type BaseProps = {
  children: React.ReactElement
  delayMs?: number
}

type AnimatedProps = {
  animate: true
  animationDurationMs?: number
}

type UnanimatedProps = {
  animate?: false | undefined
  animationDurationMs?: never
}

type Props = BaseProps & (AnimatedProps | UnanimatedProps)

const DelayRender: FC<Props> = ({
  children,
  delayMs = 500,
  animate = false,
  animationDurationMs = 250,
}) => {
  const [render, setRender] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRender(true)
    }, delayMs)
  }, [delayMs])

  if (!render) {
    return null
  }

  return (
    <AnimatedContainer
      animate={animate}
      animationDurationMs={animationDurationMs}
    >
      {children}
    </AnimatedContainer>
  )
}

export default DelayRender
