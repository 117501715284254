import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const AlfredIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 45 45" fill="none" data-testid="icon-alfred">
    <path
      d={'M3.51562 37.5213V7.47874L7.47874 3.51562H37.5213L41.4844 7.47874V37.5213L37.5213\
      41.4844H7.47874L3.51562 37.5213Z'}
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.40625"
    />
    <g clipPath="url(#clip0_6194_30896)">
      <path d={'M8.10247 23.2019L5.48544 25.8179H5.19174L2.57471 23.2019V22.9083L5.19174\
      20.2923H5.48544L8.10247 22.9083V23.2019Z'}
      />
      <path d={'M37.2973 23.0529C37.2973 26.6505 34.3822 29.5644 30.7832 29.5644V29.5381C34.0972\
      29.2314 36.6923 26.4445 36.6923 23.0529C36.6923 19.6613 34.0972 16.8788 30.7832\
      16.5721V16.5414C34.3822 16.5414 37.2973 19.4598 37.2973 23.0529Z'}
      />
      <path d={'M38.4239 19.8191C37.9943 18.785 37.3762 17.8648 36.5915 17.0804C35.8025 16.2917\
      34.8819 15.6738 33.8518 15.2488C32.9531 14.8763 32.0063 14.666 31.0199 14.6178L30.3624\
      14.6046H5.55103C4.50773 14.609 3.48634 14.8018 2.51756 15.1787L2.32468 15.2575C1.29452\
      15.6826 0.378345 16.3004 -0.410709 17.0848C-1.89238 18.5659 -2.7691 20.5333 -2.87869\
      22.6235L-2.88746 22.847C-2.89185 22.9127 -2.89185 22.9785 -2.89185 23.0442C-2.89185\
      23.1844 -2.88746 23.3246 -2.87869 23.4648C-2.77349 25.5594 -1.90114 27.5312 -0.415093\
      29.0167C1.06658 30.5022 3.03483 31.3786 5.13897 31.4925H5.27048C5.36692 31.5013 5.45898\
      31.5013 5.55103 31.5013H30.6167C31.7301 31.4969 32.8216 31.2778 33.8474 30.8527C33.9657\
      30.8045 34.0841 30.752 34.2331 30.6818C35.1143 30.2656 35.9077 29.7091 36.5915 29.0211C37.3762\
      28.2367 37.9943 27.3165 38.4239 26.2824C38.7921 25.3929 39.0069 24.4508 39.0551 23.4911L39.0639\
      23.2589V22.8426C39.0376 21.7997 38.8228 20.7831 38.4239 19.8191ZM-2.11156 22.7331H-2.26499L-2.11156\
      22.7243V22.7331ZM9.2552 23.6796L5.96309 26.9704H4.71376L1.42165 23.6796V22.4307L4.71376\
      19.1399H5.96309L9.2552 22.4307V23.6796ZM37.8233 23.2326L37.8146 23.421C37.7751 24.2448 37.591\
      25.0467 37.2754 25.8092C36.9115 26.6899 36.3855 27.4743 35.7148 28.1447C35.1274 28.7319 34.4523\
      29.2051 33.672 29.5776C33.5756 29.6214 33.4748 29.6652 33.374 29.7091C32.4972 30.0728 31.5679\
      30.2568 30.6035 30.2568H9.55329C12.0563 28.8327 13.744 26.1422 13.744 23.0529C13.744 19.9637 12.052\
      17.2688 9.54452 15.8447H30.3361L30.9805 15.8535C31.8046 15.8973 32.6112 16.077 33.374 16.3924C34.2551\
      16.7561 35.0441 17.282 35.7148 17.9568C36.3855 18.6272 36.9115 19.4116 37.2754 20.2923C37.6173 21.1161\
      37.8014 21.9838 37.8233 22.8733V23.2326Z'}
      />
      <path d="M25.9348 24.718H24.7074L24.5583 20.4019L25.9348 24.718Z" />
      <path d={'M31.5899 22.216C31.5899 23.5875 30.4765 24.7005 29.1 24.7005C27.7236 24.7005 26.6101 23.5875\
      26.6101 22.216C26.6101 20.8445 27.7279 19.7271 29.1 19.7271C30.4721 19.7271 31.5899 20.8401 31.5899\
      22.216Z'}
      />
      <path d={'M23.2171 22.216C23.2171 23.5875 22.1037 24.7005 20.7272 24.7005C19.3507 24.7005 18.2373\
      23.5875 18.2373 22.216C18.2373 20.8445 19.3551 19.7271 20.7272 19.7271C22.0993 19.7271 23.2171\
      20.8401 23.2171 22.216Z'}
      />
      <path d={'M25.7551 7.58483C25.7551 8.11066 25.2598 9.98612 24.9135 11.2481C24.7995 11.6644 24.3304\
      11.6644 24.2165 11.2481C23.8702 9.9905 23.3967 8.11504 23.3967 7.58921C23.3967 6.77418 23.9228 6.30093\
      24.5759 6.30093C25.2291 6.30093 25.7551 6.77856 25.7551 7.58921V7.58483Z'}
      />
      <path d={'M21.1526 9.07028C21.5252 9.44274 22.5027 11.1166 23.1471 12.2559C23.3575 12.6328 23.0287\
      12.9614 22.6518 12.7511C21.5164 12.1069 19.855 11.1122 19.4824 10.7398C18.9081 10.1658 18.9432\
      9.45588 19.4035 8.99578C19.8638 8.53568 20.5739 8.50063 21.1482 9.07466L21.1526 9.07028Z'}
      />
      <path d={'M27.9646 9.07028C27.5919 9.44274 26.6144 11.1166 25.97 12.2559C25.7596 12.6328 26.0884\
      12.9614 26.4654 12.7511C27.6007 12.1069 29.2621 11.1122 29.6347 10.7398C30.209 10.1658 30.1739\
      9.45588 29.7136 8.99578C29.2533 8.53568 28.5432 8.50063 27.9689 9.07466L27.9646 9.07028Z'}
      />
      <path d={'M24.4225 34.0603L23.3529 34.6781L22.2789 35.296L22.2657 35.3048L20.144 36.5273L20.0914\
      36.558L20.1002 36.5624H20.0388L20.0476 36.558L19.995 36.5273L17.8733 35.3048L17.8602 35.296L16.7906\
      34.6781L15.7209 34.0603H24.4225Z'}
      />
      <path d={'M30.6034 34.5555L30.1081 34.0603H24.4444V39.0732L22.2657 37.8156L20.4071\
      36.742V43.7224H19.7495V36.7289L17.8733 37.8156L15.6991 39.0732V34.0603H9.448L9.40416\
      34.1041L6.74768 36.7552V53.8972L9.448 56.5964H30.1081L32.804 53.8972V36.7552L30.6034\
      34.5555Z'}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.578 27.3227H22.9153V26.479H27.578V27.3227Z" />
    </g>
    <defs>
      <clipPath id="clip0_6194_30896">
        <rect width="36.5625" height="39.375" fill="transparent" transform="translate(2.8125 1.40625)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default AlfredIcon
