import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import React, { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAxiosErrorInterceptor } from '@phaidra/utils/axios-base-query'
import { useCheckForNewVersion } from '@phaidra/utils/version-check'
import { useGetInstallationQuery } from '@services/installation'
import PageLoader from '@common/page-loader'
import { environments } from '@config/app.config'
import LogRocket from 'logrocket'

import NotificationBanner from '@common/notification'
import { IngestionsProvider } from '@providers/ingestion'
import ErrorBoundary from '@phaidra/utils/error-boundary'
import { NotificationsProvider } from '@providers/notification'
import ErrorBanner from '@common/error-message'
import SnackbarManager from '@common/snackbar-manager'
import { errorMessages as ERROR_MESSAGES } from '@services/utils/error-messages'
import AppBarVertical from './app-bar-vertical'
import { Background } from './styles'
import IngestionStatusWindow from './historical-data-ingestion/ingestion-status-window'

const FallbackUI = () => (
  <NotificationsProvider>
    <IngestionsProvider>
      <AppBarVertical />
      <Background style={{ marginLeft: '65px' }}>
        <ErrorBanner />
      </Background>
    </IngestionsProvider>
  </NotificationsProvider>

)
const App: FC = () => {
  const { isLoading, isError } = useGetInstallationQuery()
  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const installationId = new URLSearchParams(search).get('installationId')

  useAxiosErrorInterceptor(ERROR_MESSAGES)

  useEffect(() => {
    // Enable Grafana FE Observability in select envs
    if (environments[process.env.TARGET_ENV as keyof typeof environments]?.grafanaObserver) {
      initializeFaro({
        url: process.env.GRAFANA_FARO_URL,
        app: {
          name: process.env.GRAFANA_APP_NAME,
          version: process.env.GRAFANA_VERSION,
          environment: process.env.TARGET_ENV,
        },
        instrumentations: [
          // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
          ...getWebInstrumentations(),

          // Initialization of the tracing package.
          // This package is optional because it increases the bundle size noticeably.
          // Only add it if you want tracing data.
          new TracingInstrumentation(),
        ],
      })
    }
    // Enable LogRocket in select envs
    if (environments[process.env.TARGET_ENV as keyof typeof environments]?.logrocket) {
      LogRocket.init(
        process.env.LOGROCKET_PRODUCT_ID,
        {
          release: process.env.LOGROCKET_RELEASE_ID,
          dom: {
            rateLimitCount: 4096,
            rateLimitPeriod: 1000,
          },
        } as unknown as any, // Workaround for missing types per LogRocket team. TODO remove when they've updated.
      )
    }
  }, [])

  useEffect(() => {
    if (pathname !== '/' && !installationId) {
      navigate('/')
    }
  }, [pathname, installationId, navigate])

  useCheckForNewVersion('Atlas')

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <ErrorBoundary hasParentError={isError} fallbackUI={<FallbackUI />}>
      <SnackbarManager />
      <NotificationsProvider>
        <IngestionsProvider>
          <AppBarVertical />
          <div style={{ marginLeft: '65px', width: 'calc(100% - 65px)' }}>
            <NotificationBanner />
          </div>
          <Background style={{ marginLeft: '65px' }}>
            <Outlet />
            <IngestionStatusWindow />
          </Background>
        </IngestionsProvider>
      </NotificationsProvider>
    </ErrorBoundary>
  )
}

export default App
