import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const DragIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 8 14" data-testid="icon-drag">
    <path
      d={'M1.49967 13.3548C1.12467 13.3548 0.80523 13.2229 0.541341 12.959C0.277452 12.6951 0.145508 12.3757\
      0.145508 12.0007C0.145508 11.6257 0.277452 11.3062 0.541341 11.0423C0.80523 10.7784 1.12467 10.6465\
      1.49967 10.6465C1.87467 10.6465 2.19412 10.7784 2.45801 11.0423C2.7219 11.3062 2.85384 11.6257 2.85384\
      12.0007C2.85384 12.3757 2.7219 12.6951 2.45801 12.959C2.19412 13.2229 1.87467 13.3548 1.49967 13.3548ZM6.49967\
      13.3548C6.12467 13.3548 5.80523 13.2229 5.54134 12.959C5.27745 12.6951 5.14551 12.3757 5.14551 12.0007C5.14551\
      11.6257 5.27745 11.3062 5.54134 11.0423C5.80523 10.7784 6.12467 10.6465 6.49967 10.6465C6.87467 10.6465 7.19412\
      10.7784 7.45801 11.0423C7.7219 11.3062 7.85384 11.6257 7.85384 12.0007C7.85384 12.3757 7.7219 12.6951 7.45801\
      12.959C7.19412 13.2229 6.87467 13.3548 6.49967 13.3548ZM1.49967 8.35482C1.12467 8.35482 0.80523 8.22287 0.541341\
      7.95898C0.277452 7.6951 0.145508 7.37565 0.145508 7.00065C0.145508 6.62565 0.277452 6.30621 0.541341\
      6.04232C0.80523 5.77843 1.12467 5.64648 1.49967 5.64648C1.87467 5.64648 2.19412 5.77843 2.45801 6.04232C2.7219\
      6.30621 2.85384 6.62565 2.85384 7.00065C2.85384 7.37565 2.7219 7.6951 2.45801 7.95898C2.19412 8.22287 1.87467\
      8.35482 1.49967 8.35482ZM6.49967 8.35482C6.12467 8.35482 5.80523 8.22287 5.54134 7.95898C5.27745 7.6951 5.14551\
      7.37565 5.14551 7.00065C5.14551 6.62565 5.27745 6.30621 5.54134 6.04232C5.80523 5.77843 6.12467 5.64648 6.49967\
      5.64648C6.87467 5.64648 7.19412 5.77843 7.45801 6.04232C7.7219 6.30621 7.85384 6.62565 7.85384 7.00065C7.85384\
      7.37565 7.7219 7.6951 7.45801 7.95898C7.19412 8.22287 6.87467 8.35482 6.49967 8.35482ZM1.49967 3.35482C1.12467\
      3.35482 0.80523 3.22287 0.541341 2.95898C0.277452 2.6951 0.145508 2.37565 0.145508 2.00065C0.145508 1.62565\
      0.277452 1.30621 0.541341 1.04232C0.80523 0.778429 1.12467 0.646484 1.49967 0.646484C1.87467 0.646484 2.19412\
      0.778429 2.45801 1.04232C2.7219 1.30621 2.85384 1.62565 2.85384 2.00065C2.85384 2.37565 2.7219 2.6951 2.45801\
      2.95898C2.19412 3.22287 1.87467 3.35482 1.49967 3.35482ZM6.49967 3.35482C6.12467 3.35482 5.80523 3.22287\
      5.54134 2.95898C5.27745 2.6951 5.14551 2.37565 5.14551 2.00065C5.14551 1.62565 5.27745 1.30621 5.54134\
      1.04232C5.80523 0.778429 6.12467 0.646484 6.49967 0.646484C6.87467 0.646484 7.19412 0.778429 7.45801\
      1.04232C7.7219 1.30621 7.85384 1.62565 7.85384 2.00065C7.85384 2.37565 7.7219 2.6951 7.45801 2.95898C7.19412\
      3.22287 6.87467 3.35482 6.49967 3.35482Z'}
    />
  </SvgIcon>
)

export default DragIcon
