/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext, useMemo, PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import _ from 'lodash-es'

import { useQueryParam } from '@common/use-query-param'
import {
  setpointsApi,
  useLazyGetSetpointsQuery, useToggleSetpointMutation, useUpdateNotesMutation, useUpdateSBRMutation,
} from '@services/setpoints'

import { SBR, Setpoint, SetpointsResponse } from '@services/setpoints/types'
import { useSnackbar } from '@phaidra/ava/snackbar'
import { SerializedError } from '@reduxjs/toolkit'
import { ServerError } from '@services/utils/types'
import { useAppDispatch } from '@store'
import { SetpointTableRow } from '../../app/setpoint-selection/columns'
import { getSetpointFormFieldName } from '../../app/setpoint-selection/utils'

type SetpointsContextValue = {
  state: {
    isToggleSetpointLoading: boolean
    isGetSetpointsLoading: boolean
    isUpdateSBRLoading: boolean
    updateSBRError: boolean
    isUpdateNotesLoading: boolean
    setpoints: SetpointsResponse['data']
    selectedSetpoint: SetpointTableRow
    selectedSetpointInitialValues: Record<string, string>
    setpointFieldErrors: Record<string, {
      status: number
      data: ServerError['data']
    } | SerializedError>
  }
  actions: {
    toggleSetpoint: (setpoint: Setpoint) => void
    updateNotes: (notes: string) => void
    updateSBR: (setpoint: Setpoint, paramId: number, value: string | number) => void
    setSetpoints: Dispatch<SetStateAction<SetpointsResponse['data']>>
    setSelectedSetpoint: Dispatch<SetStateAction<SetpointTableRow>>
    updateSetpoint: (newSetpointValues: Record<string, string>) => Promise<void>
  }
}
export const SetpointsContext = createContext<SetpointsContextValue | undefined>(undefined)

export const SetpointsProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch()
  const installationId = useQueryParam('installationId')
  const [setpointFieldErrors, setSetpointPropertyError] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [getSeptoints, {
    data: setpointResponse,
    isLoading: isGetSetpointsLoading,
  }] = useLazyGetSetpointsQuery()

  const [setpoints, setSetpoints] = useState<SetpointsResponse['data'] | null>(null)
  const [selectedSetpoint, setSelectedSetpoint] = useState<SetpointTableRow | null>(null)

  const [toggleSetpoint, { isLoading: isToggleSetpointLoading }] = useToggleSetpointMutation()

  const [updateNotes, {
    isLoading: isUpdateNotesLoading,
  }] = useUpdateNotesMutation()
  const [updateSBR, {
    isLoading: isUpdateSBRLoading,
    error: updateSBRError,
  }] = useUpdateSBRMutation()

  const initialValues = useMemo(() => {
    const fields = _.fromPairs(
      _.map(
        selectedSetpoint?.setpoint?.params,
        ({ name, value, id }) => {
          const inputFieldName = getSetpointFormFieldName(selectedSetpoint, { name, id } as SBR)
          return [inputFieldName, value ?? '']
        },
      ),
    )

    const notesFieldName = getSetpointFormFieldName(selectedSetpoint, { name: 'Notes' } as SBR)

    return selectedSetpoint ? {
      [notesFieldName]: selectedSetpoint?.setpoint?.notes,
      ...fields,
    } : {}
  }, [selectedSetpoint])

  const handleToggleSetpoint = useCallback(async (
    setpoint: Setpoint,
  ) => {
    await toggleSetpoint({
      installationId,
      setpointId: Number(setpoint.id),
      body: { isUsed: !setpoint.isUsed },
    })
  }, [installationId])

  const handleUpdateNotes = async (
    notes: string,
  ) => {
    const newNotesValue = notes || null
    return updateNotes({
      installationId,
      setpointId: Number(selectedSetpoint?.setpoint?.id),
      body: { notes: newNotesValue },
    })
  }

  const handleUpdateSBR = async (
    setpoint: Setpoint,
    paramId: number,
    value: string | number,
  ) => {
    const newValue = value || null
    return updateSBR({
      installationId,
      setpointId: Number(setpoint.id),
      paramId,
      body: { value: newValue },
    })
  }
  const handleUpdateSetpoint = async (values: SetpointsContextValue['state']['selectedSetpointInitialValues']) => {
    const responses = await Promise.all(_.keys(initialValues).map((structuredFieldName: string) => {
      const { 1: fieldName, 2: fieldId } = structuredFieldName.split('-')
      if (_.lowerCase(fieldName).includes('notes')) return handleUpdateNotes(values[structuredFieldName])
      return handleUpdateSBR(selectedSetpoint?.setpoint, Number(fieldId), values[structuredFieldName])
    }))

    const errors: SetpointsContextValue['state']['setpointFieldErrors'] = {}

    _.keys(initialValues).forEach((structuredFieldName, index) => {
      const response = responses[index]
      if ('error' in response) {
        errors[structuredFieldName] = response.error
      }
    })

    if (_.isEmpty(errors)) {
      await getSeptoints({ installationId }).unwrap()
      enqueueSnackbar(`${selectedSetpoint?.setpoint?.name} updated`, { variant: 'success' })
      setSetpointPropertyError({})
      return Promise.resolve()
    }
    setSetpointPropertyError(errors)
    throw new Error(`An error occurred updating setpoint ${selectedSetpoint?.setpoint.name}`)
  }
  useEffect(() => {
    const fetchData = async () => {
      if (!installationId) return
      setSetpoints(null)

      await getSeptoints({ installationId }).unwrap()
    }

    fetchData()
  }, [installationId])

  useEffect(() => {
    setSetpoints(setpointResponse?.data)
  }, [setpointResponse?.data])

  useEffect(() => {
    // reset context state when context unmounts
    dispatch(
      setpointsApi.util.invalidateTags(['Setpoints']),
    )

    setSetpoints(null)
    setSelectedSetpoint(null)
    setSetpointPropertyError(null)
  }, [])

  const value = useMemo(() => ({
    state: {
      isToggleSetpointLoading,
      isGetSetpointsLoading,
      isUpdateSBRLoading,
      isUpdateNotesLoading,
      setpoints,
      selectedSetpoint,
      updateSBRError: !!updateSBRError,
      selectedSetpointInitialValues: initialValues,
      setpointFieldErrors,
    },
    actions: {
      toggleSetpoint: handleToggleSetpoint,
      updateNotes: handleUpdateNotes,
      updateSBR: handleUpdateSBR,
      setSetpoints,
      setSelectedSetpoint,
      updateSetpoint: handleUpdateSetpoint,

    },
  }), [
    installationId, isToggleSetpointLoading, isUpdateNotesLoading,
    isUpdateSBRLoading, isGetSetpointsLoading, setpoints, selectedSetpoint, updateSBRError,
    initialValues, setpointFieldErrors,
  ])

  return (
    <SetpointsContext.Provider value={value}>
      {children}
    </SetpointsContext.Provider>
  )
}

export const useSetpoints = (): SetpointsContextValue => useContext(SetpointsContext)
