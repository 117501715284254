import Chip, { ChipProps as MuiChipProps } from '@mui/material/Chip'

import styled from '../styled'

export type StyledChipProps = MuiChipProps

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'iconPosition',
})<StyledChipProps & { iconPosition?: 'left' | 'right' }>(({
  color, iconPosition, theme,
}) => {
  let iconColor = theme.palette.icon.disabledInverse
  let border = `1px solid ${theme.palette.container.border.subdued}`
  let backgroundColor = theme.palette.container.surface.low

  if (color === 'success') {
    backgroundColor = theme.palette.alert.surface.success
    iconColor = theme.palette.alert.text.success
    border = `1px solid ${theme.palette.alert.border.success}`
  } else if (color === 'error') {
    backgroundColor = theme.palette.alert.surface.error
    iconColor = theme.palette.alert.text.error
    border = `1px solid ${theme.palette.alert.border.error}`
  } else if (color === 'info') {
    backgroundColor = theme.palette.alert.surface.info
    iconColor = theme.palette.alert.text.info
    border = `1px solid ${theme.palette.alert.border.info}`
  } else if (color === 'warning') {
    backgroundColor = theme.palette.alert.surface.warning
    iconColor = theme.palette.alert.text.warning
    border = `1px solid ${theme.palette.alert.border.warning}`
  }

  return ({
    minHeight: '24px',
    height: 'fit-content',
    maxHeight: '83px !important',
    borderRadius: '100px',
    padding: '0px 8px',
    backgroundColor,
    border,
    ...(iconPosition === 'left' ? {
      '& > svg': {
        margin: '0px !important',
        marginRight: '4px !important',
      },
    } : {
      flexDirection: 'row-reverse',
      '& > svg': {
        margin: '0px !important',
        marginLeft: '4px !important',
      },
    }),
    '.MuiChip-label': {
      color: theme.palette.text.default,
      padding: '0',
      fontSize: '0.75rem',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
    },
    '.MuiChip-icon': {
      color: iconColor,
      height: '15px',
      width: '15px',
    },
  })
})
