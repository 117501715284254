import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const RightChevron: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="-2 0 17 30" data-testid="icon-chevron-right">
    <path
      d={'M16.8465 14.9996L2.3838 0.599609L0.154297 2.89333L12.259 14.9996L0.154297 \
      27.1059L2.44802 29.3996L16.8465 14.9996Z'}
    />
  </SvgIcon>
)

export default RightChevron
