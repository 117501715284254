import styled from '@phaidra/ava/styled'
import Grid from '@phaidra/ava/grid'

export const TabGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.container.surface.low,
  height: '100%',
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
}))

export const ChartLegendGrid = styled(Grid)(({ theme }) => ({
  maxHeight: '100vh',
  overflow: 'auto',
  padding: theme.spacing(4),
}))
