import styled from '../styled'
import CircularProgress from '../circular-progress'

export const Input = styled('input')<{ fontSize?: string, fontWeight?: string | number }>(({
  fontSize = '16px',
  fontWeight,
}) => ({
  outline: 'none',
  padding: '3px 6px',
  margin: '-4px -7px',
  lineHeight: '24px',
  letterSpacing: '0.15008px',
  width: '100%',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  border: '1px dashed #155E2A',
  ...(fontWeight && { fontWeight }),
  fontSize,
}))

export const StyledProgress = styled(CircularProgress)({
  color: '#B8B8B9',
  height: '12px !important',
  width: '12px !important',
})
