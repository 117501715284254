import ErrorCode from '../../types/error-codes'

const ERROR_MSG_LOOKUP: Record<ErrorCode, string> = {
  [ErrorCode.StartTimeSetTooLongAgo]: 'Invalid start date',
  [ErrorCode.StartTimeSetAfterEndTime]: 'Start time must be before end time',
  [ErrorCode.EndTimeSetBeforeStartTime]: 'Start time must be before end time',
  [ErrorCode.StartTimeSetInFuture]: 'Invalid start date',
  [ErrorCode.EndTimeSetInFuture]: 'Invalid end date',
}

const getErrorMsg = (errorCode: ErrorCode | null) => {
  if (errorCode === null) {
    return
  }

  return ERROR_MSG_LOOKUP[errorCode] || 'Invalid Date'
}

export default getErrorMsg
