import React, {
  forwardRef,
  useEffect,
  useState,
  useRef,
  type ChangeEvent,
  type FocusEventHandler,
  type KeyboardEvent,
} from 'react'
import { CloseIcon, SearchSymbol } from '../../icons'

import {
  Container,
  Input,
  StyledIconButton,
} from './styles'

export type Api = {
  overrideValue: (newValue: string) => void
}

export interface Props {
  onChange: (value: string, type: 'type' | 'clear') => void
  placeholder?: string
  fullWidth?: boolean
  isThin?: boolean
  defaultValue?: string
  noBorder?: boolean
  className?: string
  ContainerProps?: React.HTMLAttributes<HTMLDivElement>
  InputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> & { value: string | number }
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onApiReady?: (api: Api) => void
  suppliedInputRef?: React.RefObject<HTMLInputElement>
}

const Search = forwardRef((
  {
    onChange,
    placeholder,
    fullWidth,
    defaultValue,
    isThin = false,
    noBorder,
    className,
    onFocus,
    onBlur,
    onApiReady,
    ContainerProps,
    InputProps,
    suppliedInputRef,
  }: Props,
  ref: React.MutableRefObject<HTMLDivElement>,
) => {
  const defaultInputRef = useRef<HTMLInputElement>()
  const inputRef = suppliedInputRef || defaultInputRef
  const [value, setValue] = useState(defaultValue ?? '')

  useEffect(() => {
    onApiReady?.({
      overrideValue: setValue,
    })
  }, [onApiReady])

  useEffect(() => {
    setValue(defaultValue ?? '')
  }, [defaultValue])

  return (
    <Container
      ref={ref}
      container
      alignItems="center"
      fullWidth={fullWidth}
      isThin={isThin}
      noBorder={noBorder}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation()
      }}
      className={className}
      {...ContainerProps}
    >
      <Input
        ref={inputRef}
        autoFocus
        data-testid="search-input"
        type="text"
        placeholder={placeholder || 'Search'}
        isThin={isThin}
        {...InputProps}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          InputProps?.onChange?.(e)
          const val = e.currentTarget.value
          setValue(val)
          onChange?.(val, 'type')
        }}
        onFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
          InputProps?.onFocus?.(e)
          onFocus?.(e)
        }}
        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
          InputProps?.onBlur?.(e)
          onBlur?.(e)
        }}
      />
      {value ? (
        <StyledIconButton
          value={value}
          isThin={isThin}
          data-testid="clear-search"
          size={isThin ? 'tiny' : 'small'}
          onClick={(e) => {
            setValue('')
            onChange('', 'clear')

            const target = { ...inputRef.current, value: '' }
            const syntheticEvent = { ...e, target } as unknown as ChangeEvent<HTMLInputElement>
            InputProps?.onChange(syntheticEvent)
          }}
        >
          <CloseIcon style={{ fontSize: '20px' }} />
        </StyledIconButton>
      ) : (
        <SearchSymbol sx={{ marginRight: '12px', fontSize: '20px' }} />
      )}
    </Container>
  )
})

export default Search
