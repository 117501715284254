import styled from '@phaidra/ava/styled'
import Typography from '@phaidra/ava/typography'
import MUITooltip, { tooltipClasses } from '@phaidra/ava/tooltip'

export const MissingTrend = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '216px',
  height: '36px',
  borderRadius: theme.shape.borderRadius * 1.5,
  marginTop: theme.spacing(0.5),
  backgroundColor: '#FFEDE6',
  border: `solid 1px ${theme.palette.container.surface.low}`,
  overflow: 'hidden',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.alert.text.error,
  },
}))

export const Name = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 'inherit',
})

export const Tooltip = styled(MUITooltip)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
})
