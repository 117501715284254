import Menu from '@mui/material/Menu'
import MenuItem from '../menu-item'
import styled from '../styled'

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiList-root': {
    padding: 0,
    minWidth: '200px',
  },
  '& .MuiPopover-paper': {
    borderRadius: theme.shape.borderRadius,
    maxWidth: '100%',
  },
}))

export const StyledMenuItem = styled(MenuItem)<{ selected?: boolean }>(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: selected ? '500' : 'normal',
}))
