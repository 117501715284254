import React, { FC } from 'react'
import logoLight from './assets/light-2.gif'
import logoDark from './assets/dark-2.gif'

import styled from '../styled'

export interface Props {
  backdrop?: boolean
}

const Image = styled('img', {
  shouldForwardProp: (prop) => prop !== 'backdrop',
})<{ backdrop: boolean }>(({ theme, backdrop }) => ({
  content: `url("${theme.palette.mode === 'dark' ? logoDark : logoLight}")`,
  width: '75px',
  ...(backdrop && {
    padding: '3px',
    borderRadius: '50px',
    backgroundColor: theme.palette.background.paper,
  }),
}))

const LoadingIcon: FC<Props> = ({ backdrop }) => (
  <div style={{ display: 'flex' }}>
    <Image data-testid="loading-icon" alt="loading-icon" backdrop={backdrop} />
  </div>
)

export default LoadingIcon
