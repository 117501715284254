import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const WarningIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20" data-testid="icon-warning">
    <path d={'M1.5625 17.0833L10 2.5L18.4375 17.0833H1.5625ZM9.375 12.6667H10.625V8.5H9.375V12.6667ZM10\
    14.8333C10.1944 14.8333 10.3542 14.7708 10.4792 14.6458C10.6042 14.5208 10.6667 14.3611 10.6667 14.1667C10.6667\
    13.9722 10.6042 13.8125 10.4792 13.6875C10.3542 13.5625 10.1944 13.5 10 13.5C9.80556 13.5 9.64583 13.5625\
    9.52083 13.6875C9.39583 13.8125 9.33333 13.9722 9.33333 14.1667C9.33333 14.3611 9.39583 14.5208 9.52083\
    14.6458C9.64583 14.7708 9.80556 14.8333 10 14.8333Z'}
    />
  </SvgIcon>
)

export default WarningIcon
