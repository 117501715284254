import { compareDesc, parseISO } from 'date-fns'
import format from 'date-fns/format'

import {
  Ingestion, IngestionStatus, IngestionStatusLabels, IngestionTimestamp,
} from '../types'

export const DATE_FORMAT = "MM.dd.yyyy 'at' hh:mmaaa"

export const transformIngestionRecords = (records: Ingestion[]): Ingestion[] => {
  if (records?.length === 0) return []

  records.sort((a, b) => {
    const aHasStatus = 'status' in a
    const bHasStatus = 'status' in b

    if (!aHasStatus && !bHasStatus) {
      return compareDesc(parseISO(a.timestamp), parseISO(b.timestamp))
    } if (!aHasStatus) {
      return -1
    } if (!bHasStatus) {
      return 1
    }
    // Both have a status, sort by 'updated_at' if present, or by 'timestamp' as a fallback
    const aHasUpdated = !!a.updated_at
    const bHasUpdated = !!b.updated_at

    if (aHasUpdated && bHasUpdated) {
      return compareDesc(parseISO(a.updated_at), parseISO(b.updated_at))
    } if (!aHasUpdated && !bHasUpdated) {
      return compareDesc(parseISO(a.timestamp), parseISO(b.timestamp))
    }
    return aHasUpdated ? 1 : -1
  })
  const activeIndex = records.findIndex((record) => record.status === IngestionStatus.COMPLETED)

  return records.map((record, index) => {
    const clonedRecord = { ...record }

    clonedRecord.formattedTimestamp = format(new Date(clonedRecord.timestamp), DATE_FORMAT)

    if (clonedRecord.updated_at) {
      clonedRecord.formattedUpdatedAt = format(new Date(clonedRecord.updated_at), DATE_FORMAT)
    }

    if (clonedRecord.started_at) {
      clonedRecord.formattedStartedAt = format(new Date(clonedRecord.started_at), DATE_FORMAT)
    }

    if (index === activeIndex) return { ...clonedRecord, status_label: IngestionStatusLabels.ACTIVE }

    switch (clonedRecord.status) {
      case IngestionStatus.PROCESSING:
        return { ...clonedRecord, status_label: IngestionStatusLabels.PROCESSING }
      case IngestionStatus.ERROR:
      case IngestionStatus.CANCELLED:
        return { ...clonedRecord, status_label: IngestionStatusLabels.FAILED }
      case IngestionStatus.COMPLETED:
        return { ...clonedRecord, status_label: IngestionStatusLabels.OUTDATED_DATA }
      default:
        return { ...clonedRecord, status_label: IngestionStatusLabels.NEW_DATA }
    }
  })
}

export const mergeIngestionsAndTimestamps = (
  timestamps: IngestionTimestamp[],
  ingestions: Ingestion[],
): Ingestion[] => {
  // First, merge ingestions with matching timestamps
  const mergedResults = ingestions.map((ingestion) => {
    const matchingTimestamp = timestamps.find(
      (timestamp) => timestamp.gcs_folder_path === ingestion.gcs_folder_path,
    )
    return { ...ingestion, ...(matchingTimestamp ?? {}) }
  })

  // Find timestamps without a matching ingestion and add them to the results
  timestamps.forEach((timestamp, index) => {
    const hasMatchingIngestion = ingestions.some(
      (ingestion) => ingestion.gcs_folder_path === timestamp.gcs_folder_path,
    )
    if (!hasMatchingIngestion) {
      mergedResults.unshift({ id: new Date().getTime() + index, ...timestamp } as Ingestion)
    }
  })

  return mergedResults
}

export const prependIngestion = (list: Ingestion[], item: Ingestion) => {
  const newIngestion = {
    ...item,
    id: new Date().getTime(),
    status_label: IngestionStatusLabels.NEW_DATA,
    formattedTimestamp: `${item.formattedTimestamp} (copy)`,
  }

  delete newIngestion.status

  return { list: [newIngestion, ...list], duplicate: newIngestion }
}

export const getMinMaxDates = (ingestions: Ingestion[] = []): { minDate: Date, maxDate: Date } => {
  if (ingestions.length === 0) return { minDate: null, maxDate: null }

  const result = ingestions.reduce(({ minDate, maxDate }, ingestion) => {
    const timestamp = new Date(ingestion.timestamp)
    return {
      minDate: timestamp < minDate ? timestamp : minDate,
      maxDate: timestamp > maxDate ? timestamp : maxDate,
    }
  }, { minDate: new Date(ingestions[0].timestamp), maxDate: new Date(ingestions[0].timestamp) })

  return { minDate: result.minDate, maxDate: result.maxDate }
}
