import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const AtlasLogo: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    data-testid="atlas-logo"
  >
    <path
      d="M3.52447 29.2472L0.921387 26.6441V3.40826L3.52447 0.805176H26.7603L29.3634 3.40826V26.6441L26.7603
      29.2472H3.52447Z"
      fill="#2D2D2E"
      stroke="white"
      strokeWidth="1.25"
    />
    <path
      d="M8.07157 7.32163L15.897 7.32153L24.577 14.1468L24.577 15.8532L15.897 22.6785L8.07163 22.6785"
      stroke="white"
      strokeWidth="1.25"
      fill="none"
    />
    <path
      d="M21.9284 7.32163L14.103 7.32153L5.42302 14.1468L5.42301 15.8532L14.103 22.6785L21.9284 22.6785"
      stroke="white"
      strokeWidth="1.25"
      fill="none"
    />
    <path
      d="M20.4065 14.5255L15.5796 10.73L15.5796 19.2699L20.4065 15.4744L20.4065 14.5255Z"
      fill="#86D98C"
    />
  </SvgIcon>
)

export default AtlasLogo
