import React, { FC, useMemo, PropsWithChildren } from 'react'
import {
  SnackbarProvider as NativeSnackbarProvider,
  OptionsWithExtraProps,
  useSnackbar as useNativeSnackbar,
  VariantType,
} from 'notistack'

import ThemeProvider from '../theme-provider'
import { Props } from './bars/template-bar'
import SuccessBar from './bars/success-bar'
import ErrorBar from './bars/error-bar'
import WarningBar from './bars/warning-bar'
import InfoBar from './bars/info-bar'

export { type EnqueueSnackbar } from 'notistack'

export interface ExtendedOptionsWithExtraProps extends OptionsWithExtraProps<VariantType> {
  actionButtons?: Props['actionButtons']
}

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNativeSnackbar()

  const result = useMemo(() => ({
    enqueueSnackbar: (message: string, { variant, persist, ...rest }: ExtendedOptionsWithExtraProps = {}) =>
      enqueueSnackbar(
        message,
        { variant: variant || 'success', persist: persist !== undefined ? persist : false, ...rest },
      ),
    closeSnackbar,
  }), [enqueueSnackbar, closeSnackbar])

  return result
}

export const SnackbarProvider: FC<PropsWithChildren> = ({
  children,
}) => (
  <ThemeProvider theme="light">
    <NativeSnackbarProvider
      maxSnack={1}
      Components={{
        success: SuccessBar,
        warning: WarningBar,
        info: InfoBar,
        error: ErrorBar,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {children}
    </NativeSnackbarProvider>
  </ThemeProvider>
)

export default SnackbarProvider
