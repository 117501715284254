import styled from '@phaidra/ava/styled'
import Tab from '@phaidra/ava/tab'
import Grid from '@phaidra/ava/grid'
import Button from '@phaidra/ava/button'

export const StyledTab = styled(Tab)({
  '&.MuiButtonBase-root': {
    backgroundColor: 'transparent',
  },
})

export const StyledTabPanel = styled('div')({
  backgroundColor: 'transparent',
  height: '100%',
})

export const LCSPointsContainer = styled('div')(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(2)})`,
  overflowY: 'auto',
  height: '100%',
  paddingBottom: '16px',
}))

export const NodeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(1),
  width: `calc(100% - ${theme.spacing(2)})`,
}))

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isTag',
})<{ isTag: boolean }>(({ isTag }) => (({ theme }) => ({
  display: 'flex',
  border: `solid 1px ${theme.palette.container.border.subdued}`,
  borderRadius: theme.shape.borderRadius,
  minWidth: '320px',
  width: 'inherit',
  minHeight: '46px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  backgroundColor: theme.palette.container.surface.default,
  overflow: 'hidden',
  ...(isTag && {
    '&:hover': {
      borderColor: theme.palette.container.border.default,
      backgroundColor: theme.palette.hover.surface.default,
    },
  }),
})))

export const IdContainer = styled(Grid)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '12px',
  color: theme.palette.container.border.default,
  height: 12,
  marginBottom: theme.spacing(0.25),
}))

export const NameContainer = styled(Grid)({
  fontSize: '0.75rem',
  lineHeight: '16px',
  height: 16,
})

export const AddTagButton = styled(Button)(({ theme }) => ({
  minHeight: '0 !important',
  minWidth: 0,
  fontSize: `${theme.typography.caption.fontSize} !important`,
}))
