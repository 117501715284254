import React from 'react'
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion'

import { Theme } from '../theme-provider/index'
import styled from '../styled'

export type AccordionProps = MuiAccordionProps

const sharedStyles = (theme: Theme) => ({
  '& .MuiAccordionSummary-root': {
    minHeight: '28px',
    '& .MuiAccordionSummary-content': {
      margin: '0px',
      fontSize: theme.typography.body.fontSize,
      fontWeight: 500,
    },
  },
  '& .MuiMenuItem-root': {
    fontSize: theme.typography.button.fontSize,
  },
})

const Accordion = styled(
  (props: MuiAccordionProps & { noSeparator?: boolean, size?: 'small' | 'medium' | 'large' }) => (
    <MuiAccordion disableGutters elevation={0} square {...props} data-testid="accordion" />
  ),
  { shouldForwardProp: (prop: string) => !['noSeparator', 'size'].includes(prop) },
)(({ noSeparator, size, theme }) => ({
  backgroundColor: 'transparent',
  ...(!noSeparator && {
    borderBottom: `1px solid ${theme.palette.container.border.subdued}`,
  }),
  '&:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
  ...(size === 'medium' && sharedStyles(theme)),
  ...(size === 'small' && {
    ...sharedStyles(theme),
    '& .MuiAccordionSummary-root': {
      minHeight: '18px',
      marginBottom: 0,
    },
  }),
}))

export default Accordion
