import React, { FC } from 'react'
import { IconButtonProps } from '@mui/material/IconButton'

import { IconButtonContainer, StyledIconButton } from './styles'

export interface Props extends Omit<IconButtonProps, 'size'> {
  size?: 'tiny' | 'small' | 'medium' | 'large'
}

const IconButton: FC<Props> = ({ size = 'medium', ...props }) => (
  <IconButtonContainer isTiny={size === 'tiny'}>
    <StyledIconButton {...(size !== 'tiny' && { size })} {...props} />
  </IconButtonContainer>
)

export default IconButton
