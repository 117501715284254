import { Highcharts } from '@phaidra/ava/chart'
import theme from '@phaidra/ava/theme-provider/themes/common'
import { XAxisOptions } from 'highcharts'
import { format as formatDateFns } from 'date-fns'
import { useTimezone } from '@phaidra/user-portal/src/providers/timezone'
import {
  Chart,
  Tags,
  ControlState,
} from '../../services/trend-viewer/types'
import { type ChartIndex } from '../../types'
import { type TimeWindow } from '../types'
import getDate from './common/tooltip-date-formatter'
import { type Dimensionality } from '../new-add-tag-drawer/tabs/types'

export const THRESHOLD = 15 * 60 * 1000 // 15 minutes

export const styles: Array<{ color: string, dashStyle: Highcharts.DashStyleValue, zIndex: number }> = [
  { color: '#1A1C19', dashStyle: 'ShortDot', zIndex: 1 },
  { color: '#6391DD', dashStyle: 'ShortDash', zIndex: 1 },
  { color: '#875300', dashStyle: 'Solid', zIndex: 1 },
  { color: '#7E66CE', dashStyle: 'ShortDot', zIndex: 1 },
  { color: '#6BBD73', dashStyle: 'LongDash', zIndex: 1 },
  { color: '#E7A340', dashStyle: 'Solid', zIndex: 1 },
]

type ControlStatesType = { color: { band: string, legend: string }, label: ControlState }

export const controlStatesInfo = (useHealthRules: boolean): Partial<Record<ControlState, ControlStatesType>> => {
  if (useHealthRules) {
    return {
      Pass: {
        color: {
          band: '#F4F4F5',
          legend: '#F4F4F5',
        },
        label: 'Pass',
      },
      Fail: {
        color: {
          band: '#F5DDDD',
          legend: '#F5DDDD',
        },
        label: 'Fail',
      },
      Ignore: {
        color: {
          band: '#7FACFB',
          legend: '#7FACFB',
        },
        label: 'Ignore',
      },
    }
  }
  return {
    'AI Mode': {
      color: {
        band: '#F4FFF1',
        legend: '#F4FFF1',
      },
      label: 'AI Mode',
    },
    'AI Constrained': {
      color: {
        band: '#1A1C191A',
        legend: '#1A1C191A',
      },
      label: 'AI Constrained',
    },
    'Connection Loss': {
      color: {
        band: '#E7A3402E',
        legend: '#E7A3402E',
      },
      label: 'Connection Loss',
    },
    'Internal Error': {
      color: {
        band: '#BA1B1B33',
        legend: '#BA1B1B33',
      },
      label: 'Internal Error',
    },
    'Operator Override': {
      color: {
        band: '#6391DD33',
        legend: '#6391DD33',
      },
      label: 'Operator Override',
    },
    Unknown: {
      color: {
        band: '#654DB333',
        legend: '#654DB333',
      },
      label: 'Unknown',
    },
  }
}

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
})

export default (
  chartIndex: ChartIndex,
  data: Chart['data'],
  yLimits: Record<string, { min: number, max: number }>,
  controlStates: XAxisOptions['plotBands'],
  tags: Tags['data'],
  startTime: number,
  endTime: number,
  onMouseOver: (chartIndex: number, xAxis: number) => void,
  onMouseOut: (chartIndex: number) => void,
  useUTC: boolean,
  showXAxis = false,
  showTooltip = true,
  isNavigatorTimezoneEnabled = false,
  timeWindow: TimeWindow = null,
  isRawClientPointsEnabled = false,
  unitDimensionality: Dimensionality[] = undefined,
): Highcharts.Options => {
  const units = data?.filter((x) => x).reduce<Array<{ symbol: string, label: string }>>((acc, curr) => {
    let symbol = curr.unit?.symbol || ''
    let label = curr.unit?.label || ''

    if (isRawClientPointsEnabled && curr.type === 'client_point') {
      const unitInfo = unitDimensionality.find((unitItem) => unitItem.id === String(curr.clientPointId))
      if (unitInfo) {
        symbol = unitInfo?.unit || ''
        label = unitInfo?.measure || ''
      }
    }
    return acc.some(({ symbol: existingSymbol }) => existingSymbol === symbol)
      ? acc
      : [...acc, { symbol, label }]
  }, [])

  let browserOffset = isNavigatorTimezoneEnabled ? new Date().getTimezoneOffset() * -1 * 60 * 1000 : 0
  if (timeWindow) {
    const { timezonePreference } = useTimezone()
    browserOffset = isNavigatorTimezoneEnabled ? 60 * 1000 * Number(timezonePreference.offset) : 0
  }

  return {
    lang: {
      noData: '',
    },
    chart: {
      animation: false,
      plotBorderColor: '#58585A',
      plotBorderWidth: 1,
      backgroundColor: 'transparent',
    },
    plotOptions: {
      series: {
        turboThreshold: 10000,
      },
    },
    time: {
      useUTC: isNavigatorTimezoneEnabled ? true : useUTC,
    },
    tooltip: {
      xDateFormat: '%m-%d-%Y, %H:%M',
      backgroundColor: 'rgba(251, 251, 251, 0.9)',
      borderRadius: 4,
      borderWidth: 0,
      padding: 10,
      hideDelay: 0,
      formatter() {
        const numericTime = Number(this.key)
        const convertToLocalTime = !isNavigatorTimezoneEnabled && !useUTC
        const date = getDate(numericTime, convertToLocalTime)

        const closestPoints: Highcharts.Point[] = []
        this.series.chart.series.forEach((series) => {
          const closestPoint = series?.points?.reduce((prev, curr) =>
            (Math.abs(curr.x - numericTime) < Math.abs(prev.x - numericTime) ? curr : prev), series?.points?.[0])
          if (!closestPoint) {
            return
          }
          if (Math.abs(closestPoint.x - numericTime) <= THRESHOLD) {
            closestPoints.push(closestPoint)
          }
        })

        let tooltipContainer = `
          <span style='color:${theme.palette.container.border.default}; font-size: 12px; font-weight: 500;'>
            ${date}
          </span>`

        closestPoints.forEach((point, index) => {
          const roundingDigits = data?.[index]?.roundingDigits || 0
          const dataModel = point.series.options.custom?.data || data
          const hasStateMap = dataModel?.[index]?.stateMap
          const xValue = point.series.name

          const yValue = hasStateMap && dataModel[index].stateMap[point.y]
            ? dataModel[index].stateMap[point.y]
            : point.y.toFixed(roundingDigits)

          tooltipContainer += `
            <div style="margin-top: 10px;">
              <div style="display:flex;">
                <div style="
                  width:6px;
                  height:6px;
                  border-radius:10px;
                  background-color:${point.color};
                  display:flex;
                  align-items:center;
                  justify-content:center;
                  margin-right: 5px;
                "></div>
                <div style="
                  display:flex;
                  font-weight: 400;
                  color: ${theme.palette.icon.disabledInverse};
                  font-size: 10px;
                  margin-top: -3px;
                ">
                  ${(point.series.options as { componentName?: string })?.componentName}
                </div>
              </div>
              <div style="align-items:center;pointer-events:all;margin-top: 3px;">
                <b style="font-weight: 400; color: ${theme.palette.icon.default}; font-size: 12px;">
                  ${xValue}:
                </b>
                <b style="font-weight: 500; color: ${theme.palette.icon.default}; font-size: 12px; margin-left: 4px;">
                  ${yValue}
                </b>
              </div>
            </div>`
        })

        return tooltipContainer
      },
      useHTML: true,
      outside: true,
      enabled: showTooltip,
    },
    legend: { enabled: false },
    xAxis: {
      type: 'datetime',
      min: startTime + browserOffset,
      max: endTime + browserOffset,
      lineColor: '#58585A',
      ...(controlStates && {
        plotBands: controlStates.map((band) => ({
          ...band,
          zIndex: 1,
        })),
      }),
      title: null,
      labels: {
        enabled: showXAxis,
        formatter() {
          return formatDateFns(Number(this.value), 'hh:mmaaa')
        },
      },
    },
    ...(data ? {
      yAxis: units.map(({ label, symbol }, index) => {
        if (!data[index]) {
          return
        }
        const hasStateMap = data[index].stateMap
        const roundingDigits = data[index].roundingDigits || 0
        const min = hasStateMap ? Number(Object.keys(data[index].stateMap).sort()[0]) : yLimits[symbol]?.min
        const max = hasStateMap ? Number(Object.keys(data[index].stateMap).sort().reverse()[0]) : yLimits[symbol]?.max
        let previousLabel: string | null = null
        const numberOfTicks = hasStateMap ? Object.keys(data[index].stateMap).length : 9

        return ({
          id: `${index}`,
          ...(min !== max ? { min: 0.9 * min, max: 1.1 * max } : { min: null, max: null }),
          ...((numberOfTicks > (max - min) && !roundingDigits && !hasStateMap && min !== max) ? {
            tickAmount: undefined,
            tickInterval: 1,
          } : {
            tickAmount: numberOfTicks,
            tickInterval: undefined,
          }),
          roundingDigits,
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            style: { color: '#1A1C19' },
            formatter() {
              const { axis, value } = this
              const stateMapInRealData = data[index].stateMap
              const stateMapInMockDataModel = axis.series?.[0].options.custom?.data?.[0].stateMap
              const stateMap = stateMapInRealData || stateMapInMockDataModel

              let yLabel: string
              if (!stateMap) {
                yLabel = `${Number(value).toFixed(roundingDigits)}`
              } else {
                const result = stateMap[value]
                yLabel = result !== undefined ? result : `${Number(value).toFixed(roundingDigits)}`
              }

              if (yLabel === previousLabel) {
                return null
              }

              previousLabel = yLabel
              return yLabel
            },
          },
          showEmpty: false,
          title: {
            text: label && `${label} (${symbol})`,
            style: { color: '#1A1C19', fontSize: '0.75rem' },
          },
          ...(index % 2 !== 0 && { opposite: true }),
        })
      }),
      series: data.reduce((acc, series, index) => {
        let symbol = series.unit?.symbol || ''

        const isClientPoint = isRawClientPointsEnabled && series.type === 'client_point'
        if (isClientPoint) {
          const unitInfo = unitDimensionality.find((unitItem) => unitItem.id === String(series.clientPointId))
          symbol = unitInfo ? unitInfo.unit : ''
        }

        const tagId = isClientPoint ? series.clientPointId : series.tagId
        const tagNode = Object.values(tags.nodes).find(({ id }) => id === tagId)

        const componentName = tags.nodes[tags.nodes[series.fullTagName]?.component]?.name || series?.clientPointId
        const name = tagNode?.name || series?.fullTagName

        return [
          ...acc,
          ...(series === null ? [] : [{
            custom: { data },
            name,
            componentName,
            type: 'line',
            yAxis: units.findIndex(({ symbol: unitSymbol }) => unitSymbol === symbol),
            data: [...series.data],
            tooltip: { valueSuffix: ` ${symbol}` },
            events: {
              mouseOver() {
                this.chart.yAxis.forEach((axis: any) => {
                  if (axis.userOptions.id === this.yAxis.userOptions.id) {
                    return
                  }
                  axis.update({
                    title: { style: { opacity: 0.5 } },
                    labels: { style: { opacity: 0.5 } },
                  })
                })
              },
              mouseOut() {
                this.chart.yAxis.forEach((axis: any) => {
                  axis.update({
                    title: { style: { opacity: 1 } },
                    labels: { style: { opacity: 1 } },
                  })
                })
                onMouseOut(chartIndex)
              },
            },
            point: {
              events: {
                mouseOver(a: Event) {
                  // @ts-ignore
                  onMouseOver(chartIndex, a.target.category)
                },
              },
            },
            ...styles[index],
          }]),
        ]
      }, []),
    } : {
      yAxis: { showEmpty: false },
      series: [{ type: 'line', data: [] }],
    }),
  }
}
