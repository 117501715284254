import React, { FC, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import Typography from '../typography'
import {
  Input,
  StyledProgress,
} from './styles'

export interface Props {
  editing: boolean
  value: string
  labelPlaceholder?: string
  fontSize?: string
  fontWeight?: string | number
  onSave: (value: string) => void
  onCancel: () => void
  loading?: boolean
}

const InlineEdit: FC<Props> = ({
  editing,
  value,
  labelPlaceholder,
  fontSize,
  fontWeight,
  onSave,
  onCancel,
  loading,
}) => {
  const [val, setVal] = useState(value)

  if (loading) {
    return (<StyledProgress data-testid="inline-progress" />)
  }

  if (!editing) {
    return (
      <Typography
        data-testid="inline-edit-typography"
        fontSize={fontSize}
        fontWeight={fontWeight}
        sx={{ ...(!value && { color: '#727273' }) }}
      >
        {value || labelPlaceholder}
      </Typography>
    )
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (val !== value) {
          onSave(val)
        } else {
          onCancel()
        }
      }}
    >
      <Input
        data-testid="inline-edit"
        autoFocus
        type="text"
        value={val}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setVal(event.target.value)}
        fontSize={fontSize}
        fontWeight={fontWeight}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          const { code } = event
          if (code === 'Enter') {
            if (val !== value) {
              onSave(val)
            } else {
              onCancel()
            }
          } else if (code === 'Escape') {
            onCancel()
          }
        }}
      />
    </ClickAwayListener>
  )
}

export default InlineEdit
