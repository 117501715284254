import React, { PropsWithChildren, ReactNode } from 'react'
import { DialogProps } from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'

import Form, { Props as FormProps } from '../form'
import Button from '../button'
import Grid from '../grid'
import { Props as ButtonProps } from '../button/types'
import {
  StyledDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Close,
  ContentContainer,
  DrawerContainer,
  DialogSubTitle,
  NavigationContent,
  CloseContainer,
  StyledWarningIcon,
  StyledCollapse,
  StyledLink,
} from './styles'
import { useTheme } from '../theme-provider'

export interface Props<T = void> extends Omit<DialogProps, 'onSubmit' | 'onChange' | 'title'> {
  title?: ReactNode
  subTitle?: ReactNode
  onSubmit?: (data: T) => Promise<boolean | void> | void
  onChange?: FormProps<T>['onChange']
  onChangeBasic?: FormProps<T>['onChange']
  defaultValues?: FormProps<T>['defaultValues']
  showBottomRightContent?: boolean
  bottomRightContent?: ReactNode
  actionButtons?: Array<{
    label: string
    onClick?: () => void
    closeOnClick?: boolean
    disabled?: boolean
    color?: ButtonProps['color']
    variant?: 'text' | 'outlined' | 'contained'
    type?: 'button' | 'submit'
    disabledOnInvalid?: boolean
    loading?: boolean
    autoFocus?: boolean
    ButtonProps?: Partial<ButtonProps>
  }>
  actionButtonsCustomStyle?: Record<string, string>
  error?: boolean
  fullHeight?: boolean
  isDrawerOpen?: boolean
  drawerContent?: ReactNode
  navigationContent?: ReactNode
  setDrawerOpen?: (open: boolean) => void
}

const Dialog = <T extends {}>({
  title,
  subTitle,
  onSubmit = () => {},
  onChange,
  onChangeBasic,
  showBottomRightContent,
  bottomRightContent,
  actionButtons = [],
  actionButtonsCustomStyle = {},
  children,
  error,
  fullHeight,
  isDrawerOpen,
  drawerContent,
  navigationContent,
  defaultValues,
  setDrawerOpen,
  ...props
}: PropsWithChildren<Props<T>>) => {
  const hasWidthLessThan1100 = useMediaQuery('(max-width:1100px)')
  const theme = useTheme()

  return (
    <StyledDialog
      fullWidth
      fullHeight={fullHeight}
      {...props}
    >
      <Grid
        container
        sx={{
          height: (isDrawerOpen || !!drawerContent) || fullHeight ? '100%' : 'auto',
        }}
      >
        <Form<T>
          formStyle={{ height: '100%', width: '100%' }}
          onSubmit={async (data) => {
            const submitError = await onSubmit(data)
            if (!submitError) {
              props.onClose({}, 'backdropClick')
            }
          }}
          defaultValues={defaultValues}
          onChange={onChange}
          onChangeBasic={onChangeBasic}
          render={(_, { formState: { isValid } }) => (
            <Grid container height="100%">
              {navigationContent ? (<NavigationContent>{navigationContent}</NavigationContent>) : null}
              <ContentContainer
                position="relative"
                flex={1}
                container
                flexDirection="column"
              >
                <DialogTitle fontWeight="700">
                  <Grid item flex={1}>
                    {subTitle ? (<DialogSubTitle>{subTitle}</DialogSubTitle>) : null}
                    <Grid container style={{ flex: 1 }} alignItems="center">
                      {error && <StyledWarningIcon />}
                      {title}
                    </Grid>
                  </Grid>
                  {drawerContent && !isDrawerOpen && (
                    <Grid container width="auto" justifyContent="flex-end" alignItems="center" gap={1.5}>
                      <StyledLink onClick={() => setDrawerOpen(true)}>Show drawer</StyledLink>
                      <CloseContainer data-testid="dialog-close-icon-container" item>
                        <Close onClick={() => props.onClose({}, 'backdropClick')} />
                      </CloseContainer>
                    </Grid>
                  )}
                  {!drawerContent && (
                    <CloseContainer data-testid="dialog-close-icon-container" item>
                      <Close onClick={() => props.onClose({}, 'backdropClick')} />
                    </CloseContainer>
                  )}
                </DialogTitle>
                <DialogContent
                  {...(!title && { sx: { marginTop: '-30px' } })}
                  isDrawerOpen={isDrawerOpen || !!drawerContent}
                  fullHeight={fullHeight}
                >
                  {children}
                </DialogContent>
                {actionButtons.length > 0 && (
                  <DialogActions {...{ sx: { ...actionButtonsCustomStyle } }}>
                    {actionButtons.map(({
                      label,
                      onClick,
                      closeOnClick = true,
                      color = 'default',
                      disabled = false,
                      variant = 'text',
                      type = 'button',
                      disabledOnInvalid,
                      loading = false,
                      autoFocus = false,
                      ButtonProps: buttonProps,
                    }) => (
                      <Button
                        data-testid={`${label.toLowerCase()}-button`}
                        key={label}
                        type={type}
                        variant={variant}
                        disabled={disabled || (disabledOnInvalid && !isValid)}
                        color={color}
                        autoFocus={autoFocus}
                        onClick={() => {
                          if (closeOnClick && type !== 'submit') {
                            props.onClose({}, 'backdropClick')
                          }
                          onClick?.()
                        }}
                        loading={loading}
                        {...buttonProps}
                      >
                        {label}
                      </Button>
                    ))}
                  </DialogActions>
                )}
                {showBottomRightContent && (
                  <Grid
                    display="flex"
                    alignItems="center"
                    position="absolute"
                    bottom={theme.spacing(3)}
                    right={theme.spacing(3)}
                  >
                    {bottomRightContent}
                  </Grid>
                )}
              </ContentContainer>
              {drawerContent && (
                <StyledCollapse
                  in={isDrawerOpen}
                  isDrawerOpen={isDrawerOpen}
                  orientation="horizontal"
                >
                  <Grid container justifyContent="flex-end" alignItems="center" gap={1.5}>
                    <StyledLink onClick={() => setDrawerOpen(false)}>Hide drawer</StyledLink>
                    <CloseContainer data-testid="dialog-close-icon-container" item>
                      <Close onClick={() => props.onClose({}, 'backdropClick')} />
                    </CloseContainer>
                  </Grid>
                  <DrawerContainer container height="calc(100% - 26px)" width={hasWidthLessThan1100 ? '368px' : '30vw'}>
                    {drawerContent}
                  </DrawerContainer>
                </StyledCollapse>
              )}
            </Grid>
          )}
        />

      </Grid>
    </StyledDialog>
  )
}

export default Dialog
