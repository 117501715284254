import React, {
  FC,
  useEffect,
  useState,
  useRef,
  useMemo,
  MutableRefObject,
} from 'react'
import {
  DataGridProProps,
  useGridApiRef,
  GridScrollParams,
  ElementSize,
  GridApi,
} from '@mui/x-data-grid-pro'

import Tooltip from '../../tooltip'
import {
  RightChevronIcon,
  LeftChevronIcon,
  FullscreenRoundedIcon,
  FullscreenExitRoundedIcon,
  KeyboardArrowUpIcon,
  KeyboardArrowDownIcon,
} from '../../icons'
import NoRowsOverlay from './no-rows-overlay'
import LoadingOverlay from './loading-overlay'
import ColumnHeader from './column-header'
import {
  Container,
  StyledDataGrid,
  ACTION_BUTTON_SIZE,
  LeftFade,
  RightFade,
  ActionButton,
  FullscreenActionButtonContainer,
} from './styles'

interface ScrollableArea {
  visibleWidth: number
  visibleLeft: number
  visibleRight: number
  fullWidth: number
  canScrollLeft: boolean
  canScrollRight: boolean
}

export interface Props extends DataGridProProps {
  fullscreen: boolean
  disableScrollFade?: boolean
  disableScrollArrows?: boolean
  disableFullscreen?: boolean
  onFullscreen: (fullscreen: boolean) => void
  noBorder?: boolean
  noRowsLabel?: string
  grayBackground?: boolean
  apiRef?: MutableRefObject<GridApi>
}

const Table: FC<Props> = ({
  columns,
  disableScrollFade,
  disableScrollArrows,
  disableFullscreen,
  onFullscreen,
  noBorder,
  loading,
  fullscreen,
  noRowsLabel,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>()
  const apiRef = useGridApiRef()
  const [scrollableArea, setScrollableArea] = useState<ScrollableArea>({
    visibleWidth: 0,
    visibleLeft: 0,
    visibleRight: 0,
    fullWidth: 0,
    canScrollLeft: false,
    canScrollRight: false,
  })
  const ACTION_BUTTON_OFFSET = 30
  const HEADER_HEIGHT = 44

  useEffect(() => {
    let visibleWidth: number
    let fullWidth: number

    const calculateScrollState = (width: number, left: number) => {
      const nonPinned = apiRef.current.state.columns.all.filter((col) =>
        !apiRef.current.state.pinnedColumns.left?.includes(col)
        && !apiRef.current.state.pinnedColumns.right?.includes(col)
        && apiRef.current.state.columns.lookup[col].computedWidth)
      const pinnedLeft = apiRef.current.state.columns.all.filter((col) =>
        apiRef.current.state.pinnedColumns.left?.includes(col)
        && apiRef.current.state.columns.lookup[col].computedWidth)
      const pinnedRight = apiRef.current.state.columns.all.filter((col) =>
        apiRef.current.state.pinnedColumns.right?.includes(col)
        && apiRef.current.state.columns.lookup[col].computedWidth)

      const pinnedLeftWidth = pinnedLeft.reduce((acc, col) =>
        acc + (apiRef.current.state.columns.lookup[col].width), 0)
      const pinnedRightWidth = pinnedRight.reduce((acc, col) =>
        acc + (apiRef.current.state.columns.lookup[col].width), 0)
      visibleWidth = width - (pinnedLeftWidth + pinnedRightWidth) + 2

      fullWidth = nonPinned.reduce((acc, col) => acc + (apiRef.current.state.columns.lookup[col].width), 0)

      setScrollableArea({
        visibleWidth,
        visibleLeft: pinnedLeftWidth,
        visibleRight: pinnedRightWidth,
        fullWidth,
        canScrollLeft: left !== 0,
        canScrollRight: fullWidth - left > visibleWidth,
      })
    }

    const unsubscribeResize = apiRef.current.subscribeEvent?.(
      'resize',
      ({ width }: ElementSize) => {
        const { left } = apiRef.current.getScrollPosition()
        calculateScrollState(width, left)
      },
    )

    const unsubscribeRowsScroll = apiRef.current.subscribeEvent?.(
      'rowsScroll',
      ({ left }: GridScrollParams) => {
        calculateScrollState(ref.current.clientWidth - 2, left)
      },
    )

    return () => {
      unsubscribeResize?.()
      unsubscribeRowsScroll?.()
    }
  }, [apiRef, props.rows])

  const updateColumns = useMemo(() => columns.map((col) => ({
    ...col,
    renderHeader: col.renderHeader || (() => {
      const [, subtitle, title] = /(.*):::(.*)/.exec(col.headerName) || [undefined, undefined, col.headerName]
      return (<ColumnHeader title={title} subtitle={subtitle} />)
    }),
  })), [columns])

  return (
    <Container ref={ref} noBorder={noBorder}>
      <StyledDataGrid
        disableFullscreen={disableFullscreen}
        fullscreen={fullscreen}
        disableColumnReorder
        hideFooter
        disableColumnMenu
        headerHeight={HEADER_HEIGHT}
        rowHeight={44}
        loading={loading}
        components={{
          ...props.components,
          LoadingOverlay,
          NoRowsOverlay,
          ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
          ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
        }}
        componentsProps={{
          ...props.componentsProps,
          noRowsOverlay: { label: noRowsLabel },
        }}
        apiRef={apiRef}
        {...props}
        columns={updateColumns}
      />
      {ref.current && !loading && (
        <>
          {scrollableArea.canScrollLeft && (
            <>
              {!disableScrollFade && (
                <LeftFade
                  sx={{
                    left: scrollableArea.visibleLeft + 1,
                    top: 1,
                    height: ref.current.clientHeight - 2,
                  }}
                />
              )}
              {!disableScrollArrows && (
                <div
                  style={{
                    position: 'absolute',
                    left: scrollableArea.visibleLeft + ACTION_BUTTON_OFFSET,
                    top: (ref.current.clientHeight * 0.5) - (ACTION_BUTTON_SIZE * 0.5),
                  }}
                >
                  <Tooltip title="Scroll left" placement="right">
                    <ActionButton
                      onClick={() => {
                        apiRef.current.scroll({
                          left: apiRef.current.getScrollPosition().left - (scrollableArea.visibleWidth * 0.8),
                        })
                      }}
                    >
                      <LeftChevronIcon />
                    </ActionButton>
                  </Tooltip>
                </div>
              )}
            </>
          )}
          {scrollableArea.canScrollRight && (
            <>
              {!disableScrollFade && (
                <RightFade
                  sx={{
                    right: scrollableArea.visibleRight + 1,
                    top: 1,
                    height: ref.current.clientHeight - 2,
                  }}
                />
              )}
              {!disableScrollArrows && (
                <div
                  style={{
                    position: 'absolute',
                    right: scrollableArea.visibleRight + ACTION_BUTTON_OFFSET,
                    top: (ref.current.clientHeight * 0.5) - (ACTION_BUTTON_SIZE * 0.5),
                  }}
                >
                  <Tooltip title="Scroll right" placement="left">
                    <ActionButton
                      onClick={() => {
                        apiRef.current.scroll({
                          left: apiRef.current.getScrollPosition().left + (scrollableArea.visibleWidth * 0.8),
                        })
                      }}
                    >
                      <RightChevronIcon />
                    </ActionButton>
                  </Tooltip>
                </div>
              )}
            </>
          )}
        </>
      )}
      {!disableFullscreen && (
        <FullscreenActionButtonContainer>
          <Tooltip title={fullscreen ? 'Exit full screen' : 'Full screen'} placement="left">
            <ActionButton data-testid="data-grid-fullscreen-button" onClick={() => onFullscreen(!fullscreen)}>
              {fullscreen ? <FullscreenExitRoundedIcon /> : <FullscreenRoundedIcon />}
            </ActionButton>
          </Tooltip>
        </FullscreenActionButtonContainer>
      )}
    </Container>
  )
}

export default Table
