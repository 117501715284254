const FeatureFlagNames = {
  TimelineValidationV2: 'timelineValidationV2',
  SetpointSelectionV1: 'releaseSetpointSelectionPage',
  ActionDebug: 'enableActionDebug',
  ActionExplorerTestAgents: 'enableActionExplorerTestAgents',
  TrendsTestAgents: 'enableTrendsTestAgents',
  Admin: 'enableAdmin',
  ModeGroup: 'enableModeGroup',
  TrendViewer: 'enableTrendViewer',
  TrendViewerConstants: 'enableTrendViewerConstants',
  UnitsOfMeasure: 'enableUnitsOfMeasure',
  SetpointEnumeration: 'enableSetpointEnumeration',
  PatchImputationV2Endpoint: 'enablePatchImputationV2Endpoint',
  EnabledPointNamesInUse: 'enabledPointNamesInUse',
  MetadataValidationV2: 'enableMetadataValidationV2',
  EnablePfizerMonthlyPerformance: 'enablePfizerMonthlyPerformance',
  DrawerNavigation: 'enableDrawerNavigation',
  EnableMerckMonthlyPerf: 'enableMerckMonthlyPerf',
  TrendsStatistics: 'enableTrendsStatistics',
  EnableCoverTimelineGaps: 'enableCoverGaps',
  EnableNELIntegration: 'enableVisualNelAtlas',
  NavigatorTimezone: 'enableNavigatorTimezoneSelection',
  ConfigurableEquipmentDashboard: 'enableConfigurableEquipmentDashboard',
  RawClientPoints: 'enableRawClientPoints',
  Insights: 'enableInsights',
  EnableNELSyntaxHighlighting: 'enableNelSyntaxHighlighting',
  HealthRulesV1: 'enableHealthRulesPage',
  EnableBuilderV2: 'enableBuilderV2',
  EnableDebugInExpressionEditor: 'enableDebugInExpressionEditor',
  EnableNELToolbox: 'enableNelToolbox',
  EnableIdentityMapping: 'enableIdentityMapping',
  EnableContinuousEnergySavings: 'enableContinuousEnergySavings',
  EnableSystemDropdown: 'enableSystemDropdown',
  EnableTimelineTab: 'enableTimelineTab',
  VersionChecker: 'enableVersionChecker',
  EnableAtlasConstraints: 'enableAtlasConstraints',
  TagsPaginationSize: 'tagsPaginationSize',
} as const

export default FeatureFlagNames
