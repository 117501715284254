import { YAxisPayload } from '../models'

export const SHOW_UNITS_IN_TOOLTIPS = true

export const formatYAxisValue = (amount: number, yAxis: YAxisPayload, showUnits = false): {
  value: number
  unit?: string
} => {
  const value = Number(amount.toFixed(yAxis.roundingDigits))

  if (!showUnits) {
    return {
      value,
    }
  }

  const unitString = yAxis.title ?? ''
  const unitRegEx = unitString.match(/\(.+?\)/gi)
  let unit = unitRegEx?.length === 1 ? unitRegEx[0] : ''
  if (unit.length > 2) {
    unit = unit.substring(1, unit.length - 1)
  }

  return {
    value,
    unit,
  }
}

export const getValueFromYAxisPosition = (
  mouseY: number,
  yAxisMin: number,
  yAxisMax: number,
  yAxisHeight: number,
  containerTop: number,
) => {
  const ratio = (yAxisHeight - Math.abs(containerTop - mouseY)) / yAxisHeight
  const yRange = yAxisMax - yAxisMin
  const yValue = (yRange * ratio) + yAxisMin

  return yValue
}

export const formatInputAsNumber = (getValue: string, roundingDigits: number): string => {
  // Only allow numbers, minus signs, and decimal points if roundingDigits > 0
  const allowedChars = roundingDigits > 0 ? /[^\d-.]+/g : /[^\d-]+/g
  let filteredValue = getValue.trim().replace(allowedChars, '')

  if (filteredValue.length === 0) {
    return ''
  }

  // If there's a minus sign, make sure it is positioned at the beginning
  if (filteredValue.includes('-')) {
    filteredValue = `-${filteredValue.replace(/-/g, '')}`
  }

  // Remove multiple decimal points
  if (roundingDigits > 0 && filteredValue.match(/\./g)?.length > 1) {
    const pointPos = filteredValue.indexOf('.')
    const filteredValueArray = filteredValue.replace(/\./g, '').split('')
    filteredValueArray.splice(pointPos, 0, '.')
    filteredValue = filteredValueArray.join('')
  }

  // Limit to roundingDigits decimal places
  const decimalIndex = filteredValue.indexOf('.')
  if (roundingDigits > 0 && decimalIndex !== -1 && filteredValue.length > decimalIndex + 1 + roundingDigits) {
    filteredValue = filteredValue.substring(0, decimalIndex + 1 + roundingDigits)
  }

  return filteredValue
}
