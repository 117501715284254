import React, { ReactNode, forwardRef } from 'react'
import { MenuProps as MuiMenuProps } from '@mui/material/Menu'
import { CheckIcon } from '../icons'
import { StyledMenu, StyledMenuItem } from './styles'

export interface CustomMenuProps extends Omit<MuiMenuProps, 'onSelect'> {
  selectedIndex?: number | null
  children: ReactNode
}

const CustomMenu = forwardRef<HTMLDivElement, CustomMenuProps>(
  ({ selectedIndex, children, ...props }, ref) => (
    <StyledMenu {...props} ref={ref}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const isSelected = selectedIndex === index
          return (
            <StyledMenuItem selected={isSelected} {...child.props}>
              {child.props.children}
              {isSelected && (
                <CheckIcon sx={{ color: '#1A1C19', fontSize: '14px' }} />
              )}
            </StyledMenuItem>
          )
        }
        return null
      })}
    </StyledMenu>
  ),
)

export default CustomMenu
