import styled from '@phaidra/ava/styled'
import { KeyboardArrowUpIcon } from '@phaidra/ava/icons'

export const KeyboardArrowUpIconContainer = styled(KeyboardArrowUpIcon, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
  color: '#155E2A',
  textTransform: 'none',
  border: '1px solid transparent',
  width: '24px',
  height: '24px',
  marginTop: '5px',
  marginRight: '2px',
  borderRadius: '50%',
  transform: 'rotate(180deg)',
  ...(isOpen && { transform: 'rotate(0deg)' }),
  transition: 'transform 0.2s',
  cursor: 'pointer',
  marginLeft: '1px',
  '&:hover': {
    backgroundColor: '#F4FFF1',
    border: '1px solid #155E2A !important',
  },
}))

export const NeedsProcessingDot = styled('div')({
  width: '9px',
  height: '9px',
  borderRadius: '50%',
  backgroundColor: '#FFB85D',
  border: '1px solid #875300',
  marginLeft: '9px',
})

export const ComponentTagNameContainer = styled('div')<{ isComponent: boolean }>(({ isComponent }) => ({
  fontWeight: isComponent ? '700' : '400',
  textWrap: 'wrap',
  width: '100%',
}))
