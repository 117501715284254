import React, {
  createContext,
  useMemo,
  useCallback,
  PropsWithChildren,
  useContext,
  useState,
  FC,
} from 'react'

import PageLoader from '@common/page-loader'
import { useQueryParam } from '@common/use-query-param'
import {
  useGetConstraintsQuery, useGetLabelsQuery, useAddConstraintMutation, useGetLabelsQueryState,
} from '@services/constraints'
import {
  Constraints, ConstraintType, LabelsResponse, ConstraintPostRequestType,
} from '@services/constraints/types'

type ConstraintsContextValue = {
  state: {
    constraints: Constraints
    labels: LabelsResponse['labels']
    selectedConstraint: ConstraintType | null
    isAddContraintLoading: boolean
  }
  actions: {
    setSelectedConstraint: React.Dispatch<React.SetStateAction<ConstraintType | null>>
    addConstraint: (constraint: ConstraintPostRequestType) => Promise<boolean>

  }
}

export const ConstraintsContext = createContext<ConstraintsContextValue | undefined>(undefined)

export const ConstraintsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedConstraint, setSelectedConstraint] = useState<ConstraintType | null>(null)

  const installationId = useQueryParam('installationId')

  const { data: constraints, isLoading: isGetConstraintsLoading } = useGetConstraintsQuery({
    installationId,
  })

  useGetLabelsQuery({ installationId })

  const { data: labels, isLoading: isGetLabelsLoading } = useGetLabelsQueryState({ installationId })

  const [addConstraint, { isLoading: isAddContraintLoading }] = useAddConstraintMutation()

  // returns isError
  const addConstraintHandler = useCallback(async (constraint: ConstraintPostRequestType) => {
    const response = await addConstraint({
      installationId,
      constraint,
    })

    if (response && 'error' in response) {
      return true
    }
    return false
  }, [addConstraint, installationId])

  const value = useMemo(() => ({
    state: {
      constraints: constraints || [],
      labels,
      selectedConstraint,
      isAddContraintLoading,

    },
    actions: {
      setSelectedConstraint,
      addConstraint: addConstraintHandler,
    },
  }), [
    constraints, labels, selectedConstraint, addConstraintHandler, isAddContraintLoading,
  ])

  if (isGetConstraintsLoading || isGetLabelsLoading) {
    return <PageLoader />
  }

  return (
    <ConstraintsContext.Provider value={value}>
      {children}
    </ConstraintsContext.Provider>
  )
}

export const useConstraints = (): ConstraintsContextValue => useContext(ConstraintsContext)
