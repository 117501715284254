import styled from '../styled'
import { colors } from '../theme-provider/themes/common'

const INPUT_WRAPPER_HEIGHT = '150px'
const FONT_SIZE = '0.75rem'
const LINE_HEIGHT = 1.5
const LETTER_SPACING = '0.15px'
const PADDING = '16px'

export interface TextAreaProps {
  isResizeable: boolean
}

export interface NelInputWrapperProps {
  height: string
  width: string
  isError: boolean
  readOnly: boolean
}

export const NelInputWrapper = styled('div')<NelInputWrapperProps>(({
  height, width, isError, theme, readOnly,
}) => ({
  position: 'relative',
  height: height || INPUT_WRAPPER_HEIGHT,
  outline: readOnly ? 'none' : `1px solid ${isError ? theme.palette.error.main : theme.palette.dividers.default}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  width: width || '100%',
  zIndex: 0,
  ...readOnly ? {
    textarea: {
      padding: 0,
    },
    code: {
      padding: 0,
    },
  } : {},
  margin: '1px',
}))

export const StyledTextArea = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'isResizeable',
})<TextAreaProps>(({ isResizeable, theme }) => ({
  caretColor: colors.gray[1],
  position: 'absolute',
  inset: 0,
  padding: PADDING,
  fontFamily: 'monospace',
  fontSize: FONT_SIZE,
  lineHeight: LINE_HEIGHT,
  letterSpacing: LETTER_SPACING,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  resize: isResizeable ? 'vertical' : 'none',
  color: 'transparent',
  outline: 'none',

  '&:disabled': {
    background: 'transparent',
  },
}))

export const StyledPre = styled('pre')({
  position: 'absolute',
  inset: 0,
  margin: 0,
  padding: PADDING,
  fontSize: FONT_SIZE,
  lineHeight: LINE_HEIGHT,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  pointerEvents: 'none',
  zIndex: 1,
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export const StyledCode = styled('code')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  bottom: 'unset',
  margin: 0,
  padding: PADDING,
  color: theme.palette.text.syntaxDefault,

  '.builtin': {
    color: theme.palette.text.syntaxBuiltin,
  },

  '.punctuation': {
    color: theme.palette.text.syntaxPunctuation,
  },

  '.string': {
    color: theme.palette.text.syntaxString,
  },

  '.number': {
    color: theme.palette.text.syntaxNumber,
  },

  '.operator': {
    color: theme.palette.text.syntaxOperator,
  },

  '.keyword': {
    color: theme.palette.text.syntaxKeyword,
  },
}))
