import Highcharts from 'highcharts'

export interface ErrorIconThreshold {
  min?: number
  minLabel?: string
  max?: number
  maxLabel?: string
  raisedIcon?: boolean
}

export default (
  horizontalLines: boolean,
  options: Highcharts.Options,
): Highcharts.Options => (
  horizontalLines ? {
    ...options,
    yAxis: {
      ...options.yAxis,
      lineColor: '#58585A',
      gridLineWidth: 1,
      gridLineColor: '#D4D4D5',
    },
  } : options
)
