import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import {
  SetpointsResponse, SetpointsRequestParams,
} from './types'

const tags = {
  InstallationSetpoints: 'InstallationSetpoints',
}

export const setpointsApi = createApi({
  reducerPath: 'setpointsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/installations`,
  }),
  tagTypes: [tags.InstallationSetpoints],
  endpoints: (builder) => ({
    toggleSetpoint: builder.mutation<void, { installationId: string, setpointId: number, body: { isUsed: boolean } }>({
      query: ({ installationId, setpointId, body = {} }) => ({
        url: `/${installationId}/setpoints/${setpointId}/toggle`,
        method: 'patch',
        data: body,
      }),
      invalidatesTags: (_, error) => !error && [{ type: tags.InstallationSetpoints }],
    }),
    updateNotes: builder.mutation<void, {
      installationId: string
      setpointId: number
      body: { notes: string | null } }>({
      query: ({ installationId, setpointId, body = {} }) => ({
        url: `/${installationId}/setpoints/${setpointId}/metadata`,
        method: 'patch',
        data: body,
      }),
    }),
    updateSBR: builder.mutation<void, {
      installationId: string
      setpointId: number
      paramId: number
      body: { value: string | number | null }
    }>({
      query: ({
        installationId, setpointId, paramId, body = {},
      }) => ({
        url: `/${installationId}/setpoints/${setpointId}/params/${paramId}/metadata`,
        method: 'patch',
        data: body,
      }),
    }),
    getSetpoints: builder.query<SetpointsResponse, SetpointsRequestParams>({
      query: ({ installationId }) => ({
        url: `/${installationId}/setpoints`,
        method: 'get',
      }),
      providesTags: () => [{ type: tags.InstallationSetpoints }],

    }),
  }),
})

export const {
  useGetSetpointsQuery,
  useLazyGetSetpointsQuery,
  useToggleSetpointMutation,
  useUpdateNotesMutation,
  useUpdateSBRMutation,
  endpoints: {
    getSetpoints: { useQueryState: useGetSetpointsQueryState },
  },
} = setpointsApi
