import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import Typography from '../../../../typography'
import { YAxisPayload } from '../models'
import { DragIcon, TrashIcon } from '../../../../icons'
import {
  formatYAxisValue,
  getValueFromYAxisPosition,
  SHOW_UNITS_IN_TOOLTIPS,
  formatInputAsNumber,
} from '../utils'

import {
  Console,
  ConsoleButton,
  ConsoleValue,
  PaletteContainer,
  RightSideMarker,
  ValueInput,
  VerticalDivider,
} from './styles'

export interface ConstantPaletteProps {
  chartPlotHeight: number
  hide?: boolean
  labelValue: number
  labelUnit?: string
  modelIndex: number
  onDelete: () => void
  onMove: (yPos: number, movePalette: () => void) => void
  onValueChange: (modelIndex: number, yValue: number) => void
  x: number
  y: number
  yAxis: YAxisPayload
  yAxisOverlayTop: number
  setPreviewConstantLine?: Dispatch<SetStateAction<JSX.Element | null>>
  isDragging: boolean
  setIsDragging?: (arg: boolean) => void
  isEditMode: boolean
  setEditMode?: (arg: boolean) => void
  currentChart?: number
  enterKeyPressed?: boolean
  setEnterKeyPressed?: (arg: boolean) => void
  setPrevConstantState?: () => void
}

const ConstantPalette = ({
  chartPlotHeight,
  labelValue,
  labelUnit,
  modelIndex,
  onDelete,
  onMove,
  onValueChange,
  x,
  y,
  yAxis,
  yAxisOverlayTop,
  hide = false,
  setPreviewConstantLine,
  isDragging = false,
  setIsDragging,
  isEditMode = false,
  setEditMode,
  currentChart = 0,
  enterKeyPressed,
  setEnterKeyPressed,
  setPrevConstantState,
}: ConstantPaletteProps) => {
  const roundingDigits = yAxis?.roundingDigits || 0
  const valueString = `${labelValue.toFixed(roundingDigits)}`
  const [previousValue, setPreviousValue] = useState<string>(valueString)
  const [unit, setUnit] = useState<string>(labelUnit)
  const [showConsole, setShowConsole] = useState<boolean>(false)
  const [xOffset, setXOffset] = useState<number>(0)

  const containerRef = useRef<HTMLDivElement>()
  const trashButtonRef = useRef<HTMLButtonElement>(null)
  const [value, setValue] = useState<string>(valueString)

  useEffect(() => {
    setValue(valueString)
  }, [valueString])

  useEffect(() => {
    setShowConsole(false)
    setIsDragging(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChart])

  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false)

  const setYAxisValueManually = useCallback(() => {
    const numberValue = Number(value)

    if (value.trim().length === 0 || Number.isNaN(numberValue) === true) {
      setValue(previousValue)
      return
    }

    setValue(`${numberValue.toFixed(roundingDigits)}`)
    setPreviousValue(`${numberValue.toFixed(roundingDigits)}`)
    containerRef.current.style.top = null

    onValueChange(modelIndex, numberValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelIndex, onValueChange, previousValue, value])

  const formatYValue = useCallback((amount: number): { value: number, unit?: string } =>
    formatYAxisValue(amount, yAxis, SHOW_UNITS_IN_TOOLTIPS), [yAxis])

  const getValueFromYPosition = useCallback((mouseY: number) =>
    getValueFromYAxisPosition(
      mouseY,
      yAxis.min,
      yAxis.max,
      yAxis.height,
      yAxisOverlayTop,
    ), [yAxis.height, yAxis.max, yAxis.min, yAxisOverlayTop])

  const getConstantValue = useCallback((clientY: number) => {
    let top = (clientY - yAxisOverlayTop)

    if (top < 0) {
      top = 0
    }
    if (top > chartPlotHeight) {
      top = chartPlotHeight
    }

    let rawYAxisValue = getValueFromYPosition(top + yAxisOverlayTop)

    if (rawYAxisValue < yAxis.min) {
      rawYAxisValue = yAxis.min
    }

    if (rawYAxisValue > yAxis.max) {
      rawYAxisValue = yAxis.max
    }

    return rawYAxisValue
  }, [chartPlotHeight, getValueFromYPosition, yAxis.max, yAxis.min, yAxisOverlayTop])

  const onDragConstant = (clientY: number) => {
    if (!containerRef?.current) {
      return
    }

    const constantValue = getConstantValue(clientY)
    onMove(constantValue, () => {})

    const yLabels = formatYValue(constantValue)
    setValue(`${yLabels.value}`)
    setPreviousValue(`${yLabels.value}`)
    setUnit(yLabels.unit)
  }

  const onDropConstant = (clientY: number) => {
    const rawYAxisValue = getConstantValue(clientY)
    onValueChange(modelIndex, rawYAxisValue)

    document.onmouseup = null
    document.onmousemove = null

    setIsDragging(false)
    setIsInputDisabled(false)
  }

  const repositionPalette = () => {
    // Prevents palette from overflowing to the right of the screen
    const containerOffsetMargin = parseInt(containerRef.current.style.marginLeft, 10) || 0
    const containerRight = containerRef.current.getBoundingClientRect().right - containerOffsetMargin
    if (containerRight >= window.innerWidth) {
      const offset = containerRight - window.innerWidth
      setXOffset(offset)
    } else {
      setXOffset(0)
    }
  }

  const dragMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    setPrevConstantState()
    setIsDragging(true)
    setIsInputDisabled(true)

    document.onmouseup = ({ clientY }) => onDropConstant(clientY)
    document.onmousemove = ({ clientY }) => onDragConstant(clientY)

    repositionPalette()
    setPreviewConstantLine?.(null)
  }

  const handleKeyboardInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const filteredValue = formatInputAsNumber(e.currentTarget.value, roundingDigits)
    setValue(filteredValue)
  }, [roundingDigits])

  useEffect(() => {
    if (!containerRef?.current) {
      return
    }

    if (showConsole === false) {
      setXOffset(0)
    }
  }, [containerRef, showConsole, xOffset])

  useEffect(() => {
    if (!containerRef?.current) {
      return
    }

    if (Number.isNaN(value) === false) {
      repositionPalette()
    }
  }, [value])

  if (x === undefined || y === undefined || Number.isNaN(y) === true) {
    return null
  }

  return (
    <PaletteContainer
      data-testid="constant-palette-container"
      ref={containerRef}
      x={x}
      y={y}
      onMouseOver={() => {
        if (!isDragging && !isEditMode) {
          setShowConsole(true)
        }
      }}
      onMouseOut={() => {
        if (!isDragging && !isEditMode) {
          setShowConsole(false)
        }
      }}
      hide={hide}
      style={{ marginLeft: `-${xOffset}px` }}
    >
      <RightSideMarker />
      {showConsole && (
        <Console>
          <ConsoleButton onMouseDown={dragMouseDown} data-testid="constant-palette-drag">
            <DragIcon style={{ width: '10px', height: '10px' }} />
          </ConsoleButton>
          <VerticalDivider />
          <ConsoleValue>
            <ValueInput
              data-testid="constant-palette-value"
              type="text"
              size={value.length < 1 ? 1 : value.length}
              value={value}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (enterKeyPressed) {
                  setShowConsole(false)
                  setEditMode(false)
                  setEnterKeyPressed(false)
                  return
                }

                if (e.relatedTarget === trashButtonRef.current) {
                  onDelete()
                  setShowConsole(false)
                  setEditMode(false)
                  return
                }

                if (previousValue === value) {
                  setIsDragging(false)
                  setShowConsole(false)
                  setValue(previousValue)
                  setEditMode(false)
                } else {
                  const numberValue = Number(value)

                  if (value.trim().length === 0 || Number.isNaN(numberValue) === true) {
                    setValue(Number(previousValue).toFixed(roundingDigits))
                    return
                  }

                  setValue(`${numberValue.toFixed(roundingDigits)}`)
                  setPreviousValue(`${numberValue.toFixed(roundingDigits)}`)
                  setShowConsole(false)
                  containerRef.current.style.top = null

                  onValueChange(modelIndex, numberValue)
                  setEditMode(false)
                }
              }}
              onFocus={() => {
                setEditMode(true)
                setIsDragging(false)
              }}
              onChange={handleKeyboardInput}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Escape') {
                  setEditMode(false)
                  setValue(previousValue)
                  setIsDragging(false)
                  setShowConsole(false)
                } else if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  setEnterKeyPressed(true)
                  setYAxisValueManually()
                }
              }}
              disabled={isInputDisabled}
            />
            <Typography style={{ fontSize: '0.75rem', cursor: 'default' }} data-testid="constant-palette-unit">
              {unit ?? ''}
            </Typography>
          </ConsoleValue>
          <VerticalDivider />
          <ConsoleButton
            ref={trashButtonRef}
            onClick={() => {
              onDelete()
              setShowConsole(false)
              setEditMode(false)
            }}
            data-testid="constant-palette-delete"
          >
            <TrashIcon style={{ width: '15px', height: '15px' }} />
          </ConsoleButton>
        </Console>
      )}
    </PaletteContainer>
  )
}

export default ConstantPalette
