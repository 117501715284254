import React, { forwardRef } from 'react'
import MUITooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

import styled from '../styled'

export { tooltipClasses }

const Tooltip = styled(
  forwardRef(({ className, children, ...props }: TooltipProps, ref) => (
    <MUITooltip arrow {...props} ref={ref} classes={{ popper: className }}>
      <span>{children}</span>
    </MUITooltip>
  )),
)(({ theme }) => ({
  zIndex: 1000,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.dividers.high,
    color: theme.palette.container.surface.drawer,
    boxShadow: theme.shadows[1],
    fontSize: '11.5px',
    padding: '6px 10px',
    fontWeight: '400',
    borderRadius: theme.shape.borderRadius,
    textAlign: 'left',
    maxWidth: '450px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.dividers.high,
  },
}))

export default Tooltip
