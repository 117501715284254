import MUIDivider from '@mui/material/Divider'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import MUIListItemButton from '@mui/material/ListItemButton'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'

import Typography from '../typography'
import Chip from '../chip'

const drawerWidth = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
})

export const DrawerHeader = styled('div')<{ open: boolean }>(({
  open, theme,
}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: 0,
  ...theme.mixins.toolbar,

  '.MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: theme.spacing(1.5),
  },

  '.MuiListItemText-root': {
    display: open ? 'unset' : 'none',
  },

  '.MuiListItemText-primary': {
    color: '#FBFBFB',
    fontWeight: 700,
  },

  '.MuiListItemText-secondary': {
    color: '#96E79C',
    fontWeight: 400,
    fontSize: 12,
    whiteSpace: 'nowrap',
    display: open ? 'block' : 'none',
  },
}))

export const DrawerFooter = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: theme.spacing(4, 1, 0),
  marginTop: 'auto',
  gap: theme.spacing(2),
}))

export const FooterListItem = styled('div')<{ open: boolean, clientName: string }>(({
  open, clientName, theme,
}) => ({
  pointerEvents: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: theme.spacing(1),
  display: 'flex',

  '& .MuiButtonBase-root': {
    justifyContent: 'center',
    cursor: 'auto',
    minHeight: '50px',
    padding: theme.spacing(0, 1.5),
  },

  '& .MuiListItemIcon-root': {
    marginRight: open ? 1 : 0,
    paddingLeft: open ? 12 : 8,
    marginLeft: 0,
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    padding: 0,

    ...(open && {
      position: 'absolute',
      left: 80,
      top: clientName ? 3 : 17,
    }),
  },

  '& .MuiListItemText-root': {
    display: open ? 'unset' : 'none',
  },
}))

export const BottomListItem = styled('div')<{ open: boolean, index: number, bottomListDataLen: number }>(({
  open, theme,
}) => ({
  display: 'flex',

  '& .MuiListItemIcon-root': {
    color: 'unset',
    minWidth: 0,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 0,
  },

  '& .MuiListItemText-root': {
    display: open ? 'unset' : 'none',
  },
}))

export const ListItemButton = styled(MUIListItemButton, {
  shouldForwardProp: (prop) => prop !== 'disabledItem',
})<{ disabledItem?: boolean }>(({ disabledItem, theme }) => ({
  color: theme.palette.icon.subduedInverse,

  ...(!disabledItem) && {
    '&:hover,&:focus': {
      color: theme.palette.hover.icon.subduedInverse,

      '& .MuiTypography-root, & .MuiSvgIcon-root': {
        color: theme.palette.hover.icon.subduedInverse,
      },
      '&.Mui-selected': {
        background: 'unset',
      },
    },
    '&:focus-visible': {
      outline: '1px solid',
    },
  },

  '& .MuiButtonBase-root': {
    width: '100%',
    justifyContent: 'flex-start',
    color: 'inherit',
  },

  '&.Mui-selected': {
    background: 'unset',
    color: theme.palette.icon.activeInverse,
  },
}))

export const Divider = styled(MUIDivider)(({ theme }) => ({
  margin: 0,
  borderColor: 'unset',
  width: '100%',
  color: theme.palette.dividers.default,
}))

export const DrawerList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'fixed',
  zIndex: 9,
  whiteSpace: 'nowrap',
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.container.surface.activeHigh,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const Label = styled(Typography)(({ theme }) => ({
  flex: 1,
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  fontSize: theme.typography.caption.fontSize,
}))

export const StyledListItemText = styled(ListItemText)(() => ({
  '&.MuiListItemText-root': {
    margin: 0,
    flex: 0,
    minWidth: 'auto',
  },
}))

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderColor: theme.palette.container.border.subdued,
  '.MuiChip-label': {
    color: theme.palette.container.border.subdued,
  },
}))
