import CircularProgress from '@phaidra/ava/circular-progress'
import styled from '@phaidra/ava/styled'

export const FrozenDropdownOption = styled('div')<{
  color: string
  borderColor: string
  disabled: boolean
}>(({ color, borderColor, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '144px',
  height: '24px',
  padding: '4px 8px',
  backgroundColor: disabled ? '#F2F2F2' : color,
  color: disabled ? '#B8B8B9' : '#1A1C19',
  fontSize: '0.75rem',
  fontWeight: 400,
  border: disabled ? '1px solid #B8B8B9' : `1px solid ${borderColor}`,
  borderRadius: '4px',
}))

export const StyledProgress = styled(CircularProgress)({
  color: '#B8B8B9',
  height: '12px !important',
  width: '12px !important',
})
