import React, { FC } from 'react'
import {
  useSnackbar,
  VariantType,
  SnackbarKey,
  CustomContentProps,
} from 'notistack'
import IconButton from '@mui/material/IconButton'
import { CloseIcon } from '../../icons'
import Button from '../../button'
import { Props as ButtonProps } from '../../button/types'
import Typography from '../../typography'
import { StyledCard, StyledCardActions, StyledIconContainerRight } from '../styles'

export interface Props {
  id: SnackbarKey
  message: string
  severity: VariantType
  hideIconVariant: boolean
  actionButtons?: Array<{
    label: string
    onClick?: () => void
    color?: ButtonProps['color']
  }>
}

export interface ExtendedCustomContentProps extends CustomContentProps {
  actionButtons?: Props['actionButtons']
}

export const TemplateBar: FC<Props> = ({
  id,
  message,
  severity,
  hideIconVariant,
  actionButtons,
}) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <StyledCard severity={severity} data-testid={`snackbar-${severity}`}>
      <StyledCardActions>
        <Typography variant="body2">
          {message}
        </Typography>
        <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
          {actionButtons && (
            actionButtons.map(({
              label,
              onClick,
              color,
            }) => (
              <Button
                data-testid={`${label.toLowerCase()}-button`}
                key={label}
                type="button"
                variant="text"
                color={color}
                size="small"
                onClick={() => {
                  closeSnackbar(id)
                  onClick?.()
                }}
                sx={{ padding: 0 }}
              >
                {label}
              </Button>
            ))
          )}
          <StyledIconContainerRight>
            {!hideIconVariant && (
              <IconButton
                style={{ marginRight: '-5px' }}
                size="small"
                data-testid="snackbar-close-button"
                onClick={() => closeSnackbar(id)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </StyledIconContainerRight>
        </div>
      </StyledCardActions>
    </StyledCard>
  )
}

export default TemplateBar
