import MuiInputBase from '@mui/material/InputBase'
import styled from '../styled'

const InputBase = styled(MuiInputBase)({
  '& textarea::placeholder': {
    color: '#58585A',
    opacity: 1,
  },
})

export default InputBase
