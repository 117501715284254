import React from 'react'
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup'

import Typography from '../typography'
import { StyledToggledButton, CheckIcon } from './styles'

export interface Props<T> extends ToggleButtonGroupProps {
  options: Array<{ label: string, value: T }>
  reduceBorder?: boolean
  isThin?: boolean
}

const ToggleButton = <T extends {}>({
  options,
  onChange,
  reduceBorder,
  isThin,
  ...props
}: Props<T>) => (
  <ToggleButtonGroup
    exclusive
    {...props}
    onChange={(event, value) => {
      if (value !== null) {
        onChange(event, value)
      }
    }}
  >
    {options.map(({ label, value: val }) => (
      <StyledToggledButton
        key={label}
        value={val}
        disableRipple
        reduceBorder={reduceBorder}
        isThin={isThin}
        data-testid={`toggle-${label}`}
      >
        <div>
          <CheckIcon sx={{ mt: '6px' }} />
        </div>
        <div>
          <Typography variant="body">{label}</Typography>
        </div>
      </StyledToggledButton>
    ))}
  </ToggleButtonGroup>
  )

export default ToggleButton
