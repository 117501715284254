import React, {
  createContext, useContext, useState, useCallback, useRef, useMemo,
} from 'react'

import storeRecentSearch from '@common/utils/store-recent-search'

interface NELEditorState {
  isEditorOpen: boolean
  showVisualNelEditor: boolean
  expression: string
  inputRef: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
  hasValidationErrors: boolean
  showInUse: boolean
  tagSearchTerm: string
  recentSearches: string[]
}

interface NELEditorActions {
  handleClose: () => void
  setExpression: (expression: string) => void
  setHasValidationErrors: (hasValidationErrors: boolean) => void
  setIsEditorOpen: (isEditorOpen: boolean) => void
  setShowInUse: (showInUse: boolean) => void
  setTagSearchTerm: (value: string) => void
  setShowVisualNelEditor: (showVisualNelEditor: boolean) => void
}

interface NELEditorContextValue {
  state: NELEditorState
  actions: NELEditorActions
}

const NELEditorContext = createContext<NELEditorContextValue | undefined>(undefined)

export const NELEditorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [validationResponse, setValidationResponse] = useState(null)
  const [showVisualNelEditor, setShowVisualNelEditor] = useState(false)
  const [expression, setExpression] = useState('')
  const [hasValidationErrors, setHasValidationErrors] = useState(false)
  const [showInUse, setShowInUse] = useState(true)
  const [tagSearchTerm, setTagSearchTerm] = useState('')
  const [recentSearches, setRecentSearches] = useState<string[]>(
    JSON.parse(localStorage.getItem('recentSearches')) ?? [],
  )

  // Actions
  const handleClose = useCallback(() => {
    setIsEditorOpen(false)
    setValidationResponse(null)
    setExpression('')
  }, [])

  const handleTagSearch = useCallback((searchTerm: string) => {
    setTagSearchTerm(searchTerm)
    setRecentSearches(storeRecentSearch(searchTerm))
  }, [])

  const value = useMemo(() => ({
    state: {
      isEditorOpen,
      validationResponse,
      showVisualNelEditor,
      expression,
      inputRef,
      hasValidationErrors,
      showInUse,
      tagSearchTerm,
      recentSearches,
    },
    actions: {
      handleClose,
      setShowVisualNelEditor,
      setExpression,
      setHasValidationErrors,
      setIsEditorOpen,
      setShowInUse,
      setTagSearchTerm: handleTagSearch,
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    isEditorOpen,
    validationResponse,
    showVisualNelEditor,
    expression,
    inputRef,
    hasValidationErrors,
    showInUse,
    tagSearchTerm,
    recentSearches,
  ])

  return (
    <NELEditorContext.Provider value={value}>
      {children}
    </NELEditorContext.Provider>
  )
}

export const useNELEditorContext = () => {
  const context = useContext(NELEditorContext)
  if (context === undefined) {
    throw new Error('useNELEditorContext must be used within a NELEditorProvider')
  }
  return context
}
