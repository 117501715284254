import React, { FC, useEffect } from 'react'
import {
  VariableSizeNodeComponentProps,
  VariableSizeNodeData,
} from 'react-vtree'

import {
  KeyboardArrowDownIcon,
} from '@phaidra/ava/icons'
import Typography from '@phaidra/ava/typography'

import getMatchingOption from '@common/get-matching-option'
import ListItem from '../list-item'
import {
  NodeContainer,
  HorizontalConnector,
  Collapser,
  VerticalTagConnector,
  StyledIconButton,
} from './styles'

export type TagsUsedInArray = Array<{ componentName: string, fullTagName: string }>

export type TreeData = Record<string, {
  children: string[]
  id: string
  name: string
  relations: { cxnName: string, componentId: string }[]
  hasActions?: boolean
}>

export type TreeItemData = { rootName: string, treeData: TreeData }

export interface TreeItemProps {
  id: string
  data: TreeItemData
  isRoot: boolean
  expanded: boolean
  search: string
  onExpanded: () => void
  level?: number
  onInsert?: (option: string) => void
  onCopy?: (option: string) => void
  tagsInUseMapping: Record<string, TagsUsedInArray>
}

type NodeProps = Omit<VariableSizeNodeData, 'id'> &
Readonly<{
  id: string
  isLeaf: boolean
  name: string
  fullTagName: string
  nestingLevel: number
  component: string
  type: 'tag' | 'component' | 'root'
  isLast: boolean
  usedIn: TagsUsedInArray
}>

const Node: FC<VariableSizeNodeComponentProps<NodeProps>> = ({
  data: {
    id,
    component,
    type,
    nestingLevel,
    fullTagName,
    name,
  },
  height,
  isOpen,
  style,
  toggle,
  resize,
  treeData: {
    search, onInsert, onCopy, installationName, tagsInUseMapping,
  },
}) => {
  let marginLeft = type === 'tag' ? '30px' : '40px'
  if (!component) {
    marginLeft = '10px'
  }

  useEffect(() => {
    if (id === installationName && height !== 0) {
      resize(0, true)
    }
  }, [height, id, installationName, resize])

  if (type === 'root') return null

  return (
    <NodeContainer
      data-testid={`tree-item-${id}`}
      style={{ marginLeft, ...style }}
    >
      <div style={{
        display: 'flex',
        height: '28px',
        ...(tagsInUseMapping[fullTagName]?.length && { marginTop: '36px' }),
      }}
      >
        {type === 'tag' && Array.from(Array(nestingLevel - 1)).map((_, index) => (
          <VerticalTagConnector
            // eslint-disable-next-line react/no-array-index-key
            key={`vertical-tag-connector-${id}-${index}`}
          />
        ))}
      </div>
      {nestingLevel > 0 && type === 'component' && (
        <Collapser>
          <StyledIconButton
            data-testid="collapse-button"
            size="large"
            onClick={toggle}
            disableRipple
          >
            <KeyboardArrowDownIcon
              sx={{
                transform: `rotateZ(${isOpen ? 0 : -180}deg)`,
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              }}
              fontSize="medium"
            />
          </StyledIconButton>
        </Collapser>
      )}
      {!!component && type === 'tag' && (
        <div style={{ position: 'relative', marginLeft: type === 'tag' ? '-15px' : '7px' }}>
          <HorizontalConnector />
        </div>
      )}
      {type === 'component'
        ? (
          <Typography fontWeight="400" fontSize="0.75rem" color="#58585A">
            {getMatchingOption(`${name} | ${fullTagName}`, search)}
          </Typography>
        )
        : (
          <ListItem
            index={id}
            search={search}
            option={{ name, fullTagName }}
            onCopy={() => onCopy(id)}
            onInsert={() => onInsert(id)}
            pointNamesUsedInMapping={tagsInUseMapping}
            isNode
            style={{ width: 'calc(100% - 80px)' }}
          />
        )}
    </NodeContainer>
  )
}

export default Node
