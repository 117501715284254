import styled from '@phaidra/ava/styled'
import Typography from '@phaidra/ava/typography'
import Grid from '@phaidra/ava/grid'
import MUIAccordion from '@phaidra/ava/accordion'
import Summary from '@phaidra/ava/accordion-summary'
import Details from '@phaidra/ava/accordion-details'
import IconButton from '@phaidra/ava/icon-button'
import MUIDataGrid from '@phaidra/ava/data-grid'
import { KeyboardArrowDownIcon as AvaKeyboardArrowDownIcon } from '@phaidra/ava/icons'

export const DataGrid = styled(MUIDataGrid)`
   .MuiDataGrid-row {
      cursor: pointer;
    }
`
export const FailedRow = styled('div')`
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background: #ffdad4;
  display: flex;
  align-items: center;
`

export const Title = styled('div')`
  color: #FBFBFB;
  display: flex;
  align-items: flex-end;
`

export const StatusText = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
`

export const AccordionWrapper = styled('div')`
  position: fixed; 
  bottom: 0;
  right: 32px;
  width: 400px;
  & .MuiAccordionSummary-expandIconWrapper {
    padding: 0;
  }
  & button:not(:disabled):hover, .MuiAccordionSummary-expandIconWrapper:hover {
    background-color: #58585A!important;
    border-radius: 50%;
  }
`

export const Accordion = styled(MUIAccordion)`
  padding: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const SummaryWrapper = styled(Summary)({
  display: 'flex',
  padding: '12px',
  alignItems: 'center',
  gap: '0',
  alignSelf: 'stretch',
  borderRadius: '4px 4px 0 0',
  background: '#1A1C19',
  height: '44px',
  '&:hover': {
    backgroundColor: '#1A1C19',
  },
})

export const DetailsWrapper = styled(Details)`
  display: flex;
  padding: 8px 12px; 
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #FFF;
  height: 52px;  
`

export const ElapsedText = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px;
  color: #727273;
`

export const TimestampText = styled(Typography)`
   color: #1A1C19;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-size: 0.875rem;
   font-weight: 400;
   line-height: '18px';
`

export const CloseButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  border: none;
  padding: 13px;
`

export const TimestampDetailsContainer = styled(Grid)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  background:  #FFF;
`

export const Header = styled(Typography)`
  color: #1A1C19;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; 
`

export const SubHeader = styled(Typography)`
  color: #1A1C19;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`

export const KeyboardArrowDownIcon = styled(AvaKeyboardArrowDownIcon)`
  height: 26px;
  width: 26px;
  color: #FBFBFB;
  transform: rotate(180deg);
`
