import styled from '@phaidra/ava/styled'

export const Window = styled('div')({
  overflowY: 'auto',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
})

export const FadeTop = styled('div')({
  position: 'absolute',
  pointerEvents: 'none',
  left: 0,
  right: 0,
  top: 0,
  height: '75px',
  background: 'linear-gradient(#FFF, #FFF0)',
  transition: 'opacity 0.2s',
})

export const FadeBottom = styled('div')({
  position: 'absolute',
  pointerEvents: 'none',
  left: 0,
  right: 0,
  bottom: 0,
  height: '75px',
  background: 'linear-gradient(#FFF0, #FFF)',
  transition: 'opacity 0.2s',
})
