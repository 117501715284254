import * as style from '@mui/material/styles'
import ThemeProvider, {
  useTheme as ThemeProviderUseTheme,
  type SxProps,
  type ThemeType as ThemeProviderThemeType,
} from './theme-provider/index'

interface SharedProps {
  default: style.SimplePaletteColorOptions
  dividers: {
    default: string
    low: string
    high: string
  }
  icon: {
    default: string
    defaultInverse: string
    subdued: string
    subduedInverse: string
    disabled: string
    disabledInverse: string
    active: string
    activeInverse: string
    link: string
    linkInverse: string
    success: string
    successInverse: string
    error: string
    errorInverse: string
    warning: string
    warningInverse: string
    info: string
    infoInverse: string
  }
  hover: {
    icon: {
      subduedInverse: string
    }
    surface: {
      default: string
      error: string
      inverse: string
    }
    text: {
      websiteMission: string
    }
  }
  container: {
    surface: {
      default: string
      low: string
      medium: string
      high: string
      highInverse: string
      accent: string
      activeLow: string
      activeHigh: string
      disabled: string
      destructive: string
      drawer: string
      constants: string
      websiteMission: string
      websiteWidget: string
      websiteFeatured: string
    }
    border: {
      default: string
      subdued: string
      subduedLow: string
      subduedHigh: string
      disabled: string
      secondary: string
      websiteShare: string
    }
  }
  gauge: {
    green: string
    blue: string
  }
  alert: {
    text: {
      success: string
      error: string
      warning: string
      info: string
    }
    surface: {
      success: string
      successLow: string
      successInverse: string
      error: string
      errorInverse: string
      warning: string
      warningInverse: string
      info: string
      infoInverse: string
    }
    border: {
      success: string
      successInverse: string
      error: string
      errorInverse: string
      warning: string
      warningInverse: string
      info: string
      infoInverse: string
    }
  }
}

declare module '@mui/material/styles' {
  interface TypeText {
    default: string
    defaultInverse: string
    subdued: string
    subduedInverse: string
    disabled: string
    active: string
    link: string
    websiteGold: string
    syntaxDefault: string
    syntaxString: string
    syntaxNumber: string
    syntaxPunctuation: string
    syntaxBuiltin: string
    syntaxOperator: string
    syntaxKeyword: string
  }
  interface Palette extends SharedProps {}
  interface PaletteOptions extends SharedProps {}

  interface TypographyVariants {
    subtitle: React.CSSProperties
    body: React.CSSProperties
    small: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    subtitle?: React.CSSProperties
    body?: React.CSSProperties
    small?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle: true
    body: true
    small: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    default: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    default: true
  }
}

export type ThemeType = ThemeProviderThemeType
export type Sx = SxProps<style.Theme>
export const useTheme = ThemeProviderUseTheme
export default ThemeProvider
