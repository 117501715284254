import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const HelpOutlineIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 14" fill="none" data-testid="HelpOutlineIcon">
    <path
      d={'M7.02272 10.2756C7.17956 10.2756 7.31295 10.2205 7.42289 10.1104C7.53281 10.0002 7.58778 9.86674 7.58778 \
        9.7099C7.58778 9.55306 7.53271 9.41968 7.42256 9.30975C7.31242 9.19981 7.17893 9.14484 7.02209 9.14484C6.86525 \
        9.14484 6.73187 9.19991 6.62194 9.31006C6.512 9.4202 6.45703 9.55369 6.45703 9.71053C6.45703 9.86737 6.5121 \
        10.0008 6.62225 10.1107C6.73239 10.2206 6.86588 10.2756 7.02272 10.2756ZM6.60959 8.2014H7.41615C7.42362 \
        7.87534 7.46083 7.6435 7.52777 7.50589C7.5947 7.3683 7.72725 7.20153 7.92544 7.00558C8.26945 6.67129 8.50241 \
        6.39308 8.62432 6.17097C8.74622 5.94885 8.80717 5.7093 8.80717 5.45231C8.80717 4.95995 8.64376 4.55073 8.31696 \
        4.22466C7.99015 3.8986 7.5706 3.73556 7.0583 3.73556C6.62979 3.73556 6.24632 3.85092 5.90791 4.08164C5.56951 \
        4.31235 5.32739 4.62327 5.18156 5.01439L5.91631 5.32961C6.02625 5.03869 6.17694 4.82817 6.36839 \
        4.69805C6.55985 4.56792 6.77636 4.50285 7.01792 4.50285C7.3238 4.50285 7.56891 4.58998 7.75326 4.76424C7.93761 \
        4.93849 8.02979 5.16323 8.02979 5.43844C8.02979 5.66579 7.95631 5.86416 7.80934 6.03355C7.66239 6.20294 \
        7.49656 6.37401 7.31183 6.54678C7.01643 6.82423 6.82554 7.06822 6.73916 7.27874C6.65278 7.48927 6.60959 \
        7.79682 6.60959 8.2014ZM7.00095 12.3083C6.27337 12.3083 5.58697 12.1701 4.94172 11.8938C4.29647 11.6175 \
        3.73169 11.2373 3.2474 10.7533C2.7631 10.2692 2.38273 9.70467 2.1063 9.05972C1.82987 8.41478 1.69165 7.72852 \
        1.69165 7.00095C1.69165 6.26365 1.8298 5.57481 2.10611 4.93443C2.38241 4.29404 2.7626 3.73169 3.24667 \
        3.2474C3.73075 2.7631 4.29527 2.38273 4.94022 2.1063C5.58516 1.82987 6.27142 1.69165 6.99899 1.69165C7.73629 \
        1.69165 8.42513 1.8298 9.06551 2.10611C9.7059 2.38241 10.2682 2.7626 10.7525 3.24667C11.2368 3.73075 11.6172 \
        4.29284 11.8936 4.93293C12.1701 5.57301 12.3083 6.2617 12.3083 6.99899C12.3083 7.72656 12.1701 8.41297 \
        11.8938 9.05822C11.6175 9.70347 11.2373 10.2682 10.7533 10.7525C10.2692 11.2368 9.7071 11.6172 9.06701 \
        11.8936C8.42693 12.1701 7.73824 12.3083 7.00095 12.3083ZM6.99997 11.55C8.26386 11.55 9.33816 11.1076 10.2229 \
        10.2229C11.1076 9.33816 11.55 8.26386 11.55 6.99997C11.55 5.73608 11.1076 4.66178 10.2229 3.77705C9.33816 \
        2.89233 8.26386 2.44997 6.99997 2.44997C5.73608 2.44997 4.66177 2.89233 3.77705 3.77705C2.89233 4.66178 \
        2.44997 5.73608 2.44997 6.99997C2.44997 8.26386 2.89233 9.33816 3.77705 10.2229C4.66177 11.1076 5.73608 \
        11.55 6.99997 11.55Z'}
      fill="#currentColor"
    />
  </SvgIcon>
)

export default HelpOutlineIcon
