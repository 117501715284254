import MUIDialogTitle from '@mui/material/DialogTitle'
import MUIDialog from '@mui/material/Dialog'
import MUIDialogContent from '@mui/material/DialogContent'
import MUIDialogActions from '@mui/material/DialogActions'
import Collapse from '@mui/material/Collapse'

import Grid from '../grid'
import { CloseIcon, WarningIcon } from '../icons'
import styled from '../styled'
import Link from '../link'

export const StyledDialog = styled(MUIDialog, {
  shouldForwardProp: (prop: string) => !['isDrawerOpen', 'fullHeight'].includes(prop),
})<{ fullHeight?: boolean }>(
  ({
    fullWidth, fullHeight, theme,
  }) => ({
    padding: 0,

    '& .MuiPaper-root': {
      borderRadius: theme.shape.borderRadius * 2,
      ...(fullWidth
        && fullHeight && {
        maxWidth: '100%',
      }),
      ...(fullHeight && {
        height: '100%',
      }),

    },
    // To make room for outlined input labels when at the top of a DialogContent.
    // Mui removes the paddingTop for the DialogContent when under a title
    // Adds back in the paddingTop and accounts for it in the marginTop.
    '& .MuiDialogTitle-root+.MuiDialogContent-root, .MuiDialogContent-root': {
      paddingTop: '5px',
      marginTop: '11px',
    },
  }),
)

export const DialogTitle = styled(MUIDialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '1.75rem',
  alignItems: 'flex-start',
  gap: '10px',
  padding: 0,
  lineHeight: '32px',
  marginBottom: theme.spacing(0.5),
}))

export const DialogSubTitle = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '20px',
}))

export const DialogContent = styled(MUIDialogContent, {
  shouldForwardProp: (prop: string) => !['isDrawerOpen', 'fullHeight'].includes(prop),
})<{ isDrawerOpen?: boolean, fullHeight?: boolean }>(({ isDrawerOpen, fullHeight, theme }) => ({
  margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`,
  padding: 0,
  flexWrap: 'nowrap',
  flex: 1,
  ...((isDrawerOpen || fullHeight) && {
    display: 'flex',
    flexDirection: 'column',
  }),
}))

export const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.icon.error,
  marginRight: theme.spacing(1),
  fontSize: '1.75rem',
}))

export const DialogActions = styled(MUIDialogActions)(({ theme }) => ({
  justifyContent: 'flex-start',
  padding: 0,
  marginTop: theme.spacing(0.5),
  '& button:not(:first-of-type):not(data-split-button="true")': {
    marginLeft: theme.spacing(1.5),
  },
}))

export const Close = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.icon.default,
  fontSize: '16px !important',
  cursor: 'pointer',
  zIndex: 1,
}))

export const CloseContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '26px',
  height: '26px',
  borderRadius: '13px',
  '&:hover': {
    backgroundColor: theme.palette.hover.surface.default,
  },
}))

export const ContentContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<{ isDrawerOpen?: boolean }>(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(3),
  '& > form': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const DrawerContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.container.surface.drawer,
}))

export const NavigationContent = styled(Grid)(({ theme }) => ({
  height: '100%',
  width: '30%',
  borderRight: `1px solid ${theme.palette.dividers.low}`,
  marginRight: theme.spacing(0.625),
  paddingRight: theme.spacing(1.25),
}))

export const StyledCollapse = styled(Collapse, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<{ isDrawerOpen?: boolean }>(({ theme, isDrawerOpen }) => ({
  height: '100%',
  paddingInline: isDrawerOpen ? theme.spacing(3) : 0,
  paddingBlock: theme.spacing(3),
  backgroundColor: theme.palette.container.surface.low,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  '.MuiCollapse-wrapperInner': {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const StyledLink = styled(Link)({
  fontSize: '0.75rem',
  textDecoration: 'none',
})
