export const MILLISECONDS_IN_A_SECOND = 1000
export const MILLISECONDS_IN_A_MINUTE = 60000
export const MILLISECONDS_IN_AN_HOUR = 3600000
export const MILLISECONDS_IN_DAY = 86400000
export const MILLISECONDS_IN_WEEK = 604800000
export const MILLISECONDS_IN_MONTH = 2592000000

export const utcToLocal = (milliseconds: number): number => {
  const utcDate = new Date(milliseconds)
  const offset = utcDate.getTimezoneOffset()
  const localDate = new Date(utcDate.getTime() - (offset * MILLISECONDS_IN_A_MINUTE))

  return localDate.getTime()
}

export const localToUTC = (milliseconds: number): Date => {
  const localDate = new Date(milliseconds)
  const offset = localDate.getTimezoneOffset()
  const utcDate = new Date(localDate.getTime() + (offset * MILLISECONDS_IN_A_MINUTE))

  return utcDate
}

export const localToUTCTimestamp = (timestamp: number) => localToUTC(timestamp).getTime()

export const getTimestampAtStartOfDay = (timestamp: number) => {
  const timestampAtStartOfDay = new Date(timestamp)
  timestampAtStartOfDay.setHours(0)
  timestampAtStartOfDay.setMinutes(0)
  timestampAtStartOfDay.setSeconds(0)
  timestampAtStartOfDay.setMilliseconds(0)
  return timestampAtStartOfDay.getTime()
}

export const getTimestampAtEndOfDay = (timestamp: number) => {
  const timestampAtEndOfDay = new Date(timestamp)
  timestampAtEndOfDay.setHours(23)
  timestampAtEndOfDay.setMinutes(59)
  timestampAtEndOfDay.setSeconds(59)
  timestampAtEndOfDay.setMilliseconds(999)
  return timestampAtEndOfDay.getTime()
}

export const getTimeInterval = (milliseconds: number) => {
  if (milliseconds <= 0) {
    return '0 milliseconds'
  }

  if (milliseconds >= MILLISECONDS_IN_DAY - 1) {
    const unit = 'day'
    const amount = Math.round(milliseconds / MILLISECONDS_IN_DAY)
    const unitSuffix = amount !== 1 ? 's' : ''
    return `${amount} ${unit}${unitSuffix}`
  }

  if (milliseconds >= MILLISECONDS_IN_AN_HOUR - 1) {
    const unit = 'hour'
    const amount = Math.round(milliseconds / MILLISECONDS_IN_AN_HOUR)
    const unitSuffix = amount !== 1 ? 's' : ''
    return `${amount} ${unit}${unitSuffix}`
  }

  if (milliseconds >= MILLISECONDS_IN_A_MINUTE - 1) {
    const unit = 'minute'
    const amount = Math.round(milliseconds / MILLISECONDS_IN_A_MINUTE)
    const unitSuffix = amount !== 1 ? 's' : ''
    return `${amount} ${unit}${unitSuffix}`
  }

  if (milliseconds >= MILLISECONDS_IN_A_SECOND - 1) {
    const unit = 'second'
    const amount = Math.round(milliseconds / MILLISECONDS_IN_A_SECOND)
    const unitSuffix = amount !== 1 ? 's' : ''
    return `${amount} ${unit}${unitSuffix}`
  }

  const unit = 'millisecond'
  const unitSuffix = milliseconds !== 1 ? 's' : ''
  return `${milliseconds} ${unit}${unitSuffix}`
}
