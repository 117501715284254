import { isWithinInterval } from 'date-fns'
import { get, isArray, isObject } from 'lodash-es'

type DateRange = {
  startDate?: Date | null
  endDate?: Date | null
}

export type TableTextSearchHookArgs<T> = {
  initialValues?: Record<keyof T, any>
  items: T[]
  searchTerm?: string
  dateRange?: DateRange
  exactMatchTerm?: {
    [key: string]: Record<string, boolean> | boolean
  }
  keys: {
    searchKeys: (keyof T)[]
    dateKeys?: (keyof T)[]
    nestedKeys?: string[]
  }
}

export function getFilteredResults<T>({
  items,
  searchTerm,
  dateRange,
  exactMatchTerm,
  keys,
}: TableTextSearchHookArgs<T>): T[] {
  const filterBySearchTerm = (item: T) =>
    !searchTerm || keys.searchKeys.some(
      (key) => item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    )

  const filterByDateRange = (item: T) =>
    !dateRange?.startDate || keys.dateKeys?.some((key) => {
      const itemDate = new Date(item[key] as unknown as string)
      const endDate = dateRange.endDate || new Date()
      return isWithinInterval(itemDate, { start: dateRange.startDate, end: endDate })
    })

  const filterByExactMatch = (item: T) => {
    if (!exactMatchTerm || Object.keys(exactMatchTerm).length === 0) { return true }

    return Object.entries(exactMatchTerm)
      .filter((filterGroup) => isObject(filterGroup[1]))
      .every(([filterGroupItemKey, values]) => {
        const filterGroupValues = Object.entries(values)

        // if all unchecked, return true
        if (filterGroupValues.every((filter) => !filter[1])) return true

        const itemValue = item[filterGroupItemKey as keyof T]

        const matchNestedValue = (value: string, arrayItem: any[]) =>
          keys.nestedKeys.some((key) => arrayItem.filter((i) => get(i, key) === value).length)

        return filterGroupValues
          .filter((activeFilter) => activeFilter[1])
          .some(([value]) =>
            // supports nested keys with array values
            (isArray(itemValue)
              ? matchNestedValue(value, itemValue)
              : itemValue === value))
      })
  }

  const applyFilters = () =>
    items.filter((item) =>
      filterBySearchTerm(item)
      && filterByDateRange(item)
      && filterByExactMatch(item))

  return applyFilters()
}
