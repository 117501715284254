import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'
import Highcharts from 'highcharts'

import debounce from '@phaidra/utils/debounce'
import Tooltip from '../../../tooltip'
import ConstantPalette from './constant-palette'
import PreviewPalette from './preview-palette'
import ConstantLine from './constant-line'
import YAxisOverlay from './y-axis-overlay'
import { formatYAxisValue, getValueFromYAxisPosition, SHOW_UNITS_IN_TOOLTIPS } from './utils'
import {
  type ConstantLineModel,
  type ConstantLinesProps,
} from './types'

const DEFAULT_MAXIMUM_NUMBER_OF_LINES = 4
const CONSTANT_PREVIEW_MARKER_WIDTH = 10

const ConstantLines = ({
  chart,
  maxLines = DEFAULT_MAXIMUM_NUMBER_OF_LINES,
  onApiReady = () => {},
  onChange = () => {},
  savedConstantLineModels = [],
  viewId = 1,
  forceRender = 1,
  isDirty = false,
  isConstantDragging = false,
  setIsConstantDragging = () => {},
  currentChart = 0,
  setPrevConstantState = () => {},
  addConstant,
  updateConstant,
  removeConstant,
}: ConstantLinesProps) => {
  const [yAxisOverlays, setYAxisOverlays] = useState<ReactNode[]>()
  const [selectedYAxisOverlay, setSelectedYAxisOverlay] = useState<HTMLElement>(null)
  const [constantLineModels, setConstantLineModels] = useState<ConstantLineModel[]>(savedConstantLineModels)
  const [constantLines, setConstantLines] = useState<JSX.Element[]>([])
  const [constantLinePalettes, setConstantLinePalettes] = useState<JSX.Element[]>([])
  const [previewConstantLine, setPreviewConstantLine] = useState<JSX.Element | null>(null)
  const [previewConstantLineValue, setPreviewConstantLineValue] = useState<number>(null)
  const [visibleAxes, setVisibleYAxes] = useState<boolean[]>([])
  const [previewPalette, setPreviewPalette] = useState<JSX.Element>(null)
  const constantLineModelsRef = useRef<ConstantLineModel[]>(null)
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [enterKeyPressed, setEnterKeyPressed] = useState<boolean>(false)

  useEffect(() => {
    setEditMode(false)
  }, [currentChart])

  useEffect(() => {
    if (constantLineModelsRef.current !== null) {
      onChange(constantLineModels)
    } else {
      constantLineModelsRef.current = constantLineModels
    }
  }, [constantLineModels, onChange])

  useEffect(() => {
    if (chart?.yAxis) {
      const intialVisibleYAxisValues = chart.yAxis.map((axes) => (axes as any).hasVisibleSeries)
      setVisibleYAxes(intialVisibleYAxisValues)
    }
  }, [chart?.yAxis])

  useEffect(() => {
    if (chart?.yAxis) {
      onApiReady({
        redraw: () => {
          setTimeout(() => {
            const intialVisibleYAxisValues = chart.yAxis.map((axes) => (axes as any).hasVisibleSeries)
            const models: ConstantLineModel[] = []

            setConstantLineModels(models)
            setVisibleYAxes(intialVisibleYAxisValues)
          }, 0)
        },
      })
    }
  }, [onApiReady, constantLineModels, chart])

  const formatValue = useCallback((amount: number, yAxis: Highcharts.Axis): {
    value: number
    unit?: string
  } => {
    const yAxisPayload = {
      min: yAxis?.min,
      max: yAxis?.max,
      len: (yAxis as any)?.len,
      ticks: yAxis?.ticks,
      title: (yAxis as any)?.axisTitle?.textStr,
      height: (yAxis as any)?.height,
      tickInterval: (yAxis as any)?.tickInterval,
      roundingDigits: (yAxis as any)?.userOptions?.roundingDigits,
    }

    return formatYAxisValue(amount, yAxisPayload, SHOW_UNITS_IN_TOOLTIPS)
  }, [])

  const getPixelsFromYAxisValue = useCallback((yAxis: Highcharts.Axis, value: number) => (
    yAxis?.toPixels(value, false)
  ), [])

  const getNumberOfConstantLines = useCallback(() =>
    constantLineModels.filter((model) => {
      if (model === null) {
        return false
      }

      const { unit: modelUnit, label: modelLabel } = model
      const yAxis = chart.yAxis.find((axis) => {
        const axisTitle = axis.userOptions?.title?.text || ''
        const unit = axisTitle.split(' ').pop().replace(/[()]/g, '') || ''
        const label = axisTitle.split('(')[0].trim() || ''
        return unit === modelUnit && label === modelLabel
      })

      return yAxis !== undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }).length, [constantLineModels])

  const updateConstantLines = useCallback(() => {
    if (!chart) {
      return
    }

    const filteredConstantLineModels = constantLineModels.filter((model) => {
      if (model === null) {
        return false
      }

      const { unit: modelUnit, label: modelLabel } = model
      const yAxis = chart.yAxis.find((axis) => {
        const axisTitle = axis.userOptions?.title?.text || ''
        const unit = axisTitle.split(' ').pop().replace(/[()]/g, '') || ''
        const label = axisTitle.split('(')[0].trim() || ''
        return unit === modelUnit && label === modelLabel
      })

      return yAxis !== undefined
    })

    setConstantLines(filteredConstantLineModels.map((model) => {
      if (model === null) {
        return
      }

      const { unit: modelUnit, label: modelLabel } = model
      let visible = true

      const yAxis = chart.yAxis.find((axis) => {
        const axisTitle = axis.userOptions?.title?.text || ''
        const unit = axisTitle.split(' ').pop().replace(/[()]/g, '') || ''
        const label = axisTitle.split('(')[0].trim() || ''
        if (unit === modelUnit && label === modelLabel) {
          visible = axis.series.some((s) => s.visible)
        }
        return unit === modelUnit && label === modelLabel
      })

      if (!yAxis) {
        return null
      }

      const key = `${model.id}`
      const y = getPixelsFromYAxisValue(yAxis, model.value)

      return (
        <ConstantLine
          id={key}
          key={key}
          x={chart.plotLeft}
          y={y}
          width={chart.plotWidth}
          show={visible}
        />
      )
    }))

    setConstantLinePalettes(filteredConstantLineModels.map((model, index) => {
      if (model === null) {
        return
      }

      const key = `${index}-${model.unit}-${model.label}`

      const { unit: modelUnit, label: modelLabel } = model
      let visible = true
      const yAxis = chart.yAxis.find((axis) => {
        const axisTitle = axis.userOptions?.title?.text || ''
        const unit = axisTitle.split(' ').pop().replace(/[()]/g, '') || ''
        const label = axisTitle.split('(')[0].trim() || ''
        if (unit === modelUnit && label === modelLabel) {
          visible = axis.series.some((s) => s.visible)
        }

        return unit === modelUnit && label === modelLabel
      })

      const y = getPixelsFromYAxisValue(yAxis, model.value)
      const markerWidth = CONSTANT_PREVIEW_MARKER_WIDTH
      const paletteLeft = chart.plotLeft + chart.plotWidth - markerWidth
      const formattedYValue = formatValue(model.value, yAxis)
      const handleMove = (value: number, movePalette: () => void) => {
        const models = [...constantLineModels]
        const updatedModel = { ...models[index], value }
        models[index] = updatedModel
        setConstantLineModels(models)
        movePalette()
      }
      const yAxisPayload = {
        min: yAxis?.min,
        max: yAxis?.max,
        len: (yAxis as any)?.len,
        ticks: yAxis?.ticks,
        title: (yAxis as any)?.axisTitle?.textStr,
        height: (yAxis as any)?.height,
        tickInterval: (yAxis as any)?.tickInterval,
        roundingDigits: (yAxis as any)?.userOptions?.roundingDigits,
      }

      return (
        <ConstantPalette
          modelIndex={index}
          yAxis={yAxisPayload}
          yAxisOverlayTop={
            selectedYAxisOverlay?.getBoundingClientRect().top || chart.container.getBoundingClientRect().top
          }
          chartPlotHeight={chart.plotHeight}
          onDelete={() => {
            removeConstant(model.id)
            const models = constantLineModels.filter((m) => m !== null && m.id !== model.id)
            setConstantLineModels(models)
          }}
          labelUnit={formattedYValue.unit}
          labelValue={formattedYValue.value}
          onValueChange={(modelIndex, newValue) => {
            updateConstant(modelIndex, newValue)
            setConstantLineModels((m) => {
              m[modelIndex].value = newValue
              return m
            })
          }}
          key={key}
          x={paletteLeft}
          y={y}
          onMove={handleMove}
          setPreviewConstantLine={setPreviewConstantLine}
          hide={!visible}
          isDragging={isConstantDragging}
          setIsDragging={setIsConstantDragging}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          currentChart={currentChart}
          enterKeyPressed={enterKeyPressed}
          setEnterKeyPressed={setEnterKeyPressed}
          setPrevConstantState={() => setPrevConstantState(constantLineModels)}
        />
      )
    }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chart,
    constantLineModels,
    getPixelsFromYAxisValue,
    formatValue,
    selectedYAxisOverlay,
    isConstantDragging,
    setIsConstantDragging,
    isEditMode,
    currentChart,
  ])

  const getValueFromYPosition = useCallback((
    mouseY: number,
    yAxis: Highcharts.Axis,
    yAxisContainer: HTMLElement,
  ) => getValueFromYAxisPosition(
    mouseY,
    yAxis.min,
    yAxis.max,
    (yAxis as any).height,
    yAxisContainer.getBoundingClientRect().top,
  ), [])

  const moveThePreviewPalette = useCallback((
    mouseY: number,
    yAxisContainer: HTMLElement,
    yAxis: Highcharts.Axis,
  ) => {
    const canAddMoreLines = getNumberOfConstantLines() < maxLines
    if (!chart || !canAddMoreLines) {
      return
    }

    const { plotLeft, plotWidth } = chart
    const containerTop = yAxisContainer.getBoundingClientRect().top
    const x = plotLeft + plotWidth
    const y = mouseY - containerTop - 11
    const yValue = getValueFromYPosition(mouseY, yAxis, yAxisContainer)
    const formattedYValue = formatValue(yValue, yAxis)

    if (!isConstantDragging && !Number.isNaN(formattedYValue.value)) {
      setPreviewConstantLineValue(yValue)
      setPreviewPalette(
        <PreviewPalette
          value={formattedYValue.value}
          unit={formattedYValue.unit ?? ''}
          x={x}
          y={y}
          aria-label="palette"
          roundingDigits={(yAxis as any)?.userOptions?.roundingDigits}
        />,
      )
    }
  }, [getNumberOfConstantLines, maxLines, chart, getValueFromYPosition, formatValue, isConstantDragging])

  const handlePaletteMouseOut = useCallback(() => {
    setPreviewPalette(null)
  }, [setPreviewPalette])

  const moveThePreviewConstantLine = useCallback((
    yAxisContainer: HTMLElement,
    mouseY: number,
  ) => {
    if (!chart || isConstantDragging) {
      return
    }
    if (getNumberOfConstantLines() >= maxLines) {
      setPreviewConstantLine(null)
      return
    }

    const containerTop = yAxisContainer.getBoundingClientRect().top

    if (!isEditMode) {
      setPreviewConstantLine(
        <ConstantLine
          id="preview"
          x={chart.plotLeft}
          y={Math.abs(containerTop - mouseY) + chart.plotTop}
          width={chart.plotWidth}
          isPreview
        />,
      )
    }
  }, [chart, isConstantDragging, getNumberOfConstantLines, maxLines, isEditMode])

  const handleYAxisMouseOut = useCallback(() => {
    if (!chart) {
      return
    }

    setPreviewConstantLine(null)
  }, [chart])

  const handleChartMouseMove = useCallback((
    mouseY: number,
    yAxisContainer: HTMLElement,
    yAxis: Highcharts.Axis,
  ) => {
    if (!chart || !yAxisContainer) {
      return
    }

    moveThePreviewConstantLine(
      yAxisContainer,
      mouseY,
    )
    moveThePreviewPalette(
      mouseY,
      yAxisContainer,
      yAxis,
    )
  }, [
    chart,
    moveThePreviewPalette,
    moveThePreviewConstantLine,
  ])

  const addConstantLine = useCallback((unit: string, label: string) => {
    if (getNumberOfConstantLines() >= maxLines || enterKeyPressed) {
      setEnterKeyPressed(false)
      return
    }

    const newConstant = {
      id: constantLineModels.length,
      unit,
      label,
      value: previewConstantLineValue,
    }

    addConstant(newConstant)

    setConstantLineModels((models) => {
      models.push(newConstant)
      return models
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constantLineModels, getNumberOfConstantLines, previewConstantLineValue, maxLines])

  const updateYAxisContainers = useCallback(() => setYAxisOverlays(chart.yAxis.map((axis, index) => {
    if (visibleAxes[index] === false) {
      return null
    }

    const spaceForConstantLineMarkers = CONSTANT_PREVIEW_MARKER_WIDTH
    const axisId = `${index}`
    const axisWidth = (axis as any).axisTitleMargin
    const axisHeight = chart.plotHeight
    const axisTop = chart.plotTop
    const isOpposite = (axis as any).opposite
    const axisXPos = isOpposite
      ? axis.chart.plotLeft + axis.chart.plotWidth + (axis as any).offset + spaceForConstantLineMarkers
      : axis.chart.plotLeft + (axis as any).offset - axisWidth - spaceForConstantLineMarkers
    const unit = chart?.yAxis[index]?.userOptions?.title?.text?.split(' ').pop().replace(/[()]/g, '') || ''
    const label = chart?.yAxis[index]?.userOptions?.title?.text?.split('(')[0].trim() || ''

    if (!isConstantDragging && !isEditMode && getNumberOfConstantLines() >= maxLines) {
      setPreviewPalette(null)
      return (
        <YAxisOverlay
          key={axisId}
          x={axisXPos}
          y={axisTop}
          width={axisWidth}
          height={axisHeight}
          allowed={false}
          onMouseOut={() => {
            handleYAxisMouseOut()
            handlePaletteMouseOut()
          }}
          onMouseMove={(ev: MouseEvent) => {
            setSelectedYAxisOverlay(ev.target as HTMLElement)
            handleChartMouseMove(
              ev.nativeEvent.clientY,
              ev.target as HTMLElement,
              axis,
            )
          }}
        />
      )
    }

    return (
      <YAxisOverlay
        key={axisId}
        x={axisXPos}
        y={axisTop}
        width={axisWidth}
        height={axisHeight}
        onMouseOut={() => {
          handleYAxisMouseOut()
          handlePaletteMouseOut()
        }}
        onClick={() => {
          if (!isEditMode) {
            addConstantLine(unit, label)
          }
          setEditMode(false)
        }}
        onMouseEnter={(ev: MouseEvent) => {
          setSelectedYAxisOverlay(ev.target as HTMLElement)
        }}
        onMouseMove={(ev: MouseEvent) => {
          handleChartMouseMove(
            ev.nativeEvent.clientY,
            ev.target as HTMLElement,
            axis,
          )
        }}
      />
    )
  })), [
    addConstantLine,
    chart.plotHeight,
    chart.plotTop,
    chart.yAxis,
    getNumberOfConstantLines,
    handleChartMouseMove,
    handlePaletteMouseOut,
    handleYAxisMouseOut,
    isConstantDragging,
    maxLines,
    visibleAxes,
    isEditMode,
  ])

  useEffect(() => {
    if (!chart) {
      return
    }
    updateConstantLines()
    updateYAxisContainers()

    chart.redraw()
  }, [chart, constantLineModels, updateConstantLines, updateYAxisContainers])

  const redrawOnResize = useCallback(() => {
    updateYAxisContainers()
    updateConstantLines()
  }, [updateYAxisContainers, updateConstantLines])

  useEffect(() => {
    if (chart?.yAxis) {
      updateYAxisContainers()
    }
  }, [
    chart,
    updateYAxisContainers,
  ])

  useEffect(() => {
    const handleResize = debounce(redrawOnResize, 100)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [redrawOnResize])

  useEffect(() => {
    const updateLines = async () => {
      updateYAxisContainers()
      setConstantLineModels(isDirty ? constantLineModels : savedConstantLineModels)

      updateConstantLines()
      setPreviewConstantLine(null)
    }

    const timeoutId = setTimeout(async () => {
      await updateLines()
    }, 0)

    return () => clearTimeout(timeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId, savedConstantLineModels, chart, onApiReady, forceRender])

  return (
    <>
      {constantLines}
      {constantLinePalettes}
      {previewConstantLine}
      {(!isConstantDragging && !isEditMode) && previewPalette}
      <Tooltip
        followCursor
        sx={{ height: 100 }}
        title={
          (!isConstantDragging && !isEditMode) && getNumberOfConstantLines() >= maxLines
            ? `Max of ${maxLines} constants per chart`
            : ''
        }
      >
        <div>
          {yAxisOverlays}
        </div>
      </Tooltip>
    </>
  )
}

export default ConstantLines
