import React, { type FC } from 'react'
import Typography from '@phaidra/ava/typography'
import IconButton from '@phaidra/ava/icon-button'
import { CloseIcon } from '@phaidra/ava/icons'
import Grid from '@phaidra/ava/grid'

interface Props {
  closeTooltip: () => void
}

const StatsTooltipContent: FC<Props> = ({ closeTooltip }) => (
  <>
    <Grid container justifyContent="space-between">
      <Typography fontSize="small" fontWeight={500}>View Statistics</Typography>
      <IconButton
        onClick={closeTooltip}
        aria-label="Close tooltip"
        size="tiny"
        disableRipple
        color="inherit"
        sx={{
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          marginRight: '-6px',
          marginTop: '-4px',
        }}
      >
        <CloseIcon color="inherit" style={{ fontSize: '0.75em' }} />
      </IconButton>
    </Grid>
    <Typography fontSize="small">
      Click the arrow to view the Statistics table. Each chart has its own Statistics table.
    </Typography>
  </>
)

export default StatsTooltipContent
