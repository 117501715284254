import React, { FC, useEffect } from 'react'
import Typography from '@phaidra/ava/typography'
import Dialog from '@phaidra/ava/dialog'
import Grid from '@phaidra/ava/grid'
import { useSnackbar } from '@phaidra/ava/snackbar'
import { WarningIcon } from '@phaidra/ava/icons'
import { useLocation } from 'react-router-dom'

import { useLazyDeleteViewQuery } from '../../../services/trend-viewer'
import { View } from '../../../services/trend-viewer/types'

interface Props {
  view: View
  onClose: () => void
}

const DeleteViewDialog: FC<Props> = ({ view, onClose }) => {
  const { search } = useLocation()
  const installationId = new URLSearchParams(search).get('installationId')
  const { enqueueSnackbar } = useSnackbar()
  const [deleteView, { isLoading, error }] = useLazyDeleteViewQuery()

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Failed to delete view. Please try again.', { variant: 'error' })
    }
  }, [enqueueSnackbar, error])

  return (
    <Dialog
      title="Delete saved view"
      open
      onClose={onClose}
      actionButtons={[
        {
          label: 'Delete',
          variant: 'contained',
          color: 'error',
          loading: isLoading,
          closeOnClick: false,
          onClick: () => {
            deleteView({ installationId, viewId: view.id }).then(({ isError }) => {
              if (!isError) {
                onClose()
              }
            })
          },
        },
        { label: 'Cancel', variant: 'outlined' },
      ]}
    >
      <Grid container flexDirection="column" gap={2}>
        <Typography>
          {'Are you sure you want to delete '}
          <strong>{view.title}</strong>
          ?
        </Typography>
        <Grid container gap={1} alignItems="center">
          <WarningIcon color="error" />
          <Typography color="error" variant="caption">
            {'Deleting '}
            <strong>{view.title}</strong>
            {' will permanently delete all saved data. This action cannot be undone.'}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DeleteViewDialog
