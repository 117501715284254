import { useCallback, useState } from 'react'
import { type StringLiteral } from '../types'

const useLocalStorage = <T extends string>(key: StringLiteral<T>) => {
  // require the use of React.State to trigger rerenders
  const [localValue, setLocalValue] = useState(localStorage.getItem(key))

  const setValue = useCallback((newValue: string) => {
    setLocalValue(newValue)
    localStorage.setItem(key, newValue)
  }, [key])

  return [localValue, setValue] as const
}

export default useLocalStorage
