import styled from '@phaidra/ava/styled'
import Button from '@phaidra/ava/button'
import Paper from '@phaidra/ava/paper'

export const OPEN_WIDTH = 260

export const StyledPaper = styled(Paper)<{ open: boolean }>(({ open, theme }) => ({
  height: '100%',
  width: open ? `${OPEN_WIDTH}px` : '0',
  borderRight: `1px solid ${theme.palette.dividers.low}`,
  transition: theme.transitions.create(['width', 'margin-right'], {
    easing: 'linear',
    duration: theme.transitions.duration.shorter * 0.5,
  }),
  display: 'flex',
  ...(!open && {
    whiteSpace: 'nowrap',
  }),
}))

export const Children = styled('div')(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  paddingBottom: theme.spacing(2.5),
}))

export const CollapseButton = styled(Button)<{ open: boolean }>(({ open, theme }) => ({
  position: 'sticky',
  top: '0',
  display: open ? 'block' : 'none',
  backgroundColor: theme.palette.container.surface.activeHigh,
  color: theme.palette.icon.defaultInverse,
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  boxShadow: 'none',
  '&:hover': {
    cursor: 'pointer',
    boxShadow: 'none',
  },
  height: '28px',
  zIndex: 1,
}))
