import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'
import { HDITags } from '@services/request-tags'
import { IngestionTimestamp, Ingestion, Metadata } from './types'
import { mergeIngestionsAndTimestamps, transformIngestionRecords } from './utils/transformers'

export const historicalDataIngestionApi = createApi({
  reducerPath: 'historicalDataIngestionApiNext',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/historical/installations`,
  }),
  tagTypes: [HDITags.IngestionTimestamps],
  endpoints: (builder) => ({
    getCombinedData: builder.query<Array<Ingestion>, { installationId: string }>({
      queryFn: async ({ installationId }, _, __, fetchWithBaseQuery) => {
        try {
          const response = await Promise.all([
            fetchWithBaseQuery({
              url: `/${installationId}/ingestion-timestamps`,
              method: 'get',
              meta: { tag: HDITags.IngestionTimestamps },
            }),
            fetchWithBaseQuery({
              url: `/${installationId}/ingestions`,
              method: 'get',
              meta: { tag: HDITags.IngestionTimestamps },
            }),
          ])

          const joinedData = mergeIngestionsAndTimestamps(
            response[0].data as IngestionTimestamp[],
            response[1].data as Ingestion[],
          )
          return { data: transformIngestionRecords(joinedData) }
        } catch (error) {
          return {
            error: {
              status: error?.status,
              data: error?.data,
            },
          }
        }
      },
      providesTags: () => [{ type: HDITags.IngestionTimestamps }],
    }),
    startIngestion: builder.mutation<Ingestion, { installationId: string, body: { gcs_folder_path: string } }>({
      query: ({ installationId, body = {} }) => ({
        url: `/${installationId}/ingestions`,
        method: 'post',
        data: body,
        meta: { tag: HDITags.IngestionTimestamps },
      }),
      invalidatesTags: (_, error) => !error && [{ type: HDITags.IngestionTimestamps }],
    }),
    getIngestions: builder.query<Array<Ingestion>, { installationId: string, ingestionId?: number }>({
      query: ({ installationId, ingestionId }) => ({
        url: `/${installationId}/ingestions`,
        method: 'get',
        params: { ingestion_id: ingestionId },
        meta: { tag: HDITags.IngestionTimestamps },
      }),
    }),
    getMetaData: builder.query<Metadata, { installationId: string }>({
      query: ({ installationId }) => ({
        url: `/${installationId}/ingestion-metadata`,
        method: 'get',
        meta: { tag: HDITags.IngestionTimestamps },
      }),
    }),
  }),
})

export const {
  useGetIngestionsQuery,
  useStartIngestionMutation,
  useGetCombinedDataQuery,
  useLazyGetCombinedDataQuery,
  useGetMetaDataQuery,
  endpoints: {
    getIngestions: { useQueryState: useGetIngestionsQueryState },
    getCombinedData: { useQueryState: useGetCombinedDataQueryState },
    getMetaData: { useQueryState: useGetMetaDataQueryState },
  },
} = historicalDataIngestionApi
