import { useState, useEffect } from 'react'

const oneMinute = 60000

const roundUpTo = () => Math.ceil(Date.now() / oneMinute) * oneMinute

/**
 * Waits until the next rounded minute to start the ticking to keep in sync
 * with actual time.
 * eg. 9:29:30 - we will wait 30 seconds to get to 9:30:00 to tick every minute
 */
const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const timeTilMinute = roundUpTo() - Date.now()

  useEffect(() => {
    let intervalId: NodeJS.Timer = null

    setTimeout(() => {
      setCurrentTime(new Date().getTime())

      intervalId = setInterval(() => {
        setCurrentTime(new Date().getTime())
      }, oneMinute)
    }, timeTilMinute)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [timeTilMinute])

  return currentTime
}

export default useCurrentTime
