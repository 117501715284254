import styled from '@phaidra/ava/styled'
import Typography from '@phaidra/ava/typography'

export const TagId = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  color: theme.palette.text.defaultInverse,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const TagLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
