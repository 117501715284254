import Typography from '../../../typography'
import styled from '../../../styled'

export const Subtitle = styled(Typography)({
  whiteSpace: 'pre',
  fontSize: '0.75rem',
  lineHeight: '1',
  fontWeight: '400',
  paddingTop: '5px',
})

export const ColumnTitle = styled(Typography)({
  fontWeight: '700',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem',
})
