import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CopyIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20" fill="none" data-testid="copy-icon">
    <path
      d="M6.20831
       14.7916V2.625H16.3749V14.7916H6.20831ZM7.2916
       13.7083H15.2916V3.70831H7.2916V13.7083ZM3.625
       17.3749V5.46475H4.70831V16.2916H13.5352V17.3749H3.625Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default CopyIcon
