import {
  PointNamesType, TagsInUseData, PointNamesUsedInType, TagsData,
} from '@services/tag-management/types'
import getKvPair from '@phaidra/utils/get-kv-pair'
import { getFilteredTagData } from '../../../app/tag-management/utils'

interface TransformSearchOptionsParams {
  pointNames: PointNamesType['data']
  tagsResponse: TagsData['data']
  tagsInUse: TagsInUseData['data']
  pointNamesUsedInMapping: PointNamesUsedInType['data']
  isPointNamesInUseEnabled: boolean
  tagSearchTerm: string
}

export const transformSearchOptions = ({
  pointNames,
  tagsResponse,
  tagsInUse,
  pointNamesUsedInMapping,
  isPointNamesInUseEnabled,
  tagSearchTerm = '',
}: TransformSearchOptionsParams) => {
  const transformTagsOptions = {
    tagSearchTerm,
    showInUse: true,
  }

  const pointNamesMapping = isPointNamesInUseEnabled ? pointNamesUsedInMapping
    : getKvPair([
      ...pointNames.historicalPointNames.map((point) => ({ key: point.name, value: point.usedIn })),
      ...pointNames.livePointNames.map((point) => ({ key: point.name, value: point.usedIn })),
    ])

  const filteredData = getFilteredTagData(
    tagsResponse,
    tagsInUse,
    pointNames,
    pointNamesMapping,
    transformTagsOptions,
  )

  return filteredData
}

export const buildExpression = (value: string, expression: string, cursorPosition: number, isPhaidraTag: boolean) =>
  `${expression.slice(0, cursorPosition)}${isPhaidraTag ? 'tag_value' : 'sensor_value'}\
('${value}', 0)${expression.slice(cursorPosition)}`
