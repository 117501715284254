import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'

interface FlagsProps {
  authorizedFlags: string[]
  renderOn: () => JSX.Element
  renderOff?: () => JSX.Element
}

const LaunchDarklyFlags: React.FC<FlagsProps> = ({ authorizedFlags, renderOn, renderOff }) => {
  const flags = useFlags()
  if (authorizedFlags.length === 0) {
    return renderOff?.()
  }
  const isFlagEnabled = authorizedFlags.every((flag) => flags[flag])
  return isFlagEnabled ? renderOn() : renderOff && renderOff()
}

export default LaunchDarklyFlags
