import React, { FC } from 'react'
import MUITab, { TabProps as MUITabProps } from '@mui/material/Tab'

import styled from '../styled'

const StyledTab = styled(MUITab)<TabProps>(({ theme }) => ({
  textTransform: 'unset',
  borderRadius: '10px 10px 0 0',
  border: `solid 1px ${theme.palette.dividers.low}`,
  fontSize: '16px',
  padding: '10px 20px',
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.container.surface.default,
    display: 'flex!important',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: theme.palette.text.default,
    cursor: 'auto',

    '&:hover': {
      '& > div': {
        color: theme.palette.text.default,
      },
    },
  },
}))

export type TabProps = MUITabProps

const Tab: FC<TabProps> = (props) => <StyledTab disableRipple {...props} />

export default Tab
