import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SystemHomeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 26" fill="none" data-testid="systems-icon">
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M12.5 15.924v3.964"
      fill="none"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.25"
      d="M2.759 25.263.625 23.13V3.647l2.134-2.134H22.24l2.134 2.134V23.13l-2.134 2.134H2.76Z"
      fill="none"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.25"
      d="M4.973 12.44 12.5 6.817l5.06 3.77 2.467 1.853M16.67 7.385v2.668m-9.793 1.051v9.64m11.271-9.64v9.64"
      fill="none"
    />
  </SvgIcon>
)

export default SystemHomeIcon
