import { Dispatch, SetStateAction } from 'react'
import Highcharts from 'highcharts'

export default (
  options: Highcharts.Options,
  zoomable: boolean,
  setZoomStack: Dispatch<SetStateAction<Array<[number, number]>>>,
  zoomStack: Array<[number, number]>,
  onZoom?: (min: number, max: number, zoomStack: Array<[number, number]>) => void,
): Highcharts.Options => (
  zoomable ? {
    ...options,
    chart: {
      ...options.chart,
      zooming: {
        resetButton: {
          theme: {
            style: {
              display: 'none',
            },
          },
        },
        type: 'x',
      },
    },
    xAxis: {
      ...options.xAxis,
      events: {
        ...((options.xAxis as Highcharts.XAxisOptions)?.events),
        setExtremes(this, event) {
          (options.xAxis as Highcharts.XAxisOptions)?.events?.setExtremes.bind(this)(event)
          if (event.min !== undefined && event.max !== undefined) {
            const zoomedOut: boolean = zoomStack.length > 0
              && (event.max - event.min) > (zoomStack[zoomStack.length - 1][1] - zoomStack[zoomStack.length - 1][0])

            if (zoomedOut) {
              onZoom?.(event.min, event.max, zoomStack.slice(0, -1))
              setZoomStack((stack) => stack.slice(0, -1))
            } else {
              onZoom?.(event.min, event.max, [...zoomStack, [event.min, event.max]])
              setZoomStack((stack) => [...stack, [event.min, event.max]])
            }
          }
        },
      },
    },
  } : options
)
