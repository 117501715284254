import getDefaultTooltip from './get-default-tooltip'

export default (options: Highcharts.Options, title: string): Highcharts.Options => (
  !options.tooltip && ['pie', 'line', 'boxplot', 'area'].includes(options.chart?.type) ? {
    ...options,
    tooltip: {
      useHTML: true,
      borderColor: 'none',
      backgroundColor: 'rgba(251, 251, 251, 1)',
      formatter() {
        return getDefaultTooltip(this, title)
      },
    },
  } : options
)
