import styled from '../../styled'

import Menu from '../../menu'
import CircularProgress from '../../circular-progress'
import { RightChevronIcon } from '../../icons'

export const StyledMenu = styled(
  Menu,
  { shouldForwardProp: (prop) => prop !== 'isThin' && prop !== 'website' },
)<{ width: number, isThin: boolean, website?: boolean }>(({
  width = 0,
  isThin,
  website,
  theme,
}) => ({
  '& .MuiMenu-paper': {
    width: `${width}px`,
    boxShadow: '0px 4px 6px -4px rgba(0,0,0,0.6)',
    borderRadius: '0 0 4px 4px',
    border: `1px solid ${theme.palette.container.border.default}`,
    borderTop: 'unset',
    ...(website && {
      boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
      border: 'none',
    }),
  },
  '& li': {
    paddingLeft: '12px',
    width: `${width}px`,
  },
  '& .MuiFormGroup-root': {
    paddingLeft: '10px',
  },
  '& .MuiMenuItem-root': {
    height: isThin ? '26px' : '32px',
    minHeight: 'max-content !important',
  },
  '& .Mui-selected': {
    backgroundColor: `${theme.palette.container.surface.default} !important`,
  },
}))

export const LabelContainer = styled('button', {
  shouldForwardProp: (prop: string) => ![
    'fullWidth', 'error', 'hover', 'isThin', 'borderColor', 'backgroundColor', 'size', 'website'].includes(prop),
})<{
  size: 'large' | 'medium' | 'small'
  open?: boolean
  fullWidth?: boolean
  error?: boolean
  hover?: boolean
  isThin?: boolean
  borderColor?: string
  backgroundColor?: string
  disabled?: boolean
  website?: boolean
}>(({
  theme,
  open,
  fullWidth,
  error,
  hover,
  isThin,
  borderColor,
  backgroundColor,
  disabled,
  size,
  website,
}) => {
  let bgColor = error ? theme.palette.alert.surface.error : theme.palette.container.surface.default
  let border = borderColor ? `1px solid ${borderColor}` : `1px solid ${theme.palette.container.border.default}`
  if (!open) {
    bgColor = theme.palette.container.surface.default
  }
  if (isThin) {
    bgColor = backgroundColor
  }
  if (website) {
    bgColor = 'transparent'
    border = `1px solid ${theme.palette.dividers.low}`
  }
  if (disabled) {
    bgColor = theme.palette.container.surface.disabled
    border = `1px solid ${theme.palette.container.border.disabled}`
  }

  let height = size === 'small' ? '28px' : '44px'
  if (size === 'large') {
    height = '52px'
  }

  return {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '-webkit-fill-available',
    maxWidth: fullWidth ? '100%' : '250px',
    height,
    color: disabled ? `${theme.palette.text.disabled} !important` : theme.palette.default.main,
    padding: isThin ? theme.spacing(0.5, 1) : theme.spacing(0.5, 1.5),
    justifyContent: 'space-between',
    borderRadius: open ? '4px 4px 0 0' : '4px',
    backgroundColor: bgColor,
    border,
    boxShadow: open ? '0px 4px 6px -4px rgba(0,0,0,0.6)' : 'unset',
    textTransform: 'unset',
    ...(open && !website && {
      border: `1px solid ${theme.palette.container.border.default}`,
      backgroundColor: theme.palette.hover.surface.default,
      color: theme.palette.text.subdued,
    }),
    ...(open && website && {
      border: `1px solid ${theme.palette.text.default}`,
      color: theme.palette.text.default,
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.default,
      },
    }),
    ...(disabled && { pointerEvents: 'none' }),
    ...(isThin && { fontWeight: 400 }),
    ...(error && { borderColor: theme.palette.error.main }),
    ...(error && website && {
      '& p': {
        color: theme.palette.alert.text.error,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.alert.text.error,
      },
    }),
    ...(hover ? {
      '&:hover': {
        backgroundColor: theme.palette.hover.surface.default,
        borderColor: error ? theme.palette.error.main : theme.palette.container.border.default,
        ...(website && {
          backgroundColor: 'transparent',
          borderColor: theme.palette.text.default,
        }),
      },
    } : {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    }),
    '& > div': {
      maxWidth: fullWidth ? '98%' : '198px',
      'p:last-of-type': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  }
})

export const StyledRightChevronIcon = styled(RightChevronIcon, {
  shouldForwardProp: (prop) => prop !== 'isThin' && prop !== 'website',
})<{ isThin?: boolean, disabled?: boolean, website?: boolean }>(
  ({
    theme, rotate, disabled,
  }) => {
    const color = theme.palette.icon.default
    return ({
      color: disabled ? theme.palette.icon.disabled : color,
      transform: rotate === '1' ? 'rotate(270deg)' : 'rotate(90deg)',
      transition: `transform ${theme.transitions.duration.shortest * 0.001}s linear`,
      width: '12px',
      fontSize: '0.75rem',
      marginRight: '3px',
      marginLeft: '8px',
    })
  },
)

export const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.icon.defaultInverse,
  height: '12px !important',
  width: '12px !important',
}))
