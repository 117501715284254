import MuiIconButton from '@mui/material/IconButton'

import styled from '../styled'

export const IconButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isTiny',
})<{ isTiny: boolean }>(({ isTiny }) => ({
  '& > .MuiButtonBase-root': {
    ...(isTiny && {
      width: '25px',
      height: '25px',
    }),
  },
}))

export const StyledIconButton = styled(MuiIconButton)(({ theme, disabled }) => ({
  '&.MuiButtonBase-root:hover': {
    backgroundColor: theme.palette.hover.surface.default,
  },
  ...(disabled && {
    '& > svg': {
      fill: theme.palette.icon.disabled,
    },
  }),
}))
