import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'
import { RootState } from '@store'
import { SystemBuilderState, updateSystemBuilderState } from '@store/system-builder'

import { SystemInstanceTags } from '@services/request-tags'
import {
  SystemInstance,
  SystemInstanceData,
  JsonFile,
  PostSystemInstanceResponse,
} from './types'

export const systemInstanceApi = createApi({
  reducerPath: 'systemInstanceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api`,
  }),
  endpoints: (builder) => ({
    getSystemInstance: builder.query<SystemInstanceData, { installationId: string }>({
      transformResponse: (response: SystemInstance) => response.data,
      query: (params) => ({
        url: '/v1/system-instance',
        method: 'get',
        params,
        meta: {
          tag: SystemInstanceTags.GetSystemInstance,
        },
      }),
    }),
    postSystemInstance: builder.query<PostSystemInstanceResponse, {
      installationId: string
      newCache: number
    }>({
      async queryFn({ installationId }, { dispatch, getState }, _extraOptions, fetch) {
        async function submitRequest(persistedComponentTreeHash: string):
        Promise<PostSystemInstanceResponse | QueryReturnValue> {
          dispatch(updateSystemBuilderState({ isFetching: true }))

          const { componentTree } = (getState() as RootState).systemBuilder
          const data = await fetch(
            {
              url: `/async/v1/system-instance?installationId=${installationId}`,
              method: 'post',
              data: { componentTree, persistedComponentTreeHash: persistedComponentTreeHash ?? null },
            },
          )

          if ('error' in data) {
            dispatch(updateSystemBuilderState({ isFetching: false }))

            return {
              error: {
                status: data.error?.status,
                data: data.error?.data,
              },
            }
          }

          const { hasSendUpdatedTreeToBackEnd } = (getState() as RootState).systemBuilder
          const result = data?.data as PostSystemInstanceResponse

          dispatch(updateSystemBuilderState({ persistedComponentTreeHash: result?.persistedComponentTreeHash }))

          if (hasSendUpdatedTreeToBackEnd) {
            dispatch(updateSystemBuilderState({ isFetching: false }))
            return result
          }

          dispatch(updateSystemBuilderState({ hasSendUpdatedTreeToBackEnd: true }))
          return submitRequest(result.persistedComponentTreeHash)
        }

        const { isFetching, persistedComponentTreeHash } = (getState() as RootState).systemBuilder as SystemBuilderState
        if (isFetching) {
          dispatch(updateSystemBuilderState({ hasSendUpdatedTreeToBackEnd: false }))
          return new Promise(() => { setTimeout(() => {}, 0) })
        }

        const data = await submitRequest(persistedComponentTreeHash) as PostSystemInstanceResponse

        if ('error' in data) {
          return data as {
            error: {
              status: number
              data: string | {
                error_code: string
                error_message: string
              }
            }
          }
        }

        return { data }
      },
      keepUnusedDataFor: 0,
    }),
    exportSystemInstance: builder.query<JsonFile, { installationId: string }>({
      query: ({ installationId }) => ({
        url: `/async/v1/system-instance/export?installationId=${installationId}`,
        method: 'get',
        meta: {
          tag: SystemInstanceTags.ExportSystemInstance,
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useGetSystemInstanceQuery,
  useLazyGetSystemInstanceQuery,
  usePostSystemInstanceQuery,
  useLazyPostSystemInstanceQuery,
  useExportSystemInstanceQuery,
  useLazyExportSystemInstanceQuery,
  endpoints: {
    getSystemInstance: { useQueryState: useGetSystemInstanceQueryState },
  },
} = systemInstanceApi
