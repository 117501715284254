import MuiLinearProgress from '@mui/material/LinearProgress'
import styled from '../../styled'

export const StyledLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  width: '248px',
  height: '4px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.container.surface.disabled,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.alert.text.success,
    borderRadius: theme.shape.borderRadius,
  },
}))

export const LinearProgressContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})
