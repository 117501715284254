import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const IngestIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    data-testid="icon-ingest"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <mask
      id="mask0_1991_10205"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_1991_10205)">
      <path
        d="M5.0003 15.5834C4.00139 15.5834 3.15444 15.2284 2.45945 14.5185C1.76448 13.8085
        1.41699 12.9541 1.41699 11.9552C1.41699 10.9221 1.7781 10.0433 2.50033 9.31897C3.22255
        8.59462 4.08312 8.27785 5.08205 8.36867C5.31176 7.2832 5.86946 6.36787 6.75514
        5.62267C7.64082 4.87748 8.54199 4.50488 9.45866 4.50488C9.75363 4.50488 10.0079
        4.60024 10.2215 4.79095C10.4351 4.98164 10.542 5.2335 10.542 5.54653V11.1844L11.9218
        9.78853L12.6958 10.5625L10.0003 13.258L7.3048 10.5625L8.07883 9.78853L9.45866
        11.1844V5.54653C8.47788 5.70785 7.6563 6.16591 6.99391 6.92072C6.33151 7.67552
        5.96558 8.5353 5.89614 9.50005H5.0003C4.30983 9.50005 3.72055 9.74451 3.23245
        10.2334C2.74435 10.7223 2.5003 11.3126 2.5003 12.0043C2.5003 12.6959 2.74435
        13.2848 3.23245 13.7709C3.72055 14.257 4.30983 14.5 5.0003 14.5H15.5003C16.0603
        14.5 16.5336 14.3072 16.9203 13.9214C17.307 13.5357 17.5003 13.0634 17.5003
        12.5047C17.5003 11.9461 17.307 11.4723 16.9203 11.0834C16.5336 10.6945 16.0603
        10.5 15.5003 10.5H14.0003V9.00005C14.0003 8.50326 13.8822 8.0257 13.6461
        7.56736C13.41 7.10903 13.0697 6.6977 12.6253 6.33338V5.07701C13.3774 5.46697
        13.9752 6.01283 14.4186 6.71459C14.8619 7.41635 15.0836 8.17817 15.0836
        9.00005V9.41674H15.34C16.2321 9.374 16.9957 9.65418 17.6309 10.2573C18.266
        10.8604 18.5836 11.601 18.5836 12.4792C18.5836 13.3531 18.2813 14.089 17.6766
        14.6867C17.0719 15.2845 16.3464 15.5834 15.5003 15.5834H5.0003Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)

export default IngestIcon
