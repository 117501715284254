import React from 'react'
import Switch, { SwitchProps } from '@mui/material/Switch'

import styled from '../styled'

const checkIcon = (color: string) => ({
  content: "''",
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '0',
  top: 0,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: 'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" '
    + `width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(color)}" `
    + 'd="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>\')',
})

const Toggle = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: theme.transitions.duration.standard,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme.palette.container.surface.default,
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.container.surface.default,
        '&:before': {
          ...checkIcon(theme.palette.icon.default),
        },
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.icon.default,
        borderColor: theme.palette.icon.default,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.container.surface.disabled,
        borderColor: theme.palette.container.surface.disabled,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        '&:before': {
          ...checkIcon(theme.palette.icon.disabled),
        },
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.container.surface.default,
      '&:not(.Mui-disabled)': {
        border: 'none',
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
      cursor: 'not-allowed',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.icon.subdued,
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '& .MuiSwitch-track': {
    border: `1px solid ${theme.palette.container.border.default}`,
    borderRadius: 20,
    backgroundColor: theme.palette.container.surface.default,
    opacity: 1,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short * 2,
    }),
  },
  '.Mui-disabled > .MuiSwitch-thumb': {
    backgroundColor: theme.palette.container.border.disabled,
  },
  '.Mui-disabled + .MuiSwitch-track': {
    border: `1px solid ${theme.palette.container.border.disabled}`,
  },
  '&:hover': {
    '& :not(.Mui-disabled) .MuiSwitch-switchBase': {
      background: theme.palette.hover.surface.default,
    },
    '.Mui-checked:not(.Mui-disabled) > .MuiSwitch-thumb': {
      backgroundColor: '#f3f3f3',
    },
  },
}))

export default Toggle
