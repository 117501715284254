import React, { FC } from 'react'
import { LinkProps } from '@mui/material/Link'

import { StyledLink } from './styles'

export interface Props extends LinkProps {
  disabled?: boolean
  inverse?: boolean
}

const Link: FC<Props> = ({ disabled, ...props }) => <StyledLink disabled={disabled} {...props} role="link" />

export default Link
