import Highcharts from 'highcharts'

export default (options: Highcharts.Options): Highcharts.Options => ({
  ...options,
  series: options.series?.map((series) => ({
    ...series,
    ...(series.type === 'column' && {
      dataLabels: {
        enabled: true,
        useHTML: true,
        inside: true,
        formatter() {
          if (this.y !== 0) {
            return `<div style="height:6px; margin-top:3px;"></div>
            `
          }
        },
      },
    }),
  })),
})
