import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'
import { NELDebuggerTags } from '@services/request-tags'
import {
  EvalRequest,
  EvalResponse,
  EvalValue,
  ExpressionData,
  GetExpressionDataResponse,
  GetExpressionDataRequest,
  ReactFlowData,
  RenderableExpressionData,
  TextualisedExpressionData,
} from './types'

export const nelDebuggerApi = createApi({
  reducerPath: 'nelDebuggerApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/nel-debugger`,
  }),
  endpoints: (builder) => ({
    getRenderableExpression: builder.query<ReactFlowData, { expression: string }>({
      transformResponse: (response: RenderableExpressionData) => response.data,
      query: ({ expression }) => ({
        url: '/visualise',
        method: 'post',
        data: { expression },
        meta: { tag: NELDebuggerTags.GetRenderableExpression },
      }),
    }),
    getTextualisedExpression: builder.query<string, { body: ReactFlowData }>({
      transformResponse: (response: TextualisedExpressionData) => response.data.expression,
      query: ({ body }) => ({
        url: '/textualise',
        method: 'post',
        data: body,
        meta: { tag: NELDebuggerTags.GetTextualisedExpression },
      }),
    }),
    evalExpression: builder.query<EvalValue, { body: EvalRequest }>({
      transformResponse: (response: EvalResponse) => response.data,
      query: ({ body }) => ({
        url: '/eval',
        method: 'post',
        data: body,
        meta: { tag: NELDebuggerTags.EvalExpression },
      }),
    }),
    evalConstraintExpression: builder.query<EvalValue, { body: EvalRequest }>({
      transformResponse: (response: EvalResponse) => response.data,
      query: ({ body }) => ({
        url: '/eval-constraints',
        method: 'post',
        data: body,
        meta: { tag: NELDebuggerTags.EvalConstraintExpression },
      }),
    }),
    getExpressionData: builder.query<ExpressionData, { installation_id: string, body: GetExpressionDataRequest }>({
      transformResponse: (response: GetExpressionDataResponse) => response.data,
      query: ({ installation_id, body }) => ({
        url: `/installations/${installation_id}/data`,
        method: 'post',
        data: body,
        meta: { tag: NELDebuggerTags.GetExpressionData },

      }),
    }),
  }),
})

export const {
  useGetRenderableExpressionQuery,
  useLazyGetRenderableExpressionQuery,
  useEvalExpressionQuery,
  useLazyEvalExpressionQuery,
  useEvalConstraintExpressionQuery,
  useLazyEvalConstraintExpressionQuery,
  useGetExpressionDataQuery,
  useLazyGetExpressionDataQuery,
  useGetTextualisedExpressionQuery,
  useLazyGetTextualisedExpressionQuery,
  endpoints: {
    getRenderableExpression: { useQueryState: useGetRenderableExpressionQueryState },
    evalExpression: { useQueryState: useEvalExpressionQueryState },
    evalConstraintExpression: { useQueryState: useEvalConstraintExpressionQueryState },
    getExpressionData: { useQueryState: useGetExpressionDataQueryState },
    getTextualisedExpression: { useQueryState: useGetTextualisedExpressionQueryState },
  },
} = nelDebuggerApi
