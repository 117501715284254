import React, { FC } from 'react'
import Grid from '@phaidra/ava/grid'
import Dropdown from '@phaidra/ava/dropdown/basic'
import TreeSearch from '@common/tag-search/tree'
import ListSearch from '@common/tag-search/list'
import { Installation } from '@services/installation/types'
import { Component, PointNamesUsedInType } from '@services/tag-management/types'
import { useFormContext } from 'react-hook-form'
import { buildExpression } from '../utils'

interface NelExpressionQueryPanelProps {
  searchOptions: {
    all: string[]
    clientLive: string[]
    clientHistorical: string[]
    phaidraTag: string[]
  }
  components: Component[]
  enableFilterByDropdown?: boolean
  installation: Installation
  pointsUsedInMapping: PointNamesUsedInType['data']
  expressionFieldName: string
  filterByFieldName: string
}

const NelExpressionQueryPanel: FC<NelExpressionQueryPanelProps> = ({
  searchOptions,
  components,
  enableFilterByDropdown = true,
  installation,
  pointsUsedInMapping,
  expressionFieldName,
  filterByFieldName = 'filterBy',
}) => {
  const { watch, setValue } = useFormContext()
  const expression = watch(expressionFieldName, '')
  const filterBy = watch(filterByFieldName, 'all')

  const handleOnInsert = (selectedValue: string) => {
    if (!selectedValue) return
    const inputValue = expression ?? ''
    const cursorPosition = inputValue?.length
    const isPhaidraTag = filterBy === 'phaidraTag'
    const newExpression = buildExpression(selectedValue, inputValue, cursorPosition, isPhaidraTag)
    setValue(expressionFieldName, newExpression)
  }

  const handleOnCopy = (selectedValue: string) => {
    const isPhaidraTag = filterBy === 'phaidraTag'
    navigator.clipboard.writeText(
      isPhaidraTag ? `tag_value('${selectedValue}', 0)` : `sensor_value('${selectedValue}', 0)`,
    )
  }

  return (
    <Grid>
      <Grid mt="8px" display="flex">
        {enableFilterByDropdown && (
          <Grid width="250px">
            <Dropdown
              name={filterByFieldName}
              label="Filter By"
              clearable
              fullWidth
              options={[
                { label: 'LCS Point (live)', value: 'clientLive' },
                { label: 'LCS Point (historical)', value: 'clientHistorical' },
                { label: 'Phaidra Tag', value: 'phaidraTag' },
              ]}
            />
          </Grid>
        )}

        <Grid ml={enableFilterByDropdown ? '20px' : '0'} sx={{ flex: 1 }}>
          {filterBy === 'phaidraTag' && enableFilterByDropdown
            ? (
              <TreeSearch
                onChange={handleOnInsert}
                data={components}
                size="medium"
                placeholder="Search"
                handleOnCopy={handleOnCopy}
                installationId={installation?.id}
                installationName={installation?.name}
              />
            )
            : (
              <ListSearch
                onChange={handleOnInsert}
                options={searchOptions[filterBy as keyof typeof searchOptions || 'all'] as any}
                size="medium"
                placeholder="Search"
                handleOnCopy={handleOnCopy}
                installationId={installation?.id}
                pointNamesUsedInMapping={pointsUsedInMapping}
              />
            )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NelExpressionQueryPanel
