import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import { ComponentsTags } from '@services/request-tags'
import { Components, ComponentsData } from './types'

export const componentsApi = createApi({
  reducerPath: 'componentsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/components`,
  }),
  endpoints: (builder) => ({
    getComponents: builder.query<ComponentsData, { installationId: string }>({
      transformResponse: (response: Components) => response.data,
      query: (params) => ({
        url: '',
        method: 'get',
        params,
        meta: {
          tag: ComponentsTags.GetComponents,
        },
      }),
    }),
  }),
})

export const {
  useGetComponentsQuery,
  useLazyGetComponentsQuery,
  endpoints: {
    getComponents: { useQueryState: useGetComponentsQueryState },
  },
} = componentsApi
