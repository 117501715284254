import Highcharts from 'highcharts'
import { utcToLocal } from '@phaidra/utils/time'

interface BoxplotPoint extends Highcharts.Point {
  low: number
  q1: number
  median: number
  q3: number
  high: number
}

const getLineChartTooltipContent = (context: Highcharts.TooltipFormatterContextObject): string => {
  const point = context.point || context.points[0].point
  const date = Highcharts.dateFormat(
    // @ts-ignore
    point.series.tooltipOptions.xDateFormat,
    utcToLocal(Number(context.x)),
  )
  const labelName = point.series.name
  const labelValue = context.y

  return `
    <div style="font-weight: 400; font-size: 0.6rem; margin-bottom: 6px;">
      ${date}
    </div>
    <div>
      ${labelName}: <b>${labelValue}</b>
    </div>
  `
}

const getPieTooltipContent = (context: Highcharts.TooltipFormatterContextObject, title: string): string => `
    <div style="background-color: rgba(251, 251, 251, 1);">
      <div style="font-weight: 500; font-size: 0.75rem; margin-bottom: 6px;">${title}</div>
      <div style="display:flex;align-items:center;pointer-events:all;">
        <div
          style="
            width:6px;
            height:6px;
            border-radius:10px;
            background-color:${'color' in context.point && context.point.color};
            display:flex;
            align-items:center;
            justify-content:center;
          "
        ></div>
        <span style="padding-left:4px;">
          ${context.point.options.name}: <b>${context.point.percentage.toFixed(1)}%</b>
        </span>
      </div>
    </div>`

const getBoxplotTooltipContent = (context: Highcharts.TooltipFormatterContextObject): string => {
  const point = (context.point || context.points[0].point) as BoxplotPoint
  const title = (point.series.yAxis as any).axisTitle.textStr

  return `
    <div>
      <div style="font-weight: 500; font-size: 0.75rem; margin-bottom: 6px; color: #58585A;">${context.x}</div>
      <div style="display:flex;align-items:center;pointer-events:all;">
        <div
          style="
            width:6px;
            height:6px;
            border-radius:10px;
            background-color:${'color' in point && point.color};
            display:flex;
            align-items:center;
            justify-content:center;
          "
        ></div>
        <span style="padding-left:4px; font-weight: 500;">
          ${title}, ${point.series.name}
        </span>
      </div>
      <div style="margin-top: 6px;">
        Upper fence : <b style="font-weight: 500; font-size: 0.75rem;">
          ${point.high}
        </b>
      </div>
      <div style="margin-top: 6px;">
        Upper quartile (q3): <b style="font-weight: 500; font-size: 0.75rem;">
          ${point.q3}
        </b>
      </div>
      <div style="margin-top: 6px;">
        Median: <b style="font-weight: 500; font-size: 0.75rem;">
          ${point.median}
        </b>
      </div>
      <div style="margin-top: 6px;">
        Lower quartile (q1): <b style="font-weight: 500; font-size: 0.75rem;">
          ${point.q1}
        </b>
      </div>
      <div style="margin-top: 6px;">
        Lower fence: <b style="font-weight: 500; font-size: 0.75rem;">
          ${point.low}
        </b>
      </div>
    </div>
  `
}

const getDefaultTooltip = (context: Highcharts.TooltipFormatterContextObject, title: string): string => {
  if (context.point || context.points) {
    const chartType = context.point ? context.point.series.options.type : context.points[0].point.series.options.type

    if (chartType === 'line' || chartType === 'area') {
      return getLineChartTooltipContent(context)
    }

    if (chartType === 'pie') {
      return getPieTooltipContent(context, title)
    }
    if (chartType === 'boxplot') {
      return getBoxplotTooltipContent(context)
    }
  }
}

export default getDefaultTooltip
