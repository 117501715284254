import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const PhaidraLogo: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    width="26"
    height="6"
    viewBox="0 0 26 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="phaidra-logo"
  >
    <g clipPath="url(#clip0_2259_40454)">
      <path
        d="M17.0832 4.98601C18.5046 4.76478 19.4699 3.48062 19.2391 2.11774C19.0084
      0.754871 17.6691 -0.170618 16.2476 0.0506075C14.8261 0.271833 13.8609 1.556 14.0916
      2.91887C14.3223 4.28175 15.6617 5.20724 17.0832 4.98601Z"
        fill="#96E79C"
      />
      <path
        d="M23.2757 4.98577C24.6971 4.76454 25.6624 3.48037 25.4317 2.1175C25.2009
      0.754626 23.8616 -0.170862 22.4401 0.0503633C21.0186 0.271589 20.0534 1.55576 20.2841
      2.91863C20.5148 4.2815 21.8542 5.20699 23.2757 4.98577Z"
        fill="#96E79C"
      />
      <path
        d="M10.4727 0.019165H3.1665C3.16252 0.0191985 3.15857 0.0198604 3.15482
      0.0211243C3.12767 0.019165 3.10023 0.019165 3.07279 0.019165C2.38125 0.019165
      1.71803 0.282557 1.22904 0.751398C0.740045 1.22024 0.465332 1.85612 0.465332
      2.51917C0.465332 3.18221 0.740045 3.81809 1.22904 4.28693C1.71803 4.75577 2.38125
      5.01917 3.07279 5.01917C3.10023 5.01917 3.12767 5.01917 3.15482 5.01721C3.15857
      5.01847 3.16252 5.01913 3.1665 5.01917H10.4727C11.1637 5.01724 11.8257 4.75324 12.3143
      4.28481C12.8028 3.81639 13.0782 3.18162 13.0802 2.51917C13.0782 1.85671 12.8028 1.22194
      12.3143 0.753519C11.8257 0.285094 11.1637 0.021085 10.4727
      0.019165V0.019165ZM10.4727 4.08768H5.10136C5.47591 3.6438 5.68025 3.09012 5.68025 2.51917C5.68025
      1.94821 5.47591 1.39453 5.10136 0.950647H10.4249C11.2764 0.950647 12.0217 1.55522 12.1014
      2.36802C12.1231 2.5857 12.0971 2.80533 12.0252 3.01284C11.9532 3.22035 11.8369 3.41116
      11.6836 3.57305C11.5302 3.73494 11.3433 3.86434 11.1348 3.95296C10.9263 4.04158 10.7008
      4.08747 10.4727 4.08768Z"
        fill="#96E79C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2259_40454">
        <rect width="25" height="5" fill="white" transform="translate(0.465332 0.019165)" />
      </clipPath>
    </defs>

  </SvgIcon>
)

export default PhaidraLogo
