import { Highcharts } from '@phaidra/ava/chart'
import { utcToLocal } from '@phaidra/utils/time'

const TOOLTIP_DATE_FORMAT = '%b %e, %Y'
const TOOLTIP_FORMATTER = `${TOOLTIP_DATE_FORMAT}, %l:%M%P`
const getDate = (time: number, convertToLocalTime: boolean) => {
  const formattedTime = convertToLocalTime ? utcToLocal(time) : time

  return Highcharts.dateFormat(
    TOOLTIP_FORMATTER,
    formattedTime,
  )
}

export default getDate
