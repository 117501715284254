import styled from '@phaidra/ava/styled'
import { DragIcon } from '@phaidra/ava/icons'
import Grid from '@phaidra/ava/grid'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isTag',
})<{ isTag: boolean }>(({ isTag }) => (({ theme }) => ({
  display: 'flex',
  border: `solid 1px ${theme.palette.container.border.subdued}`,
  borderRadius: theme.shape.borderRadius,
  minWidth: '320px',
  width: 'inherit',
  minHeight: '46px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  backgroundColor: theme.palette.container.surface.default,
  overflow: 'hidden',
  ...(isTag && {
    '&:hover': {
      cursor: 'grab',
      borderColor: theme.palette.alert.text.success,
      backgroundColor: theme.palette.alert.surface.successLow,
    },
  }),
})))

export const HorizontalConnector = styled('div')(({ theme }) => ({
  height: '1px',
  width: '36px',
  backgroundColor: theme.palette.container.border.subdued,
  position: 'absolute',
  right: 0,
  zIndex: -1,
}))

export const VerticalConnector = styled('div')(({ theme }) => ({
  width: '1px',
  backgroundColor: theme.palette.container.border.subdued,
  marginLeft: theme.spacing(1.5),
  zIndex: -1,
}))

export const Collapser = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.container.surface.default,
  border: `solid 1px ${theme.palette.container.border.subdued}`,
  marginLeft: theme.spacing(0.625),
  marginRight: theme.spacing(3.75),
  height: '14px',
  width: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}))

export const DraggableIcon = styled(DragIcon)(({ theme }) => ({
  height: '14px',
  cursor: 'grab',
  fill: theme.palette.container.border.default,
  marginRight: theme.spacing(0.25),
  marginLeft: theme.spacing(-0.75),
}))

export const IdContainer = styled(Grid)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '12px',
  color: theme.palette.container.border.default,
  height: 12,
  marginBottom: theme.spacing(0.25),
}))

export const NameContainer = styled(Grid)({
  fontSize: '0.75rem',
  lineHeight: '16px',
  height: 16,
})
