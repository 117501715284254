import React, {
  forwardRef, MutableRefObject, useEffect, useRef, useState,
} from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-python'

import { type InputBaseComponentProps } from '@mui/material'
import {
  NelInputWrapper,
  StyledTextArea,
  TextAreaProps,
  StyledPre,
  StyledCode,
  NelInputWrapperProps,
} from './styles'

export interface Props extends Partial<TextAreaProps>, Partial<NelInputWrapperProps> {
  onChange?: (expression: string) => void
  name?: string
  dataTestId?: string
  value?: string
  placeholder?: string
  isSpellCheckEnabled?: boolean
  readOnly?: boolean
  inputProps?: InputBaseComponentProps
}

const NelInput = forwardRef<HTMLTextAreaElement, Props>(({
  dataTestId,
  value,
  name,
  onChange,
  placeholder,
  height,
  width,
  isError,
  isResizeable = false,
  isSpellCheckEnabled = false,
  inputProps,
  readOnly = false,
}, textAreaRef) => {
  const preRef = useRef<HTMLPreElement>()
  const codeRef = useRef<HTMLElement>()
  const [content, setContent] = useState(value)
  const [codeContent, setCodeContent] = useState(value)
  const [wrapperHeight, setWrapperHeight] = useState(height)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    setContent(value)
    setCodeContent(value)
  }, [value])

  useEffect(() => {
    Prism.highlightElement(codeRef.current)
  }, [codeContent])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (isResizeable && isDragging) {
        setWrapperHeight(`${(textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current.offsetHeight}px`)
      }
    })

    if ((textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current) {
      resizeObserver.observe((textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current)
    }

    return () => {
      if ((textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current) {
        resizeObserver.unobserve((textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current)
      }
    }
  }, [isDragging, isResizeable, textAreaRef])

  const syncScroll = () => {
    // Need to make sure that the textarea and pre
    // are always in sync to show the caret in the right place
    preRef.current.scrollTop = (textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current.scrollTop
    preRef.current.scrollLeft = (textAreaRef as MutableRefObject<HTMLTextAreaElement>)?.current.scrollLeft
  }

  const handleInput = (inputText: string) => {
    setContent(inputText)
    setCodeContent(inputText)
    // If the last character is a newline character
    // Add a placeholder space character to the final line
    if (inputText.at(-1) === '\n') {
      setCodeContent(`${inputText} `)
    }

    syncScroll()
  }

  return (
    <NelInputWrapper
      readOnly={readOnly}
      width={width}
      height={wrapperHeight}
      isError={isError || false}
      data-testid={dataTestId}
    >
      <StyledTextArea
        ref={textAreaRef}
        name={name}
        readOnly={readOnly}
        spellCheck={isSpellCheckEnabled}
        isResizeable={isResizeable}
        value={content}
        onChange={(event: { target: HTMLTextAreaElement }) => {
          handleInput((event.target as HTMLTextAreaElement).value)
          onChange((event.target as HTMLTextAreaElement).value)
        }}
        onScroll={syncScroll}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        placeholder={placeholder ?? 'Enter NEL expression'}
        {...inputProps}
      />
      <StyledPre ref={preRef}>
        <StyledCode data-testid="nel-code" className="language-python" ref={codeRef}>{codeContent}</StyledCode>
      </StyledPre>
    </NelInputWrapper>
  )
})

export default NelInput
