import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import {
  HealthRulesRequestParams,
  HealthRulesResponse,
  SingleHealthRuleRequestParams,
  SingleHealthRuleResponse,
} from './types'

const tags = {
  GET_HEALTH_RULES: 'GetHealthRules',
  GET_SINGLE_HEALTH_RULE: 'GetSingleHealthRule',
}

export const healthRulesApi = createApi({
  reducerPath: 'healthRulesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/health-rules`,
  }),
  tagTypes: [tags.GET_HEALTH_RULES, tags.GET_SINGLE_HEALTH_RULE],
  endpoints: (builder) => ({

    getHealthRules: builder.query<HealthRulesResponse, HealthRulesRequestParams>({
      query: ({ installationId }) => ({
        url: `/${installationId}/results`,
        method: 'get',
      }),
      providesTags: () => [{ type: tags.GET_HEALTH_RULES }],

    }),
    getHealthRuleDetails: builder.query<SingleHealthRuleResponse, SingleHealthRuleRequestParams>({
      query: ({ installationId, healthRuleId }) => ({
        url: `/${installationId}/${healthRuleId}`,
        method: 'get',
      }),
      providesTags: () => [{ type: tags.GET_SINGLE_HEALTH_RULE }],
    }),
  }),
})

export const {
  useLazyGetHealthRulesQuery,
  useLazyGetHealthRuleDetailsQuery,
} = healthRulesApi
