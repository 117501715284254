import styled from '@phaidra/ava/styled'
import Button from '@phaidra/ava/button'
import AccordionSummary from '@phaidra/ava/accordion-summary'
import IconButton from '@phaidra/ava/icon-button'

export const Container = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
})

export const TabTitle = styled('div')<{ active?: boolean }>(({ active, theme }) => ({
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  fontSize: '0.875rem',
  color: !active ? theme.palette.text.default : theme.palette.text.active,
  fontWeight: !active ? 400 : 500,
  '&:hover': {
    color: theme.palette.text.active,
    textDecoration: 'underline',
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.container.surface.default,
  textAlign: 'left',
  paddingLeft: theme.spacing(2.5),
  paddingTop: theme.spacing(2),
  '& span': {
    width: '100%',
  },
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  margin: `0 ${theme.spacing(2.5)}`,
  height: '20px',
  minHeight: '20px',
  marginTop: theme.spacing(2),
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(-0.625),
  marginRight: theme.spacing(1.25),
  width: '24px',
  height: '24px',
}))
