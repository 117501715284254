import styled from '../../styled'
import Grid, { GridProps } from '../../grid'
import IconButton from '../../icon-button'

export const Container = styled(Grid, {
  shouldForwardProp: (prop: string) => !['fullWidth', 'isThin', 'noBorder'].includes(prop),
})<GridProps & { fullWidth: boolean, isThin?: boolean, noBorder: boolean }>(({
  theme, fullWidth, isThin, noBorder,
}) => ({
  height: !isThin ? '44px' : '28px',
  border: noBorder ? 'none' : 'solid 1px #58585A',
  borderRadius: '4px',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  ...(!fullWidth && { minWidth: '300px' }),
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? '#86D98C' : '#155E2A',
    backgroundColor: theme.palette.mode === 'dark' ? '#00390E' : '#F4FFF1',
  },
}))

export const Input = styled('input', {
  shouldForwardProp: (prop) => prop !== 'isThin',
})<{ isThin?: boolean, value: string | number }>(({
  theme, isThin, value,
}) => ({
  backgroundColor: 'inherit',
  border: 'none',
  padding: !isThin ? '12px' : '6px',
  paddingRight: !isThin ? '12px' : '4px',
  paddingLeft: !isThin ? '12px' : '12px',
  flex: 1,
  fontSize: '0.875rem',
  color: theme.palette.text.default,
  caretColor: theme.palette.mode === 'dark' ? '#86D98C' : '#155E2A',
  ...(isThin && value && {
    marginTop: '-2px',
  }),
  '&:focus': {
    outline: 'none',
  },
  '::placeholder': {
    color: theme.palette.mode === 'dark' ? '#D4D4D5' : '#58585A',
  },
}))

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'value' && prop !== 'isThin',
})<{ isThin?: boolean, value: string | number }>(({ isThin, value }) => ({
  marginRight: '8px',
  ...(isThin && value && {
    marginTop: '-5px',
  }),
}))
