import { FC } from 'react'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { StandardLonghandProperties, StandardShorthandProperties } from 'csstype'
import { Props } from './types'
import styled from '../styled'

// ############# https://app.shortcut.com/phaidra/story/88520 ############# //
//                                                                          //
// Remove use of `styled` within this Ava component to allow `styled` to be //
// used to customise this component when consuming this in our applications //

export const StyledButton: FC<Props> = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'isWithinButtonGroup',
})<Props & { isWithinButtonGroup?: boolean }>(({
  theme,
  color,
  variant,
  size,
  children,
  isWithinButtonGroup,
  disabled,
}) => {
  type ButtonStyle = Partial<Record<typeof variant, StandardLonghandProperties & StandardShorthandProperties>>

  const hoverColor: Partial<Record<LoadingButtonProps['color'], string>> = {
    default: '#313330',
    error: '#B21B1B',
  }

  const mainColor: Partial<Record<LoadingButtonProps['color'], string>> = {
    default: theme.palette.container.surface.activeHigh,
    error: theme.palette.container.surface.destructive,
  }

  const hover = {
    contained: {
      backgroundColor: hoverColor[color],
    },
    outlined: {
      border: `1px solid ${theme.palette.container.border.secondary}`,
      backgroundColor: theme.palette.hover.surface.default,
    },
    text: {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  }

  const main: ButtonStyle = {
    outlined: {
      border: `1px solid ${theme.palette.container.border.secondary}`,
      color: theme.palette.text.active,
    },
    text: {
      color: theme.palette.text.link,
    },
    contained: {
      backgroundColor: mainColor[color],
      color: theme.palette.icon.defaultInverse,
    },
  }

  const minHeight: Record<Props['size'], string> = {
    medium: '44px',
    small: '28px',
  }

  const splitButtonMenuListStyle = (
    isWithinButtonGroup
    && !disabled
    && variant === 'contained'
  ) ? {
      borderRight: '1px solid',
      borderRightColor: `${theme.palette.dividers.default} !important`,
    } : {}

  return {
    ...(!children ? {
      '& .MuiButton-startIcon': {
        marginRight: '-4px',
      },
      '& .MuiButton-endIcon': {
        marginLeft: '-4px',
      },
      '&.MuiLoadingButton-root': {
        minWidth: 0,
        minHeight: minHeight[size],
        fontSize: '0.875rem',
        lineHeight: '18px',
      },
    } : {
      '&.MuiLoadingButton-root': {
        boxShadow: 'none',
        minHeight: minHeight[size],
        fontSize: '0.875rem',
        lineHeight: '18px',
      },
      '& .MuiButton-startIcon': {
        marginRight: '4px',
      },
      '& .MuiButton-endIcon': {
        marginLeft: '4px',
      },
    }),
    '&.Mui-disabled': {
      border: 'none',
      ...(variant !== 'text' && {
        backgroundColor: theme.palette.container.surface.disabled,
      }),
      color: theme.palette.text.disabled,
      pointerEvents: 'all',
      cursor: 'not-allowed',
      '& .MuiCircularProgress-svg': {
        color: 'white',
      },
      ...((variant === 'text' && disabled) && {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }),
    },
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    minWidth: '96px',
    fontWeight: 500,
    ...main[variant],
    ...splitButtonMenuListStyle,
    '&:hover:not(.Mui-disabled)': {
      ...hover[variant],
    },
  }
})
