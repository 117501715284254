import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LinkIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 10" data-testid="link">
    <path
      d={'M8.79995 9.54922H4.99995C3.74995 9.54922 2.67928 9.10355 1.78795 8.21222C0.895951 7.32022 0.449951 \
      6.24922 0.449951 4.99922C0.449951 3.74922 0.895951 2.67822 1.78795 1.78622C2.67928 0.894885 3.74995 0.449219 \
      4.99995 0.449219H8.79995V1.94922H4.99995C4.16662 1.94922 3.44995 2.24922 2.84995 2.84922C2.24995 3.44922 \
      1.94995 4.16589 1.94995 4.99922C1.94995 5.83255 2.24995 6.54922 2.84995 7.14922C3.44995 7.74922 4.16662 \
      8.04922 4.99995 8.04922H8.79995V9.54922ZM6.24995 5.74922V4.24922H13.75V5.74922H6.24995ZM11.2 \
      9.54922V8.04922H15C15.8333 8.04922 16.5499 7.74922 17.15 7.14922C17.75 6.54922 18.0499 5.83255 18.0499 \
      4.99922C18.0499 4.16589 17.75 3.44922 17.15 2.84922C16.5499 2.24922 15.8333 1.94922 15 \
      1.94922H11.2V0.449219H15C16.25 0.449219 17.3206 0.894885 18.212 1.78622C19.104 2.67822 19.5499 3.74922 \
      19.5499 4.99922C19.5499 6.24922 19.104 7.32022 18.212 8.21222C17.3206 9.10355 16.25 9.54922 15 9.54922H11.2Z'}
    />
  </SvgIcon>
)

export default LinkIcon
