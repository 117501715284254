import styled from '@phaidra/ava/styled'
import { DragIcon } from '@phaidra/ava/icons'
import Typography from '@phaidra/ava/typography'

export const Container = styled('div')<{ highlightTag: boolean }>(({ highlightTag, theme }) => ({
  display: 'flex',
  width: '216px',
  height: '36px',
  borderRadius: theme.shape.borderRadius * 1.5,
  marginTop: '4px',
  paddingRight: '4px',
  backgroundColor: theme.palette.container.surface.drawer,
  border: `solid 1px ${theme.palette.container.surface.low}`,
  overflow: 'hidden',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  ...(highlightTag && {
    borderColor: theme.palette.container.border.default,
    backgroundColor: theme.palette.hover.surface.default,
  }),
}))

export const DraggableIcon = styled(DragIcon)(({ theme }) => ({
  height: '14px',
  cursor: 'grab',
  fill: theme.palette.dividers.low,
  marginRight: theme.spacing(0.25),
  marginLeft: theme.spacing(-0.125),
}))

export const Component = styled(Typography)({
  flex: 1,
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const Name = styled(Typography)({
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
