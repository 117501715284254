export const IngestionStatus = {
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
} as const

export const IngestionStatusLabels = {
  NEW_DATA: 'New data',
  PROCESSING: 'In progress',
  ACTIVE: 'Active',
  FAILED: 'Failed',
  OUTDATED_DATA: 'Outdated data',
} as const

export interface IngestionTimestamp {
  timestamp: string
  gcs_folder_path: string
}

export interface Ingestion {
  id: number
  timestamp?: string
  formattedTimestamp?: string
  gcs_folder_path: string
  status?: keyof typeof IngestionStatus
  status_label?: typeof IngestionStatusLabels[keyof typeof IngestionStatusLabels]
  hasIngestionStarted?: boolean
  ingest?: () => Promise<void>
  duplicateIngestion?: () => void
  viewMetadata?: () => void
  status_message?: string
  started_at: string
  formattedStartedAt?: string
  updated_at?: string
  formattedUpdatedAt?: string
}

export interface RawDataSummary {
  pointName: string
  startTimestamp: number
  endTimestamp: number
  numberOfRecords: number
  numberOfDuplicates?: number
  duplicateStartTimestamp?: number | null
  status: 'ERROR' | 'OK'
}

export interface Metadata {
  data: RawDataSummary[]
}
