import React from 'react'
import Typography from '@phaidra/ava/typography'
import Grid from '@phaidra/ava/grid'
import { SBR, Setpoint } from '@services/setpoints/types'
import { Section } from '@common/drawer'

import {
  ExpressionField, NumberField, SetpointNotes, StringField,
} from '../setpoint-form-fields'
import { CaptionText } from '../styles'

const getSetpointPropertyFormField = (setpoint: Setpoint, param: SBR, fieldName: string): Section => {
  const {
    inputType, name, status,
  } = param
  const renderers = {
    expression: {
      id: `${setpoint.id}-${fieldName}`,
      title: name,
      status,
      content: () => <ExpressionField setpoint={setpoint} param={param} fieldName={fieldName} />,
    },
    string: {
      id: `${setpoint.id}-${fieldName}`,
      title: name,
      status,
      content: () => <StringField setpoint={setpoint} param={param} fieldName={fieldName} />,
    },
    timespan: {
      id: `${setpoint.id}-${fieldName}`,
      title: name,
      status,
      content: () => (
        <>Modifying this field is not yet avialable</>
      ),
    },
    float: {
      id: `${setpoint.id}-${fieldName}`,
      title: name,
      status,
      content: () => <NumberField setpoint={setpoint} param={param} fieldName={fieldName} />,
    },
    notes: {
      id: `${setpoint.id}-${fieldName}`,
      title: (
        <Grid container rowSpacing="4px" direction="column">
          <Grid item>
            <CaptionText>
              {setpoint?.fullSetpointName}
            </CaptionText>
          </Grid>
          <Grid item>
            <Typography fontWeight={700} variant="h3" sx={{ height: '26px' }}>
              {setpoint?.name}
            </Typography>
          </Grid>

        </Grid>
      ),
      status: null as any,
      content: () => <SetpointNotes setpoint={setpoint} fieldName={fieldName} />,
    },
  }

  return renderers[inputType]
}

export default getSetpointPropertyFormField
