import React, {
  FC,
  useEffect,
  useState,
  ReactElement,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import PageSpinner from '@common/page-loader'
import axios from 'axios'
import LogRocket from 'logrocket'

const AuthHelper: FC<{ children: ReactElement }> = ({ children }) => {
  const [showLoading, setShowLoading] = useState(true)
  const {
    getAccessTokenSilently,
    user,
    isAuthenticated,
    isLoading,
    logout,
  } = useAuth0()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    async function getToken() {
      const accessToken = await getAccessTokenSilently()

      axios.interceptors.request.use(
        (config) => ({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        }),
      )

      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            logout({ returnTo: `${window.location.origin}/login` })
          }
          return Promise.reject(error)
        },
      )

      setShowLoading(false)
    }

    if (!isLoading) {
      if (isAuthenticated) {
        getToken()
        if (user) {
          const redirect = localStorage.getItem('originalURL')
          if (redirect) {
            localStorage.removeItem('originalURL')
            navigate(redirect)
          }
          LogRocket.identify(user.email, {
            name: user.name,
            email: user.email,
            environment: process.env.TARGET_ENV,
            org: user.organizationDisplayName,
          })
        }
      } else {
        if (pathname !== '/login') {
          localStorage.setItem('originalURL', pathname + search)
          navigate('/login')
        }
        setShowLoading(false)
      }
    }
  }, [isLoading, isAuthenticated, navigate, user, getAccessTokenSilently, pathname, search, logout])

  useEffect(() => {
    if (isLoading) {
      setShowLoading(true)
    }
  }, [isLoading])

  if (showLoading) {
    return <PageSpinner />
  }

  return children
}

export default AuthHelper
