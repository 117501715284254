import React, {
  FC,
} from 'react'

import Grid from '../../../grid'
import { ColumnTitle, Subtitle } from './styles'

interface Props {
  title?: string
  subtitle?: string
}

const ColumnHeader: FC<Props> = ({ title, subtitle }) => (
  <Grid container direction="column">
    {subtitle && (
      <Subtitle data-testid="subtitle" sx={{ color: 'grey.600' }} variant="caption">{subtitle}</Subtitle>
    )}
    <ColumnTitle style={{ width: 'inherit' }}>
      {title}
    </ColumnTitle>
  </Grid>
)

export default ColumnHeader
