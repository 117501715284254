import { useSnackbar } from '@phaidra/ava/snackbar'
import { useEffect } from 'react'
import { useVersionCheckerEnabled } from '../darkly-feature-flags/hooks'

export const useCheckForNewVersion = (site = 'Portal') => {
  const { enqueueSnackbar } = useSnackbar()
  const isVersionCheckerEnabled = useVersionCheckerEnabled()
  const isProduction = process.env.NODE_ENV === 'production'

  useEffect(() => {
    if (!isVersionCheckerEnabled || !isProduction) return
    const checkForNewVersion = async () => {
      try {
        const response = await fetch('/version.txt', { cache: 'no-cache' })
        const responseData = response.ok ? await response.text() : ''
        const lastKnownVersion = sessionStorage.getItem('lastKnownVersion') || ''
        const mostRecentAppVersion = responseData.trim()
        if (!mostRecentAppVersion) {
          return
        }

        const isNewVersion = mostRecentAppVersion !== lastKnownVersion && lastKnownVersion !== ''
        if (isNewVersion) {
          enqueueSnackbar(`A new version of ${site} is available. Update to see the latest.`, {
            variant: 'info',
            persist: true,
            hideIconVariant: false,
            actionButtons: [
              {
                label: 'Update',
                onClick: () => {
                  window.location.reload()
                },
              },
            ],
            onClose: () => {
              sessionStorage.setItem('lastKnownVersion', mostRecentAppVersion)
            },
          })
        }

        if (!lastKnownVersion) {
          sessionStorage.setItem('lastKnownVersion', mostRecentAppVersion)
        }
      } catch (error) { /* no error handling needed */ }
    }

    checkForNewVersion()
    const interval = setInterval(checkForNewVersion, 60_000)
    return () => clearInterval(interval)
  }, [enqueueSnackbar, isProduction, isVersionCheckerEnabled, site])
}
