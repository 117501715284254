export default (searchTerm: string): Array<string> => {
  const recentSearches: Array<string> = JSON.parse(localStorage.getItem('recentSearches')) || []
  let updatedSearches = recentSearches
  if (searchTerm) {
    updatedSearches = Array.from(recentSearches?.length === 10
      ? new Set([searchTerm, ...recentSearches.slice(0, 9)])
      : new Set([searchTerm, ...recentSearches]))
  }
  localStorage.setItem('recentSearches', JSON.stringify(updatedSearches))
  return updatedSearches
}
