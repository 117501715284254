import { AxiosError } from 'axios'
import {
  type AxiosRequestConfig,
  type GetAxiosResponseErrorHandler,
  type ServerError,
  type ServiceMessage,
} from '../../types'

export const formatErrorMessage = (
  serviceError: ServiceMessage,
  errorMessages: Record<string, string>,
): string | null => {
  if (!navigator.onLine) return 'You appear to be offline'
  if (serviceError?.status_code === 422) return null

  const customMessage = errorMessages[serviceError.service]

  if (customMessage === null) return customMessage

  return customMessage ?? serviceError.description ?? errorMessages.default
}

export const extractErrorDetails = (
  error: ServerError,
  service?: string,
  status_code?: number,
): ServiceMessage | null => {
  if (error?.error_code) {
    return {
      code: error.error_code,
      description: error.error_message,
      service,
      status_code,
    }
  }

  if (typeof error?.data === 'string') {
    return {
      code: undefined,
      description: error.data,
      service,
      status_code,
    }
  }

  if (typeof error?.data === 'object' && 'error_code' in error.data) {
    return {
      code: error.data.error_code,
      description: error.data.error_message,
      service,
      status_code,
    }
  }

  return { status_code, service }
}

const getAxiosResponseErrorHandler = ({
  enqueueSnackbar, errorMessages,
}: GetAxiosResponseErrorHandler) => (error: AxiosError<ServerError>): Promise<never> => {
  const { response: { data, status } = {}, config } = error
  const requestTag = (config as AxiosRequestConfig)?.meta?.tag
  const responseError = extractErrorDetails(data, requestTag, status)
  const message = formatErrorMessage(responseError, errorMessages)
  const noResponseReceived = !error.response && error.request
  if (noResponseReceived) {
    // TODO: Log to Faro and Logrocket
  }

  if (message) {
    enqueueSnackbar(message, { variant: 'error' })
  }

  return Promise.reject(error)
}

export default getAxiosResponseErrorHandler
