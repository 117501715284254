import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import {
  ConstraintsResponse, Constraints, LabelsResponse, ConstraintType, ConstraintResponseType, ConstraintPostRequestType,
} from './types'
import { transformAllConstraints, transformConstraint } from './utils/transform-constraint-response'

const tags = {
  GetConstraints: 'GetConstraints',
  GetLabels: 'GetLabels',
}

export const constraintsApi = createApi({
  reducerPath: 'constraintsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/installations`,
  }),
  tagTypes: [
    tags.GetLabels,
    tags.GetConstraints,
  ],
  endpoints: (builder) => ({
    getConstraints: builder.query<Constraints, {
      installationId: string
    }>({
      providesTags: () => [{ type: tags.GetConstraints }],
      transformResponse: (response: ConstraintsResponse) => transformAllConstraints(response.constraints),
      query: ({ installationId }) => ({
        url: `/${installationId}/constraint`,
        method: 'get',
      }),
    }),
    getLabels: builder.query<LabelsResponse['labels'], { installationId: string }>({
      providesTags: () => [{ type: tags.GetLabels }],
      transformResponse: (response: LabelsResponse) => response.labels,
      query: ({ installationId }) => ({
        url: `/${installationId}/constraint/labels`,
        method: 'get',
      }),
    }),
    createLabel: builder.mutation<LabelsResponse['labels'], {
      installationId: string
      label: { name: string, color: string }
    }>({
      transformResponse: (response: LabelsResponse) => response.labels,
      query: ({ installationId, label }) => ({
        url: `/${installationId}/constraint/labels`,
        method: 'post',
        data: label,
      }),
      onQueryStarted: (args, api) => {
        api.queryFulfilled.then((response) => {
          api.dispatch(
            constraintsApi.util.updateQueryData(
              'getLabels',
              { installationId: args.installationId },
              () => (response.data),
            ),
          )
        }).catch(() => {})
      },
    }),
    updateConstraint: builder.mutation<ConstraintsResponse['constraints'], {
      installationId: string
      constraintId: string
      body: Partial<ConstraintType>
    }>({
      transformResponse: (response: ConstraintsResponse) => response.constraints,
      query: ({ installationId, constraintId, body }) => ({
        url: `/${installationId}/constraint/${constraintId}`,
        method: 'patch',
        data: body,
      }),
      invalidatesTags: (_, error) => !error && [{ type: tags.GetConstraints }],
    }),
    deleteLabel: builder.mutation<LabelsResponse['labels'], {
      installationId: string
      labelId: string | number
    }>({
      transformResponse: (response: LabelsResponse) => response.labels,
      query: ({ installationId, labelId }) => ({
        url: `/${installationId}/constraint/labels/${labelId}`,
        method: 'delete',
      }),
      onQueryStarted: (args, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            constraintsApi.util.updateQueryData(
              'getLabels',
              { installationId: args.installationId },
              (draft) => (draft.filter((label) => label.id !== args.labelId)),
            ),
          )
        }).catch(() => {})
      },
    }),
    addConstraint: builder.mutation<ConstraintType, {
      installationId: string
      constraint: ConstraintPostRequestType
    }>({
      invalidatesTags: (_, error) => !error && ['Constraints'],
      transformResponse: (response: ConstraintResponseType) => transformConstraint(response),
      query: ({ installationId, constraint }) => ({
        url: `/${installationId}/constraint`,
        method: 'post',
        data: constraint,
      }),
    }),
  }),
})

export const {
  useGetConstraintsQuery,
  useGetLabelsQuery,
  useCreateLabelMutation,
  useDeleteLabelMutation,
  useUpdateConstraintMutation,
  useAddConstraintMutation,
  endpoints: {
    getConstraints: { useQueryState: useGetConstraintsQueryState },
    getLabels: { useQueryState: useGetLabelsQueryState },
  },
} = constraintsApi
