import React, { useContext } from 'react'

import Banner from '@phaidra/ava/banner'
import { NotificationsContext } from '@providers/notification'

const NotificationBanner = () => {
  const { notifications, removeNotification } = useContext(NotificationsContext)
  if (!notifications || notifications.length === 0) return null

  return (
    <Banner
      data={notifications}
      onDismiss={(index) => {
        removeNotification(notifications[index].id)
      }}
    />
  )
}

export default NotificationBanner
