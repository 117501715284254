import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const KeyboardArrowRightIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="-1 0 21 20" data-testid="arrow-icon-right">
    <path
      d="M13.7461 10L7.71997 16L6.79102 15.0443L11.8347 10L6.79102 4.95572L7.74673 4L13.7461 10Z"
    />
  </SvgIcon>
)

export default KeyboardArrowRightIcon
