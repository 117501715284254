import Typography from '../typography'
import styled from '../styled'

export const StyledTabPanel = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['noPadding', 'minHeight', 'flatBottom', 'variant'].includes(prop),
})<{
  noPadding: boolean
  height: string
  minHeight: string
  flatBottom?: boolean
  flex?: boolean
  variant?: 'noBorder'
}>(({
  noPadding,
  minHeight,
  height,
  flatBottom,
  flex,
  variant,
  theme,
}) => ({
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.dividers.low}`,
  borderRadius: theme.shape.borderRadius * 2,
  borderTopLeftRadius: 0,
  marginTop: theme.spacing(-0.125),
  overflow: 'auto',
  padding: theme.spacing(3),
  minHeight,
  height,
  flexDirection: 'column',
  width: '100%',
  ...(noPadding && { padding: 0 }),
  ...(flatBottom && { borderBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }),
  ...(flex && { flex: 1 }),
  ...(variant === 'noBorder' && { border: 'none', padding: 0, borderRadius: 0 }),
}))

export const EllipsisText = styled(Typography)({
  fontSize: '0.75rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
})

export const RightComponentContainer = styled('div')<{ noBorder: boolean }>(({ noBorder, theme }) => ({
  minWidth: 'max-content',
  marginLeft: noBorder ? 0 : theme.spacing(2.5),
  ...(noBorder && {
    borderBottom: `1px solid ${theme.palette.dividers.low}`,
    justifyContent: 'flex-end',
  }),
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}))
