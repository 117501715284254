import React, { FC, ReactNode } from 'react'
import {
  Theme as MUITheme,
  ThemeProvider as MuiThemeProvider,
  useTheme as MuiUseTheme,
  SxProps as MUISxProps,
} from '@mui/material/styles'
import 'typeface-roboto'

import lightTheme from './themes/light'
import darkTheme from './themes/dark'

export type SxProps<T extends object> = MUISxProps<T>
export type Theme = MUITheme
export type ThemeType = 'light' | 'dark'

interface ThemeProviderProps {
  theme: ThemeType
  children: ReactNode
}

const themes: Record<ThemeType, Theme> = {
  light: lightTheme,
  dark: darkTheme,
}

export const useTheme = () => MuiUseTheme<Theme>()

const ThemeProvider: FC<ThemeProviderProps> = ({ theme, children }) => (
  <MuiThemeProvider theme={themes[theme]}>
    {children}
  </MuiThemeProvider>
)

export default ThemeProvider
