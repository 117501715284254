import {
  PointNamesType,
  PointName,
  TagRow,
  TagsData,
  TagsInUseData,
  TagStatus,
  PointNamesUsedInType,
  TagsUsedInArray,
} from '@services/tag-management/types'
import getKvPair from '@phaidra/utils/get-kv-pair'

export type TransformTagOptions = {
  tagSearchTerm?: string
  showInUse?: boolean
}

type UsedIn = { componentName: string, fullTagName: string }

export const createTagsUsageMap = (tags: TagsInUseData['data']) => {
  const latestUsedInMap = new Map<string, UsedIn[]>()

  if (!tags) return latestUsedInMap

  Object.entries(tags).forEach(([key, value]) => {
    latestUsedInMap.set(key, value)
  })

  return latestUsedInMap
}

export const getTagsInUseMap = (
  allTagsInUseData: TagsInUseData['data'],
): Record<string, TagsUsedInArray> => {
  // Create tags usage map
  const tagsInUse = createTagsUsageMap(allTagsInUseData)

  // Filter and map the tags
  const filteredTags = Array.from(tagsInUse.values()).filter((usedIn: UsedIn[]) => usedIn.length > 0)
  const tagsInUseMap = filteredTags.map((tags: UsedIn[]) => ({
    key: tags[0].fullTagName,
    value: tags,
  }))

  return getKvPair(tagsInUseMap)
}

export const combineComponentTags = (originalTags: TagsData['data']): TagRow[] => {
  const combinedTags: TagRow[] = []
  originalTags?.components?.forEach((component) => {
    combinedTags.push(...component.tags)
  })

  return combinedTags
}

export const naturalSort = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })

const excludedTagStatuses: TagStatus[] = ['NOT_REQUIRED', 'DOES_NOT_EXIST']

export const transformAndFilterTags = (
  tags: TagRow[],
  tagsInUseMapping: PointNamesUsedInType['data'],
  options: TransformTagOptions,
): TagRow[] => tags
  .filter((tag) => !excludedTagStatuses.includes(tag.tagStatus))
  .filter((tag) => tag.name.toLowerCase().includes(options.tagSearchTerm.toLowerCase())
    || tag.fullTagName.toLowerCase().includes(options.tagSearchTerm.toLowerCase()))
  .filter((tag) => (tagsInUseMapping?.[tag.fullTagName]?.length ? options.showInUse : true))
  .sort((a, b) => naturalSort(a.name, b.name))

export const transformAndFilterPointNames = (
  pointNames: PointName[],
  pointNamesUsedInMapping: PointNamesUsedInType['data'],
  options: TransformTagOptions,
): PointName[] => pointNames
  .filter((pointName) => pointName.name.toLowerCase().includes(options.tagSearchTerm.toLowerCase()))
  .filter((pointName) => {
    const usedInData = pointNamesUsedInMapping?.[pointName.name] ?? []
    return usedInData.length ? options.showInUse : true
  })
  .sort((a, b) => naturalSort(a.name, b.name))

export const getFilteredTagData = (
  tagsResponse: TagsData['data'],
  tagsInUse: TagsInUseData['data'],
  pointNames: PointNamesType['data'],
  pointNamesUsedInMapping: PointNamesUsedInType['data'],
  options: TransformTagOptions,
) => {
  const livePointNames = pointNames?.livePointNames ?? []
  const historicalPointNames = pointNames?.historicalPointNames ?? []
  const phaidraTags = combineComponentTags(tagsResponse)

  const tagsInUseMapping = tagsInUse as Record<string, TagsUsedInArray>

  return {
    all: [
      ...transformAndFilterTags(phaidraTags, tagsInUseMapping, options),
      ...transformAndFilterPointNames(livePointNames, pointNamesUsedInMapping, options),
      ...transformAndFilterPointNames(historicalPointNames, pointNamesUsedInMapping, options),
    ].sort((a, b) => naturalSort(a.name, b.name)),
    clientLive: transformAndFilterPointNames(livePointNames, pointNamesUsedInMapping, options),
    clientHistorical: transformAndFilterPointNames(historicalPointNames, pointNamesUsedInMapping, options),
    phaidraTag: transformAndFilterTags(phaidraTags, tagsInUseMapping, options),
  }
}

export const getTagProcessingGrafanaLink = (installationId: string) => {
  const GRAFANA_URL = new URL('https://phaidra.grafana.net/d/bddzs0ehn785cb/ray-tag-processing')

  const queryParams = new URLSearchParams()
  queryParams.set('from', 'now-1h')
  queryParams.set('to', 'now')
  queryParams.set('var-installation_id', installationId)
  queryParams.set('var-project', `phaidra-foundation-${process.env.GRAFANA_ENV}`)

  GRAFANA_URL.search = queryParams.toString()

  return GRAFANA_URL.toString()
}
