import React, {
  FC, useState, useEffect, PropsWithChildren, MutableRefObject,
} from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { Window, FadeTop, FadeBottom } from './styles'

interface Props {
  flex?: number
  height?: string
  minHeight?: string
  childRef?: MutableRefObject<any>
  hideTopFade?: boolean
}

const FadeScroller: FC<PropsWithChildren<Props>> = ({
  children, childRef, hideTopFade, ...props
}) => {
  const [showTopFade, setShowTopFade] = useState(false)
  const [showBottomFade, setShowBottomFade] = useState(false)
  const { ref } = useResizeDetector({
    onResize: () => {
      setShowBottomFade(ref.current.scrollHeight > ref.current.clientHeight)
      if (!hideTopFade) {
        setShowTopFade(ref.current.scrollTop > 0)
      }
    },
  })

  useEffect(() => {
    if (childRef?.current) {
      setTimeout(() => {
        if (childRef?.current?.props) {
          setShowBottomFade(
            childRef.current.props.height < childRef.current.props.itemCount * childRef.current.props.itemSize,
          )
        }
      }, 0)

      // eslint-disable-next-line no-underscore-dangle
      childRef.current._outerRef?.addEventListener('scroll', (event: UIEvent) => {
        if (!hideTopFade) {
          setShowTopFade((event.currentTarget as HTMLInputElement).scrollTop > 0)
        }
        setShowBottomFade(
          Math.round((event.currentTarget as HTMLInputElement).scrollTop)
          !== ((event.currentTarget as HTMLInputElement).scrollHeight
          - (event.currentTarget as HTMLInputElement).clientHeight),
        )
      })
    }
  }, [childRef, hideTopFade])

  return (
    <div style={{ position: 'relative', ...props }}>
      {childRef ? (
        children
      ) : (
        <Window
          ref={ref}
          onScroll={(event: React.UIEvent<HTMLDivElement>) => {
            const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
            if (!hideTopFade) {
              setShowTopFade(scrollTop > 0)
            }
            setShowBottomFade(scrollHeight - scrollTop > clientHeight)
          }}
        >
          {children}
        </Window>
      )}
      <FadeTop style={{ opacity: showTopFade ? 1 : 0 }} />
      <FadeBottom style={{ opacity: showBottomFade ? 1 : 0 }} />
    </div>
  )
}

export default FadeScroller
