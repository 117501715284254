import React, { forwardRef } from 'react'
import { SnackbarContent } from 'notistack'

import { TemplateBar, ExtendedCustomContentProps } from './template-bar'

const InfoBar = forwardRef<HTMLDivElement, ExtendedCustomContentProps>(({
  id,
  message,
  variant,
  hideIconVariant,
  actionButtons,
}, ref) => (
  <SnackbarContent
    ref={ref}
  >
    <TemplateBar
      id={id}
      message={message}
      severity={variant}
      hideIconVariant={hideIconVariant}
      actionButtons={actionButtons}
    />
  </SnackbarContent>
))

export default InfoBar
