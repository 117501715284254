import React, { memo, type FC } from 'react'
import StyledAccordionHeader from './styled-accordion-header'
import { StyledAccordionWrapper } from './styles'

export interface Props {
  expanded: boolean
  children: React.ReactNode[] | React.ReactNode
  onChange: () => void
}

const StyledAccordion: FC<Props> = ({
  expanded,
  children,
  onChange,
}) => {
  const [header, ...content] = Array.isArray(children) ? children : [children]

  return (
    <StyledAccordionWrapper expanded={expanded} noSeparator TransitionProps={{ timeout: 0 }}>
      <StyledAccordionHeader onClick={onChange}>
        {header}
      </StyledAccordionHeader>
      {content}
    </StyledAccordionWrapper>
  )
}

export default memo(StyledAccordion)
