/* eslint-disable @typescript-eslint/comma-dangle */
import React, { FC, ReactNode } from 'react'

import { AlfredIcon } from '@phaidra/ava/icons'
import Grid from '@phaidra/ava/grid'
import Typography from '@phaidra/ava/typography'

interface Props {
  textOne?: string
  textTwo?: string
  height?: number | string
  hideDescription?: boolean
}

const NoRowsOverlay: FC<Props> = ({
  textOne, textTwo, height, hideDescription,
}) => (
  <Grid
    container
    flexDirection="column"
    gap={1}
    alignItems="center"
    justifyContent="center"
    minHeight={`${height}px`}
    height="100%"
    style={{
      // fixes custom noRowsOverlay z-index issue with MUI DataGrid https://github.com/mui/mui-x/issues/5725
      position: 'relative',
      zIndex: 1
    }}
  >
    <AlfredIcon sx={{ height: '45px', color: '#B8B8B9', width: 'auto' }} />
    <Typography fontWeight="700">{textOne || 'No results'}</Typography>
    <Typography>{!hideDescription && (textTwo || 'Please adjust your filters.')}</Typography>
  </Grid>
)

export const getGridSlotsOverrideProps = <T, >(
  data: T[],
  isFiltered: boolean,
  activeTextSearch: string,
  noDataLabel: ReactNode,
) => {
  if (!data.length) {
    return {
      noRowsOverlay: {
        textOne: noDataLabel,
        hideDescription: true,
      },
    }
  }
  if (isFiltered && activeTextSearch) {
    return {
      noRowsOverlay: {
        textTwo: 'Please try another search.',
      },
    }
  }
  return {
    noRowsOverlay: {
      textTwo: 'Please adjust your filters.',
    },
  }
}

export default NoRowsOverlay
