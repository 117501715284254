import MUIPaper, { PaperProps as MUIPaperProps } from '@mui/material/Paper'

import styled from '../styled'

export interface PaperProps extends MUIPaperProps {
  padding?: 'sm' | 'md' | 'lg'
  borderRadius?: 'sm' | 'md'
}

const p = {
  sm: 10,
  md: 18,
  lg: 30,
}

const br = {
  sm: 4,
  md: 8,
}

const Paper = styled(MUIPaper)<PaperProps>(({
  theme,
  padding,
  square,
  borderRadius = 'md',
}) => ({
  padding: p[padding],
  ...(!square && { borderRadius: br[borderRadius] }),
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
}))

export default Paper
