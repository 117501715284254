export const NELDebuggerTags = {
  GetRenderableExpression: 'GetRenderableExpression',
  GetTextualisedExpression: 'GetTextualisedExpression',
  EvalExpression: 'EvalExpression',
  EvalConstraintExpression: 'EvalConstraintExpression',
  GetExpressionData: 'GetExpressionData',
}

export const InstallationTags = {
  GetInstallationConfigs: 'GetInstallationConfigs',
  GetInstallation: 'Installation',
}

export const ComponentsTags = {
  GetComponents: 'GetComponents',
}

export const SystemInstanceTags = {
  GetSystemInstance: 'GetSystemInstance',
  ExportSystemInstance: 'ExportSystemInstance',
}

export const TagManagementTags = {
  UpdateTagStatuses: 'UpdateTagStatuses',
  GetTagsValidation: 'GetTagsValidation',
  StopProcessingTags: 'StopProcessingTags',
  Find: 'Find',
  Replace: 'Replace',
  GetPointNames: 'GetPointNames',
  GetPointNamesUsedIn: 'GetPointNamesUsedIn',
  GetTagMetadata: 'GetTagMetadata',
  GetTagMetadataValidation: 'GetTagMetadataValidation',
  UpdateTagMetadata: 'UpdateTagMetadata',
  ToggleTestResult: 'ToggleTestResult',
  CreateTimeslice: 'CreateTimeslice',
  UpdateTimeslice: 'UpdateTimeslice',
  DeleteTimeslice: 'DeleteTimeslice',
  GetTagFilters: 'GetTagFilters',
  GetTagPage: 'GetTagPage',
}

export const DatasetTags = {
  GetDataset: 'GetDataset',
}

export const HDITags = {
  IngestionTimestamps: 'IngestionTimestamps',
}
