import React, { useState, useCallback, MutableRefObject } from 'react'
import {
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApi,
  GridTreeDataGroupingCell,
} from '@mui/x-data-grid-pro'

import Modal from '@mui/material/Modal'
import { LicenseInfo } from '@mui/x-license-pro'

import { useTheme } from '../theme-provider'
import { Container } from './styles'
import Table from './table'
import ColumnHeader from './table/column-header'

export {
  type GridColDef,
  type GridCellParams,
  type GridColumns,
  type GridRowId,
  type GridRowParams,
  type GridRenderCellParams,
  type GridRowsProp,
  type DataGridProProps,
  type GridSortItem,
  type GridApi,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRow,
  useGridApiRef,
} from '@mui/x-data-grid-pro'

export { ColumnHeader, GRID_CHECKBOX_SELECTION_COL_DEF }

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY)

export interface Props<R> extends DataGridProProps<R> {
  noBorder?: boolean
  disableScrollFade?: boolean
  disableScrollArrows?: boolean
  disableFullscreen?: boolean
  noRowsLabel?: string
  grayBackground?: boolean
  grayBackgroundOnHover?: boolean
  grayBackgroundOnSelected?: boolean
  apiRef?: MutableRefObject<GridApi>
}

const DataGrid = <R extends {}>(props: Props<R>) => {
  const theme = useTheme()
  const [fullscreen, setFullscreen] = useState(false)

  const onFullscreen = useCallback((fs: boolean) => setFullscreen(fs), [])

  return (
    <>
      <Table
        fullscreen={false}
        onFullscreen={onFullscreen}
        disableSelectionOnClick
        {...props}
        sx={{ backgroundColor: theme.palette.container.surface.default }}
      />
      <Modal open={fullscreen}>
        <Container data-testid="data-grid-fullscreen">
          <Table
            fullscreen
            onFullscreen={onFullscreen}
            disableSelectionOnClick
            {...props}
            sx={{ backgroundColor: theme.palette.container.surface.default }}
          />
        </Container>
      </Modal>
    </>
  )
}

export {
  GridTreeDataGroupingCell,
}
export default DataGrid
