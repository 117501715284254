import MUILink, { LinkProps } from '@mui/material/Link'

import styled from '../styled'

interface Props extends LinkProps {
  disabled?: boolean
  inverse?: boolean
}

export const StyledLink = styled(MUILink, {
  shouldForwardProp: (prop) =>
    prop !== 'inverse',
})<Props>(({
  theme,
  disabled,
  fontWeight,
  inverse = false,
}) => {
  /* istanbul ignore next */
  let color = theme.palette.mode === 'dark' ? theme.palette.icon.linkInverse : theme.palette.icon.link
  /* istanbul ignore next */
  let hoverColor = theme.palette.mode === 'dark' ? theme.palette.container.surface.low : theme.palette.text.default
  if (inverse) {
    /* istanbul ignore next */
    if (theme.palette.mode === 'dark') {
      color = theme.palette.container.surface.low
      hoverColor = theme.palette.icon.linkInverse
    } else {
      color = theme.palette.text.default
      hoverColor = theme.palette.icon.link
    }
  }

  return {
    color: disabled ? theme.palette.text.disabled : color,
    textDecorationColor: disabled ? theme.palette.text.disabled : color,
    fontWeight: fontWeight as string || theme.typography.fontWeightMedium,
    ...(disabled && { pointerEvents: 'none' }),
    '&.MuiLink-root:hover': {
      color: hoverColor,
      cursor: 'pointer',
    },
  }
})
