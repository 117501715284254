import Accordion from '@phaidra/ava/accordion'
import styled from '@phaidra/ava/styled'

export const StyledAccordionWrapper = styled(Accordion)({
  '& .MuiAccordion-region': {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const StyledAccordionHeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '8px',
  marginBottom: '16px',
})
