import {
  Edge,
  Node,
  useEdgesState,
  useNodesState,
} from '@xyflow/react'
import React, {
  createContext, PropsWithChildren, useCallback, useContext,
  useMemo,
  useState,
} from 'react'
import { DndNode } from '@common/nel/toolbox/types'

interface VisualNelContextType {
  highlightedId: Set<string>
  setHighlightedId: React.Dispatch<React.SetStateAction<Set<string>>>
  nodes: Node[]
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>
  edges: Edge[]
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
  handleEvaluation: (n: Node[], e: Edge[]) => void
  clearCanvas: () => void
  getTableNodes: () => Node<Record<string, unknown>, string>[]
  output: string
  setOutput: React.Dispatch<React.SetStateAction<string>>
  dndNode: DndNode
  setDndNode: React.Dispatch<DndNode>
  toolboxUsed: boolean
  setToolboxUsed: React.Dispatch<boolean>
  runAutoLayout: boolean
  setRunAutoLayout: React.Dispatch<boolean>
}

const VisualNelContext = createContext<VisualNelContextType>(null)

export const useVisualNel = () => useContext(VisualNelContext)

export const VisualNelProvider = ({ children }: PropsWithChildren) => {
  const [dndNode, setDndNode] = useState<DndNode>(null)

  const [highlightedId, setHighlightedId] = useState<Set<string>>(new Set())
  const [nodes, setNodes] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])
  const [output, setOutput] = useState<string>()
  const [toolboxUsed, setToolboxUsed] = useState(false)
  const [runAutoLayout, setRunAutoLayout] = useState(false)

  const clearCanvas = useCallback(() => {
    setNodes([])
    setEdges([])
  }, [setEdges, setNodes])

  const handleEvaluation = useCallback((n: Node[], e: Edge[]) => {
    setNodes(n)
    setEdges(e)
  }, [setEdges, setNodes])

  const getTableNodes = useCallback(() => nodes.filter((node: Node) => node.type === 'nabu_function'), [nodes])

  const value = useMemo(() => ({
    highlightedId,
    setHighlightedId,
    nodes,
    setNodes,
    edges,
    setEdges,
    handleEvaluation,
    clearCanvas,
    getTableNodes,
    output,
    setOutput,
    dndNode,
    setDndNode,
    toolboxUsed,
    setToolboxUsed,
    runAutoLayout,
    setRunAutoLayout,
  }), [
    highlightedId,
    setHighlightedId,
    nodes,
    setNodes,
    edges,
    setEdges,
    handleEvaluation,
    clearCanvas,
    getTableNodes,
    output,
    setOutput,
    dndNode,
    setDndNode,
    toolboxUsed,
    setToolboxUsed,
    runAutoLayout,
    setRunAutoLayout,
  ])

  return (
    <VisualNelContext.Provider value={value}>
      {children}
    </VisualNelContext.Provider>
  )
}
