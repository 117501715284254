import { SBR, Setpoint, SetpointsResponse } from '@services/setpoints/types'
import { getFilteredResults, TableTextSearchHookArgs } from '@common/utils/table-search-with-filters'
import _ from 'lodash-es'
import getSetpointPropertyFormField from '../setpoint-field-renderers'
import { DataTypes, SetpointTableRow } from '../columns'

export const getSetpointFallbackStatus = (setpoint: Setpoint) => {
  const fallbackParam = setpoint.params.find((param) => param.paramType === 'fallback')
  if (!fallbackParam) {
    return
  }

  return fallbackParam.value === null ? 'INCOMPLETE' : 'COMPLETE'
}

/**
 * Transforms the Setpoints API response data into an array of rows, where each row
 * represents a component, setpoint, or parameter.
 *
 * @param {SetpointsResponse['data']} response - The API response containing components and setpoints.
 * @returns {SetpointTableRow[]} - Transformed data for table rows, including components, setpoints, and parameters.
 *
 * @field component_search_terms -  a concatenation of component and setpoint names.
 * It is used to support search functionality by matching components, setpoints, or parameters
 * against a searchTerm and returning the component tree of the matched row.
 */

export const transformData = (response: SetpointsResponse['data']): {
  rows: SetpointTableRow[]
  enabledSetpoints: number
  enabledSetpointsWithFallback: number
} => {
  const rows: SetpointTableRow[] = []
  let enabledSetpoints = 0
  let enabledSetpointsWithFallback = 0

  if (!response) {
    return { rows, enabledSetpoints, enabledSetpointsWithFallback }
  }

  response.components?.forEach((component) => {
    rows.push({
      node_path: [component.name],
      id: `component-${component.componentId}`,
      component_search_terms: String(component.name),
      name: component.name,
      status: component.status,
      parentId: null,
      type: DataTypes.COMPONENT,
      isUsed: component?.setpoints?.some(({ isUsed }) => isUsed) ? 'true' : 'false',
      componentType: component.defName,
      param_status: undefined,
    })

    component.setpoints.forEach((setpoint) => {
      if (setpoint.isUsed) {
        enabledSetpoints += 1
      }

      rows.push({
        node_path: [component.name, setpoint.name],
        component_search_terms: `${component.name}${setpoint.name}`,
        id: `setpoint-${setpoint.id}`,
        name: setpoint.name,
        status: setpoint.status,
        parentId: `component-${component.componentId}`,
        type: DataTypes.SETPOINT,
        isUsed: String(setpoint.isUsed),
        componentType: component.defName,
        setpoint,
        navigationId: String(setpoint.id),
        param_status: undefined,
      })

      if (setpoint.isUsed && getSetpointFallbackStatus(setpoint) === 'COMPLETE') {
        enabledSetpointsWithFallback += 1
      }

      setpoint.params.forEach((param) => {
        rows.push({
          node_path: [component.name, setpoint.name, param.name],
          component_search_terms: `${component.name}${setpoint.name}${param.name}`,
          id: `param-${param.id}`,
          name: param.name,
          parentId: `setpoint-${setpoint.id}`,
          status: param.value ? 'COMPLETE' : 'INCOMPLETE',
          param_status: param.value ? 'COMPLETE' : 'INCOMPLETE',
          setpoint_fallback_status: getSetpointFallbackStatus(setpoint),
          type: DataTypes.PARAM,
          isUsed: String(setpoint.isUsed),
          componentType: component.defName,
          setpoint,
          navigationId: String(setpoint.id),
          value: param.value,
          paramType: param.paramType,
          paramId: param.id,
        })
      })
    })
  })

  return { rows, enabledSetpoints, enabledSetpointsWithFallback }
}

export const getSetpointFormFieldName = (setpoint: SetpointTableRow, param: SBR) =>
  `${setpoint?.setpoint?.id}-${param.name}${param.id ? `-${param.id}` : ''}`

export const getSetpointDrawerSections = (setpointRow: SetpointTableRow) => {
  const { setpoint: setpointData } = setpointRow

  const getSection = (inputType: SBR['inputType'], title: string) => {
    const paramData = { inputType, name: title, status: null } as SBR
    const fieldName = getSetpointFormFieldName(setpointRow, paramData)
    return getSetpointPropertyFormField(
      setpointData,
      paramData,
      fieldName,
    )
  }

  const setpointSBRSections = setpointData?.params?.map(
    (param) =>
      getSetpointPropertyFormField(
        setpointData,
        { ...param, status: param.value ? 'COMPLETE' : 'INCOMPLETE' },
        getSetpointFormFieldName(setpointRow, param),

      ),
  ) || []

  return [
    getSection('notes', 'Notes'),
    ...setpointSBRSections,
  ]
}

export function getFilteredResultsWithFullBranch<T extends SetpointTableRow>({
  items,
  searchTerm,
  dateRange,
  exactMatchTerm,
  keys,
}: TableTextSearchHookArgs<T>): T[] {
  const idToItem = _.keyBy(items, 'id')
  const parentIdToChildren = _.groupBy(items, (item) => item.parentId || null)

  const getAllDescendants = (item: T): T[] =>
    _.flattenDeep(
      _.map(parentIdToChildren[item.id] || [], (child) => [child, getAllDescendants(child)]),
    )

  const getAncestors = (item: T): T[] =>
    (item.parentId && idToItem[item.parentId]
      ? [idToItem[item.parentId], ...getAncestors(idToItem[item.parentId])]
      : [])

  const applyFilters = () => {
    const matchedRows = getFilteredResults({
      items,
      searchTerm,
      dateRange,
      exactMatchTerm,
      keys,
    })

    return _.uniqBy(
      _.flattenDeep(
        _.map(matchedRows, (row) => [
          row,
          ...getAncestors(row),
          ...(row.type === DataTypes.COMPONENT || row.type === DataTypes.SETPOINT
            ? getAllDescendants(row)
            : []),
        ]),
      ),
      'id',
    )
  }

  return applyFilters()
}
