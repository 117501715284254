import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExtendedOptionsWithExtraProps } from '@phaidra/ava/snackbar'

export type SnackBarPayload = {
  message: string
  options?: ExtendedOptionsWithExtraProps
}
export type SnackbarState = {
  messages: SnackBarPayload[]
}

const initialState: SnackbarState = {
  messages: [],
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    enqueueSnackbar: (state: SnackbarState, action: PayloadAction<SnackBarPayload>) => {
      const newState = { ...state }
      newState.messages = [...state.messages, action.payload]
      return newState
    },
    closeSnackbar: (state) => {
      state.messages.shift()
    },
  },
})

export const { enqueueSnackbar, closeSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
