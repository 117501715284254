import React, { ReactNode, useEffect, CSSProperties } from 'react'
import {
  FormProvider,
  useForm,
  useFormState,
  UseFormStateReturn,
  DeepPartial,
  EventType,
  FieldPath,
  PathValue,
  Path,
} from 'react-hook-form'
import type UseForm from './types/use-form'

export type UseFormReturn<T> = UseForm<T>

export type ChangeEvent<T> = {
  name?: FieldPath<T>
  type?: EventType
  value?: unknown
}

export interface Props<T> {
  flex?: boolean
  onSubmit?: (data: T, form: UseFormReturn<T>) => void
  onChange?: (
    value: DeepPartial<T>,
    info: ChangeEvent<T>,
    updatedValues?: DeepPartial<T>,
  ) => Promise<boolean> | void
  onChangeBasic?: (
    value: DeepPartial<T>,
    info: ChangeEvent<T>,
    updatedValues?: DeepPartial<T>,
  ) => Promise<boolean> | void
  render: (formState: UseFormStateReturn<T>, form: UseFormReturn<T>) => ReactNode
  defaultValues?: DeepPartial<T>
  formStyle?: CSSProperties
}

const Form = <T extends {}>({
  flex,
  onSubmit,
  onChange,
  onChangeBasic,
  render,
  defaultValues,
  formStyle,
}: Props<T>) => {
  const form = useForm<T>({ defaultValues })
  const formState = useFormState({ control: form.control })

  useEffect(() => {
    form.reset()
  }, [form])

  useEffect(() => {
    if (onChange) {
      let prevValues: DeepPartial<T>
      setTimeout(() => {
        // Needs to be in a setTimeout for the form.reset() to set default values set by Controller...
        prevValues = form.getValues() as DeepPartial<T>
      }, 0)
      const subscription = form.watch(async (values, info) => {
        const isManuallySettingValue: boolean = info.name?.split('.')
          .reduce((acc, curr) => acc[curr], values as any)?.isManuallySettingValue

        if (info.type === 'change' || isManuallySettingValue) {
          const updatedValues = JSON.parse(JSON.stringify(values))
          form.setValue(info.name, { loading: true } as PathValue<T, Path<T>>)
          const failed = await onChange(values, info, updatedValues)
          const valueToSet = info.name // object varaible path of the form element (e.g. foo.bar.bux)
            .split('.')
            .reduce((acc, curr) => acc?.[curr], !failed ? updatedValues : prevValues)

          form.setValue(
            info.name,
            valueToSet?.isManuallySettingValue ? valueToSet.value : valueToSet,
          )
          if (!failed) {
            prevValues = values
          }
        }
      })
      return () => subscription.unsubscribe()
    }

    const subscription = form.watch(async (values, info) => {
      const isManuallySettingValue: boolean = info.name?.split('.')
        .reduce((acc, curr) => acc[curr], values as any)?.isManuallySettingValue

      if (isManuallySettingValue) {
        const updatedValues = JSON.parse(JSON.stringify(values))
        form.setValue(info.name, { loading: true } as PathValue<T, Path<T>>)
        const valueToSet = info.name
          .split('.')
          .reduce((acc, curr) => acc[curr], updatedValues)

        form.setValue(
          info.name,
          valueToSet.value,
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [form, onChange])

  useEffect(() => {
    if (onChangeBasic) {
      const subscription = form.watch(async (values, info) => {
        onChangeBasic(values, info)
      })
      return () => subscription.unsubscribe()
    }
  }, [form, onChangeBasic])

  return (
    <FormProvider {...form}>
      <form
        aria-label="form"
        onSubmit={form.handleSubmit((data: T) => onSubmit?.(data, form))}
        style={{ ...formStyle, ...(flex && { flex: 1 }) }}
      >
        {render(formState, form)}
      </form>
    </FormProvider>
  )
}

export default Form
