import React, {
  FC, CSSProperties, useEffect, useRef, useState,
} from 'react'

import { AddIcon, CopyIcon } from '@phaidra/ava/icons'
import Typography from '@phaidra/ava/typography'
import Chip from '@phaidra/ava/chip'
import Tooltip from '@phaidra/ava/tooltip'
import Grid from '@phaidra/ava/grid'
import getMatchingOption from '@common/get-matching-option'
import {
  StyledListItem, StyledIconButton, Container,
} from './styles'

export interface PointName {
  name: string
  fullTagName?: string
}

export type Props = {
  onCopy: (value: string) => void
  onInsert: (value: string) => void
  index: number | string
  option: PointName
  search: string
  style?: CSSProperties
  setRowHeight?: (index: number | string, size: number) => void
  pointNamesUsedInMapping: Record<string, Array<{ componentName: string, fullTagName: string }>>
  isNode?: boolean
}

const ListItem: FC<Props> = ({
  index, option, onCopy, onInsert, style, search, setRowHeight, pointNamesUsedInMapping, isNode,
}) => {
  const { name, fullTagName } = option
  const usedIn = pointNamesUsedInMapping?.[fullTagName] ?? []
  const rowRef = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)
  const displayName = isNode ? name : fullTagName

  useEffect(() => {
    if (rowRef?.current) {
      setRowHeight?.(index, rowRef.current.clientHeight + 4 + (usedIn.length ? 4 : 0))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowRef, hover])

  return (
    <StyledListItem
      container
      alignItems="center"
      data-testid={`tags-list-${fullTagName}`}
      key={`tags-list-${index}`}
      style={{
        flexWrap: 'nowrap',
        gap: '8px',
        ...style,
        ...(!isNode && {
          flex: 1,
          justifyContent: 'space-between',
        }),
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <Container
        ref={rowRef}
        data-testid="tags-list-container"
        container
        alignItems="center"
        flexWrap="nowrap"
        sx={{ width: '100%' }}
      >
        <StyledIconButton
          onClick={(e) => {
            e.stopPropagation()
            onInsert(fullTagName)
          }}
        >
          <AddIcon fontSize="small" />
        </StyledIconButton>
        <StyledIconButton
          onClick={(e) => {
            e.stopPropagation()
            onCopy(fullTagName)
          }}
          sx={{ marginLeft: '8px', marginRight: '8px' }}
        >
          <CopyIcon />
        </StyledIconButton>
        <Typography
          color="#58585A"
          fontSize="0.75rem"
          marginRight="8px"
          width="calc(100% - 48px)"
          sx={{ flex: 1, overflowWrap: 'break-word' }}
        >
          {getMatchingOption(displayName, search)}
        </Typography>
      </Container>
      {usedIn.length > 0
      && (
        <Tooltip
          style={{ flexShrink: 0 }}
          placement="top"
          title={(
            <Grid sx={{ fontWeight: '500' }}>
              Currently in use with:
              <ul style={{
                margin: '0px',
                padding: 0,
                paddingLeft: '20px',
              }}
              >
                {usedIn
                  .map((tag, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${tag.fullTagName}-${idx}`} style={{ fontWeight: '400' }}>
                      {`${tag.componentName} | ${tag.fullTagName}`}
                    </li>
                  ))}
              </ul>
            </Grid>
          )}
        >
          <Chip label="In use" color="info" sx={{ cursor: 'pointer' }} />
        </Tooltip>
      )}
    </StyledListItem>
  )
}

export default ListItem
