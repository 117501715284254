import React, { FC, useState } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import { StyledButton } from './styles'
import { Props } from './types'
import { ArrowDropDownIcon } from '../icons'
import MenuItem from '../menu-item'
import Menu from '../menu'
import { useTheme } from '../theme-provider'

export type ButtonProps = Props

const Button: FC<Props> = ({
  size = 'medium',
  variant = 'contained',
  children,
  splitButtonMenuHeading,
  splitButtonMenuList,
  ...props
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const hasSplitButtonMenuList = splitButtonMenuList !== null && splitButtonMenuList !== undefined

  const handleClose = () => setAnchorEl(null)

  return !splitButtonMenuList ? (
    <StyledButton disableRipple variant={variant} size={size} color="default" {...props}>
      {children}
    </StyledButton>
  ) : (
    <>
      <ButtonGroup disableElevation variant={variant}>
        <StyledButton
          disableRipple
          variant={variant}
          size={size}
          isWithinButtonGroup={hasSplitButtonMenuList}
          color="default"
          {...props}
        >
          {children}
        </StyledButton>
        <StyledButton
          data-split-button
          disableRipple
          aria-label="Toggle Dropdown"
          aria-expanded={!!anchorEl}
          variant={variant}
          size="small"
          color="default"
          {...props}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </StyledButton>
      </ButtonGroup>
      <Menu
        sx={{ marginTop: '4px' }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* Styles are overwritten when by `Menu` component, so we have to apply to element's `style` instead */}
        {splitButtonMenuHeading && (
          <MenuItem disabled style={{ opacity: 1, fontSize: theme.typography.caption.fontSize }}>
            {splitButtonMenuHeading}
          </MenuItem>
        )}
        {splitButtonMenuList.map(({ label, onClick, disabled: itemDisabled }) => (
          <MenuItem
            key={label}
            disableRipple
            disabled={itemDisabled}
            onClick={() => {
              handleClose()
              onClick()
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>

  )
}

export default Button
