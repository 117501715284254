import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '@phaidra/utils/axios-base-query'

import { DatasetTags } from '@services/request-tags'
import { Dataset, DatasetData } from './types'

export const datasetsApi = createApi({
  reducerPath: 'datasetsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.PHAIDRA_API}/api/v1/datasets`,
  }),
  endpoints: (builder) => ({
    getDataset: builder.query<Array<Dataset>, void>({
      transformResponse: (response: DatasetData) => response.data,
      query: () => ({
        url: '',
        method: 'get',
        meta: {
          tag: DatasetTags.GetDataset,
        },
      }),
      keepUnusedDataFor: 10000000000,
    }),
  }),
})

export const { useGetDatasetQuery, endpoints: { getDataset: { useQueryState: useGetDatasetQueryState } } } = datasetsApi
