import Highcharts, { SeriesColumnOptions, Point, DashStyleValue } from 'highcharts'
import NoChartDataFoundIcon from '../assets/no-chart-data-found.svg'
import { type Theme } from '../../theme-provider/index'

interface CustomPoint extends Point {
  userOptions?: CustomSeriesColumnOptions
}

interface CustomSeriesColumnOptions extends Omit<SeriesColumnOptions, 'dashStyle'> {
  iconMarker?: string
  dashStyle?: DashStyleValue | 'ShortAsterisk'
}

const getDefaultOptions = (theme: Theme): Highcharts.Options => ({
  accessibility: {
    enabled: false,
  },
  colors: ['#6D9B7B', '#121717', '#D8973C', '#0D5923', '#B8B8B9', '#E7C08A'],
  chart: {
    style: {
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    },
    selectionMarkerFill: 'rgba(242, 242, 242, 0.5)',
    backgroundColor: 'none',
  },
  noData: {
    useHTML: true,
  },
  lang: {
    noData: `
      <div style="text-align: center; line-height: 18px;">
        <img src=${NoChartDataFoundIcon} />
        <br/>
        <span style="font-weight: 700; font-size: 14px; color: ${theme.palette.text.default}">
          No data to display. Try changing your settings.
        </span>
      </div>`,
  },
  legend: {
    align: 'left',
    symbolHeight: 0,
    symbolWidth: 0,
    symbolPadding: 0,
    symbolRadius: 0,
    squareSymbol: false,
    alignColumns: false,
    itemStyle: {
      pointerEvents: 'none',
    },
    margin: 5,
    useHTML: true,
    labelFormatter(this: CustomPoint) {
      const isColumn = ('type' in this.options && this.options.type === 'column') as boolean
      const isRange = this.name === 'Limit'
      const isError = this.name === 'Excursion'
      const dotStyle = ('dashStyle' in this.options && this.options.dashStyle === 'ShortDash') as boolean
      const shortDot = ('dashStyle' in this.options && this.options.dashStyle === 'ShortDot') as boolean
      const solid = ('dashStyle' in this.options && this.options.dashStyle === 'Solid') as boolean
      const areaChart = ('type' in this.options && this.options.type === 'area') as boolean
      const shortAsterisk = ('dashStyle' in this.options
        && this.options.dashStyle as CustomSeriesColumnOptions['dashStyle'] === 'ShortAsterisk') as boolean

      if (solid || areaChart) {
        return `
          <div style="display:flex; align-items:center; pointer-events:all;">
            <div
              style="
              width:12px;
              height:12px;
              background-color:${'color' in this && this.color};
              border: 1px solid ${'borderColor' in this.options && this.options.borderColor};
              "
            ></div>
            <span style="padding-left:8px; font-weight:normal">${this.name}</span>
          </div>
        `
      }

      if (shortAsterisk) {
        return `
          <div style="display:flex; align-items:center; pointer-events:all;">
            <div
              style="
                width:5px;
                height:12px;
                color:${'color' in this && this.color};
                font-size: 14px;
              "
            >*</div>
            <span style="padding-left:8px; font-weight:normal">${this.name}</span>
          </div>
        `
      }

      if (shortDot) {
        return `
          <div style="display:flex; align-items:center; pointer-events:all;">
            <div
              style="width:8px; height:8px; background-color:${'color' in this && this.color}; border-radius: 50%;"
            ></div>
            <span style="padding-left:8px; font-weight:normal">${this.name}</span>
          </div>
        `
      }

      if (dotStyle) {
        return `
          <div style="display:flex; align-items:center; pointer-events:all;">
            <div style="width:18px; height:0; border:1.5px dashed ${'color' in this && this.color}"></div>
            <span style="padding-left:8px; font-weight:normal">${this.name}</span>
          </div>
        `
      }

      if (!isRange && !isError) {
        return `
          <div style="display:flex; align-items:center; pointer-events:all;">
            <div style="width:${isColumn ? '12px' : '20px'}; height:${isColumn ? '12px' : '3px'};
              background-color:${'color' in this && this.color}; display:flex; align-items:center;
              justify-content:center;">
            </div>
            <span style="padding-left:8px; font-weight:normal">${this.name}</span>
          </div>
        `
      }

      if (isError) {
        return `
          <div style="display:flex; align-items:center;">
            <div style="width:${isColumn ? '12px' : '18px'}; height:${isColumn ? '12px' : '3px'};
            ${isColumn ? `background-color:${'color' in this && this.color}; display:flex; align-items:center;` : ''}
            ${!isColumn ? `border:1.5px dashed ${this.color};` : ''}
            justify-content:center;">
          </div>
            <span style="padding-left:8px; font-weight:normal">${this.name}</span>
          </div>
        `
      }

      return `
      <div style="display:flex; align-items:center;">
        <div style="width:12px; height:0; border:1px dashed ${theme.palette.text.disabled}"></div>
        <span style="padding-left:8px; font-weight:normal">${this.name}</span>
      </div>
    `
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      animation: false,
      custom: { theme },
    },
    column: {
      maxPointWidth: 50,
      borderWidth: 1,
      minPointLength: 3,
    },
    line: {
      connectNulls: true,
      marker: {
        enabled: false,
      },
      animation: false,
    },
    pie: {
      allowPointSelect: true,
      minSize: 8,
      dataLabels: {
        enabled: true,
        format: '{point.name} - <b>{point.percentage:.1f}%</b>',
        style: {
          fontSize: '0.75rem',
          fontWeight: '400',
        },
      },
      states: {
        hover: {
          brightness: 0,
          halo: {
            size: 0,
          },
        },
      },
      point: {
        events: {
          mouseOver() {
            const currentPoint = this
            const { series } = currentPoint

            series.points.forEach((point) => {
              if (point === currentPoint) {
                point.setState('hover')
                if (point.graphic) {
                  point.graphic.element.style.filter = 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4))'
                  point.graphic.attr({
                    'stroke-width': 0,
                    stroke: 'none',
                  })
                }
              } else {
                point.setState('')

                if (point.index === currentPoint.index - 1 || point.index === currentPoint.index + 1) {
                  if (point.graphic) {
                    point.graphic.attr({
                      'stroke-width': 0,
                      stroke: 'none',
                    })
                  }
                }
                if (point.graphic) {
                  point.graphic.element.style.filter = 'opacity(0.2)'
                }
                if ('dataLabel' in point && point.dataLabel) {
                  const dataLabel = point.dataLabel as Highcharts.SVGElement
                  dataLabel.attr({
                    'fill-opacity': 0.2,
                  })
                }
              }
            })
          },
          mouseOut() {
            this.series.points.forEach((point) => {
              if (point.graphic) {
                point.graphic.attr({
                  'stroke-width': 1.5,
                  stroke: theme.palette.container.surface.default,
                })
                point.graphic.element.style.filter = ''
              }
              if ('dataLabel' in point && point.dataLabel) {
                const dataLabel = point.dataLabel as Highcharts.SVGElement
                dataLabel.attr({
                  'fill-opacity': 1,
                })
              }
            })
            this.series.setState('')
          },
        },
      },
      borderWidth: 1.5,
      borderColor: theme.palette.container.surface.default,
    },
    boxplot: {
      point: {
        events: {
          mouseOver() {
            const currentPoint = this
            const { series } = currentPoint

            series.points.forEach((point) => {
              if (point === currentPoint) {
                point.setState('hover')
              } else {
                point.setState('')

                if (point.index === currentPoint.index - 1 || point.index === currentPoint.index + 1) {
                  if (point.graphic) {
                    point.graphic.attr({
                      'stroke-width': 0,
                      stroke: 'none',
                    })
                  }
                }
                if (point.graphic) {
                  point.graphic.element.style.filter = 'opacity(0.2)'
                }
                if ('dataLabel' in point && point.dataLabel) {
                  const dataLabel = point.dataLabel as Highcharts.SVGElement
                  dataLabel.attr({
                    'fill-opacity': 0.2,
                  })
                }
              }
            })
          },
          mouseOut() {
            this.series.points.forEach((point) => {
              if (point.graphic) {
                point.graphic.attr({
                  'stroke-width': 1.5,
                  stroke: theme.palette.container.surface.default,
                })
                point.graphic.element.style.filter = ''
              }
              if ('dataLabel' in point && point.dataLabel) {
                const dataLabel = point.dataLabel as Highcharts.SVGElement
                dataLabel.attr({
                  'fill-opacity': 1,
                })
              }
            })
            this.series.setState('')
          },
        },
      },
    },
  },
  title: {
    text: '',
  },
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
    fallbackToExportServer: false,
  },
  xAxis: {
    lineColor: theme.palette.icon.disabledInverse,
    tickLength: 0,
    labels: {
      style: {
        fontSize: '0.75rem',
        color: theme.palette.text.default,
        fontWeight: '400',
      },
    },
    title: {
      text: 'X Axis Title',
      offset: 35,
      style: {
        fontSize: '0.875rem',
        color: theme.palette.text.default,
        fontWeight: '700',
      },
    },
    dateTimeLabelFormats: {
      hour: '%l:%M%P',
      day: '%m.%d.%Y',
      week: '%m.%d.%Y',
      month: '%b %Y',
    },
  },
  tooltip: {
    xDateFormat: '%d.%m.%Y %l:%M%P',
  },
  yAxis: {
    lineColor: theme.palette.icon.disabledInverse,
    gridLineWidth: 0,
    lineWidth: 1,
    labels: {
      style: {
        fontSize: '0.75rem',
        color: theme.palette.text.default,
        fontWeight: '400',
      },
    },
    title: {
      text: 'Y Axis Title',
      x: -10,
      style: {
        fontSize: '0.875rem',
        color: theme.palette.text.default,
        fontWeight: '700',
      },
    },
  },
})

export default getDefaultOptions
