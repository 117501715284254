import React, {
  FC,
  useRef,
  useState,
  ReactNode,
  MouseEvent,
  useEffect,
} from 'react'
import Collapse from '@mui/material/Collapse'
import { LabelContainer, StyledRightChevronIcon, StyledMenu } from './styles'

export interface BaseApi {
  close: () => void
}

export type DropdownProps = {
  label: ReactNode
  menuLabel: string
  menu: ReactNode
  fullWidth?: boolean
  error?: boolean
  onOpen?: (isOpen: boolean) => void
  onApiReady?: (api: BaseApi) => void
  'data-testid'?: string
  isThin?: boolean
  borderColor?: string
  backgroundColor?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  website?: boolean
}

const BaseDropdown: FC<DropdownProps> = ({
  label,
  menu,
  menuLabel,
  fullWidth,
  error,
  onOpen,
  onApiReady,
  'data-testid': dataTestId,
  isThin = false,
  borderColor = '',
  backgroundColor = '',
  disabled = false,
  size,
  website = false,
}) => {
  const [hover, setHover] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [buttonLabelWidth, setButtonLabelWidth] = useState(0)

  useEffect(() => {
    if (dropdownRef.current && buttonRef.current) {
      const initialPositionButton = buttonRef.current.getBoundingClientRect().top
        + buttonRef.current.offsetHeight
      const heightDropdown = window.innerHeight - initialPositionButton - 16
      const dropdown = dropdownRef.current.querySelector<HTMLElement>('.MuiPaper-root')
      if (dropdown) {
        dropdown.style.maxHeight = `${heightDropdown}px`
        dropdown.style.top = `${initialPositionButton}px`
      }
    }
  }, [buttonRef, dropdownRef, open])

  useEffect(() => {
    onApiReady?.({
      close: () => {
        setAnchorEl(null)
        onOpen?.(false)
      },
    })
  }, [onApiReady, onOpen])

  useEffect(() => {
    if (!buttonRef.current) return
    const resizeObserver = new ResizeObserver(() => setButtonLabelWidth(buttonRef.current?.offsetWidth || 0))
    resizeObserver.observe(buttonRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  return (
    <>
      <LabelContainer
        ref={buttonRef}
        data-testid={dataTestId || 'dropdown-button'}
        fullWidth={fullWidth}
        isThin={isThin}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        error={error}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        type="button"
        hover={hover}
        open={open}
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
          onOpen?.(true)
        }}
        disabled={disabled}
        size={size}
        website={website}
      >
        {label}
        <StyledRightChevronIcon rotate={open ? '1' : '0'} disabled={disabled} />
      </LabelContainer>
      <StyledMenu
        ref={dropdownRef}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
          onOpen?.(false)
        }}
        TransitionComponent={Collapse}
        width={buttonLabelWidth}
        isThin={isThin}
        MenuListProps={{ 'aria-label': menuLabel }}
        website={website}
      >
        {menu}
      </StyledMenu>
    </>
  )
}

export default BaseDropdown
