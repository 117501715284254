import React from 'react'

import Typography from '../../../../typography'
import { PaletteContainer, PreviewValue, Marker } from './styles'

export interface PreviewPaletteProps {
  value: number
  unit: string
  x: number
  y: number
  roundingDigits: number
}

const PreviewPalette = ({
  value,
  unit,
  x,
  y,
  roundingDigits = 0,
}: PreviewPaletteProps) => (
  <div
    style={{
      position: 'absolute',
      top: `${y}px`,
      left: `${x}px`,
      minWidth: '150px',
    }}
  >
    <PaletteContainer data-testid="preview-palette">
      <Marker data-testid="preview-palette-marker" />
      <PreviewValue>
        <Typography style={{ fontSize: '0.75rem' }}>
          {value.toFixed(roundingDigits)}
          {' '}
          {unit}
        </Typography>
      </PreviewValue>
    </PaletteContainer>
  </div>
)

export default PreviewPalette
