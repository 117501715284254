import FeatureFlagNames from '../flag-names'
import { useLaunchDarklyFeatureFlags } from '../provider'

export const useCheckAdminEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.Admin)

export const useCheckActionExplorerTestAgentsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.ActionExplorerTestAgents)

export const useCheckInsightsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.Insights)

export const useCheckTrendStatisticsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.TrendsStatistics)

export const useCheckTrendTestAgentsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.TrendsTestAgents)

export const useCheckTrendViewerEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.TrendViewer)

export const useCheckUnitsOfMeasureEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.UnitsOfMeasure)

export const useCheckNavigatorTimezoneEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.NavigatorTimezone)

export const useCheckMetadataValidationV2Enabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.MetadataValidationV2)

export const useCheckTrendViewerConstantsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.TrendViewerConstants)

export const useCheckRawClientPointsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.RawClientPoints)

export const useContinuousEnergySavingsEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.EnableContinuousEnergySavings)

export const useCheckSystemDropdownEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.EnableSystemDropdown)

export const useVersionCheckerEnabled = () =>
  useLaunchDarklyFeatureFlags(FeatureFlagNames.VersionChecker)
