import MUIMenuItem from '@mui/material/MenuItem'

import styled from '../styled'

const MenuItem = styled(
  MUIMenuItem,
  { shouldForwardProp: (prop: string) => !['size', 'backgroundColorOnHover', 'colorOnHover'].includes(prop) },
)<{
  size?: 'large' | 'medium' | 'small'
  backgroundColorOnHover?: string
  colorOnHover?: string
}>(({
  theme,
  size,
  backgroundColorOnHover,
  colorOnHover,
}) => ({
  backgroundColor: theme.palette.background.default,
  fontSize: size === 'small' ? '0.75rem' : '0.875rem',
  fontWeight: 400,
  height: 'auto !important',
  textWrap: 'wrap !important',
  '&.MuiMenuItem-root': {
    minHeight: '32px',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.container.surface.default,
  },
  '&.MuiMenuItem-root:hover': {
    backgroundColor: `${backgroundColorOnHover || theme.palette.hover.surface.default} !important`,
    color: colorOnHover || theme.palette.text.default,
  },
  variants: [], // type requirement
}))

export default MenuItem
