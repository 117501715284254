import Highcharts from 'highcharts'
import chevronUpPositive from '../assets/chevron-up-positive.svg'
import chevronDownPositive from '../assets/chevron-down-positive.svg'
import chevronUpNegative from '../assets/chevron-up-negative.svg'
import chevronDownNegative from '../assets/chevron-down-negative.svg'

export default (
  options: Highcharts.Options,
  gainLoss?: Array<{ value: number, positiveChange: boolean } | null>,
): Highcharts.Options => (
  gainLoss !== undefined ? {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
        events: {
          ...options.plotOptions?.series?.events,
          legendItemClick() {
            return false
          },
        },
      },
    },
    chart: {
      ...options.chart,
      events: {
        ...options.chart?.events,
        render(this, event) {
          options.chart?.events?.render.bind(this)(event)
          const chart = this
          const IMAGE_SIZE = 20

          if (chart.options.plotOptions.series.custom?.gainLossGroups) {
            for (let i = 0; i < chart.options.plotOptions.series.custom.gainLossGroups.length; i += 1) {
              chart.options.plotOptions.series.custom.gainLossGroups[i].destroy()
            }
            chart.options.plotOptions.series.custom.gainLossGroups = []
          } else {
            chart.options = {
              ...chart.options,
              plotOptions: {
                ...chart.options.plotOptions,
                series: {
                  ...chart.options.plotOptions.series,
                  custom: {
                    ...chart.options.plotOptions.series.custom,
                    gainLossGroups: [],
                  },
                },
              },
            }
          }

          if (chart.series.length > 0) {
            for (let i = 0; i < gainLoss.length; i += 1) {
              if (gainLoss[i].value !== 0 && gainLoss[i].value !== null) {
                const largest = Math.max(chart.series[0].data[i].y, chart.series[1].data[i].y)

                const group = chart.renderer.g(`comparative-group-${i}`)
                  .translate(
                    chart.axes[0].toPixels(i, false),
                    chart.axes[1].toPixels(largest, false) - 15,
                  )
                  .add()

                chart.options.plotOptions.series.custom.gainLossGroups.push(group)

                const text = chart.renderer.text(`${gainLoss[i].value}%`, 0, 0)
                  .css({
                    color: gainLoss[i].positiveChange ? '#0D5923' : '#A1072F',
                    fontSize: '1rem',
                    fontWeight: '500',
                    padding: '50px',
                  })
                  .add(group)

                text.translate(-(text.getBBox().width * 0.5) - (IMAGE_SIZE * 0.5), 0)

                const chevrons = gainLoss[i].positiveChange ? {
                  up: chevronUpPositive,
                  down: chevronDownPositive,
                } : {
                  up: chevronUpNegative,
                  down: chevronDownNegative,
                }

                chart.renderer.image(
                  gainLoss[i].value < 0 ? chevrons.down : chevrons.up,
                  (text.getBBox().width * 0.5) - (IMAGE_SIZE * 0.5),
                  -15,
                  IMAGE_SIZE,
                  IMAGE_SIZE,
                ).add(group)
              }
            }
          }
        },
      },
    },
  } : options
)
