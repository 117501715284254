import React, { FC, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import AvaAppBarVertical, { TopListData } from '@phaidra/ava/app-bar-vertical'
import { localToUTC } from '@phaidra/utils/time'

import {
  SystemHomeIcon,
  DataLogo,
  SystemSetupIcon,
  TrendsIcon,
  TagsIcon,
  SetpointsIcon,
  HealthRulesIcon,
  ConstraintsIcon,
} from '@phaidra/ava/icons'
import AtlasLogo from '@assets/atlas-logo'
import { useGetInstallationQueryState } from '@services/installation'
import { IngestionsContext } from '@providers/ingestion'
import useCurrentTime from '@phaidra/utils/use-current-time'
import { FeatureFlagNames, useLaunchDarklyFeatureFlags } from '@phaidra/utils/darkly-feature-flags'

const AppBarVertical: FC = () => {
  const { pathname } = useLocation()
  const {
    actions: { setProcessingIngestion },
  } = useContext(IngestionsContext)
  const navigate = useNavigate()
  const { search } = useLocation()
  const selectedInstallation = new URLSearchParams(search).get('installationId')

  const { data } = useGetInstallationQueryState()
  const { logout } = useAuth0()

  const currentTime = useCurrentTime()
  const isHealthRulesV1Enabled = useLaunchDarklyFeatureFlags(FeatureFlagNames.HealthRulesV1)
  const isConstraintsEnabled = useLaunchDarklyFeatureFlags(FeatureFlagNames.EnableAtlasConstraints)

  const handleLogout = () => {
    setProcessingIngestion(null)
    logout({ returnTo: `${window.location.origin}/login` })
  }

  const selectedInstallationName = data?.data.find(
    ({ id }) => `${id}` === selectedInstallation,
  )?.name

  const headerData = {
    title: 'Atlas',
    subTitle: selectedInstallationName,
    icon: AtlasLogo,
  }

  const topListData: TopListData[] = [
    {
      children: [
        {
          label: 'Systems Home',
          path: 'systems-home',
          icon: SystemHomeIcon,
        },
      ],
    },
  ]

  if (selectedInstallation) {
    topListData.push(
      {
        children: [
          {
            icon: SystemSetupIcon,
            label: 'Builder',
            path: 'builder',
            params: { installationId: selectedInstallation },
          },
          {
            icon: TagsIcon,
            label: 'Tags',
            path: 'tags',
            params: { installationId: selectedInstallation },
          },
          {
            icon: SetpointsIcon,
            label: 'Setpoints',
            path: 'setpoints',
            params: { installationId: selectedInstallation },
          },
        ],
      },
      {
        children: [
          {
            icon: DataLogo,
            label: 'Ingestion',
            path: 'data',
            params: { installationId: selectedInstallation },
          },
          {
            icon: TrendsIcon,
            label: 'Trends',
            path: 'trend-viewer',
            params: { installationId: selectedInstallation },
          },
        ],
      },
    )
    if (isHealthRulesV1Enabled) {
      topListData[1].children.push({
        icon: HealthRulesIcon,
        label: 'Health rules',
        path: 'health-rules',
        params: { installationId: selectedInstallation },
      })
    }
    if (isConstraintsEnabled) {
      topListData[1].children.push({
        icon: ConstraintsIcon,
        label: 'Constraints',
        path: 'constraints',
        params: { installationId: selectedInstallation },
      })
    }
  }

  const bottomListData = [
    {
      label: `${`UTC ${format(localToUTC(currentTime), 'h:mmaaa LL.dd.yy')}`}`,
      icon: 'schedule',
      iconTestId: 'icon-clock',
      onClick: () => { },
    },
    {
      label: 'Logout',
      onClick: handleLogout,
      icon: 'logout',
      iconTestId: 'LogoutIcon',
    },
  ]

  return (
    <AvaAppBarVertical
      headerData={headerData}
      clientName={data?.companyName}
      topListData={topListData}
      bottomListData={bottomListData}
      push={navigate}
      pathname={pathname}
    />
  )
}

export default AppBarVertical
