import styled from '@phaidra/ava/styled'
import Grid from '@phaidra/ava/grid'
import CircularProgress from '@phaidra/ava/circular-progress'
import Typography from '@phaidra/ava/typography'

export const StyledTextContainer = styled(Grid)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  display: 'inline-flex',
  '& > *': {
    color: disabled ? theme.palette.text.disabled : undefined,
  },
  alignItems: 'center',
}))

export const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.icon.defaultInverse,
  height: '12px !important',
  width: '12px !important',
}))

export const StyledSmallTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.subdued,
  marginBottom: theme.spacing(-0.25),
  whiteSpace: 'pre',
  paddingLeft: theme.spacing(0.5),
}))

export const StyledLargeTitle = styled(Typography)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  color: disabled ? theme.palette.text.disabled : theme.palette.text.subdued,
  whiteSpace: 'pre',
  paddingLeft: theme.spacing(0.5),
}))

export const StyledValue = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre',
  paddingLeft: theme.spacing(0.5),
  fontSize: '1rem',
}))
