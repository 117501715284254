import React, { useContext, useEffect, useState } from 'react'
import { formatDuration, intervalToDuration, parseISO } from 'date-fns'

import { CloseIcon, IngestIcon } from '@phaidra/ava/icons'
import Grid from '@phaidra/ava/grid'
import { IngestionStatus } from '@services/historical-data-ingestion/types'
import { IngestionsContext } from '@providers/ingestion'
import * as Styled from '../styles'

const statusLabels = {
  CANCELLED: 'Ingestion failed',
  ERROR: 'Ingestion failed',
  PROCESSING: 'Ingestion in progress',
  COMPLETED: 'Ingestion complete',
}

export const DATE_FORMAT = "MM.dd.yyyy 'at' hh:mmaaa"
const IngestionStatusWindow = () => {
  const {
    state: { processingIngestion, clientIngestions: ingestions }, actions: { setProcessingIngestion },
  } = useContext(IngestionsContext)
  const [elapsedTime, setElapsedTime] = useState('')
  const ingestionInProgress = processingIngestion?.timestamp?.status === IngestionStatus.PROCESSING
  useEffect(() => {
    if (!processingIngestion || processingIngestion.timestamp.status !== IngestionStatus.PROCESSING) return

    let intervalId: NodeJS.Timer
    const ingestion = ingestions?.find(({ id }) => id === processingIngestion.timestamp.id)
      ?? processingIngestion?.timestamp

    const updateElapsedTime = () => {
      if (!ingestion) return setElapsedTime('0h')

      const startedAt = parseISO(ingestion.started_at)
      const now = new Date(new Date().toISOString().split('Z')[0])

      const endedAt = ingestion.status !== IngestionStatus.PROCESSING
        ? parseISO(ingestion.updated_at)
        : now

      const duration = intervalToDuration({ start: startedAt, end: endedAt })
      const formattedTime = formatDuration(duration, { format: ['hours', 'minutes'], delimiter: ' ' })
        .replace('hours', 'h')
        .replace('minutes', 'min')
      setElapsedTime(formattedTime.includes('h') ? formattedTime : `0h ${formattedTime}`)
    }

    // Update elapsed time every minute if not completed
    if (ingestion?.status === IngestionStatus.PROCESSING) {
      intervalId = setInterval(updateElapsedTime, 60000)
    }

    if (ingestion?.status !== IngestionStatus.PROCESSING) {
      setProcessingIngestion({
        ...processingIngestion,
        timestamp: {
          formattedTimestamp: processingIngestion.timestamp.formattedTimestamp,
          ...ingestion,
        },
      })
    }
    // Initial update or final update if completed
    updateElapsedTime()

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingIngestion, ingestions])

  return processingIngestion?.visible ? (
    <Styled.AccordionWrapper>
      <Styled.Accordion color="#FFF" defaultExpanded>
        <Styled.SummaryWrapper expandIcon={(
          <Styled.KeyboardArrowDownIcon
            fontSize="medium"
          />
        )}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Styled.Title>
              <IngestIcon sx={{ marginRight: '2px' }} />
              <Styled.StatusText>
                {statusLabels[processingIngestion.timestamp.status]}
              </Styled.StatusText>
            </Styled.Title>
            <Styled.CloseButton
              data-testid="close-ingestion-window"
              onClick={() => setProcessingIngestion({ ...processingIngestion, visible: false })}
              disabled={ingestionInProgress}
            >
              <CloseIcon sx={{
                height: '19px',
                width: '19px',
                color: ingestionInProgress ? '#B8B8B9' : '#FBFBFB',
              }}
              />
            </Styled.CloseButton>
          </Grid>
        </Styled.SummaryWrapper>
        <Styled.DetailsWrapper>
          <Styled.TimestampText>
            {processingIngestion.timestamp.formattedTimestamp}
          </Styled.TimestampText>
          <Styled.ElapsedText>
            Time elapsed
            {' '}
            {elapsedTime}
          </Styled.ElapsedText>
        </Styled.DetailsWrapper>
      </Styled.Accordion>
    </Styled.AccordionWrapper>
  ) : null
}

export default IngestionStatusWindow
