import Highcharts, { SeriesOptionsType, YAxisOptions, YAxisPlotLinesOptions } from 'highcharts'
import exclamationTriangle from '../assets/exclamation-triangle.svg'
import exclamationTriangleAbove from '../assets/exclamation-triangle-above.svg'
import exclamationTriangleBelow from '../assets/exclamation-triangle-below.svg'
import {
  getSoftMin,
  getSoftMax,
  addThresholdSeries,
  ThresholdLimit,
} from './thresholds'

const ERROR_COLOR = '#CD0D0D'

interface CustomYAxisOptions extends YAxisOptions {
  plotLines?: YAxisPlotLinesOptions[]
}
export interface ErrorIconThreshold {
  min?: number | number[][]
  minLabel?: string
  max?: number | number[][]
  maxLabel?: string
  raisedIcon?: boolean
}

const optimiseDynamicConstraintValues = (constraintSeries: number[][]): number[][] => {
  if (constraintSeries === null) {
    return []
  }

  if (constraintSeries.length < 2) {
    return constraintSeries
  }

  // Step 0: Ensure constraintd are ordered correctly.
  // TODO(john-paul.holt): Remove this once BE has fixed the problem.
  // https://app.shortcut.com/phaidra/story/58200/dynamic-constraints-be-constraints-arrays-are-not-ordered-by-date
  const sortedConstrainSeries = [...constraintSeries].sort((a, b) => {
    if (a[0] === b[0]) {
      return 0
    }
    return (a[0] < b[0]) ? -1 : 1
  })

  // Step 1: Remove excess constraint datapoints from the series.
  // For example, a constraint line that looks like •──•──•──• becomes •────────•
  const constraints = sortedConstrainSeries.filter(
    (value, index) =>
      index === 0
      || index === constraintSeries.length - 1
      || !(
        value[1] === constraintSeries[index - 1][1]
        && value[1] === constraintSeries[index + 1][1]
      ),
  )

  // Step 2: Add intermediate datapoints to ensure constraints lines are stepped.
  // For example, avoid ___/‾‾\___ and instead show ___|‾‾‾|___
  let index = 1

  while (index < constraints.length) {
    const currentSerie = constraints[index]
    const previousSerie = constraints[index - 1]

    if (currentSerie[1] !== previousSerie[1]) {
      const intermediateValue = [
        currentSerie[0],
        previousSerie[1],
      ]
      constraints.splice(index, 0, intermediateValue)
      index += 1
    }

    index += 1
  }

  return constraints
}

const minThreshold = (
  threshold: ErrorIconThreshold,
  index?: number,
): number | undefined => {
  if (typeof threshold.min === 'number') {
    return threshold.min as number
  }
  if (index !== undefined && Array.isArray(threshold.min)) {
    return (threshold.min as number[][])[index][1]
  }
  return undefined
}

const maxThreshold = (
  threshold: ErrorIconThreshold,
  index?: number,
): number | undefined => {
  if (typeof threshold.max === 'number') {
    return threshold.max as number
  }
  if (index !== undefined && Array.isArray(threshold.max)) {
    return (threshold.max as number[][])[index][1]
  }
  return undefined
}

const convertSeriesToZones = (
  series: SeriesOptionsType[],
  lowLimitSeries: SeriesOptionsType,
  highLimitSeries: SeriesOptionsType,
): SeriesOptionsType[] => {
  const results: SeriesOptionsType[] = []

  const timeStampIsAlsoWithinAThresholdSeries = (timestamp: number): boolean => {
    let result = false
    const thresholds: SeriesOptionsType[] = [
      highLimitSeries,
      lowLimitSeries,
    ].filter((threshold) => threshold !== undefined)

    thresholds.forEach((serie: SeriesOptionsType) => {
      if (serie.type === 'line') {
        const timestamps: number[] = (serie.data as number[][]).map((x: number[]): number => x[0])
        if (timestamps.includes(timestamp)) {
          result = true
        }
      }
    })
    return result
  }

  series.forEach((serie: SeriesOptionsType) => {
    if (serie.type === 'line') {
      let data: Highcharts.PointOptionsObject[] = []
      let { showInLegend } = serie
      if (showInLegend === undefined) showInLegend = true

      // Empty series are sometimes used for controls in the x-axis legend.
      // We need to ensure they don't get filtered out when constraints are specified.
      if (serie.data.length === 0) {
        results.push(serie)
        return
      }

      serie.data.forEach((value: Highcharts.PointOptionsObject, index: number) => {
        data.push(value)
        if (timeStampIsAlsoWithinAThresholdSeries(value.x)) {
          const serieWithSection: SeriesOptionsType = {
            ...serie,
            showInLegend,
            data,
            point: {
              events: {
                mouseOver: (event) => {
                  const point: Highcharts.Point = (event.target as unknown) as Highcharts.Point
                  const { name } = point.series
                  point.series.chart.series
                    .filter((s: Highcharts.Series) => s.name === name)
                    .forEach((s: Highcharts.Series) => s.setState('hover'))
                },
              },
            },
          }

          // Ignore the first value - it is a duplicate of the first data value in the
          // next array based on how the constraints are sliced up.
          // TODO(john-paul.holt): Find a more elegant way to handle this.
          if (index > 0) {
            results.push(serieWithSection)
            showInLegend = false
          }
          data = [value]
        }
      })
    }
  })

  // Returns the first "low constraint" for a given serie.
  // The serie's timestamp (or x axis value) may not have a corresponding constraint
  // so we need to find the first constraint that has an x-axis value >= the serie's x-axis value.
  const getLowConstraintForAGivenSerie = (serie: SeriesOptionsType): number | null => {
    let result: number

    if (serie.type === 'line' && serie.data.length > 0) {
      const firstDataValue = (serie.data[0] as Highcharts.PointOptionsObject)
      if (lowLimitSeries.type === 'line') {
        const matchedResults = lowLimitSeries.data
          .filter((lowLimitSerie: number[]) => lowLimitSerie[0] >= firstDataValue.x)

        if (matchedResults.length > 0) {
          if (typeof matchedResults === 'number') {
            return matchedResults[0]
          }

          const firstResultObj = matchedResults[0] as [string | number, number]
          const nextResultObj = matchedResults[1] as [string | number, number] | undefined

          // In some cases, the constraint has two y axis values for the same x axis value
          // when the constraint value changes dynamically e.g. [1,0],[2,0], ... [56,0],[56,1], ... [100,1]
          // When this happens we need to get the second y axis value as opposed to the first.
          if (nextResultObj && firstResultObj[0] === nextResultObj[0]) {
            return nextResultObj[1]
          }

          return firstResultObj[1]
        }
      }
    }

    return result
  }

  // Returns the first "high constraint" for a given serie.
  // The serie's timestamp (or x axis value) may not have a corresponding constraint
  // so we need to find the first constraint that has an x-axis value >= the serie's x-axis value.
  const getHighConstraintForAGivenSerie = (serie: SeriesOptionsType): number | null => {
    let result: number

    if (serie.type === 'line' && serie.data.length > 0) {
      const firstDataValue = (serie.data[0] as Highcharts.PointOptionsObject)
      if (highLimitSeries.type === 'line') {
        const matchedResults = highLimitSeries.data
          .filter((highLimitSerie: number[]) => highLimitSerie[0] >= firstDataValue.x)

        if (matchedResults.length > 0) {
          if (typeof matchedResults === 'number') {
            return matchedResults[0]
          }

          const firstResultObj = matchedResults[0] as [string | number, number]
          const nextResultObj = matchedResults[1] as [string | number, number] | undefined

          // In some cases, the constraint has two y axis values for the same x axis value
          // when the constraint value changes dynamically e.g. [1,0],[2,0], ... [56,0],[56,1], ... [100,1]
          // When this happens we need to get the second y axis value as opposed to the first.
          if (nextResultObj && firstResultObj[0] === nextResultObj[0]) {
            return nextResultObj[1]
          }

          return firstResultObj[1]
        }
      }
    }

    return result
  }

  results.map((serie: SeriesOptionsType) => {
    if (serie.type === 'line') {
      serie.zones = []
      serie.zoneAxis = 'y'
      const lowZone: number | null = lowLimitSeries
        ? getLowConstraintForAGivenSerie(serie)
        : null
      const highZone: number | null = highLimitSeries
        ? getHighConstraintForAGivenSerie(serie)
        : null

      if (lowZone !== null && highZone !== null) {
        serie.zones.push({
          color: ERROR_COLOR,
          value: lowZone,
        })
        serie.zones.push({
          value: highZone,
        })
        serie.zones.push({
          color: ERROR_COLOR,
        })
      } else if (highZone !== null) {
        serie.zones.push({
          value: highZone,
        })
        serie.zones.push({
          color: ERROR_COLOR,
        })
      } else if (lowZone !== null) {
        serie.zones.push({
          color: ERROR_COLOR,
        })
        serie.zones.push({
          value: lowZone,
        })
      }
    }

    return serie
  })

  if (lowLimitSeries) results.push(lowLimitSeries)
  if (highLimitSeries) results.push(highLimitSeries)
  return results
}

export default (
  options: Highcharts.Options = {},
  threshold?: ErrorIconThreshold,
): Highcharts.Options => {
  if (!threshold || (!threshold.min && !threshold.max)) {
    return options
  }

  const yAxis: CustomYAxisOptions = {
    ...options.yAxis,
    softMin: getSoftMin(options.series),
    softMax: getSoftMax(options.series),
    plotLines: [
      ...(options.yAxis as Highcharts.YAxisOptions)?.plotLines || [],
    ],
  }

  const dataSeries: SeriesOptionsType[] = options.series.map((serie: SeriesOptionsType) => {
    if (serie.type === 'line') {
      const data = [...serie.data]

      // Groups any points that exceed a threshold into clusters of [x,y] values
      // For example:
      // min = 0   = [ [[6,-1],[7,-1]], [[14,-1],[15,-12][16,-9]] ]
      // max = 10  = [ [[1,10][2,11]], [[3,14]], [[19,50]] ]
      // TODO(john-paul.holt): This would be better as a HashMap, with the key as the x index.
      const minThresholdGroups: number[][][] = []
      const maxThresholdGroups: number[][][] = []

      const errors: {
        icon: string
        x: number
        y: number
        index: Number
      }[] = []

      if (threshold.min !== undefined) {
        let minGroup: number[][] = []
        data.forEach((datum, index) => {
          if (Array.isArray(threshold.min) && (threshold.min[index] === undefined)) {
            return
          }

          const point = datum as [string | number, number]
          const thresholdPoint = minThreshold(threshold, index)

          if (thresholdPoint !== undefined && point[1] !== null && point[1] < thresholdPoint) {
            minGroup.push([point[0] as number, point[1]])
          } else if (minGroup.length > 0) {
            minThresholdGroups.push(minGroup)
            minGroup = []
          }
        })
        if (minGroup.length > 0) minThresholdGroups.push(minGroup)
      }

      // Get the minimum values by sorting each minThresholdGroups cluster by the
      // y-axis value, then returning the first result for each one using a map.
      // For example [ [[6,-1],[7,-1]], [[14,-1],[15,-12][16,-9]] ]
      // becomes     [ [6,-1], [15,-2] ]
      minThresholdGroups
        .map((x) => x.sort((a: number[], b: number[]) => {
          if (a[1] < b[1]) return -1
          if (a > b) return 1
          return 0
        })[0])
        .forEach((smallestValueInThresholdGroup) => {
          errors.push({
            icon: threshold.raisedIcon ? exclamationTriangleBelow : exclamationTriangle,
            x: smallestValueInThresholdGroup[0] as number,
            y: smallestValueInThresholdGroup[1],
            index: smallestValueInThresholdGroup[0],
          })
        })

      if (threshold.max !== undefined) {
        let maxGroup: number[][] = []
        data.forEach((datum, index) => {
          if (Array.isArray(threshold.max)) {
            if (threshold.max[index] === undefined || (threshold.max[index][1] === null)) {
              return
            }
          }

          const point = datum as [string | number, number]
          const thresholdPoint = maxThreshold(threshold, index)
          if (thresholdPoint !== undefined && point[1] !== null && point[1] > thresholdPoint) {
            maxGroup.push([point[0] as number, point[1]])
          } else if (maxGroup.length > 0) {
            maxThresholdGroups.push(maxGroup)
            maxGroup = []
          }
        })
        if (maxGroup.length > 0) maxThresholdGroups.push(maxGroup)
      }

      // Get the maximum values by sorting each minThresholdGroups cluster by the
      // y-axis value, then returning the first result for each one using a map.
      // For example [ [[1,10][2,11]], [[3,14]], [[19,50]] ]
      // becomes     [ [2,11], [19,50] ]
      maxThresholdGroups
        .map((x) => x.sort((a: number[], b: number[]) => {
          if (a[1] > b[1]) return -1
          if (a < b) return 1
          return 0
        })[0])
        .forEach((largestValueInThresholdGroup) => {
          errors.push({
            icon: threshold.raisedIcon ? exclamationTriangleAbove : exclamationTriangle,
            x: largestValueInThresholdGroup[0] as number,
            y: largestValueInThresholdGroup[1],
            index: largestValueInThresholdGroup[0],
          })
        })

      const dataAsAnObjectArray: Highcharts.PointOptionsObject[] = data.map((datum: [string | number, number]) => {
        const point: Highcharts.PointOptionsObject = {
          x: datum[0] as number,
          y: datum[1],
        }

        // TODO(john-paul.holt): Look into a HashMap for this (much quicker, but more memory).
        errors.forEach((error) => {
          if (error.x === datum[0]) {
            point.marker = {
              symbol: `url(${error.icon})`,
              enabled: true,
            }
          }
        })

        return point
      })

      const result = {
        ...serie,
        data: dataAsAnObjectArray,
      }
      return result
    }

    return serie
  })

  const getLongestSeriesLength = () => {
    const lineSeries = options.series.filter(
      (serie: SeriesOptionsType) => (serie.type === 'line'),
    )
    if (lineSeries.length === 0) {
      return 0
    }

    return lineSeries.map(
      (serie: SeriesOptionsType) => (serie.type === 'line' ? serie.data.length : 1),
    ).reduce((acc, number) => Math.max(acc, number))
  }

  let lowLimitSeries: SeriesOptionsType
  if (threshold.min !== undefined) {
    const data = typeof threshold.min === 'number'
      ? [[0, threshold.min], [getLongestSeriesLength() - 1, threshold.min]]
      : optimiseDynamicConstraintValues(threshold.min as number[][])

    lowLimitSeries = addThresholdSeries({
      label: 'Low Limit',
      data,
      limit: ThresholdLimit.Low,
    })
  }

  let highLimitSeries: SeriesOptionsType
  if (threshold.max !== undefined) {
    const data = typeof threshold.max === 'number'
      ? [[0, Number(threshold.max)], [getLongestSeriesLength() - 1, Number(threshold.max)]]
      : optimiseDynamicConstraintValues(threshold.max as number[][])

    highLimitSeries = addThresholdSeries({
      label: 'High Limit',
      data,
      limit: ThresholdLimit.High,
    })
  }

  const hasAtLeastOneConstraint = lowLimitSeries !== undefined || highLimitSeries !== undefined
  const series = hasAtLeastOneConstraint
    ? convertSeriesToZones(dataSeries, lowLimitSeries, highLimitSeries)
    : dataSeries

  return {
    ...options,
    yAxis,
    plotOptions: {
      series: {
        events: {
          legendItemClick: (ev) => {
            if (hasAtLeastOneConstraint) {
              ev.preventDefault()
              const { name } = ev.target
              const isVisibile = ev.target.visible === true

              ev.target.xAxis.series
                .filter((serie) => serie.name === name)
                .forEach((serie) => serie.setVisible(!isVisibile))
            }
          },
        },
      },
    },
    series: [
      ...series,
      {
        type: 'line',
        name: 'Excursion',
        color: '#BA1B1B',
        data: [],
        legendIndex: 9,
        showInLegend: true,
      },
      {
        name: 'Limit',
        legendIndex: 10,
        showInLegend: true,
      },
    ],
  } as Highcharts.Options
}
