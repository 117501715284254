import React, {
  createContext, useState, useEffect, useContext, ReactNode, useMemo, useCallback,
} from 'react'
import { useTimezoneUtils } from '@phaidra/utils/timezone'

interface TimezoneContextProps {
  timezonePreference: {
    offset: string
    type: string
  }
  setTimezonePreference: (preference: { offset?: string, type?: string }) => void
}

const TimezoneContext = createContext<TimezoneContextProps>({
  timezonePreference: {
    offset: '',
    type: 'Local',
  },
  setTimezonePreference: () => {},
})

interface TimezoneProviderProps {
  children: ReactNode
}

const STORAGE_KEY = 'timezoneUserPreference'

const getStoredPreference = () => {
  const stored = localStorage.getItem(STORAGE_KEY)
  if (stored) {
    try {
      return JSON.parse(stored)
    } catch {
      return { offset: '', type: 'Local' }
    }
  }
  return { offset: '', type: 'Local' }
}

const initialTimezonePreference = (getTimezoneOffset: (tz?: string, unit?: string) => string) => {
  const storedValue = getStoredPreference()

  return {
    offset: storedValue.offset || getTimezoneOffset(undefined, 'minutes').replace(/^\+/, ''),
    type: storedValue.type || 'Local',
  }
}

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({ children }) => {
  const { getTimezoneOffset } = useTimezoneUtils()

  const [timezonePreference, setTimezonePreferenceState] = useState(() => initialTimezonePreference(getTimezoneOffset))

  const setTimezonePreference = useCallback(
    (preference: { offset: string, type: string }) => {
      const newOffset = preference.offset?.replace(/^\+/, '') || timezonePreference.offset
      const newType = preference.type || timezonePreference.type

      const newPreference = {
        offset: newOffset,
        type: newType,
      }

      localStorage.setItem(STORAGE_KEY, JSON.stringify(newPreference))
      setTimezonePreferenceState(newPreference)
    },
    [timezonePreference],
  )

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === STORAGE_KEY && event.newValue) {
        const updatedPreference = JSON.parse(event.newValue)
        setTimezonePreferenceState({
          offset: updatedPreference.offset.replace(/^\+/, '') || getTimezoneOffset(undefined, 'minutes'),
          type: updatedPreference.type || 'Local',
        })
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [getTimezoneOffset])

  const value = useMemo(() => ({
    timezonePreference,
    setTimezonePreference,
  }), [timezonePreference, setTimezonePreference])

  return (
    <TimezoneContext.Provider value={value}>
      {children}
    </TimezoneContext.Provider>
  )
}

export const useTimezone = () => useContext(TimezoneContext)
