import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import styled from '../../styled'

export const StyledTextField = styled(
  (props: TextFieldProps) => (<TextField variant="filled" {...props} />),
)<{ error?: boolean, size?: 'medium' | 'small' }>(({ theme, error, size }) => {
  const inputStyles = size === 'small' ? {
    fontSize: theme.typography.body.fontSize,
    marginRight: theme.spacing(-0.75),
    paddingTop: theme.spacing(0.5),

    '&::placeholder': {
      color: theme.palette.text.subdued,
      opacity: 1,
    },
  } : {
    fontSize: '1rem',
    marginRight: theme.spacing(-0.5),
    paddingTop: theme.spacing(2.625),
    paddingLeft: theme.spacing(1.5),
  }

  return {
    width: '251px',
    height: size === 'small' ? '28px' : '44px',
    '& .Mui-disabled': {
      color: `${theme.palette.text.disabled} !important`,
      '& svg': {
        color: theme.palette.text.disabled,
      },
    },
    '& .MuiFilledInput-root': {
      color: theme.palette.text.default,
      border: `solid 1px ${error ? theme.palette.error.main : theme.palette.container.border.default}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.container.surface.drawer,
      '&::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.hover.surface.default,
        borderColor: error ? theme.palette.error.main : theme.palette.container.border.default,
        color: theme.palette.text.default,
      },
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: theme.palette.container.surface.disabled,
      borderColor: theme.palette.container.surface.disabled,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiInputLabel-root': {
      fontSize: size === 'small' ? '0.75rem' : '1rem',
      marginTop: theme.spacing(-0.625),
      color: theme.palette.text.subdued,
      '&.Mui-focused': {
        color: theme.palette.text.subdued,
      },
      '&.Mui-error': {
        color: theme.palette.text.subdued,
      },
    },
    '& input': inputStyles,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& svg': {
      position: 'relative',
      width: '20px',
      height: '20px',
      color: theme.palette.default.main,
    },
  }
})
