import React, {
  FC,
  useState,
  useEffect,
  memo,
} from 'react'
import Grid from '@phaidra/ava/grid'
import { useLocation } from 'react-router-dom'
import Button from '@phaidra/ava/button'
import Divider from '@phaidra/ava/divider'
import Typography from '@phaidra/ava/typography'
import Accordion from '@phaidra/ava/accordion'
import AccordionDetails from '@phaidra/ava/accordion-details'
import Search from '@phaidra/ava/search/search'
import { AddIcon, TrashIcon } from '@phaidra/ava/icons'
import { useSnackbar } from '@phaidra/ava/snackbar'

import { useTrendViewerProps } from '../../provider'
import { updateQueryData } from '../../services/trend-viewer'
import SidebarCommon from '../../common/sidebar'
import { OPEN_WIDTH } from '../../common/sidebar/styles'
import { Views, View } from '../../services/trend-viewer/types'
import {
  Container,
  TabTitle,
  StyledButton,
  StyledAccordionSummary,
  StyledIconButton,
} from './styles'
import DeleteViewDialog from './delete-view-dialog'
import MAX_SAVED_VIEWS from '../utils/saved-views'

export interface SidebarApi {
  setSavedOpen: (open: boolean) => void
}

interface Props {
  views: Views['data']
  selectedViewId: number | null
  setSelectedViewId: (viewId: number | null) => void
  createNewView: () => void
  isDirty: boolean
  onBlock: (unblock: (jumpToNext?: boolean) => void) => void
  onApiReady: (api: SidebarApi) => void
  setComponentViewId: (viewId: number | null) => void
}

const Sidebar: FC<Props> = ({
  views,
  selectedViewId,
  setSelectedViewId,
  createNewView,
  isDirty,
  onBlock,
  onApiReady,
  setComponentViewId,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [init, setInit] = useState(false)
  const [search, setSearch] = useState('')
  const [hover, setHover] = useState<string>(null)
  const [savedOpen, setSavedOpen] = useState(true)
  const [showDeleteDialog, setShowDeleteDialog] = useState<View | null>(null)
  const [componentsOpen, setComponentsOpen] = useState(false)
  const [filteredViews, setFilteredViews] = useState<Views['data']>({ saved: [], component: [] })
  const { useAppDispatch } = useTrendViewerProps()
  const location = useLocation()
  const installationId = new URLSearchParams(location.search).get('installationId')
  const dispatch = useAppDispatch()

  useEffect(() => {
    onApiReady({
      setSavedOpen,
    })
  }, [onApiReady, setSavedOpen])

  useEffect(() => {
    if (views && !init) {
      const getChartTags = new URLSearchParams(location.search)
      const chartTag1 = getChartTags.get('chartTag1')
      const chartTag2 = getChartTags.get('chartTag2')
      const chartTag3 = getChartTags.get('chartTag3')

      if (chartTag1 || chartTag2 || chartTag3) {
        setTimeout(() => {
          dispatch(
            updateQueryData(
              'getViews',
              { installationId },
              (draft) => {
                draft.unsaved.tags = [
                  chartTag1 ? chartTag1.split(',') : [],
                  chartTag2 ? chartTag2.split(',') : [],
                  chartTag3 ? chartTag3.split(',') : [],
                ]
                return draft
              },
            ),
          )
          setTimeout(() => {
            setSelectedViewId(views.unsaved.id)
          }, 0)
        }, 0)
      }

      setInit(true)
    }
  }, [views, init, setSelectedViewId, location, dispatch, installationId])

  useEffect(() => {
    setFilteredViews((prev) => {
      const newFilteredViews = {
        saved: views.saved.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase())),
        component: views.component.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase())),
      }
      if (prev.component.length > 0 && newFilteredViews.component.length === 0 && newFilteredViews.saved.length > 0) {
        setSavedOpen(true)
        setComponentsOpen(false)
      }
      if (prev.saved.length > 0 && newFilteredViews.saved.length === 0 && newFilteredViews.component.length > 0) {
        setComponentsOpen(true)
        setSavedOpen(false)
      }
      return newFilteredViews
    })
  }, [views, search])

  return (
    <>
      <SidebarCommon>
        <Container>
          <div>
            <Divider color="low" sx={{ width: '100%', mb: 1.875 }} />
            <div style={{ padding: '0 20px', width: '260px' }}>
              <Typography fontSize="1rem" fontWeight="700" color="text.default">View Selection</Typography>
              <Button
                sx={{ ml: -0.625, color: 'text.default', fontWeight: 700 }}
                onClick={() => {
                  if (filteredViews.saved.length >= MAX_SAVED_VIEWS) {
                    enqueueSnackbar('Delete a Saved View to save. Views are currently full.', { variant: 'warning' })
                  }

                  if (selectedViewId !== -1) {
                    dispatch(
                      updateQueryData(
                        'getViews',
                        { installationId },
                        (draft) => {
                          draft.unsaved.tags = [[], [], []]
                          return draft
                        },
                      ),
                    )
                  }
                  setTimeout(() => {
                    if (!isDirty) {
                      createNewView()
                    } else {
                      onBlock(() => () => {
                        createNewView()
                      })
                    }
                  }, 0)
                }}
                startIcon={<AddIcon />}
                variant="text"
              >
                Create view
              </Button>
              <div style={{ marginTop: '5px' }}>
                <Search onChange={setSearch} fullWidth isThin />
              </div>
              <Divider color="low" sx={{ marginTop: 2.5 }} />
            </div>
            <Accordion
              noSeparator
              disabled={filteredViews.saved.length === 0}
              expanded={savedOpen}
              onChange={(_, expanded) => {
                setSavedOpen(expanded)
                if (expanded) {
                  setComponentsOpen(false)
                }
              }}
              sx={{ width: `${OPEN_WIDTH}px` }}
            >
              <StyledAccordionSummary>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="body" color="icon.disabledInverse" fontWeight={700}>Saved Views</Typography>
                  <Typography variant="caption" color="icon.disabledInverse" sx={{ mt: 0.125 }}>
                    {`${filteredViews.saved.length}/${MAX_SAVED_VIEWS}`}
                  </Typography>
                </Grid>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" data-testid="saved-views">
                  {filteredViews.saved.map(({ id, title }, index) => (
                    <StyledButton
                      key={title}
                      onMouseOver={() => setHover(title)}
                      onMouseOut={() => setHover(null)}
                      onClick={() => {
                        setComponentViewId(0)
                        if (!isDirty) {
                          setSelectedViewId(filteredViews.saved[index].id)
                        } else {
                          onBlock(() => () => {
                            setSelectedViewId(filteredViews.saved[index].id)
                          })
                        }
                      }}
                      size="small"
                      variant="text"
                    >
                      <Grid container justifyContent="space-between" alignItems="center">
                        <TabTitle active={id === selectedViewId}>{title}</TabTitle>
                        {hover === title && (
                          <StyledIconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              setShowDeleteDialog(
                                [...views.component, ...views.saved].find((view) => view.id === id),
                              )
                            }}
                          >
                            <TrashIcon sx={{ fontSize: '18px' }} />
                          </StyledIconButton>
                        )}
                      </Grid>
                    </StyledButton>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            {filteredViews.component.length > 0 && (
              <Accordion
                noSeparator
                expanded={componentsOpen}
                onChange={(_, expanded) => {
                  setComponentsOpen(expanded)
                  if (expanded) {
                    setSavedOpen(false)
                  }
                }}
                sx={{ width: `${OPEN_WIDTH}px` }}
              >
                <StyledAccordionSummary>
                  <Typography variant="body" color="icon.disabledInverse" fontWeight={700}>Template Views</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <Grid container direction="column" data-testid="template-views">
                    {filteredViews.component.map(({ title, id }, index) => (
                      <StyledButton
                        key={title}
                        onMouseOver={() => setHover(title)}
                        onMouseOut={() => setHover(null)}
                        onClick={() => {
                          setComponentViewId(filteredViews.component[index].id)
                          if (!isDirty) {
                            setSelectedViewId(filteredViews.component[index].id)
                          } else {
                            onBlock(() => () => {
                              setSelectedViewId(filteredViews.component[index].id)
                            })
                          }
                        }}
                        size="small"
                        variant="text"
                      >
                        <Grid container justifyContent="space-between" alignItems="center">
                          <TabTitle active={selectedViewId === id}>{title}</TabTitle>
                        </Grid>
                      </StyledButton>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {filteredViews.saved.length === 0 && filteredViews.component.length === 0 && (
              <Typography variant="body" color="text.default" p={2.5} sx={{ pt: 1.875 }}>
                No results available. Try again.
              </Typography>
            )}
          </div>
        </Container>
      </SidebarCommon>
      {showDeleteDialog !== null && (
        <DeleteViewDialog
          view={showDeleteDialog}
          onClose={() => {
            setShowDeleteDialog(null)
          }}
        />
      )}
    </>
  )
}

export default memo(Sidebar)
