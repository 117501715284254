import MUIFormControlLabel from '@mui/material/FormControlLabel'

import styled from '../styled'

const FormControlLabel = styled(MUIFormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    marginLeft: '8px',
    fontSize: '0.875rem',
    color: theme.palette.mode === 'dark' ? '#F2F2F2' : '#1A1C19',
    '&.Mui-disabled': {
      color: theme.palette.mode === 'dark' ? '#B8B8B9' : '#58585A',
    },
  },
  '&.MuiFormControlLabel-labelPlacementEnd': {
    marginLeft: '0',
  },
  marginRight: '8px',
}))

export default FormControlLabel
