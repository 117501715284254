import styled from '@phaidra/ava/styled'
import DataGrid from '@phaidra/ava/data-grid'
import InputBase from '@phaidra/ava/input-base'
import TextField from '@phaidra/ava/text-field'
import Typography from '@phaidra/ava/typography'
import Grid from '@phaidra/ava/grid'
import { ChevronDownIcon } from '@phaidra/ava/icons'

export const MuiDataGrid = styled(DataGrid)`
  & .status-column {
    max-height: fit-content !important;
    align-items: flex-start;
    padding-top: 11px;
  }

  & .MuiDataGrid-row {
    max-height: fit-content !important;
  }

  & .param-record {
    padding-left: 120px;
  }

  & .MuiDataGrid-columnHeaderTitle:first-of-type {
    margin: 12px;
  }
`

export const SwitchContainer = styled('div')`
  width: 60px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 12px;
`

export const SetpointFullName = styled('span')`
  width: 60px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 12px;
`

export const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (prop: string) => !['isError', 'height'].includes(prop),
})<{ isError?: boolean, height?: string }>(({ isError, height, theme }) => ({
  flex: 2,
  height,
  minHeight: '44px',
  border: '1px solid #58585A',
  minWidth: '100%',
  borderRadius: '4px',
  display: 'block',
  padding: '7px 10px',
  outline: 'none',
  fontSize: theme.typography.body.fontSize,
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  ...(isError && { borderColor: '#BA1B1B' }),
}))

export const StyledTextField = styled(TextField)(({ disabled, theme }) => ({
  input: {
    ...theme.typography.body,
  },
  ...(disabled && {
    '& .MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline': {
      backgroundColor: '#fff !important',
    },
  }),
}))

export const CaptionText = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme?.palette?.default?.light,
  fontWeight: 500,
}))

export const NotesEditorContainer = styled(Grid)({
  input: {
    margin: '0!important',
  },
})

export const KeyboardArrowUpIconContainer = styled(ChevronDownIcon, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
  color: 'inherit',
  height: '20px',
  width: '20px',
  textTransform: 'none',
  ...(isOpen && { transform: 'rotate(180deg)' }),
  transition: 'transform 0.2s',
}))

export const SBRName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>(({ disabled, theme }) => ({
  color: disabled ? theme.palette.text.subdued : theme.palette.default.main,
  fontWeight: 500,
}))

export const SBRValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>(({ disabled, theme }) => ({
  color: disabled ? theme.palette.text.subdued : theme.palette.default.main,
  fontWeight: 400,
  whiteSpace: 'normal',
  wordBreak: 'break-word',
}))
