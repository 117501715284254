import styled from '@phaidra/ava/styled'

export const Backdrop = styled('div')(({ theme }) => ({
  position: 'fixed',
  pointerEvents: 'none',
  backgroundColor: theme.palette.container.surface.activeHigh,
  zIndex: 101,
  opacity: 0.45,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}))

export const Container = styled('div')({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 103,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
})
