import { createTheme, Palette, ThemeOptions } from '@mui/material/styles'
import deepmerge from '@mui/utils/deepmerge'
import common, { colors } from './common'

const light: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: colors.gray[10],
    },
    text: {
      primary: colors.gray[1],
      secondary: colors.gray[4],
      default: colors.gray[1],
      defaultInverse: colors.gray[9],
      subdued: colors.gray[4],
      subduedInverse: colors.gray[5],
      disabled: colors.gray[5],
      active: colors.green[2],
      link: colors.blue[2],
      websiteGold: colors.gold[3],
      syntaxDefault: colors.gray[4],
      syntaxString: '#008C28',
      syntaxNumber: colors.gold[2],
      syntaxPunctuation: '#CCAF75',
      syntaxBuiltin: '#5928F9',
      syntaxOperator: colors.red[2],
      syntaxKeyword: colors.gray[1],
    },
    primary: {
      main: colors.green[2],
    },
    secondary: {
      main: colors.gold[4],
    },
    info: {
      main: colors.blue[2],
    },
    error: {
      main: colors.red[2],
    },
    dividers: {
      default: colors.gray[4],
      low: colors.gray[5],
      high: colors.gray[2],
    },
  } as Palette,
}

export default createTheme(deepmerge(common, light))
