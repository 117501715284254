import styled from '@phaidra/ava/styled'

export const SlideButton = styled('div')<{ isLeft?: boolean }>(({ isLeft, theme }) => ({
  ...(isLeft ? {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  } : {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  }),
  height: '12px',
  width: '16px',
  backgroundColor: theme.palette.container.border.subdued,
  marginTop: theme.spacing(0.375),
  border: 'none',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}))

export const SliderContainer = styled('div')({
  position: 'relative',
  height: '18px',
  flex: 1,
})

export const DraggableWindow = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '12px',
  backgroundColor: theme.palette.icon.disabledInverse,
  marginTop: theme.spacing(0.375),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'ew-resize',
}))

export const DraggableButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '11px',
  height: '18px',
  border: `solid 1px ${theme.palette.text.default}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.container.surface.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'ew-resize',
}))

export const BackgroundBar = styled('div')(({ theme }) => ({
  width: '100%',
  height: '12px',
  backgroundColor: theme.palette.container.surface.low,
  marginTop: theme.spacing(0.375),
}))

export const WindowIcon = styled('img')(({ theme }) => ({
  width: '10px',
  height: '8px',
  marginBottom: theme.spacing(0.125),
  marginLeft: theme.spacing(0.125),
}))
