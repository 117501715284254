import { Ingestion, IngestionStatus, IngestionStatusLabels } from '@services/historical-data-ingestion/types'
import React, {
  createContext, useState, useMemo, PropsWithChildren, useEffect,
} from 'react'
import { useGetCombinedDataQueryState, useLazyGetCombinedDataQuery } from '@services/historical-data-ingestion'
import { useGetInstallationQueryState } from '@services/installation'

import { prependIngestion } from '@services/historical-data-ingestion/utils/transformers'
import { useQueryParam } from '@common/use-query-param'
import {
  useLocalStorage, useNotification, useStartIngestionProcess,
} from './hooks'
import { IngestionsContextValue, ProcessingIngestion } from './types'

export const IngestionsContext = createContext<IngestionsContextValue | undefined>(undefined)

export const IngestionsProvider = ({ children }: PropsWithChildren) => {
  const installationId = useQueryParam('installationId')
  const [processingIngestion, setProcessingIngestion] = useLocalStorage<ProcessingIngestion>(
    'processingIngestion',
    null,
  )
  const { notifySuccess, notifyError } = useNotification()
  const [startIngestion, { isLoading: isIngesting }] = useStartIngestionProcess({ onComplete: setProcessingIngestion })

  const requestId = installationId || processingIngestion?.installationId || ''
  const hasIngestionStarted = (ingestions: Ingestion[]) => isIngesting
        || ingestions.some((i) => i.status_label === IngestionStatusLabels.PROCESSING)

  const [ingestionTimestamps, setIngestions] = useState<Ingestion[]>([])
  const [duplicateTimestamps, setDuplicateTimestamps] = useState<Ingestion[]>([])

  const installationData = useGetInstallationQueryState(undefined, {
    selectFromResult: (response) => response?.data?.data.find(({ id }) => id === requestId),
  })

  const {
    data: cachedIngestionTimestamps,
    isError: pollingError,
  } = useGetCombinedDataQueryState({ installationId: requestId })

  const [getCombinedData, { data, isLoading: isCombinedDataLoading, isError }] = useLazyGetCombinedDataQuery({
    pollingInterval: pollingError || !processingIngestion
        || processingIngestion.timestamp.status !== IngestionStatus.PROCESSING ? 0 : 6000,
  })

  const [isIngestionsLoading, setIsIngestionsLoading] = useState(false)

  const handleStartIngestion = (timestamp: Ingestion) => {
    setDuplicateTimestamps((prev) =>
      prev.filter((item) => item.formattedTimestamp !== timestamp.formattedTimestamp))
    startIngestion(installationId, timestamp)
  }

  const handleDuplicateIngestion = (ingestion: Ingestion) => {
    const { list, duplicate } = prependIngestion(ingestionTimestamps, ingestion)
    if (!duplicateTimestamps.some((i) => i.id === duplicate.id)) {
      setDuplicateTimestamps([
        ...duplicateTimestamps,
        duplicate,
      ])
    }
    setIngestions(list)
  }

  const handleIngestionNotifications = (
    serverData: Ingestion[],
    clientData: Ingestion[],
  ) => {
    const activeIngestionIndex = serverData?.findIndex((i) => i.status_label === IngestionStatusLabels.ACTIVE)
    const failedIngestionIndex = serverData?.findIndex((i) => i.status === IngestionStatus.ERROR)
    const hasIngestionFinished = hasIngestionStarted(clientData) && !hasIngestionStarted(serverData)
    const dataContainsActiveIngestion = activeIngestionIndex > -1
    const dataContainsIngestionFailure = failedIngestionIndex > -1
    const dataContainsRecentIngestionFailure = failedIngestionIndex < activeIngestionIndex
    const reIngest = () => handleStartIngestion(serverData[failedIngestionIndex])
    const ongoingIngestion = serverData?.find(({ status }) => status === IngestionStatus.PROCESSING)

    if (hasIngestionFinished) {
      if (dataContainsActiveIngestion) {
        if (!dataContainsIngestionFailure || !dataContainsRecentIngestionFailure) {
          notifySuccess(serverData[activeIngestionIndex].formattedTimestamp)

          if (processingIngestion?.timestamp) {
            setProcessingIngestion({
              ...processingIngestion,
              timestamp: {
                ...processingIngestion.timestamp,
                status: IngestionStatus.COMPLETED,
              },
            })
          }
        } else {
          notifyError(serverData[failedIngestionIndex], reIngest)
          if (processingIngestion?.timestamp) {
            setProcessingIngestion({
              ...processingIngestion,
              timestamp: {
                ...processingIngestion.timestamp,
                status: IngestionStatus.ERROR,
              },
            })
          }
        }
      }

      if (!dataContainsActiveIngestion && dataContainsIngestionFailure) {
        notifyError(serverData[failedIngestionIndex], reIngest)
      }
    }

    if (!processingIngestion && ongoingIngestion) {
      setProcessingIngestion({
        installationId,
        visible: true,
        timestamp: {
          ...ongoingIngestion,
          id: ongoingIngestion.id,
          status: IngestionStatus.PROCESSING,
          started_at: ongoingIngestion.started_at,
        },
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!requestId) return
      try {
        setIsIngestionsLoading(true)
        setIngestions([])
        setDuplicateTimestamps([])

        const ingestions = await getCombinedData({ installationId: requestId }).unwrap()
        setIngestions([...duplicateTimestamps, ...ingestions ?? []])

        handleIngestionNotifications(ingestions, ingestionTimestamps)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setIsIngestionsLoading(false)
      }
    }

    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId])

  useEffect(() => {
    const ingestionTimestampsRespone = isError ? cachedIngestionTimestamps : data
    setIngestions([...duplicateTimestamps, ...ingestionTimestampsRespone ?? []])

    handleIngestionNotifications(data, ingestionTimestamps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    localStorage.setItem('processingIngestion', JSON.stringify(processingIngestion))
  }, [processingIngestion])

  const value = useMemo(() => ({
    state: {
      isIngestionsLoading: isIngestionsLoading || isCombinedDataLoading,
      timestamps: data || [],
      installationData,
      installationId,
      processingIngestion,
      hasIngestionStarted,
      clientIngestions: ingestionTimestamps,
    },
    actions: {
      setProcessingIngestion,
      handleIngestionNotifications,
      handleStartIngestion,
      handleDuplicateIngestion,
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    isIngestionsLoading,
    isCombinedDataLoading,
    data,
    installationData,
    installationId,
    processingIngestion,
    hasIngestionStarted,
    ingestionTimestamps,
  ])

  return (
    <IngestionsContext.Provider value={value}>
      {children}
    </IngestionsContext.Provider>
  )
}
