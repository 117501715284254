import React, { FC, forwardRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Checkbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'

import {
  CheckedIcon as AvaCheckedIcon,
  UncheckedIcon as AvaUncheckedIcon,
  IndeterminateIcon as AvaIndeterminateIcon,
  HoverDiv,
  Container,
} from './styles'

export type CheckboxProps = MuiCheckboxProps

export const CheckedIcon = AvaCheckedIcon
export const UncheckedIcon = AvaUncheckedIcon
export const IndeterminateIcon = AvaIndeterminateIcon

export const BasicCheckbox = forwardRef<HTMLButtonElement, CheckboxProps>(({ disabled, ...props }, ref) => (
  <HoverDiv disabled={disabled}>
    <Checkbox
      {...props}
      ref={ref}
      disabled={disabled}
      style={{ padding: '0' }}
      disableRipple
      checkedIcon={<CheckedIcon disabled={disabled} />}
      icon={<UncheckedIcon />}
      indeterminateIcon={<IndeterminateIcon disabled={disabled} />}
    />
  </HoverDiv>
))

const StyledCheckbox: FC<CheckboxProps> = ({
  name,
  disabled,
  defaultChecked,
  ...props
}) => {
  const {
    control,
  } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked}
      render={({ field }) => (
        <Container>
          <BasicCheckbox
            {...field}
            {...props}
            data-testid={name}
            disabled={disabled}
            onChange={(e) => field.onChange(e.target.checked)}
            checked={typeof field.value === 'boolean' ? field.value : false}
            inputRef={field.ref}
            sx={{ padding: '0' }}
          />
        </Container>
      )}
    />
  )
}
export default StyledCheckbox
