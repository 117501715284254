import React, {
  FC,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  memo,
} from 'react'
import { createRoot } from 'react-dom/client'
import NativeHighcharts from 'highcharts/highstock'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsBorderRadius from 'highcharts-border-radius'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import Box from '@mui/material/Box'
import { useResizeDetector } from 'react-resize-detector'
import deepmerge from '@mui/utils/deepmerge'

import LinearProgress from '../progress/linear'
import loadingIcon from './assets/light-2.gif'
import {
  SearchIcon,
  LeftChevronIcon,
  RightChevronIcon,
  WarningIcon,
} from '../icons'
import Grid from '../grid'
import Typography from '../typography'
import Link from '../link'
import getDefaultOptions from './utils/default-options'
import ThemeProvider, { useTheme } from '../theme-provider'

import ConstantLines from './utils/constant-lines'
import { ConstantsProps, ConstantsApi } from './utils/constant-lines/types'
import withConstantLines from './utils/with-constant-lines'
import withHorizontalLines from './utils/with-horizontal-lines'
import withErrorIcons, { ErrorIconThreshold } from './utils/with-error-icons'
import withGainLossMarkers from './utils/with-gain-loss-markers'
import withZoom from './utils/with-zoom'
import withColumnMarkers from './utils/with-column-markers'
import withDefaultTooltip from './utils/with-default-tooltip'
import { PaginationNoOfTotalLabel } from './styles'
import styled from '../styled'

HighchartsExporting(NativeHighcharts)
HighchartsExportData(NativeHighcharts)
HighchartsBorderRadius(NativeHighcharts)
NoDataToDisplay(NativeHighcharts)
HighchartsMore(NativeHighcharts)

export const Highcharts = NativeHighcharts

export interface ChartProps {
  options?: Highcharts.Options // https://api.highcharts.com/highcharts/
  loading?: boolean
  error?: boolean
  height?: 'flex' | 'auto'
  errorIconThreshold?: ErrorIconThreshold
  gainLoss?: Array<{ value: number, positiveChange: boolean } | null>
  zoomable?: boolean
  onZoom?: (min: number, max: number, zoomStack: Array<[number, number]>) => void
  onApiReady?: (api: Highcharts.Chart) => void
  horizontalLines?: boolean
  paginator?: boolean
  barsPerPage?: number
  index?: number
  constants?: ConstantsProps
  initialSettings?: { zoomStack?: Array<[number, number]> }
  className?: string
  estimate?: number
  initialPage?: number
  previewMode?: boolean
  title?: string
}

interface ChartDataItem {
  name: string
  category?: string
  chartType: string
}

interface SeriesOptions {
  data: ChartDataItem[]
  horizontalLines?: boolean
}

interface ExtendedXAxisOptions extends Highcharts.XAxisOptions {
  categories?: string[]
}

const Chart: FC<ChartProps> = ({
  options,
  loading,
  error,
  height,
  errorIconThreshold,
  gainLoss,
  zoomable,
  onZoom,
  onApiReady,
  paginator,
  barsPerPage,
  horizontalLines,
  index,
  constants,
  initialSettings,
  className,
  estimate,
  initialPage = 1,
  previewMode,
  title,
}) => {
  const theme = useTheme()
  // @ts-ignore
  const highchartsRef = useRef<HighchartsReact.RefObject>()
  const [zoomStack, setZoomStack] = useState<Array<[number, number]>>(initialSettings?.zoomStack || [])
  const [constantsApi, setConstantsApi] = useState<ConstantsApi>(null)

  const onResize = useCallback(() => {
    highchartsRef.current.chart.reflow()
  }, [])
  const { ref: containerRef } = useResizeDetector({ onResize })
  const [currentChartPage, setCurrentChartPage] = useState<number>(initialPage)
  const [noOfTotalChartPages, setNoOfTotalChartPages] = useState<number>(1)
  const [currentChartLabel, setCurrentChartLabel] = useState<string>(null)
  const [chartData, setChartData] = useState<Highcharts.Options>(null)
  const seriesVisibility = useRef<boolean[]>(options?.series?.map(() => true))
  const [showConstants, setShowConstants] = useState<boolean>(false)

  useEffect(() => {
    if (!loading) {
      setCurrentChartPage(1)
    }
  }, [loading])

  useEffect(() => {
    if (loading || error) {
      highchartsRef.current.chart.showLoading(estimate && loading ? 'Loading chart data...' : '')
      const loadingElement = highchartsRef.current.container.current
        .getElementsByClassName('highcharts-loading')[0] as HTMLDivElement
      loadingElement.style.backgroundColor = '#fff7'
      loadingElement.style.opacity = '1'
      if (loading) {
        if (estimate) {
          const linearProgressElement = document.createElement('div')
          linearProgressElement.setAttribute('data-testid', 'loading-icon')
          linearProgressElement.style.marginTop = theme.spacing(2)
          const root = createRoot(linearProgressElement)
          root.render(<ThemeProvider theme="light"><LinearProgress estimate={estimate} /></ThemeProvider>)
          loadingElement.append(linearProgressElement)
          highchartsRef.current.container.current.getElementsByClassName('highcharts-loading-inner')[0]
            .append(linearProgressElement)
        } else {
          const img = document.createElement('img')
          img.src = loadingIcon
          img.width = 75
          img.style.marginTop = theme.spacing(0.625)
          img.setAttribute('data-testid', 'loading-icon')
          loadingElement.append(img)
          highchartsRef.current.container.current.getElementsByClassName('highcharts-loading-inner')[0].append(img)
        }
      } else {
        const warningIconContainer = document.createElement('div')
        const root = createRoot(warningIconContainer)
        root.render(<WarningIcon width={15} sx={{ color: 'error' }} />)

        const errorMessage = document.createElement('div')
        errorMessage.style.fontWeight = '400'
        errorMessage.innerHTML = 'An error has occurred'

        highchartsRef.current.container.current.getElementsByClassName('highcharts-loading-inner')[0]
          .append(warningIconContainer)
        highchartsRef.current.container.current.getElementsByClassName('highcharts-loading-inner')[0]
          .append(errorMessage)
      }
    } else {
      highchartsRef.current.chart.hideLoading()
    }
  }, [loading, error, estimate, theme])

  useEffect(() => {
    if (onApiReady) {
      onApiReady(highchartsRef.current.chart)
    }
  }, [onApiReady])

  useEffect(() => {
    // This code fixes an issue (with the library) where dataLabels overlap with tooltips
    const style = document.createElement('style')
    style.innerHTML = `
      .highcharts-data-label {
        z-index: -1 !important;
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  const fullOptions: Highcharts.Options = useMemo(
    () => {
      const customOptions = withDefaultTooltip(
        withHorizontalLines(
          horizontalLines,
          withColumnMarkers(
            withZoom(
              withGainLossMarkers(
                withErrorIcons(options, errorIconThreshold),
                !paginator ? gainLoss
                  : gainLoss?.slice((currentChartPage * barsPerPage) - barsPerPage, currentChartPage * barsPerPage),
              ),
              zoomable,
              setZoomStack,
              zoomStack,
              onZoom,
            ),
          ),
        ),
        title,
      )

      return deepmerge(
        getDefaultOptions(theme),
        constants
          ? withConstantLines(
            customOptions,
            () => {
              constantsApi?.redraw()
            },
            constants,
          )
          : customOptions,
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      constants, constantsApi, errorIconThreshold, gainLoss, zoomable,
      options, onZoom, zoomStack, horizontalLines, loading, currentChartPage,
      theme,
    ],
  )

  useEffect(() => {
    if (constants && fullOptions?.series.length > 0) {
      setTimeout(() => setShowConstants(true), 1000)
    }
  }, [constants, fullOptions, showConstants])

  /**
   * Effect to adjust the minimum y-axis of line charts to ensure they have
   * enough padding for the Warning icon (where it is shown).
   */
  useEffect(() => {
    const TICK_TO_ALLOW_CHART_TO_INITALISE = 0
    const ICON_HEIGHT = 25

    if (fullOptions) {
      setTimeout(() => {
        if (!highchartsRef.current || !fullOptions.series || fullOptions?.series[0]?.type !== 'line') {
          return
        }

        const { chart } = highchartsRef.current
        const originalExtremes = chart?.yAxis[0]?.getExtremes()
        if (!errorIconThreshold || (originalExtremes.min >= errorIconThreshold.min)) {
          return
        }

        const { min, max, dataMin } = originalExtremes
        const ratio = (max - min) / chart.plotHeight
        const newMin = dataMin - (ratio * ICON_HEIGHT)
        chart.yAxis[0].setExtremes(newMin, max)
      }, TICK_TO_ALLOW_CHART_TO_INITALISE)
    }
  }, [fullOptions, highchartsRef, errorIconThreshold])

  const handlePaginationColumns = () => {
    const seriesOptions: SeriesOptions = fullOptions.series[0] as SeriesOptions
    if (seriesOptions.data) {
      if (seriesOptions.data && Array.isArray(seriesOptions.data)) {
        setChartData({
          ...fullOptions,
          xAxis: {
            ...fullOptions.xAxis,
            categories: (fullOptions.xAxis as ExtendedXAxisOptions)
              .categories.slice((currentChartPage * barsPerPage) - barsPerPage, currentChartPage * barsPerPage),
          },
          series: (fullOptions.series as Array<Highcharts.SeriesColumnOptions>).map((s) => ({
            ...s,
            data: s.data.slice((currentChartPage * barsPerPage) - barsPerPage, currentChartPage * barsPerPage),
          })),
        })
      }
    }
  }

  const handlePaginationStacked = () => {
    const seriesOptions: SeriesOptions = fullOptions.series[0] as SeriesOptions
    if (seriesOptions.data) {
      if (seriesOptions.data && Array.isArray(seriesOptions.data)) {
        const chartTypeCounts = seriesOptions.data.reduce<{ [key: string]: number }>(
          (counts, dataItem) => {
            const { chartType } = dataItem
            counts[chartType] = (counts[chartType] || 0) + 1
            return counts
          },
          {},
        )

        let startIndex = 0
        let endIndex = 0
        let currentPageCount = 0
        let tempChartLabel = ''

        Object.entries(chartTypeCounts).some(([chartType, count]: [string, number]) => {
          const chartTypePages = Math.ceil(count / barsPerPage)

          if (currentChartPage <= currentPageCount + chartTypePages) {
            const currentPageIndex = currentChartPage - currentPageCount - 1
            startIndex = endIndex + currentPageIndex * barsPerPage
            endIndex = Math.min(startIndex + barsPerPage, endIndex + count)
            tempChartLabel = chartType
            return true
          }

          currentPageCount += chartTypePages
          endIndex += count
          return false
        })

        if (tempChartLabel !== 'undefined') {
          setCurrentChartLabel(tempChartLabel)
        }

        const slicedData: { y: number, threshold?: number, chartType?: string, name: string, category?: string }[] = []
        const slicedSeriesData: Highcharts.SeriesColumnOptions[] = []

        fullOptions.series.forEach((series, ind) => {
          if ('data' in series && Array.isArray(series.data)) {
            const slicedSeriesDataItem = {
              ...series,
              data: series.data.slice(startIndex, endIndex).map((
                data: { y: number, threshold?: number, chartType?: string, name: string },
              ) => ({ ...data })),
            }

            if (slicedSeriesDataItem.type === 'column') {
              slicedSeriesData.push({
                visible: seriesVisibility.current?.[ind] ?? true,
                ...slicedSeriesDataItem,
              })
              slicedData.push(...slicedSeriesDataItem.data)
            }
          }
        })

        setChartData({
          ...fullOptions,
          xAxis: {
            ...fullOptions.xAxis,
            categories: slicedData.map((data) => data.category),
          },
          series: slicedSeriesData,
          plotOptions: {
            ...fullOptions.plotOptions,
            series: {
              ...fullOptions.plotOptions.series,
              events: {
                ...fullOptions.plotOptions.series.events,
                legendItemClick(event: Highcharts.SeriesLegendItemClickEventObject) {
                  fullOptions.plotOptions.series.events?.legendItemClick?.call(event)
                  const self = this
                  const visibility: boolean[] = []
                  self.chart.series.forEach((series) => {
                    if (self === series) {
                      visibility.push(!series.visible)
                    } else {
                      visibility.push(series.visible)
                    }
                  })
                  seriesVisibility.current = visibility
                },
              },
            },
          },
        })
      }
    }
  }

  const handlePaginationBoxplot = () => {
    setCurrentChartLabel('')

    const seriesOptions: Highcharts.SeriesBoxplotOptions = fullOptions.series[0] as Highcharts.SeriesBoxplotOptions
    if (seriesOptions.data) {
      if (seriesOptions.data && Array.isArray(seriesOptions.data)) {
        const chartType = 'setpoints'
        const chartTypeCounts = seriesOptions.data.reduce<{ [key: string]: number }>(
          (counts) => {
            counts[chartType] = (counts[chartType] || 0) + 1
            return counts
          },
          {},
        )

        let startIndex = 0
        let endIndex = 0

        Object.entries(chartTypeCounts).forEach(([, count]: [string, number]) => {
          const chartTypePages = Math.ceil(count / barsPerPage)

          if (currentChartPage <= chartTypePages) {
            const currentPageIndex = currentChartPage - 1
            startIndex = endIndex + currentPageIndex * barsPerPage
            endIndex = Math.min(startIndex + barsPerPage, endIndex + count)
          }
        })

        const slicedData: Highcharts.PointOptionsObject[] = []
        const slicedSeriesData: Highcharts.SeriesBoxplotOptions[] = []

        fullOptions.series.forEach((series, ind) => {
          if ('data' in series && Array.isArray(series.data)) {
            const slicedSeriesDataItem = {
              ...series as Highcharts.SeriesBoxplotOptions,
              data: series.data.slice(startIndex, endIndex) as Highcharts.PointOptionsObject[],
            }

            slicedSeriesData.push({
              ...slicedSeriesDataItem,
              visible: seriesVisibility.current?.[ind] ?? true,
            })
            slicedData.push(slicedSeriesDataItem.data)
          }
        })

        const hasNonNullValues = slicedSeriesData.some((series) =>
          series.data.some((rowData: Array<(null | number)>) =>
            rowData.some((value: null | number) => value !== null)))

        const updateChartData = {
          ...fullOptions,
          xAxis: {
            ...fullOptions.xAxis,
            categories: (fullOptions.xAxis as ExtendedXAxisOptions).categories?.slice(startIndex, endIndex),
          },
          yAxis: {
            ...(fullOptions.yAxis as Highcharts.YAxisOptions),
            min: hasNonNullValues ? null : 0,
            max: hasNonNullValues ? null : 10,
          },
          series: slicedSeriesData,
          plotOptions: {
            ...fullOptions.plotOptions,
            series: {
              ...fullOptions.plotOptions.series,
              events: {
                ...fullOptions.plotOptions.series.events,
                legendItemClick(event: Highcharts.SeriesLegendItemClickEventObject) {
                  fullOptions.plotOptions.series.events?.legendItemClick?.call(event)
                  const self = this
                  const visibility: boolean[] = []
                  self.chart.series.forEach((series: NativeHighcharts.Series) => {
                    if (self === series) {
                      visibility.push(!series.visible)
                    } else {
                      visibility.push(series.visible)
                    }
                  })
                  seriesVisibility.current = visibility
                },
              },
            },
          },
        }

        setChartData(updateChartData)
      }
    }
  }

  const handlePagination = useCallback((increment: number) => {
    setCurrentChartPage((prevPage) => prevPage + increment)

    if (fullOptions.series[0].type === 'column') {
      if (fullOptions.plotOptions.column.stacking) {
        handlePaginationStacked()
      } else {
        handlePaginationColumns()
      }
    } else if (fullOptions.series[0].type === 'boxplot') {
      handlePaginationBoxplot()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChartPage, fullOptions, barsPerPage, index])

  useEffect(() => {
    const yAxis = highchartsRef.current.chart.yAxis[0] as Highcharts.Axis & { left: number }
    if (yAxis) {
      highchartsRef.current.chart.legend.update({ x: yAxis.left - 15 })
    }
    if (paginator && fullOptions.series?.length) {
      handlePagination(0)

      const seriesData = (fullOptions.series[0] as SeriesOptions).data
      if (seriesData) {
        if (fullOptions.plotOptions.column.stacking) {
          if (Array.isArray(seriesData)) {
            const uniqueChartTypes = new Set()
            seriesData.forEach((dataItem) => uniqueChartTypes.add(dataItem.chartType ?? 'setpoints'))

            const filteredData = seriesData.filter((dataItem) =>
              uniqueChartTypes.has(dataItem.chartType ?? 'setpoints'))
            const chartTypeCounts: Record<string, number> = {}

            filteredData.forEach((dataItem) => {
              const chartType = dataItem.chartType ?? 'setpoints'
              if (chartType in chartTypeCounts) {
                chartTypeCounts[chartType] += 1
              } else {
                chartTypeCounts[chartType] = 1
              }
            })

            let totalChartPages = 0
            Object.values(chartTypeCounts).forEach((count) => {
              totalChartPages += Math.ceil(count / barsPerPage)
            })

            setNoOfTotalChartPages(totalChartPages || 1)
          }
        } else {
          setNoOfTotalChartPages(Math.ceil(seriesData.length / barsPerPage))
        }
      }
    }
  }, [fullOptions, handlePagination, paginator, barsPerPage])

  return (
    <>
      {!previewMode && zoomable && (
        <Grid container gap={2} alignItems="center" mb={1.875}>
          <Grid container justifyContent="flex-end" width="100%">
            <SearchIcon sx={{ fontSize: '15px', mr: 1, mt: 0.25 }} />
            <Typography style={{ fontSize: '0.75rem' }}>
              Click and drag in the chart to zoom in
            </Typography>
            {zoomStack.length > 0 && (
              <>
                <Link
                  type="button"
                  onClick={() => {
                    highchartsRef.current.chart.zoomOut()
                    onZoom?.(0, 0, [])
                    setZoomStack([])
                  }}
                  sx={{ fontSize: '0.75rem', ml: 1.25 }}
                >
                  Reset graph
                </Link>
                <Link
                  type="button"
                  onClick={() => {
                    if (zoomStack.length > 1) {
                      highchartsRef.current.chart.xAxis[0].setExtremes(...zoomStack[zoomStack.length - 2])
                    } else {
                      highchartsRef.current.chart.zoomOut()
                      onZoom?.(0, 0, [])
                      setZoomStack([])
                    }
                  }}
                  sx={{ fontSize: '0.75rem', ml: 1.25 }}
                >
                  Undo
                </Link>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {!previewMode && paginator && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} data-testid="paginator">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.75rem', fontWeight: '500', mr: 1.25 }}>
              {currentChartLabel}
            </Typography>
            <LeftChevronIcon
              style={{
                fontSize: '10px',
                color: currentChartPage > 1 ? theme.palette.text.default : theme.palette.text.disabled,
                cursor: currentChartPage > 1 ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (!loading && currentChartPage > 1) {
                  handlePagination(-1)
                }
              }}
            />
            <PaginationNoOfTotalLabel>
              {currentChartPage}
              {' '}
              of
              {' '}
              {noOfTotalChartPages}
            </PaginationNoOfTotalLabel>
            <RightChevronIcon
              style={{
                fontSize: '10px',
                color: currentChartPage < noOfTotalChartPages
                  ? theme.palette.text.default : theme.palette.text.disabled,
                cursor: currentChartPage < noOfTotalChartPages ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (!loading && currentChartPage < noOfTotalChartPages) {
                  handlePagination(1)
                }
              }}
            />
          </div>
        </div>
      )}
      <Box
        ref={containerRef}
        data-testid="highchart-container"
        sx={{
          position: 'relative',
          ...(height === 'flex' && { flex: 1, height: 0 }),
          ...(height === 'auto' && { height: '100%' }),
          '& > [data-highcharts-chart]': {
            height: '100%',
          },
        }}
      >
        <>
          {showConstants && highchartsRef?.current && (
            <div style={{ position: 'relative', left: `${highchartsRef.current.chart.container.offsetLeft}px` }}>
              <ConstantLines
                chart={highchartsRef.current.chart}
                maxLines={constants?.maxLines || 4}
                onApiReady={setConstantsApi}
                savedConstantLineModels={constants?.values || []}
                viewId={constants?.viewId || 1}
                forceRender={constants?.forceRender || 1}
                {...constants}
              />
            </div>
          )}
          <HighchartsReact
            id="chart"
            highcharts={NativeHighcharts}
            options={chartData || fullOptions}
            ref={highchartsRef}
            key={currentChartPage}
            containerProps={{ className }}
          />
        </>
      </Box>
    </>
  )
}

const StyleWrapper = styled(Chart)({
  '.highcharts-legend-item path': {
    display: 'none',
  },
})

export default memo(StyleWrapper)
