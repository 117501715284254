import React, { FC } from 'react'
import { TypographyProps as MUITypographyProps } from '@mui/material/Typography'

import { StyledTypography } from './styles'

export interface TypographyProps extends MUITypographyProps {
  disabled?: boolean
  component?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'li'
}

const Typography: FC<TypographyProps> = React.forwardRef<HTMLSpanElement | HTMLParagraphElement, TypographyProps>(({
  children,
  ...props
}, ref) => (
  <StyledTypography ref={ref} {...props}>
    {children}
  </StyledTypography>
))

export default Typography
