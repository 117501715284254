import styled from '@phaidra/ava/styled'

export const DataGridContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'autoHeight',
})<{ autoHeight: boolean }>(({ autoHeight, theme }) => ({
  marginBottom: theme.spacing(2.5),
  ...(!autoHeight && {
    height: '242px',
  }),
}))
